import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Imageview from './components/imageview'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import cheerio from 'cheerio';

import Skeleton from '@material-ui/lab/Skeleton';

import Sidebar from "react-sidebar";
import verified from './Help/verified.png';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import shoptik_icon from './Help/shoptik_icon.png';
 
	const responsive = {
	  desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
		slidesToSlide: 3 // optional, default to 1.
	  },
	  tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
		slidesToSlide: 2 // optional, default to 1.
	  },
	  mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		slidesToSlide: 1 // optional, default to 1.
	  }
};

class Embed extends Component {

	constructor(props){
		super(props);
			//set state(none for now)
			 this.state = { //state is by default an object
				 videos: [],
				 links: [],
				 productstore: [],
				 show: false,
				 current_video: "",
				 feed: true,
				 left_opacity: 1,
				 right_opacity: 0.15,
				 showImg: false,
			}

			this.getClientData = this.getClientData.bind(this);
			this.getProducts = this.getProducts.bind(this);
			this.productClicked = this.productClicked.bind(this);
			this.newestTikToks = this.newestTikToks.bind(this);
			this.testError = this.testError.bind(this);
		}
	
		async newestTikToks(vid_id, client, videos) {

			var new_url = 'https://www.tiktok.com/@' + client + '/video/' + vid_id + '?lang=en'
			var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url
			
			const scrape_data = await fetch(scrape_url)
			const $ = cheerio.load(await scrape_data.text())
			
			var finalurl = JSON.parse($('div #videoObject').html())["contentUrl"];
			
			var newvideos = videos
			newvideos[0]['thumbnail'] = finalurl
			
			this.setState({
				videos: newvideos
			})
		}
	
	
		getClientData(client) {
			//fetch
			fetch('https://shoptik-app.herokuapp.com/' + client, {
				method: "GET",
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
					
						this.setState({
							isLogged: true,
							thumbnail_url: data.data.thumbnail_url,
							following: data.data.following,
							followers: data.data.followers,
							likes: data.data.likes,
							profile_name: data.data.profile_name,
							bio: data.data.bio,
							bio_link: data.data.bio_link,
							bio_string: data.data.bio_string,
							videos: data.data.videos,
							productstore: data.data.store
						})

					} else {
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
						this.setState({
							isLogged: false,
						})
						//redirect them to login page here
				   }
				})
			})

		}
	
		//track_product_clicks
		productClicked(event, link_id, clicks, product_link){
			fetch('https://shoptik-app.herokuapp.com/trackproductclicks?product_id=' + link_id + '&product_clicks=' + clicks, {
					method: "GET",
					credentials: 'include',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
				}).then(response => {
					response.json().then(data =>{
						if (data.status === 400) {
							
						} else if (data.status === 200){
							window.location = product_link;
								var results = data.data;
						}
					})
				})

		}

		componentDidMount(){
			console.log("embedaable");
			var pathArray = window.location.pathname.split('/');
			var client = pathArray[1];

			this.getClientData(client);

		}

		//fetch products with the same link id
		getProducts(event, vid_id, thumbnail, clicks) {
			
			console.log("clicked:");
			
			this.setState({
				links: []
			})
//			this.setState({
//				current_vid: (thumbnail + '?rand=' + Math.random()),
//				show: true,
//			})
			
			fetch('https://shoptik-app.herokuapp.com/products?vid_id=' + vid_id + '&clicks=' + clicks, {
					method: "GET",
					credentials: 'include',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
				}).then(response => {
					response.json().then(data =>{
						if (data.status === 400) {
						
							//show error popup
						} else if (data.status === 200){
							
							if (data.data.links.length != 0){
								
								window.open(data.data.links[0]["product_link"], '_blank');
	
							}
							
//								window.location.href = links[0]["product_links"]
								var results = data.data;
								this.setState({
									links: results.links,
									
								})	
						}
					})
				})
		}

	async testError(tiktok_vid_id, vid_id, index){
		var is_chrome = ((navigator.userAgent.toLowerCase().indexOf('chrome') > -1) &&(navigator.vendor.toLowerCase().indexOf("google") > -1));
		
		var is_chrome_ios = /CriOS/.test(navigator.userAgent);
		
		if (is_chrome){

			return;
		}

		
		var client = this.state.profile_name;
		var videos = this.state.videos;
		
		var new_url = 'https://www.tiktok.com/@' + client + '/video/' + tiktok_vid_id + '?lang=en'
			var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url
		
			const scrape_data = await fetch(scrape_url)
			const $ = cheerio.load(await scrape_data.text())
			
			var finalurl = JSON.parse($('div #videoObject').html())["contentUrl"];

			var newvideos = videos
			newvideos[index]['thumbnail'] = finalurl
			this.setState({
				videos: newvideos
			})
			
			var info = {
				'vid_id': vid_id,
				'video_url': finalurl,
			}
			
			fetch('https://shoptik-app.herokuapp.com/update_video', {
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
//						this.setState({
//							openAlert: true,
//							alertMessage: "Video Successfully Added to Feed"
//						})
					} else {

				   }
				})
			})

	}

	render() {

			let currentComponent = this;
			
			
			var items = []
			var sidebar = []
			
			//get videos
//			const items = this.state.videos.map(function(item, index){
//				return (
//						<img onError={event => (currentComponent.testError(item.tiktok_video_id, item.vid_id, index))} referrerPolicy="no-referrer" src={item.thumbnail} style={{float:"left", width:"33.33%", padding:"1px", objectFit: "cover", height:"180px"}} onClick={event => currentComponent.getProducts(event, item.vid_id, item.thumbnail, item.clicks)} />
//					)	
//    		});
		
			var links = this.state.links.map(function(link, index){
				return (
					<div key={link.link_id} class="img" style={{float:"left", width:"50%", padding:"0px", height:"250px"}}>
						
						<div class="shop_items" >
						
							<div class="tag_num">
							{index+1}	
							</div>

							<a onClick={event => currentComponent.productClicked(event, link.link_id, link.clicks, link.product_link)} >
								
								<img id="shop_img" referrerPolicy="no-referrer" src={link.thumbnail_link} style={{width:"85%", padding:"0px"}}/>
							
								<p id="shop_text">{link.name}<p id="price">{link.price}</p></p>	</a>

						</div>
						
					</div>)
    		});
			//get most recent links
			
			var new_url = this.state.current_vid;
		
			
		
		
			if (true){
				
				var test = ["1", "2"];
				
				console.log(this.state.links);
				
//				onMouseOver={e => (e.currentTarget.src = shoptik_icon)} onMouseOut={e => (e.currentTarget.src = item.thumbnail_link)}
				return(
					<div>
<Carousel
	responsive={responsive}
	infinite={true}
	autoPlay={this.props.deviceType !== "mobile" ? true : false}
  	autoPlaySpeed={5000}
  	deviceType={this.props.deviceType} 
	containerClass="carousel-container" 
	itemClass="carousel-item-padding-40-px" 
	centerMode={true} draggable={true} swipeable={true}>
	
	{this.state.videos.map(function(item, index){
		
		var thumburl = "https://www.tiktok.com/api/img/?itemId=" + item.tiktok_video_id + "&location=1";
		
		if (item.thumbnail_link.includes("trello")){
			thumburl = item.thumbnail_link;	
		}
		
		
				return (
					
						<center>
					<div style={{width: "100%", height: "700px", position: "center"}}>
					
						<a href="#" onClick={event => currentComponent.getProducts(event, item.vid_id, item.thumbnail, item.clicks)} class="image-wrap">
							
							<img src={thumburl} />
							
						<div class="overlay2">
							<img src="https://www.shoptik.co/images/tiktok_white.png" width="20%"></img><br/><br/><br/><br/><br/><br/>
							SHOP THIS LOOK
							
							</div>
						</a>
						
					</div>
						
						</center>
				)
    		})}
	
</Carousel>
						
						<Modal size="lg" show={this.state.show} onHide={() => this.setState({ show: false })}>
						<Modal.Header closeButton style={{borderBottom:"0 none", height: "0px"}}>
                        	<Modal.Title id="contained-modal-title-vcenter" centered />
                        </Modal.Header>
                        <Modal.Body>
                     		<center>
							<video id="mainvid" referrerPolicy="no-referrer" crossOrigin src={new_url} controls muted playsInline autoPlay loop width="70%" ></video>
				
							</center>	
							
							<br />
							
						<div> {links} </div>
                        </Modal.Body>
        			</Modal>
					
						</div>

				)
				
			}

		} 
}

export default Embed