import React, { useState, useEffect, Component } from 'react';
import Feed from './Feed';
import Dashboard from './Dashboard';
import Button from 'react-bootstrap/Button';
import addnew from './add_vid.jpg';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Link, Switch, Redirect, withRouter } from "react-router-dom";
import Sidebar from "react-sidebar";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/Info';
		
class Toprow extends Component {
	constructor(props){
		super(props);
	}
	
	componentDidMount() {
		
    }
	
	render() {
		if (this.props.type == "video"){
			return (
				<img src={this.props.content} width="40px" style={{padding: "0px"}}></img>
			)
		} else {
			return (
				<img src={this.props.content} width="40px" style={{padding: "0px"}}></img>
			)
		}
		
	}
}

export default Toprow

//		 if (this.props.username == "beekeepers_naturals" || this.props.username == "ebay" || this.props.username == "mejuri"){
//			 
//			return(
//			 	<video src={this.props.content} width="40px" style={{padding: "0px"}}></video>
//			)
//		 }
//		else {
//			return (
//				<img src={this.props.content} width="40px" style={{padding: "0px"}}></img>
//			)
//		}