import React, { useState, useEffect, Component } from 'react';
import Feed from './Feed';
import Dashboard from './Dashboard';
import Button from 'react-bootstrap/Button';

import addnew from './add_vid.jpg';

import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Link, Switch, Redirect, withRouter } from "react-router-dom";

import Sidebar from "react-sidebar";

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/Info';
		
		
class Largestbox extends Component {
	
	constructor(props){
		super(props);
		
	}
	
	componentDidMount() {
    }
	
	render() {
		
		return(
			
		<div class="largestbox_container">
				
				<Tooltip style={{marginTop: "-10px", position: "absolute", opacity: "0.1"}} title={this.props.message}>
				  <IconButton id="moreinfo" aria-label={this.props.message}>
					<FilterListIcon />
				  </IconButton>
				</Tooltip>
				<center>
				<img class="backshadow" src={this.props.img} style={{width: "40px", paddingBottom: "10px"}} /><br />
				
			
			<h1>{this.props.bigtitle}</h1>
			<h4>{this.props.subtitle}</h4>
				</center>
		</div>)
	}
}

export default Largestbox