import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import addnew from './add_vid.jpg';
import Sidebar from "react-sidebar";
import NewSidebar from "./NewSidebar";
import shoptik_icon from './Help/shoptik_icon.png';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import Switch from '@material-ui/core/Switch';

import cheerio from 'cheerio';
import NewAlert from './NewAlert';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/Info';

import verified from './Help/verified.png';

class Profile extends Component {
	constructor(props){
		super(props);
		this.state = {
			showProducts: false,
			showEmail: true,
			showEmailAllowed: false,
			email_placeholder: "",
			email_title: ""
		}
		this.loginStatus = this.loginStatus.bind(this);
		this.navigatePage = this.navigatePage.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
		this.updateSettings = this.updateSettings.bind(this);
		this.updateAddons = this.updateAddons.bind(this);
	}
	
	updateAddons(event){
		event.preventDefault();
		
		var info = {
			'email_title': this.state.email_title,
			'email_placeholder': this.state.email_placeholder, 
			'email_addon': this.state.showEmailAllowed,
		}
		
		this.setState({
			showEditProduct: false,
			current_item_id: "",
			openAlert: true,
			alertMessage: "Email Successfully Edited"
		})	
		
		fetch('https://shoptik-app.herokuapp.com/dashboard/updateaddons', {
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
					} else {
				   }
				})
			})
	}
	
	updateSettings(event){
		event.preventDefault();
		//show alert
		var info = {
			'bio': this.state.bio,
			'bio_link': this.state.bio_link, 
			'bio_string': this.state.bio_string,
			'thumbnail_url': this.state.thumbnail_url,
			'client': this.state.current_user,
			'products_first': this.state.showProducts
		}
		
		this.setState({
			showEditProduct: false,
			current_item_id: "",
			openAlert: true,
			alertMessage: "Profile Successfully Edited"
		})	
		
		fetch('https://shoptik-app.herokuapp.com/dashboard/updatesettings', {
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
					} else {
				   }
				})
			})
	}
	
	navigatePage(event, type){
		if (type == "feed"){
			var base_url = window.location.origin;
			window.open((base_url + '/' + this.state.current_user), '_blank');
		} else {
			var base_url = window.location.origin;
		window.location.href = base_url + '/dashboard/' + type;
		}
	}
	
	handleProductChecked(){
		if (this.state.showProducts){
			this.setState({
				showProducts: false
			})
		} else {
			this.setState({
				showProducts: true
			})
		}
	}
	
	handleChecked(){
		if (this.state.showEmailAllowed){
			this.setState({
				showEmailAllowed: false
			})
		} else {
			this.setState({
				showEmailAllowed: true
			})
		}
	}

	componentDidMount(){
		
		const script = document.createElement("script");

		script.src = "https://www.tiktok.com/embed.js";
		script.async = true;

		document.body.appendChild(script);


		this.loginStatus();

	}

	loginStatus(){
		var curr = this;
		//fetch to see if logged in
		fetch('https://shoptik-app.herokuapp.com/dashboard/whoami', {
			method: "GET",
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						
						var current_user = data.data.current_user;
						
						this.getClientData(current_user);
						
						this.setState({
							isLogged: true,
							current_user: data.data.current_user
						})
					} else {
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
						this.setState({
							isLogged: false,
							current_user: ""
						})
				   }
				})
			})
	}
	
	getClientData(client) {
			//fetch
			fetch('https://shoptik-app.herokuapp.com/api/' + client, {
				method: "GET",
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						var creation_date = new Date(data.data.creation_date).toUTCString();
						
						this.setState({
							isLogged: true,
							thumbnail_url: data.data.thumbnail_url,
							following: data.data.following,
							followers: data.data.followers,
							likes: data.data.likes,
							profile_name: data.data.profile_name,
							bio: data.data.bio,
							bio_link: data.data.bio_link,
							bio_string: data.data.bio_string,
							
							showEmailAllowed: data.data.email_addon,
							email_placeholder: data.data.email_placeholder,
							email_title: data.data.email_title,
							
							plan_type: data.data.plan_type,
							creation_date: creation_date,
							
							showProducts: data.data.products_first
							
						})
						
						console.log("thingy", data.data.products_first)

					} else {
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
						this.setState({
							isLogged: false,
						})
						//redirect them to login page here
				   }
				})
			})

		}
	
	showEmailClicked(event){
		var icon = document.getElementById('drop');
		
		if (this.state.showEmail == true){
			this.setState({
				showEmail: false
			})
			icon.className = 'fa fa-caret-down';
		} else {
			this.setState({
				showEmail: true
			})
			icon.className = 'fa fa-caret-down open';
		}
	}
	
	handleSwitch(event){
		if (this.state.switchChecked == false){
		this.setState({
			switchChecked: true
		})} else {
			this.setState({switchChecked: false})
		}
	}

	render() {
		let currentComponent = this;
		
		var drop = "";
			var test = [0];
			var email_input = "";
		
			if (this.state.showEmailAllowed){

				drop = test.map(function(test, index){
				return(
					<span class="email-span">
					<i className="fa fa-envelope-o" style={{marginLeft:"25px"}}></i>
					<i onClick={event => currentComponent.showEmailClicked(event)} className="fa fa-caret-down open" id="drop" style={{fontSize: "17px", marginLeft:"5px"}}> </i>
						</span>
				)
			})
			}
			
		
			if (currentComponent.state.showEmail == true && currentComponent.state.showEmailAllowed){
				email_input = test.map(function(test, index){
				return(
					<center>
						<br />
						<p style={{marginBottom: "5px"}} id="bio">{currentComponent.state.email_title}</p>
						<input style={{width: "250px"}} maxlength="60" required onChange={event => currentComponent.setState({userHashtags: event.target.value})} value={currentComponent.state.userHashtags} id="product_title" name="product_title" type="text" placeholder={currentComponent.state.email_placeholder} />
						
						<Button style={{marginLeft: "5px", padding: "11px", fontSize: "14px"}} type="submit" class="tag_btn" variant="tag_btn"> Send </Button>
					</center>
				)
			})
			}
		
		if (this.state.isLogged){
			return(
				<div>
					
					
					
<NewSidebar selected={1}></NewSidebar>
				
					
					<br /><br /><br />
					<img id="profile_image" onClick={event => this.navigatePage(event, "settings")} src={this.state.thumbnail_url} />
					<div class="main">	
						
						<div style={{width: "50%", float: "right", position: "fixed", right: "0"}}>
							
							<center>
							</center></div>
						<h3>ShopTik Profile</h3>
						<p style={{fontSize: "12px"}} id="no_acc">Note: Your followers, following, and likes are updated hourly</p>
						

						<br />
						<h5>General</h5>
						<br />
						
						<div style={{float: "left", width: "50%"}}>
						
						<form onSubmit={event => this.updateSettings(event)}>
							
						<Tooltip style={{marginLeft: "44%", marginTop: "25px", position: "absolute", opacity: "0.2", zIndex: "1"}} title="This is what your bio will say. Maximum 80 characters">
							  <IconButton id="moreinfo" aria-label={this.props.message}>
								<FilterListIcon />
							  </IconButton>
							</Tooltip>
							
						<span>Bio</span><input maxlength="60" required onChange={event => this.setState({bio: event.target.value})} value={this.state.bio} id="product_title" name="product_title" type="text" placeholder="bio" /> <br /><br /><br />
							
						<Tooltip style={{marginLeft: "44%", marginTop: "25px", position: "absolute", opacity: "0.2", zIndex: "1"}} title="This is the link that your link in bio will redirect to. It will likely be the link to your website/store">
							  <IconButton id="moreinfo" aria-label={this.props.message}>
								<FilterListIcon />
							  </IconButton>
							</Tooltip>
							
						<span>Bio Link</span><input required onChange={event => this.setState({bio_link: event.target.value})} value={this.state.bio_link} id="product_title" name="product_title" type="text" placeholder="bio link (where your bio link goes)" /> <br /><br /><br />
							
							<Tooltip style={{marginLeft: "44%", marginTop: "25px", position: "absolute", opacity: "0.2", zIndex: "1"}} title="This is the clickable text that redirects to the bio link. Maximum 60 characters">
							  <IconButton id="moreinfo" aria-label={this.props.message}>
								<FilterListIcon />
							  </IconButton>
							</Tooltip>
							
						<span>Bio Link String</span><input  maxlength="60" required onChange={event => this.setState({bio_string: event.target.value})} value={this.state.bio_string} id="product_title" name="product_title" type="text" placeholder="bio link text (what your bio link says)" /> <br /><br /><br />
							

							<Tooltip style={{marginLeft: "44%", marginTop: "25px", position: "absolute", opacity: "0.2", zIndex: "1"}} title="This is the link to your profile picture. If you want it to be the same as your tiktok profile, go to your account on the tiktok website. Right click on the profile image, select copy image address, and paste it here">
							  <IconButton id="moreinfo" aria-label={this.props.message}>
								<FilterListIcon />
							  </IconButton>
							</Tooltip>
							
							
						<span>Profile Image Link</span><input required onChange={event => this.setState({thumbnail_url: event.target.value})} value={this.state.thumbnail_url} id="product_title" name="product_title" type="text" placeholder="profile picture link" /> <br /><br /><br />
							
							
						
							<Tooltip style={{marginLeft: "44%", marginTop: "-5px", position: "absolute", opacity: "0.2", zIndex: "1"}} title="Shoptik defaults to the videos page. If you want to default to the products page, turn this switch on">
							  <IconButton id="moreinfo" aria-label={this.props.message}>
								<FilterListIcon />
							  </IconButton>
							</Tooltip>	
							
						<span>Default on Product Page</span>
						<Switch name="checkedA" checked={this.state.showProducts}
            onChange={event => this.handleProductChecked(event)} style={{width: "70%"}} inputProps={{ 'aria-label': 'primary checkbox' }} />
						<br /><br />
					
							
						<center>
							<Button type="submit" class="tag_btn" variant="tag_btn"> Save Changes </Button>
						</center>
							
						</form>
							
							
						<hr />
							
						<br />
							<h5>Add-Ons</h5>
						<br />
							
							
							<form onSubmit={event => this.updateAddons(event)}>

						<span>Show Email Collection Section </span>
						<Switch name="checkedB" checked={this.state.showEmailAllowed}
            onChange={event => this.handleChecked(event)} style={{width: "70%"}} inputProps={{ 'aria-label': 'primary checkbox' }} />
						<br /><br />
						
								
						<Tooltip style={{marginLeft: "44%", marginTop: "25px", position: "absolute", opacity: "0.2", zIndex: "1"}} title="This is what the text above the email collection form would say. For example: Leave your email for cool coupons!">
							  <IconButton id="moreinfo" aria-label={this.props.message}>
								<FilterListIcon />
							  </IconButton>
							</Tooltip>
							
						<span>Email Collection Title</span><input maxlength="60" required onChange={event => this.setState({email_title: event.target.value})} value={this.state.email_title} id="email_title" name="product_title" type="text" placeholder="leave your email for new coupons!" /> <br /><br /><br />
								
								
						<Tooltip style={{marginLeft: "44%", marginTop: "25px", position: "absolute", opacity: "0.2", zIndex: "1"}} title="This is the placeholder in the email collection input. For example: email address. ">
							  <IconButton id="moreinfo" aria-label={this.props.message}>
								<FilterListIcon />
							  </IconButton>
							</Tooltip>
							
						<span>Email Collection PlaceHolder</span><input maxlength="60" required onChange={event => this.setState({email_placeholder: event.target.value})} value={this.state.email_placeholder} id="email_placeholder" name="product_title" type="text" placeholder="email address" /> <br /><br /><br />
								
									
						<center>
							<Button type="submit" class="tag_btn" variant="tag_btn"> Save Changes </Button>
						</center>
								
							</form>
							<br /><br /><br /><br /><br /><br />
							
						</div>

					</div>
						
						 <Snackbar open={this.state.openAlert} autoHideDuration={3000} onClose={()=>{this.setState({openAlert: false, alertMessage: ""})}}>
						
					<MuiAlert elevation={6} variant="filled">{this.state.alertMessage}</MuiAlert>
				 </Snackbar>
						
				
				</div>)
			
		} else {
			return(<p></p>)
		}
	}
}

export default Profile

						//<img id="profile_image" src={this.state.thumbnail_url} />
	
//						<Switch
//							checked={this.state.is_checked}
//							onChange={() => this.handleChecked()}
//							name="checkedA"
//							inputProps={{ 'aria-label': 'secondary checkbox' }}
//						  />
//						