import React, { useState, useEffect, Component } from 'react';
import Feed from './Feed';
import Dashboard from './Dashboard';
import Button from 'react-bootstrap/Button';

import addnew from './add_vid.jpg';

import CompetitorsTable from "./CompetitorsTable"

import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Link, Redirect, withRouter } from "react-router-dom";

import Switch from '@material-ui/core/Switch';

import Sidebar from "react-sidebar";
import NewSidebar from "./NewSidebar";
import Largestbox from "./Largestbox";
import Largebox from "./Largebox";
import Comparebox from "./Comparebox";
import Smallbox from "./Smallbox";
import Select from 'react-select';
import Table from "./Table"

import EmailTable from "./EmailTable";
import ProductsTable from "./ProductsTable"
import shoptik_icon from './Help/shoptik_icon.png';

import { CSVLink } from 'react-csv';
import cheerio from 'cheerio';

import Chart from 'react-google-charts';
import { LineOptions } from './charts';

import GraphboxComps from "./graphboxComps";
import Ranktable from "./Ranktable";

import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/Info';
import Avatar from '@material-ui/core/Avatar';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import { follower_graph, engagement_graph, likes_graph, comments_graph } from './charts';

let Options = [
  { label: "posted in the last day", value: 1 },
  { label: "posted in the last week", value: 7 },
  { label: "posted in the last month", value: 30 },
  { label: "posted in the last 3 months", value: 90 },
  { label: "posted in the last 6 months", value: 182 },
  { label: "all time", value: 1000 },
];

class Competitors extends Component {
	constructor(props){
		super(props);
		this.state = {
			api_key: "9c363ae588mshd71440a101292d4p1c4061jsn32d687f5b44f",
			
			isLogged: false,
			placeholder:"all time",
			
			total_videos: 0,
			total_products: 0,
			
			shop_clicks_total: 0,
			video_clicks_total: 0,
			product_clicks_total: 0,
			video_clicks_average: 0,
			product_clicks_average: 0,
			conversion: 0,
			
			videos: [],
			rows: [],
			product_rows: [],
			email_rows: [],
			product_message: "",
			
			
			originalfollowingCount: 0,
			originalfollowerCount: 0,
			originalheartCount: 0,
			originalvideoCount: 0,
			originalaverageLikes: 0,
			originalfollowerRatio:0,
//			recent_posts: 1,
			originalcommentCount: 0,
			originallikeCount: 0,
			originalshareCount: 0,
			originalcommentAvg: 0,
			originallikeAvg: 0,
			originalshareAvg: 0,
			
			followingCount: 0,
			followerCount: 0,
			heartCount: 0,
			videoCount: 0,
			averageLikes: 0,
			followerRatio:0,
			commentCount: 0,
			likeCount: 0,
			shareCount: 0,
			commentAvg: 0,
			likeAvg: 0,
			shareAvg: 0,
			
			allLikes: [],
			allComments: [],
			allShares: [],
			allVideos: [],
			recentVideos: [],
			
			allLikesNum : [],
			allSharesNum : [],
			allCommentsNum : [],
			
			showVideo: false,
			
			current_video:"",
			isloading: "",
			thumbnail_url: "",
			
			engagement_average: 0,

			competitors_posts: [],
			competitors_likes_per_posts: [],
			competitors_followers: [],
			
			followers_row: [],
			likes_per_post_row: [],
			posts_row:[],
			competitors: [],
			userMention: "",
			showNewCompetitor: false,
			openAlert: false,
			alertMessage: "",
			openAlert2: false,
			alertMessage2: "",
			global_competitors: [{"name": "","profile_name": "", "followers": "", "posts": "", "likes": "", "following": "", "color": "#fff", "allLikes": [], "allComments": [], "allShares": [], "allLikesNum": new Array(40).fill(0), "allCommentsNum": [], "allSharesNum": [], "commentCount": 0, "likeCount": 0, "shareCount": 0, "commentAvg": "", "likeAvg": "", "shareAvg": "" }, {"name": "","profile_name": "", "followers": "", "posts": "", "likes": "", "following": "", "color": "#fff", "allLikes": [], "allComments": [], "allShares": [], "allLikesNum": new Array(40).fill(0), "allCommentsNum": [], "allSharesNum": [], "commentCount": 0, "likeCount": 0, "shareCount": 0, "commentAvg": "", "likeAvg": "", "shareAvg": "" }, {"name": "","profile_name": "", "followers": "", "posts": "", "likes": "", "following": "", "color": "#fff", "allLikes": [], "allComments": [], "allShares": [], "allLikesNum": new Array(40).fill(0), "allCommentsNum": [], "allSharesNum": [], "commentCount": 0, "likeCount": 0, "shareCount": 0, "commentAvg": "", "likeAvg": "", "shareAvg": "" }],
			
			competitors_len: 0
		}
		
		this.navigatePage = this.navigatePage.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.updateData = this.updateData.bind(this);
		this.kFormatter = this.kFormatter.bind(this);
		
		this.videoClicked = this.videoClicked.bind(this);
		this.navigatePage = this.navigatePage.bind(this);
		
		this.getCompetitors = this.getCompetitors.bind(this);
		this.sortByIndex = this.sortByIndex.bind(this);
		
		this.searchUser = this.searchUser.bind(this);
		
		this.removeComp = this.removeComp.bind(this);
	
	}
	
		getPlan(client){
		fetch('https://shoptik-app.herokuapp.com/api/' + client, {
				method: "GET",
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						var creation_date = new Date(data.data.creation_date).toUTCString();

						var plan = data.data.plan_type;
						var is_active = data.data.is_active;
						
						if (is_active == true && plan == "analytics_monthly"){
							this.setState({
								showUpgradeModal2: true
							})
						}
						else if (is_active == true && plan == "standard_monthly"){
							this.setState({
								showUpgradeModal2: true
							})
						}

					} else {
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
						this.setState({
							isLogged: false,
						})
						//redirect them to login page here
				   }
				})
			})
	}

	
	async removeComp(comp, index){

		var info = {
				'tiktok_name': this.state.current_user,
				'competitor_name': comp,
				'objectId': this.state.competitor_ids[index],
			}
		
		
			fetch('https://shoptik-app.herokuapp.com/delete_competitor', {
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					
					if (data.status === 200){
						this.setState({
							alertMessage: "@" + comp + " successfully removed",
							openAlert: true,
							competitors: [],
//							showNewCompetitor: false
						}, function() {
							this.componentDidMount();
//							this.updateData(this.state.profile_name, "1000");
						})
					} else {

				   }
				})
			})
	}
	
	async searchUser(event){
		event.preventDefault();
		
		
		if (this.state.competitors.length >= 3){
			this.setState({
				alertMessage2: "please remove a competitor before adding more",
				openAlert2: true,
			})
			return;
		}
		var client = document.getElementById("current_competitor").value.replace("@", "");
		
		//lets try to get info for this competitor
		fetch("https://social-media-data-tt.p.rapidapi.com/live/user?username=" + client + "&fresh=1", {
	"method": "GET",
	"headers": {
		"x-rapidapi-key": this.state.api_key,
		"x-rapidapi-host": "social-media-data-tt.p.rapidapi.com"
	}
})
.then(response => {
	console.log(response);
			
			if (response.status == 400){
					this.setState({
					alertMessage2: "@" + client + " not found",
					openAlert2: true,
					showNewCompetitor: false
				})
			} else {
							var info = {
				'tiktok_name': this.state.current_user,
				'competitor_name': client,
			}
	
			fetch('https://shoptik-app.herokuapp.com/add_competitor', {
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						this.setState({
							alertMessage: "@" + client + " successfully added to competitors",
							openAlert: true,
							showNewCompetitor: false,
							competitors: [],
						}, function() {
							this.componentDidMount()
						})
					} else {
						this.setState({
							alertMessage2: "there was an error, please try again later",
							openAlert2: true,
							showNewCompetitor: false,
							competitors: [],
						})
				   }
				})
			})
			}
			
})
.catch(err => {
			this.setState({
				alertMessage2: "@" + client + " not found",
				openAlert2: true,
				showNewCompetitor: false
			})
});
		return;
		
		
		
		
		
		//check if they have tiktok
		//fetch tiktok, see if it contains string userData
		var new_url = 'https://www.tiktok.com/@' + client + '?lang=en'
		var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url;
		const scrape_data = await fetch(scrape_url);
		var all_text = await scrape_data.text();
		
		console.log("ALL TEXT: ", all_text);
		
		if (all_text.includes("shareMeta")){
			//go store this, upon result show this
			var info = {
				'tiktok_name': this.state.current_user,
				'competitor_name': client,
			}
	
			fetch('https://shoptik-app.herokuapp.com/add_competitor', {
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						this.setState({
							alertMessage: "@" + client + " successfully added to competitors",
							openAlert: true,
							showNewCompetitor: false,
							competitors: [],
						}, function() {
							this.componentDidMount()
						})
					} else {
						this.setState({
							alertMessage2: "there was an error, please try again later",
							openAlert2: true,
							showNewCompetitor: false,
							competitors: [],
						})
				   }
				})
			})
//			this.setState({
//				alertMessage: "@" + client + " successfully added to competitors",
//				openAlert: true,
//				showNewCompetitor: false
//			}, function() {
//				this.updateData(this.state.profile_name, "1000");
//			})
		} else {
			this.setState({
				alertMessage2: "@" + client + " not found",
				openAlert2: true,
				showNewCompetitor: false
			})
		}
		
	}
	
	sortByIndex(a, b) {
		if (a[2] === b[2]) {
			return 0;
		} else {
			return (a[2] > b[2]) ? 1 : -1;
		}
	}
	
	async getCompetitors(competitors){
		//scrape data for competitors
		var competitors_posts = [];
		var competitors_followers = [];
		var competitors_likes_per_post = [];
		
		var global_competitors = [{"name": "","profile_name": "", "followers": "", "posts": "", "likes": "", "following": "", "color": "#fff", "allLikes": [], "allComments": [], "allShares": [], "allLikesNum": new Array(40).fill(0), "allCommentsNum": [], "allSharesNum": [], "commentCount": 0, "likeCount": 0, "shareCount": 0, "commentAvg": "", "likeAvg": "", "shareAvg": "" }, {"name": "","profile_name": "", "followers": "", "posts": "", "likes": "", "following": "", "color": "#fff", "allLikes": [], "allComments": [], "allShares": [], "allLikesNum": new Array(40).fill(0), "allCommentsNum": [], "allSharesNum": [], "commentCount": 0, "likeCount": 0, "shareCount": 0, "commentAvg": "", "likeAvg": "", "shareAvg": "" }, {"name": "","profile_name": "", "followers": "", "posts": "", "likes": "", "following": "", "color": "#fff", "allLikes": [], "allComments": [], "allShares": [], "allLikesNum": new Array(40).fill(0), "allCommentsNum": [], "allSharesNum": [], "commentCount": 0, "likeCount": 0, "shareCount": 0, "commentAvg": "", "likeAvg": "", "shareAvg": "" }]
		
		//var global_competitors = this.state.global_competitors;
		for (var p=0;p<competitors.length;p++){
			var current_user = competitors[p];
			
					var followingCount = 1;
			var followerCount = 1;
			var heartCount = 1;
			var videoCount = 1;
			var averageLikes = 1;
			var followerRatio = 1;
			
		let newresp = await fetch("https://social-media-data-tt.p.rapidapi.com/live/user?username=" + current_user + "&fresh=1", {
			"method": "GET",
			"headers": {
				"x-rapidapi-key": this.state.api_key,
				"x-rapidapi-host": "social-media-data-tt.p.rapidapi.com"
			}
		})
		let newdata = await newresp.json();
			followingCount = newdata.following_count;
				followerCount = newdata.follower_count;
				heartCount = newdata.total_favorited;
				videoCount = newdata.video_count;
				
				var averageLikes = Math.round(heartCount / videoCount);
				var followerRatio = Math.round(followingCount / followerCount);

		
		//lets try to scrape from our other api
//		fetch("https://tiktok.p.rapidapi.com/live/user?username=" + current_user + "&fresh=1", {
//			"method": "GET",
//			"headers": {
//				"x-rapidapi-key": "865f499c17mshb07873cd88baaa1p116340jsneb0033f90f8e",
//				"x-rapidapi-host": "tiktok.p.rapidapi.com"
//			}
//		})
//		.then(response => {
//			response.json().then(data =>{
//				followingCount = data.following_count;
//				followerCount = data.follower_count;
//				heartCount = data.total_favorited;
//				videoCount = data.video_count;
//				
//				var averageLikes = Math.round(heartCount / videoCount);
//				var followerRatio = Math.round(followingCount / followerCount);
//
////				this.setState({
////					followingCount: followingCount,
////					followerCount: followerCount,
////					heartCount: heartCount,
////					videoCount: videoCount,
////					averageLikes: averageLikes,
////					followerRatio: followerRatio,
////					
////				})
//
//			})
//		})
//		.catch(err => {
//			console.error("ERRRRR", err);
//		});			
//			var manual_link = 'https://www.tiktok.com/@' + current_user + '?lang=en'
//			var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + manual_link;
//			const scrape_data = await fetch(scrape_url)
//			var all_text = await scrape_data.text();
//			var key3 = "stats\":";
//			var key4 = "shareMeta\"";
//			var original = all_text.match(new RegExp(key3 + "(.*)" + key4))[1].replace(/['",]+/g, '').slice(1,-1);
//			var sub_values = original.match(/\d+/g).map(Number);
//
//			var followingCount = sub_values[0];
//			var followerCount = sub_values[1];
//			var videoCount = sub_values[3];
//			var heartCount = Math.round(sub_values[2]/videoCount);

//			var color = "#" + Math.floor(Math.random()*16777215).toString(16);
			var color = '#'+(0x1000000+(Math.random())*0xffffff).toString(16).substr(1,6);
			
			competitors_followers.push({"profile_name": current_user, "num": followerCount, "color": color});
			competitors_posts.push({"profile_name": current_user, "num": videoCount, "color": color});
			competitors_likes_per_post.push({"profile_name": current_user, "num": heartCount, "color": color});
				
			
		const new_scrape_data = await fetch('https://mysterious-reef-90754.herokuapp.com/https://urlebird.com/user/' + current_user + '/');
		const $ = cheerio.load(await new_scrape_data.text())
		var allVideos = [];
		var vids = $('.thumb .img img');
		$('.thumb .img img').each(function(i, elem) {
			if (i <= 4){
				allVideos[i] = $(this).attr("src");
			} else {
				allVideos[i] = $(this).attr("data-src");
			}
		});

		const allLikes = [];
		const allComments = [];
		const allShares = [];
		const allLikesNum = [];
		var allCommentsNum = [];
		const allSharesNum = [];
	
		var counter = 0
		$('span').each(function(i, elem) {
			var classname = $(this).attr("class");
			if (classname === undefined){
				
				if (counter == 0){
					var newnum = $(this).text();
					allLikes.push(newnum);
					if (newnum.includes("K")){
						allLikesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000);
					} else if (newnum.includes("M")){
						allLikesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000000);
					} else {
						allLikesNum.push(parseInt(newnum));
					}
				} else if (counter == 1){
					var newnum = $(this).text();
					allComments.push(newnum);
					if (newnum.includes("K")){
						allCommentsNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000)
					} else if (newnum.includes("M")){
						allCommentsNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000000)
					} else {
						allCommentsNum.push(parseInt(newnum));
					}
				} else {
					var newnum = $(this).text();
					allShares.push(newnum);
					if (newnum.includes("K")){
						allSharesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000)
					} else if (newnum.includes("M")){
						allSharesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000000)
					} else {
						allSharesNum.push(parseInt(newnum));
					}
				}
				counter = (counter + 1)%3
			}
		});
			
			allCommentsNum = allCommentsNum.filter(function (value) {
    			return !Number.isNaN(value);
			});
			
		var commentCount = allCommentsNum.reduce(function(a, b){
        	return a + b;
    	}, 0);
		var likeCount = allLikesNum.reduce(function(a, b){
        	return a + b;
    	}, 0);
		var shareCount = allSharesNum.reduce(function(a, b){
        	return a + b;
    	}, 0);
		
		var denom = commentCount.length;
		var commentAvg = Math.round(commentCount/allCommentsNum.length);
		var likeAvg = Math.round(likeCount/allLikesNum.length);
		var shareAvg = Math.round(shareCount/allSharesNum.length);
		
			global_competitors[p] = ({"name": competitors[p],"profile_name": current_user, "followers": followerCount, "posts": videoCount, "likes": heartCount, "following": followingCount, "color": color, "allLikes": allLikes, "allComments": allComments, "allShares": allShares, "allLikesNum": allLikesNum, "allCommentsNum": allCommentsNum, "allSharesNum": allSharesNum, "commentCount": commentCount, "likeCount": likeCount, "shareCount": shareCount, "commentAvg": commentAvg, "likeAvg": likeAvg, "shareAvg": shareAvg })

		}
			
		
		
		// sort each array and perhaps update pic too
//		competitors_followers.sort(this.sortByIndex);
//		competitors_likes_per_post.sort(this.sortByIndex);
//		competitors_posts.sort(this.sortByIndex);
		this.setState({
			followers_row: competitors_followers,
			likes_per_post_row: competitors_likes_per_post,
			posts_row: competitors_posts,
			global_competitors: global_competitors,
			isloading: "none"
		})
							
	}


	navigatePage(event, type){
		var base_url = window.location.origin;
		window.location.href = base_url + '/dashboard/' + type;
	}
	
	//DEPRECATED FUNCTION. DO NOT USE
	async videoClicked(event, index){
//		var current_id = this.state.allIds[index];
//		var video_url = "https://www.tiktok.com/@" + this.state.current_user + '/video/' + current_id + '?lang=en';
//		
//		var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + video_url
//			
//		const scrape_data = await fetch(scrape_url)
//
//		var all_text = await scrape_data.text();
//
//		var key3 = "urls\":";
//		var key4 = "videoMeta\"";
//
//		var fin_url = all_text.match(new RegExp(key3 + "(.*)" + key4));
//		var finalurl = fin_url[1].replace(/['",]+/g, '').slice(1,-1);
//
//		var comments = this.state.allCommentsNum[index];
//		var likes = this.state.allLikesNum[index];
//		var shares = this.state.allSharesNum[index];
//		var engagement = comments + likes + shares;
//		var engagement_rate = Math.round((engagement / this.state.followerCount) * 100);
//		
//		var post_score = Math.round((likes - this.state.likeAvg) / this.state.likeAvg * 100);
//		var rate_info = "better than avg post";
//		
//		if (post_score < 0){
//			post_score = post_score * -1;
//			rate_info = "worse than avg post";
//		}
//		
//		this.setState({
//			showVideo: true,
//			current_video: finalurl,
//			
//			current_comments: this.kFormatter(comments, 1),
//			current_likes: this.kFormatter(likes, 1),
//			current_shares: this.kFormatter(shares, 1),
//			current_engagement: this.kFormatter(engagement, 2),
//			current_engagement_rate: engagement_rate,
//			current_post_score: post_score,
//			rate_info: rate_info
//			
//		})
//		
	}
	
	
	handleChange(e) {
        this.updateData(this.state.current_user, e.value, this.state.api_key);
    }
	
	navigatePage(event, type){
		if (type == "feed"){
			var base_url = window.location.origin;
			window.open((base_url + '/' + this.state.current_user), '_blank');
		} else {
			var base_url = window.location.origin;
		window.location.href = base_url + '/dashboard/' + type;
		}
	}
	
//	createData(name, calories, fat, carbs, newone, protein) {
//  		return { name, calories, fat, carbs, newone, protein };
//	}
	
//	createProductData(name, thumb, calories, fat, carbs, newone, protein) {
//  		return { name, thumb, calories, fat, carbs, newone, protein };
//	}
//	
//	createEmailData(email_address, date, timestamp) {
//  		return { email_address, date, timestamp};
//	}
	
	kFormatter(num, digits) {

  var si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "k" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "B" },
    { value: 1E12, symbol: "T" },
    { value: 1E15, symbol: "P" },
    { value: 1E18, symbol: "E" }
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

	
	async updateData(current_user, time_period, api_key){
		//scrape stuff from tiktok and urlbird
		
		var followingCount = 1;
		var followerCount = 1;
		var heartCount = 1;
		var videoCount = 1;
		var averageLikes = 1;
		var followerRatio = 1;
		
		//lets try to scrape from our other api
		fetch("https://social-media-data-tt.p.rapidapi.com/live/user?username=" + current_user + "&fresh=1", {
			"method": "GET",
			"headers": {
				"x-rapidapi-key": api_key,
				"x-rapidapi-host": "social-media-data-tt.p.rapidapi.com"
			}
		})
		.then(response => {
			response.json().then(data =>{
				followingCount = data.following_count;
				followerCount = data.follower_count;
				heartCount = data.total_favorited;
				videoCount = data.video_count;
				
				var averageLikes = Math.round(heartCount / videoCount);
				var followerRatio = Math.round(followingCount / followerCount);

				this.setState({
					followingCount: followingCount,
					followerCount: followerCount,
					heartCount: heartCount,
					videoCount: videoCount,
					averageLikes: averageLikes,
					followerRatio: followerRatio,
					
				})
				
				if (this.state.originalfollowerRatio == 0){
					this.setState({
						originalaverageLikes: averageLikes,
						originalfollowerRatio: followerRatio,
					})
				}
			})
		})
		.catch(err => {
			console.error("ERRRRR", err);
		});

//		var manual_link = 'https://www.tiktok.com/@' + current_user + '?lang=en'
//		var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + manual_link;
//		const scrape_data = await fetch(scrape_url)
//		var all_text = await scrape_data.text();
//		var key3 = "stats\":";
//		var key4 = "shareMeta\"";
//		var original = all_text.match(new RegExp(key3 + "(.*)" + key4))[1].replace(/['",]+/g, '').slice(1,-1);;
//		var sub_values = original.match(/\d+/g).map(Number);
//		var followingCount = sub_values[0];
//		var followerCount = sub_values[1];
//		var heartCount = sub_values[2];
//		var videoCount = sub_values[3];
//		
//		var averageLikes = Math.round(heartCount / videoCount);
//		var followerRatio = Math.round(followingCount / followerCount);
//		
//		this.setState({
//			followingCount: followingCount,
//			followerCount: followerCount,
//			heartCount: heartCount,
//			videoCount: videoCount,
//			averageLikes: averageLikes,
//			followerRatio: followerRatio,
//		})
//		if (this.state.originalfollowerRatio == 0){
//			this.setState({
//				originalaverageLikes: averageLikes,
//				originalfollowerRatio: followerRatio,
//			})
//		}
		
		//SCRAPING URLEBIRD VALUES (may need to convert to integers)
		const new_scrape_data = await fetch('https://mysterious-reef-90754.herokuapp.com/https://urlebird.com/user/' + current_user + '/');
		
		const $ = cheerio.load(await new_scrape_data.text())
	
		var allVideos = [];
		
		var vids = $('.thumb .img img');
		$('.thumb .img img').each(function(i, elem) {
			if (i <= 4){
				allVideos[i] = $(this).attr("src");
			} else {
				allVideos[i] = $(this).attr("data-src");
			}
		});

		const allLikes = [];
		const allComments = [];
		const allShares = [];
		
		const allLikesNum = [];
		var allCommentsNum = [];
		const allSharesNum = [];
	
		var counter = 0
		$('span').each(function(i, elem) {
			
			var classname = $(this).attr("class");
			if (classname === undefined){
				
				if (counter == 0){
					var newnum = $(this).text();
					allLikes.push(newnum);
					if (newnum.includes("K")){
						allLikesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000);
					} else if (newnum.includes("M")){
						allLikesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000000);
					} else {
						allLikesNum.push(parseInt(newnum));
					}
				} else if (counter == 1){
					var newnum = $(this).text();
					allComments.push(newnum);
					if (newnum.includes("K")){
						allCommentsNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000)
					} else if (newnum.includes("M")){
						allCommentsNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000000)
					} else {
						allCommentsNum.push(parseInt(newnum));
					}
				} else {
					var newnum = $(this).text();
					allShares.push(newnum);
					if (newnum.includes("K")){
						allSharesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000)
					} else if (newnum.includes("M")){
						allSharesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000000)
					} else {
						allSharesNum.push(parseInt(newnum));
					}
				}
				counter = (counter + 1)%3
			}
		});
		allCommentsNum = allCommentsNum.filter(function (value) {
    			return !Number.isNaN(value);
			});
		
//		https://urlebird.com/video/
		const allHrefs = [];
		const allIds = [];
		
		var hrefs = $('a');

		$('a').each(function(i, elem) {
			
			var link = $(this).attr("href");
			if (link.includes("https://urlebird.com/video/")){
				allHrefs.push(link);
							  
				var new_id = [];
//				var name = "John Smith".split("");
				
				for(var i = link.length-2; i >= 0; i--) {
					
					if (/^\d+$/.test(link[i])){
						new_id.push(link[i]);
					} else {
						break;
					}
				}
				allIds.push(new_id.reverse().join(''));
			}
		});
		
		// CHANGE THIS BACK!
//		var new_all_videos = [];
//		for (var k=0; k < allHrefs.length; k++){
//			var new_url = allHrefs[k];
//			var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url
//
//			const scrape_data = await fetch(scrape_url)
//			const $ = cheerio.load(await scrape_data.text())
//			var final_url = $('video').attr("src");
//
//			new_all_videos.push(final_url);
//		}
//		allVideos = new_all_videos;
		this.setState({
			recentVideos : allVideos.slice(0, 4),
			allVideos : allVideos,
			allLikes : allLikes,
			allShares : allShares,
			allComments : allComments,
			
			allLikesNum : allLikesNum,
			allSharesNum : allSharesNum,
			allCommentsNum : allCommentsNum,
			
			allHrefs : allHrefs,
			allIds: allIds,
		})

		var commentCount = allCommentsNum.reduce(function(a, b){
        	return a + b;
    	}, 0);
		var likeCount = allLikesNum.reduce(function(a, b){
        	return a + b;
    	}, 0);
		var shareCount = allSharesNum.reduce(function(a, b){
        	return a + b;
    	}, 0);
		
		var denom = commentCount.length;
		
		var commentAvg = Math.round(commentCount/allCommentsNum.length);
		var likeAvg = Math.round(likeCount/allLikesNum.length);
		var shareAvg = Math.round(shareCount/allSharesNum.length);
		
		this.setState({
			commentCount: commentCount,
			likeCount: likeCount,
			shareCount: shareCount,
			
			commentAvg: commentAvg,
			likeAvg: likeAvg,
			shareAvg: shareAvg,
		})
		
		
		//lets calculate general rows
		var info = {
			'tiktok_name': this.state.current_user,
			'following': followingCount,
			'followers': followerCount,
			'likes': heartCount,
			'videos': videoCount,
			'recent_comments': commentCount,
			'recent_likes': likeCount,
			'recent_shares': shareCount,
			'recent_posts': allLikes.length
		}

		await fetch('https://shoptik-app.herokuapp.com/dashboard/overview', {
			method: "POST",
			credentials: 'include',
			body: JSON.stringify(info),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						data = data.data;

						if (data.message == "original_entry"){
							this.setState({
								originalfollowingCount: followingCount,
								originalfollowerCount: followerCount,
								originalheartCount: heartCount,
								originalvideoCount: videoCount,
								
								originalcommentCount: commentCount,
								originallikeCount: likeCount,
								originalshareCount: shareCount,
								originalcommentAvg: commentAvg,

								originalshareAvg: shareAvg,
								
								creation_date: data.creation_date,
								competitors: [],
								competitors_len: 0
								
							})
							
							

						} else {
							var averageLikes = Math.round(data.likes / data.videos);
							var followerRatio = Math.round(data.following / data.followers);
							
							var commentAvg = Math.round(data.recent_comments/data.recent_posts);
							var likeAvg = Math.round(data.recent_likes/data.recent_posts);
							var shareAvg = Math.round(data.recent_shares/data.recent_posts);

						
							
;							
							this.setState({
								originalfollowingCount: data.following,
								originalfollowerCount: data.followers,
								originalheartCount: data.likes,
								originalvideoCount: data.videos,
								originalaverageLikes: averageLikes,
								originalfollowerRatio: followerRatio,
								originalcommentCount: data.recent_comments,
								originallikeCount: data.recent_likes,
								originalshareCount: data.recent_shares,
								originalcommentAvg: commentAvg,
								originallikeAvg: likeAvg,
								originalshareAvg: shareAvg,
								
								creation_date: data.creation_date,
								competitors: data.competitors,
								competitor_ids: data.competitor_ids,
								
								competitors_len: data.competitors.length
							})
							
							this.getCompetitors(data.competitors);
							
							
							//console.log("creation date: ", data.creation_date)
						}

					} else {
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
						this.setState({
							isLogged: false,
							current_user: ""
						})
				   }
				})
			})

		//for each likes, create array of increasing like sums and plot those, x axis is just posts
		
		
		
//		var competitors = this.state.competitors;
//		
//		//scrape data for competitors
//		var competitors_posts = [];
//		var competitors_followers = [];
//		var competitors_likes_per_post = [];
//		
//		for (var p=0;p<competitors.length;p++){
//			var current_user = competitors[p];
//			var manual_link = 'https://www.tiktok.com/@' + current_user + '?lang=en'
//			var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + manual_link;
//			const scrape_data = await fetch(scrape_url)
//			var all_text = await scrape_data.text();
//
//			var key3 = "stats\":";
//			var key4 = "userData\"";
//
//			var original = all_text.match(new RegExp(key3 + "(.*)" + key4))[1].replace(/['",]+/g, '').slice(1,-1);;
//			//console.log(original);
//
//			var sub_values = original.match(/\d+/g).map(Number);
//			//console.log(sub_values);
//
//			var followingCount = sub_values[0];
//			var followerCount = sub_values[1];
//			var heartCount = sub_values[2];
//			var videoCount = sub_values[3];
//			
//			competitors_followers.push({"profile_image": "img1", "profile_name": current_user, "num": followerCount});
//			competitors_posts.push({"profile_image": "img1", "profile_name": current_user, "num": videoCount});
//			competitors_likes_per_post.push({"profile_image": "img1", "profile_name": current_user, "num": heartCount});
//			
//		}
//		this.setState({
//			followers_row: competitors_followers,
//			likes_per_post_row: competitors_likes_per_post,
//			posts_row: competitors_posts
//		})
//		
//		this.setState({
//			competitors_posts: [],
//			competitors_likes_per_post: [],
//			competitors_followers: []
//		})
//		
//		this.setState({
//			competitors_followers: competitors_followers,
//			competitors_posts: competitors_posts,
//			competitors_likes_per_post: competitors_likes_per_post
//		})

//		var averageLikes = Math.round(heartCount / videoCount);
//		var followerRatio = Math.round(followingCount / followerCount);
			
		return;
	}
	
			attemptNewUpgrade(event, plan){
		event.preventDefault();
		this.setState({
			showUpgradeModal1: false,
			showUpgradeModal2: false
		})

		var tiktok_handle = this.state.current_user;
		
		//send post to api that will send us an email
		var info = {
			'tiktok_handle': tiktok_handle,
			'plan': plan
		}
		//fetch new route to handle payment
		fetch('https://shoptik-app.herokuapp.com/attemptnewupgrade',{
			method: "POST",
			body: JSON.stringify(info),
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		}).then(response => {
			response.json().then(async data =>{
				if (data.status === 400) {
					this.setState({
					})
				} else if (data.status === 200){
					//refresh window
					this.updateData(this.state.current_user, "1000", this.state.api_key);
//					this.getClientData(this.state.current_user);
					this.setState({
						showUpgradeModal: false,
						openAlert: true,
						alertMessage: "Successfully Upgraded Plan!"
					})
					//show footer thing
				}
			})
		})
	}
	
	
	loginStatus(){
		var curr = this;
		//fetch to see if logged in
		fetch('https://shoptik-app.herokuapp.com/dashboard/whoami', {
			method: "GET",
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						var current_user = data.data.current_user;
						
						var current_user = data.data.current_user;
						this.getPlan(current_user);
//						var api_key = data.data.api_key;
						this.updateData(data.data.current_user, "1000", this.state.api_key);
				
						this.setState({
							isLogged: true,
							current_user: data.data.current_user,
							thumbnail_url: data.data.thumbnail_url,
//							api_key: api_key
						})

					} else {
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
						this.setState({
							isLogged: false,
							current_user: "",
						})
				   }
				})
			})
	}
	
	componentDidMount() {
		this.loginStatus();
    }
	
	createData(name, you, competitor_1, competitor_2, competitor_3) {
  		return { name, you, competitor_1, competitor_2, competitor_3};
	}
	
	render() {
		if (this.state.isLogged){
			
			if (this.state.isloading == "none" && this.state.competitors_len == 0){

				return (
					
					<div class="main_container">
			<div>
				
						
    	<NewSidebar selected={7}></NewSidebar>
				
				<br /><br /><br />
				<div class="main">
<Modal size="lg" class="widemodal2" id="testy" backdrop="static" keyboard={false} show={this.state.showUpgradeModal2} onHide={() => this.setState({ showUpgradeModal2: false })}>
                        <Modal.Body>
							<center>
							<h3 style={{color:"white", marginTop:"40px"}}><b> Automatically upgrade your plan<br /> now to Track Competitors</b></h3><br/>
							<p style={{color:"white", fontSize:"14px", fontWeight:"100"}}><b> You're already set up, we'll automatically upgrade you to the plan you select</b></p>
							<br />
								<center>
    <div class="prices">

        
        <div class="pricingplans" >
        <div class="priceingbox" style={{marginTop:"20px"}} >
            <h1 class="pricetitle">Professional</h1>
            <hr class="price-hr" />
            <span id="underline"></span>
            <br />
            <h1 id="p3" class="price">$199</h1><span class="pricetext">&nbsp;/ month</span>
            <p id="underpricetext">For large brands and companies</p>
            <br />
            <button id="standard_monthly" class="paybtn2" onClick={event => this.attemptNewUpgrade(event, "premium_monthly")}>SELECT</button>
            <br /><br /><br />
			
			
			<img src="https://www.shoptik.co/images/krisicons/shoppable feed.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px", marginLeft: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/Analytics.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/Schedule.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/right influencer.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/listening.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
        </div>
    </div>
        </div>
   </center>
								

			<br />
							<p style={{fontSize: "10px", color: "#fff"}}>*By continuing, your plan will automatically be upgraded to the one you select, and your next bill will be updated to reflect this change. </p>
							</center>
                        </Modal.Body>
        			</Modal>
					
					
									<Snackbar open={this.state.openAlert} autoHideDuration={3000} onClose={()=>{this.setState({openAlert: false, alertMessage: ""})}}>
						
					<MuiAlert elevation={6} variant="filled">{this.state.alertMessage}</MuiAlert>
				 </Snackbar>
				
				 <Snackbar open={this.state.openAlert2} autoHideDuration={5000} onClose={()=>{this.setState({openAlert2: false, alertMessage2: ""})}}>
						
					<MuiAlert elevation={6} variant="filled" severity="error">{this.state.alertMessage2}</MuiAlert>
				 </Snackbar>
					
					
					
					<center>
					<div class="rankbox_container" style={{width: "96%"}}>
						<br /><br />
						<p class="feature_description_text">Track your competitors and compare <br /> your TikTok performance</p><br /><br /><br />
						
						
						<div style={{float: "left", width: "44%", textAlign: "left", marginLeft: "5%", marginBottom: "50px"}}>
						<h5 style={{paddingBottom:"10px"}}>Wondering how your competitors perform? <br /> Track these insights: </h5> 
						
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> Number of posts, followers, and likes per post</p>
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> In-depth likes and comments history</p>
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> Average likes/post, comments/post, shares/post</p>
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> 30 day growth history</p>
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> Rankings among competitors</p><br />
							
							<h5 style={{paddingBottom:"10px"}}>Our overview helps you benchmark against competitors with:</h5> 
						
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> Exportable comparative table</p>
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> Sort and Rank competitors by all mentioned metrics</p>
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> Quick adding/removal of competitors</p>
							<br /><br />
							
							
							
							<Button style={{padding: "10px 20px"}} class="tag_btn start_btn" variant="tag_btn"  onClick={event => this.setState({showNewCompetitor: true, competitors_len: 1})} > Add your first competitor </Button>
						</div>
						<div style={{width: "44%", float: "right", marginRight: "5%"}}>
							<center>
							
							<img width="120%" 
 src="https://trello-attachments.s3.amazonaws.com/5f0caadc2f38561e0724eb3a/5f516bd673f5432c83a7f108/024dcaf5158399832bac70c13489a4be/BEST_COMPETITORS_(2).png" style={{marginTop:"0px"}}></img>
						
							</center>
						</div>
						
						
					</div>
						</center>
					

		<br /><br />

				
				</div></div></div>
			
				)
				
			}
			
			let currentComponent = this;
			
			let likes = currentComponent.state.allLikes;
			let comments = currentComponent.state.allComments;
			let shares = currentComponent.state.allShares;

			var tiktoklinks = this.state.recentVideos.map(function(link, index){
				return (
					<div key={index} class="img" style={{float:"left", width:"260px"}}>
						
						<div class="active_vid_container" style={{textAlignLast:"center"}}>

							
							<img id="active_vids" referrerPolicy="no-referrer" src={link} style={{objectFit: "cover", width: "230px"}} onClick={event => currentComponent.videoClicked(event, index)}/>
							
							<p id="shop_text" style={{marginLeft:"0%"}}>
								<p id="video_stats">
									💬 &nbsp; {comments[index]}&nbsp; &nbsp;
									❤️ &nbsp; {likes[index]}&nbsp; &nbsp;
									📤 &nbsp; {shares[index]}
								</p>
							</p>
						</div>
					</div>)
    		});
			
			var creation_date = new Date(this.state.creation_date).toUTCString();

			

			var follower_columns = [
			  {
				type: "string",
				label: "year"
			  },
			  {
				label: "Followers",
				type: "number"
			  }
			];
			
			var engagement_columns = [
			  {
				type: "string",
				label: "post"
			  },
			  {
				label: "Actions",
				type: "number"
			  }
			];

			var comments_columns = [
			  {
				type: "string",
				label: "post"
			  },
			  {
				label: "Comments",
				type: "number"
			  }
			];

			
			var testyrows = [[2015, 2], [2016, 3], [2018, 6], [2019, 8], [2020, 14]];
			//calculate rows for follower growth, engagement rate, likes history, comments history
			//also collect the date
			var creation_date = new Date(this.state.creation_date).toUTCString().split(' ').slice(0, 4).join(' ');;
			var today = new Date().toUTCString().split(' ').slice(0, 4).join(' ');;
			var follower_rows = [[creation_date, this.state.originalfollowerCount], [today, this.state.followerCount]]
			
			
			var likes_rows = [];
			var sum = 0;
			for (var i=0; i<this.state.allLikesNum.length; i++){
				sum += this.state.allLikesNum[i];
				var current = [i, sum];
				likes_rows.push(current);
			}
			
			var comments_rows = [];
			var sum = 0;
			for (var i=0; i<this.state.allCommentsNum.length; i++){
				sum += this.state.allCommentsNum[i];
				var current = [i, sum];
				comments_rows.push(current);
			}
			
			var engagement_rows = [];
			var engagementSum = 0;
			for (var i=0; i<this.state.allCommentsNum.length; i++){
				engagementSum += this.state.allCommentsNum[i] + this.state.allLikesNum[i] + this.state.allSharesNum[i];
				var current = [i, engagementSum];
				engagement_rows.push(current);
			}
			
			//calculate engagement average
			var engagement_average = 0;
			if (this.state.followers != 0){
				if (this.state.allCommentsNum != 0){
					engagement_average = Math.round(engagementSum/this.state.allCommentsNum.length/this.state.followerCount);
				}
			}
			
			//calculate likes before half and after half
			var posts = this.state.allCommentsNum.length;
			var mid = Math.round(posts/2);
			
			var firstlikes = 0;
			var secondlikes = 0;
			for (var i=0; i<this.state.allCommentsNum.length; i++){
				if (i < mid){
					firstlikes += this.state.allLikesNum[i];
				} else {
					secondlikes += this.state.allLikesNum[i];
				}
			}
			var average_likes = 0;
			if (firstlikes != 0){
				average_likes = Math.round((secondlikes - firstlikes)/firstlikes);
			}
			var follower_evolution = 0;
			var follower_evolution_percentage = 0;
			
			if (this.state.originalfollowerCount != 0){
				follower_evolution = (this.state.followerCount - this.state.originalfollowerCount);
				follower_evolution_percentage = (((this.state.followerCount - this.state.originalfollowerCount)/this.state.originalfollowerCount) * 100);
			}
//			var engagementSum = 0;
//			for (var i=0; i<this.state.allCommentsNum.length; i++){
//				engagementSum += this.state.allCommentsNum[i] + this.state.allLikesNum[i] + this.state.allSharesNum[i];
//			}
			var newrows = [];
			
			var finalcomps = this.state.global_competitors;
			
			newrows.push(this.createData("Profile", this.state.current_user,finalcomps[0].profile_name, finalcomps[1].profile_name, finalcomps[2].profile_name));
			newrows.push(this.createData("Followers", this.state.followerCount, finalcomps[0].followers, finalcomps[1].followers, finalcomps[2].followers));
			newrows.push(this.createData("Average Likes/Post",this.state.likeAvg, finalcomps[0].likeAvg, finalcomps[1].likeAvg, finalcomps[2].likeAvg));
			newrows.push(this.createData("Average Comments/Post", this.state.commentAvg, finalcomps[0].commentAvg, finalcomps[1].commentAvg, finalcomps[2].commentAvg));
			newrows.push(this.createData("Average Shares/Post", this.state.shareAvg,finalcomps[0].shareAvg, finalcomps[1].shareAvg, finalcomps[2].shareAvg));
			newrows.push(this.createData("Total Posts", this.state.originalvideoCount, finalcomps[0].posts, finalcomps[1].posts, finalcomps[2].posts));

//			var currentComponent = this;
			//list of competitors for potential removal
			var msg = "Type in the exact tiktok profile name of a competitor";
			if (this.state.competitors.length >= 3){
				msg = "You can only track up to 3 competitors. Please remove one if you want to track more."
			}
			
			var comps = this.state.competitors.map(function(comp, index){
				return (
					<div><hr /> {comp} <span><button onClick={() => currentComponent.removeComp(comp, index)} class="xanalytics"><i class="fa fa-times-circle" aria-hidden="true"></i></button></span></div>
				)
			})

			var graphcomps = [];
			for (var p=0;p<this.state.allLikesNum.length; p++){
				graphcomps.push([this.state.allLikesNum[p], finalcomps[0].allLikesNum[p], finalcomps[1].allLikesNum[p], finalcomps[2].allLikesNum[p]])
			}
			var sum = 0;
			var sum1 = 0;
			var sum2 = 0;
			var sum3 = 0;
			var final_rows_graph = [];
//			console.log("given array", given_array[0].length)
			for (var i=0; i<this.state.allLikesNum.length; i++){
				sum += graphcomps[i][0];
				sum1 += graphcomps[i][1];
				sum2 += graphcomps[i][2];
				sum3 += graphcomps[i][3];
				
				var current = [i, sum, sum1, sum2, sum3];
				final_rows_graph.push(current);
			}
			
			
			var graphcomps2 = [];
			for (var p=0;p<this.state.allCommentsNum.length; p++){
				graphcomps2.push([this.state.allCommentsNum[p], finalcomps[0].allCommentsNum[p], finalcomps[1].allCommentsNum[p], finalcomps[2].allCommentsNum[p]])
			}
			var sum = 0;
			var sum1 = 0;
			var sum2 = 0;
			var sum3 = 0;
			var final_rows_graph2 = [];
//			console.log("given array", given_array[0].length)
			for (var i=0; i<this.state.allCommentsNum.length; i++){
				sum += graphcomps2[i][0];
				sum1 += graphcomps2[i][1];
				sum2 += graphcomps2[i][2];
				sum3 += graphcomps2[i][3];
				
				var current = [i, sum, sum1, sum2, sum3];
				final_rows_graph2.push(current);
			}
			
			var likes_columns = [{
				type: "string",
				label: "post"
			  },
			  {
				label: this.state.current_user,
				type: "number"
			  },
			  {
				label: this.state.global_competitors[0].name,
				type: "number"
			  },
			  {
				label: this.state.global_competitors[1].name,
				type: "number"
			  },
			  {
				label: this.state.global_competitors[2].name,
				type: "number"
			  }]


			
			
			
		return (
			<div class="main_container">
			<div>
				

    	<NewSidebar selected={7}></NewSidebar>
			
				
					<br /><br /><br />
<span class="newloader" style={{display: this.state.isloading}}></span>
	<img id="profile_image" onClick={event => this.navigatePage(event, "settings")} src={this.state.thumbnail_url} />
	<div class="main">
		
		
		
							<Modal centered size="m" show={this.state.showNewCompetitor} onHide={() => this.setState({ showNewCompetitor: false })}>
						<Modal.Header closeButton style={{borderBottom:"0 none", height: "0px"}}>
							
                        	<Modal.Title id="contained-modal-title-vcenter">
								<center>
							<p style={{marginLeft:"auto", marginRight:"auto", display:"block"}}>Add New Competitor</p>
									</center>
							</Modal.Title>
								
                        </Modal.Header>
                        <Modal.Body>
							<br /><br />
								
								
								<form onSubmit={event => this.searchUser(event)}>
									<center>
								<span>{msg}</span><br /><br />
										<input style={{width: "80%"}} maxlength="60" required id="current_competitor" name="product_title" type="text" placeholder="ie. fashionnova (don't include the @)" />
										
										<span><button class="plus" style={{marginLeft: "10px", width:"40px", height:"40px",borderStyle:"dashed",borderRadius:"100%",borderColor:"lightgrey",backgroundColor:"transparent",color:"#2c88ff",borderWidth:"1px",display:"inline-block",verticalAlign:"baseline"}}><center><img src="https://trello-attachments.s3.amazonaws.com/5f0caadc2f38561e0724eb3a/5f57de4b78930230867ec4d1/6611378912dda0bf52bc797637a194d0/plus2.png" width="100%"></img></center></button></span>
										
								
										
										<br /><br />

	</center>
								</form><br /><br />
							{comps}
						<br />
                        </Modal.Body>
        			</Modal>
		

			<h3 style={{display:"inline-block"}}>Competitive Analysis</h3>
		<button onClick={event => currentComponent.setState({showNewCompetitor: true})} class="plus" style={{marginLeft: "10px", width:"40px", height:"40px",borderStyle:"dashed",borderRadius:"100%",borderColor:"lightgrey",backgroundColor:"transparent",color:"#2c88ff",borderWidth:"1px",display:"inline-block",verticalAlign:"baseline"}}>
			<center><img src="https://trello-attachments.s3.amazonaws.com/5f0caadc2f38561e0724eb3a/5f57de4b78930230867ec4d1/6611378912dda0bf52bc797637a194d0/plus2.png" width="100%"></img></center></button>
		<p style={{fontSize: "12px"}}>Track the performance of 3 of your competitors</p>

		<br /><br />
		<h5>Overview</h5><p style={{fontSize: "12px"}}>Primarily based on the last 30 days</p><hr />

		<Ranktable rows={this.state.posts_row} width="31%" real_image={this.state.thumbnail_url} title="Total Posts" description="This is the total amount of posts " main_symbol="%" current_user={this.state.current_user} user_value={this.state.originalvideoCount}/>
		
		<Ranktable rows={this.state.followers_row} width="31%" real_image={this.state.thumbnail_url} title="Followers" description="This is the total amount of followers" main_symbol="%" current_user={this.state.current_user} user_value={this.state.originalfollowerCount}/>
		
		<Ranktable rows={this.state.likes_per_post_row} width="31%" real_image={this.state.thumbnail_url} title="Likes per Post" description="This is the average amount of likes on a post" main_symbol="%" current_user={this.state.current_user} user_value={this.state.likeAvg}/>
		<br />
		
		
		
		<br /><br />
		<h5>History charts</h5><p style={{fontSize: "12px"}}>Based on the last 30 days</p><hr />
		
		<GraphboxComps color1={this.state.global_competitors[0].color} color2={this.state.global_competitors[1].color} color3={this.state.global_competitors[2].color} rows={final_rows_graph} graphtitle="Likes" columns={likes_columns} type="likes_graph" width="46.5%" bigtitle={this.state.followerRatio + "%"} title="Total Likes History"  message="This is the amount of likes your recent posts have received, by post" />
		
		
		<GraphboxComps color1={this.state.global_competitors[0].color} color2={this.state.global_competitors[1].color} color3={this.state.global_competitors[2].color} rows={final_rows_graph2} graphtitle="Comments" columns={likes_columns} type="likes_graph" width="46.5%" bigtitle={this.state.followerRatio + "%"} title="Total Comments History"  message="This is the amount of comments your recent posts have received, by post" />
		
		
		<br /><br /><br /><br />
		<h5>Comparative Table</h5><p style={{fontSize: "12px"}}>Based on the last 30 days</p><hr />
		
		<div style={{display: "inline-block", width: "100%"}}>
		<br />
		<CompetitorsTable new_title="Competitors Table" username={this.state.current_user} total_videos={8} rows={newrows} class="table_shadow" ></CompetitorsTable><br />
			<p style={{fontSize: "12px"}}>*Sort posts by clicking any column title</p>
		</div>
		
		
		
	<br /><br /><br /><br />
		
					</div>
				
				<Snackbar open={this.state.openAlert} autoHideDuration={3000} onClose={()=>{this.setState({openAlert: false, alertMessage: ""})}}>
						
					<MuiAlert elevation={6} variant="filled">{this.state.alertMessage}</MuiAlert>
				 </Snackbar>
				
				 <Snackbar open={this.state.openAlert2} autoHideDuration={5000} onClose={()=>{this.setState({openAlert2: false, alertMessage2: ""})}}>
						
					<MuiAlert elevation={6} variant="filled" severity="error">{this.state.alertMessage2}</MuiAlert>
				 </Snackbar>
				
			</div>
			</div>

			)
		} else {
			return ( <div></div>
			)
		}

	}
}

export default withRouter(Competitors)
	