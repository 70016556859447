import React, { useState, useEffect, Component } from 'react';
import Feed from './Feed';
import Dashboard from './Dashboard';
import Button from 'react-bootstrap/Button';

import addnew from './add_vid.jpg';

import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Link, Switch, Redirect, withRouter } from "react-router-dom";

import Sidebar from "react-sidebar";

import NewSidebar from "./NewSidebar";

import Largestbox from "./Largestbox";
import Largebox from "./Largebox";
import Smallbox from "./Smallbox";
import Select from 'react-select';
import VideosTable from "./VideosTable"

import EmailTable from "./EmailTable";
import ProductsTable from "./ProductsTable"
import shoptik_icon from './Help/shoptik_icon.png';

import { CSVLink } from 'react-csv';
import cheerio from 'cheerio';

import Chart from 'react-google-charts';
import { LineOptions } from './charts';

import Graphbox from "./Graphbox";

import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import verified from './Help/verified.png';


import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/Info';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

let Options = [
	{ label: "order by least to most expensive", value: 0 },
  	{ label: "order by most to least expensive", value: 1 },
	{ label: "order by most fans to least fans", value: 2 },
  	{ label: "order by least fans to most fans", value: 3 },
];

class Influencers extends Component {
	constructor(props){
		super(props);
		this.state = {
			isLogged: false,
			placeholder:"order influencers",
			
			total_videos: 0,
			total_products: 0,
			
			shop_clicks_total: 0,
			video_clicks_total: 0,
			product_clicks_total: 0,
			video_clicks_average: 0,
			product_clicks_average: 0,
			conversion: 0,
			
			videos: [],
			product_message: "",
			
			originalfollowingCount: 560,
			originalfollowerCount: 7550,
			originalheartCount: 4560,
			originalvideoCount: 450,
			originalaverageLikes: 450,
			originalfollowerRatio:540,
			originalcommentCount: 540,
			originallikeCount: 450,
			originalshareCount: 450,
			originalcommentAvg: 450,
			originallikeAvg: 540,
			originalshareAvg: 540,
			
			followingCount: 0,
			followerCount: 0,
			heartCount: 0,
			videoCount: 0,
			averageLikes: 0,
			followerRatio:0,	
			
			commentCount: 0,
			likeCount: 0,
			shareCount: 0,
			commentAvg: 0,
			likeAvg: 0,
			shareAvg: 0,
			
			allLikes: [],
			allComments: [],
			allShares: [],
			allVideos: [],
			recentVideos: [],
			
			showVideo: false,
			
			current_video:"",
			
			big_tuple: [],
			
			rows: [],
			thumbnail_url: "",
			
			influencers: []
			
		}
		
		this.navigatePage = this.navigatePage.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.updateData = this.updateData.bind(this);
		this.kFormatter = this.kFormatter.bind(this);
		
		this.videoClicked = this.videoClicked.bind(this);
		this.navigatePage = this.navigatePage.bind(this);
		
		this.sortByLikes = this.sortByLikes.bind(this);
		this.sortByShares = this.sortByShares.bind(this);
		this.sortByComments = this.sortByComments.bind(this);
		
		this.generateReport = this.generateReport.bind(this);
		this.showForm = this.showForm.bind(this);
		this.submitForm = this.submitForm.bind(this);
		this.sortByLikes = this.sortByLikes.bind(this);
	}
	
	submitForm(event){
		event.preventDefault();
		
		var business_name = this.state.current_user;
		var business_email = this.state.business_email;
		var influencer_tiktok = this.state.influencer_tiktok;
		var business_budget = this.state.budget;
		var details = this.state.details;
		
		var info = {
			'business_name': business_name,
			'business_email': business_email,
			'influencer_tiktok': influencer_tiktok,
			'business_budget': business_budget,
			'details': details
		}
		
		//fetch new route to handle payment
		fetch('https://shoptik-app.herokuapp.com/dashboard/requestinfluencer',{
			method: "POST",
			body: JSON.stringify(info),
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		}).then(response => {
			response.json().then(async data =>{
				if (data.status === 400) {
					this.setState({
					})
				} else if (data.status === 200){
					//refresh window
//					this.getClientData(this.state.current_user);
					this.setState({
						showFormModal: false,
						openAlert: true,
						alertMessage: "Offer Sent! You will be notified within 3 business days if it is approved."
					})
					//show footer thing
				}
			})
		})
	}
	
	showForm(tiktok, name, thumb, price_range){
		this.setState({
			showVideo: false,
			showFormModal: true
		})
	}
	
	generateReport(event){
		event.preventDefault();
		const input = document.getElementById('main');
		html2canvas(input).then((canvas) => {
			const imgData = canvas.toDataURL('image/png');
			const pdf = new jsPDF({
			  orientation: "landscape"
			});
			pdf.addImage(imgData, 'PNG', 0, 0);
			pdf.save("download.pdf");  
		  });
	}


	createData(name, calories, fat, carbs, newone, protein) {
  		return { name, calories, fat, carbs, newone, protein };
	}
	
	navigatePage(event, type){
		var base_url = window.location.origin;
		window.location.href = base_url + '/dashboard/' + type;
	}
	
	async videoClicked(event, index, thumbnail, name, tiktok_name, low_price, high_price){
		console.log("video cliked")
		
		var followingCount = 1
				var followerCount = 1
				var heartCount = 1
				var videoCount = 1
				
				var averageLikes = 1
				var followerRatio = 1
				var engagementRate = 1
		
		
		fetch("https://social-media-data-tt.p.rapidapi.com/live/user?username=" + tiktok_name + "&fresh=1", {
			"method": "GET",
			"headers": {
				"x-rapidapi-host": "social-media-data-tt.p.rapidapi.com",
				"x-rapidapi-key": "9c363ae588mshd71440a101292d4p1c4061jsn32d687f5b44f"
			}
		})
		.then(response => {
			response.json().then(data =>{
				console.log("data:", data)
				
				followingCount = data.following_count;
				followerCount = data.follower_count;
				heartCount = data.total_favorited;
				videoCount = data.video_count;
				
				averageLikes = Math.round(heartCount / videoCount);
				followerRatio = Math.round(followingCount / followerCount);
				engagementRate = Math.round(averageLikes/followerCount);
				
				this.setState({
					engagementRate: engagementRate,
			followingCount: followingCount,
			followerCount: followerCount,
			heartCount: heartCount,
			videoCount: videoCount,
			averageLikes: averageLikes,
			followerRatio: followerRatio})
				
			})
//			console.log(response.getJSON);
			
		})
		.catch(err => {
			console.error(err);
		});
		
		
		
		
		
		
//		
//		let newresp = await fetch("https://social-media-data-tt.p.rapidapi.com/live/user?username=" + tiktok_name + "&fresh=1", {
//			"method": "GET",
//			"headers": {
//				"x-rapidapi-key": "9c363ae588mshd71440a101292d4p1c4061jsn32d687f5b44f",
//				"x-rapidapi-host": "https://social-media-data-tt.p.rapidapi.com"
//			}
//		})
//		let newdata = await newresp.json();
//		var followingCount = newdata.following_count;
//				var followerCount = newdata.follower_count;
//				var heartCount = newdata.total_favorited;
//				var videoCount = newdata.video_count;
//				
//				var averageLikes = Math.round(heartCount / videoCount);
//				var followerRatio = Math.round(followingCount / followerCount);
//		
//		
//		console.log("NEWDATA:", newdata);
		
		
		
		
		
		
		
		
//		var manual_link = 'https://www.tiktok.com/@' + tiktok_name + '?lang=en'
//		var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + manual_link;
//		const scrapedata = await fetch(scrape_url)
//		var all_text = await scrapedata.text();
//		
//		//scrape urlebird most recent vid for the influencer, first scrape profile, then vid, or maybe all vids
		const new_scrape_data = await fetch('https://mysterious-reef-90754.herokuapp.com/https://urlebird.com/user/' + tiktok_name + '/');
		const $ = cheerio.load(await new_scrape_data.text())
		const allHrefs = [];
		const allIds = [];
		var hrefs = $('a');
		$('a').each(function(i, elem) {
			var link = $(this).attr("href");
			if (link.includes("https://urlebird.com/video/")){
				allHrefs.push(link);			  
				var new_id = [];
				for(var i = link.length-2; i >= 0; i--) {
					if (/^\d+$/.test(link[i])){
						new_id.push(link[i]);
					} else {
						break;
					}
				}
				allIds.push(new_id.reverse().join(''));
			}
		});
		
//		//now scrape each video url from each href
		var new_all_videos = [];
		var allVideos = [];
		//allHrefs.length
		for (var k=0; k < 1; k++){
			var new_url = allHrefs[k];
			var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url

			const scrape_data = await fetch(scrape_url)
			const $ = cheerio.load(await scrape_data.text())
			var final_url = $('video').attr("src");

			new_all_videos.push(final_url);
		}
		allVideos = new_all_videos;
//		
//
//		var key3 = "stats\":";
//		var key4 = "userData\"";
//		
//		var original = all_text.match(new RegExp(key3 + "(.*)" + key4))[1].replace(/['",]+/g, '').slice(1,-1);;
//		console.log(original);
//		
//		var sub_values = original.match(/\d+/g).map(Number);
//		console.log(sub_values);
//		
//		var followingCount = sub_values[0];
//		var followerCount = sub_values[1];
//		var heartCount = sub_values[2];
//		var videoCount = sub_values[3];
//		
//		var averageLikes = (heartCount / videoCount);
//		var followerRatio = (followingCount / followerCount);
		
		var engagementRate = (heartCount / followerCount / 100);
		
		var price_range = ("$" + this.kFormatter(low_price) + " - " + ("$" + this.kFormatter(high_price)))
		
		this.setState({

			influencer_name: name,
			influencer_thumbnail: thumbnail,
			showVideo: true,
			price_range: price_range,
			influencer_tiktok: tiktok_name,
			current_video:allVideos[0]
		})


		//perhaps fetch some data from tiktok about this influencer here
		
		
		return;
		
		
		var current_id = this.state.big_tuple[index][5];
		var video_url = "https://www.tiktok.com/@" + this.state.current_user + '/video/' + current_id + '?lang=en';
		
		var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + video_url
			
		const scrape_data = await fetch(scrape_url)

		var all_text = await scrape_data.text();

		var key3 = "urls\":";
		var key4 = "videoMeta\"";

		var fin_url = all_text.match(new RegExp(key3 + "(.*)" + key4));
		var finalurl = fin_url[1].replace(/['",]+/g, '').slice(1,-1);
		finalurl = finalurl.replace('-web','');
		
		
		var comments = this.state.big_tuple[index][3];
		var likes = this.state.big_tuple[index][1];
		var shares = this.state.big_tuple[index][2];
		
		var engagement = comments + likes + shares;
		var engagement_rate = Math.round((engagement / this.state.followerCount) * 100);
		
		var post_score = Math.round((likes - this.state.likeAvg) / this.state.likeAvg * 100);
		var rate_info = "better than avg post";
		
		if (post_score < 0){
			post_score = post_score * -1;
			rate_info = "worse than avg post";
		}

		this.setState({
			showVideo: true,
			current_video: finalurl,
			
			current_comments: this.kFormatter(comments, 1),
			current_likes: this.kFormatter(likes, 1),
			current_shares: this.kFormatter(shares, 1),
			current_engagement: this.kFormatter(engagement, 2),
			current_engagement_rate: engagement_rate,
			current_post_score: post_score,
			rate_info: rate_info,
			
		})
		
	}

  
	sortByLikes(a, b) {
		if (a.followers === b.followers) {
			return 0;
		} else {
			return (a.followers < b.followers) ? 1 : -1;
		}
	}

	handleChange(e) {
//		alert(e.value)
		//re-sort influencers
		var type = e.value;
		var big_tuple = this.state.influencers;

		if (type == 1){
			big_tuple.sort(function(a,b) {
    			return b.low_price - a.low_price
			});
		}
		else if (type == 0){
			big_tuple.sort(function(a,b) {
    			return a.low_price - b.low_price
			});
		} else if (type == 3){
			big_tuple.sort(function(a,b) {
    			return a.followers - b.followers
			});
		} else if (type == 2){
			big_tuple.sort(function(a,b) {
    			return b.followers - a.followers
			});
		}
//		
		console.log("after tuple: ", big_tuple);
		
		this.setState({
			influencers: big_tuple
		})
		
//        this.updateData(this.state.current_user, e.value);
    }
	
	navigatePage(event, type){
		if (type == "feed"){
			var base_url = window.location.origin;
			window.open((base_url + '/' + this.state.current_user), '_blank');
		} else {
			var base_url = window.location.origin;
		window.location.href = base_url + '/dashboard/' + type;
		}
	}
	
	createData(name, calories, fat, carbs, newone, protein) {
  		return { name, calories, fat, carbs, newone, protein };
	}

	kFormatter(num, digits) {

	  var si = [
		{ value: 1, symbol: "" },
		{ value: 1E3, symbol: "k" },
		{ value: 1E6, symbol: "M" },
		{ value: 1E9, symbol: "B" },
		{ value: 1E12, symbol: "T" },
		{ value: 1E15, symbol: "P" },
		{ value: 1E18, symbol: "E" }
	  ];
	  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	  var i;
	  for (i = si.length - 1; i > 0; i--) {
		if (num >= si[i].value) {
		  break;
		}
	  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}
	
  
	sortByLikes(a, b) {
		if (a[1] === b[1]) {
			return 0;
		} else {
			return (a[1] < b[1]) ? 1 : -1;
		}
	}
	sortByShares(a, b) {
		if (a[2] === b[2]) {
			return 0;
		} else {
			return (a[2] < b[2]) ? 1 : -1;
		}
	}
	sortByComments(a, b) {
		if (a[3] === b[3]) {
			return 0;
		} else {
			return (a[3] < b[3]) ? 1 : -1;
		}
	}
	
	async updateData(current_user, time_period){
		
		fetch('https://shoptik-app.herokuapp.com/dashboard/influencers', {
			method: "GET",
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			}).then(response => {
				response.json().then(data =>{
//					console.log(data.data.influencers);
					var influencers = data.data.influencers.sort(function(a,b) {
    					return a.low_price - b.low_price
					});
					
					if (data.status === 200){
						this.setState({
							influencers: influencers
						})

					} else {
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
						this.setState({
							isLogged: false,
							current_user: ""
						})
				   }
				})
			})
		
		return;
		
		
//		
//		
//		
//		
//		
//		
//		
//		
//		
//
//		var manual_link = 'https://www.tiktok.com/@' + current_user + '?lang=en'
//		var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + manual_link;
//		const scrape_data = await fetch(scrape_url)
//		var all_text = await scrape_data.text();
//
//		var key3 = "stats\":";
//		var key4 = "userData\"";
//		
//		var original = all_text.match(new RegExp(key3 + "(.*)" + key4))[1].replace(/['",]+/g, '').slice(1,-1);;
//		console.log(original);
//		
//		var sub_values = original.match(/\d+/g).map(Number);
//		console.log(sub_values);
//		
//		var followingCount = sub_values[0];
//		var followerCount = sub_values[1];
//		var heartCount = sub_values[2];
//		var videoCount = sub_values[3];
//		
//		var averageLikes = Math.round(heartCount / videoCount);
//		var followerRatio = Math.round(followingCount / followerCount);
//		
//		this.setState({
//			followingCount: followingCount,
//			followerCount: followerCount,
//			heartCount: heartCount,
//			videoCount: videoCount,
//			averageLikes: averageLikes,
//			followerRatio: followerRatio
//		})
//		
//		
//		//SCRAPING URLEBIRD VALUES (may need to convert to integers)
//		const new_scrape_data = await fetch('https://mysterious-reef-90754.herokuapp.com/https://urlebird.com/user/' + current_user + '/');
//		
//		const $ = cheerio.load(await new_scrape_data.text())
//	
//		var allVideos = [];
//		
//		var vids = $('.thumb .img img');
//		$('.thumb .img img').each(function(i, elem) {
//			var reg_src = $(this).attr("src");
//			var data_src = $(this).attr("data-src");
//			
//			if (reg_src.includes("data:image")){
//				allVideos[i] = data_src;
//			} else if (reg_src.includes("https://p16")){
//				allVideos[i] = reg_src
//			}
//
//		});
//
//		const allLikes = [];
//		const allComments = [];
//		const allShares = [];
//		
//		const allLikesNum = [];
//		const allCommentsNum = [];
//		const allSharesNum = [];
//	
//		var counter = 0
//		$('span').each(function(i, elem) {
//			
//			var classname = $(this).attr("class");
//			if (classname === undefined){
//				
//				if (counter == 0){
//					var newnum = $(this).text();
//					allLikes.push(newnum);
//					if (newnum.includes("K")){
//						console.log(parseInt(newnum.replace))
//						allLikesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000);
//					} else if (newnum.includes("M")){
//						allLikesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000000);
//					} else {
//						allLikesNum.push(parseInt(newnum));
//					}
//				} else if (counter == 1){
//					var newnum = $(this).text();
//					allComments.push(newnum);
//					if (newnum.includes("K")){
//						allCommentsNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000)
//					} else if (newnum.includes("M")){
//						allCommentsNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000000)
//					} else {
//						allCommentsNum.push(parseInt(newnum));
//					}
//				} else {
//					var newnum = $(this).text();
//					allShares.push(newnum);
//					if (newnum.includes("K")){
//						allSharesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000)
//					} else if (newnum.includes("M")){
//						allSharesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000000)
//					} else {
//						allSharesNum.push(parseInt(newnum));
//					}
//				}
//				counter = (counter + 1)%3
//			}
//		});
//		
//		const allHrefs = [];
//		const allIds = [];
//		
//		var hrefs = $('a');
//
//		$('a').each(function(i, elem) {
//			
//			var link = $(this).attr("href");
//			if (link.includes("https://urlebird.com/video/")){
//				allHrefs.push(link);
//							  
//				var new_id = [];
////				var name = "John Smith".split("");
//				
//				for(var i = link.length-2; i >= 0; i--) {
//					
//					if (/^\d+$/.test(link[i])){
//						console.log(link[i]);
//						new_id.push(link[i]);
//					} else {
//						break;
//					}
//				}
//				allIds.push(new_id.reverse().join(''));
//			}
//		});
//		
//		// CHANGE THIS BACK!
////		var new_all_videos = [];
////		for (var k=0; k < allHrefs.length; k++){
////			var new_url = allHrefs[k];
////			var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url
////
////			const scrape_data = await fetch(scrape_url)
////			const $ = cheerio.load(await scrape_data.text())
////			var final_url = $('video').attr("src");
////
////			new_all_videos.push(final_url);
////		}
////		allVideos = new_all_videos;
//		
//		//could potentially sort videos here
//		//need a tuple type of thing here
//		
//		var big_tuple = [];
//		var rows = [];
//		
//		var max_performance = Math.max(...allLikesNum) + Math.max(...allSharesNum) + Math.max(...allCommentsNum);
//		for (var i=0;i<allVideos.length;i++){
//			big_tuple.push([allVideos[i], allLikesNum[i], allSharesNum[i], allCommentsNum[i], allHrefs[i], allIds[i]]);
//			
//			
//			var performance = Math.round(((allLikesNum[i] + allCommentsNum[i] + allSharesNum[i])/max_performance) * 100);
//			rows.push(this.createData(allVideos[i], allLikesNum[i], allCommentsNum[i], allSharesNum[i], performance, i));
//		}
//		
//		console.log("big_tuple: ", big_tuple);
//		
////		sort big tuple
//		if (time_period == 1){
//			big_tuple.sort(this.sortByLikes);
//		} else if (time_period == 2){
//			big_tuple.sort(this.sortByShares);
//		} else if (time_period == 3){
//			big_tuple.sort(this.sortByComments);
//		}
//		
//		this.setState({
//			rows: rows,
//			big_tuple: big_tuple,
//			
//			recentVideos : allVideos,
//			allVideos : allVideos,
//			allLikes : allLikes,
//			allShares : allShares,
//			allComments : allComments,
//			
//			allLikesNum : allLikesNum,
//			allSharesNum : allSharesNum,
//			allCommentsNum : allCommentsNum,
//			
//			allHrefs : allHrefs,
//			allIds: allIds,
//		})
//		
//		console.log("rows: ", rows);
//
//		var commentCount = allCommentsNum.reduce(function(a, b){
//        	return a + b;
//    	}, 0);
//		var likeCount = allLikesNum.reduce(function(a, b){
//        	return a + b;
//    	}, 0);
//		var shareCount = allSharesNum.reduce(function(a, b){
//        	return a + b;
//    	}, 0);
//		
//		var denom = commentCount.length;
//		
//		var commentAvg = Math.round(commentCount/allCommentsNum.length);
//		var likeAvg = Math.round(likeCount/allLikesNum.length);
//		var shareAvg = Math.round(shareCount/allSharesNum.length);
//		
//		this.setState({
//			commentCount: commentCount,
//			likeCount: likeCount,
//			shareCount: shareCount,
//			
//			commentAvg: commentAvg,
//			likeAvg: likeAvg,
//			shareAvg: shareAvg
//		})
//
//		return;

	}
	
	loginStatus(){
		var curr = this;
		//fetch to see if logged in
		fetch('https://shoptik-app.herokuapp.com/dashboard/whoami', {
			method: "GET",
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						var current_user = data.data.current_user;
						
						this.updateData(data.data.current_user, "1000");
				
						this.setState({
							isLogged: true,
							current_user: data.data.current_user,
							thumbnail_url: data.data.thumbnail_url
						})

					} else {
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
						this.setState({
							isLogged: false,
							current_user: ""
						})
				   }
				})
			})
	}
	
	componentDidMount() {
		this.loginStatus();
    }
	
	render() {
		if (this.state.isLogged){
			
			let currentComponent = this;
			

			var influencers = this.state.influencers.map(function(link, index){
				return (
					<div key={index} class="img influencerpill" style={{float:"left", width:"320px"}}>
						
						<div id="active_vid_container" style={{textAlignLast:"center",position:"relative"}}>

							<div class="influencer-price">
							<p class="">${link.low_price}</p>
							</div>
							
							<img id="active_vids" class="influencer" referrerPolicy="no-referrer" src={link.thumbnail_url} style={{objectFit: "cover", width: "290px", height:"270px",borderBottomStyle:"solid", borderBottomWidth:"60px",borderBottomColor:"#fafafa"}} onClick={event => currentComponent.videoClicked(event, index, link.thumbnail_url, link.name, link.tiktok_name, link.low_price, link.high_price)}/>
							
							
							 
							<div class="bottom-left-username">{"@" + link.tiktok_name} <p class="follcount">{currentComponent.kFormatter(link.followers, 2)} fans</p></div>
								
						<br/><br/>
						</div>
					</div>)
    		});

		return (
			
			<div class="main_container">
			<div>
				
					<Modal centered size="mid" class="midmodal" show={this.state.showVideo} onHide={() => this.setState({ showVideo: false })}>

					<center>

					<center><img src={this.state.influencer_thumbnail} style={{width:"90px", paddingTop:"10px", paddingBottom:"5px", borderRadius:"50%"}}/></center>

					<div class="username" >@{this.state.influencer_tiktok} <img src={verified} width="14px"  /></div>
					
						
					<div class="account_stats">
						
						<div class="stat">
							<center>
								<span>{this.kFormatter(this.state.followingCount, 2)}</span>
								<h6>Following</h6>
							</center>
						</div>
						
						<div class="stat">
							<center>
								<span>{this.kFormatter(this.state.followerCount, 2)}</span>
								<h6>Followers</h6>
							</center>
						</div>
						
						<div class="stat">
							<center>
								<span>{this.kFormatter(this.state.heartCount, 2)}</span>
								<h6>&nbsp;&nbsp;Likes&nbsp;&nbsp;</h6>
							</center>
						</div>
					</div>

						</center>
                        <Modal.Body >
						
							
							<div class="left-vid" style={{float:"left", width:"35%"}}>
								
								<center><h5>Most Recent TikTok</h5>
							<video style={{borderRadius: "10px"}} id="mainvid" referrerPolicy="no-referrer" crossOrigin src={this.state.current_video} controls muted playsInline autoPlay loop width="80%" ></video><a href={"https://www.tiktok.com/@" + this.state.influencer_tiktok} target="_blank">
										<Button style={{padding: "10px 20px"}} class="tag_btn start_btn" variant="tiktokbutton" >  View on TikTok </Button> </a><br /><br />

								</center>
								</div>
							<br />
							
							
		
						<div class="vid-right" style={{float:"right", marginTop: "-40px", width:"60%"}}>

														<center>
																
								
								
							<Smallbox width="42%" img="https://www.shoptik.co/images/analytics_icons/2.png" current={currentComponent.kFormatter(this.state.averageLikes, 2)} subtitle="Average Likes/Post" message="This is the average likes on a post" />
							<Smallbox width="42%" img="https://www.shoptik.co/images/analytics_icons/2.png" current={currentComponent.kFormatter(this.state.heartCount, 2)} subtitle="Total Likes" message="This is the total likes" />
							<Smallbox width="42%" img="https://www.shoptik.co/images/analytics_icons/7.png" current={currentComponent.kFormatter(this.state.followerCount, 2)} subtitle="Followers" message="This is the number of followers" />
							<Smallbox width="42%" img="https://www.shoptik.co/images/analytics_icons/4.png" current={currentComponent.kFormatter(this.state.videoCount, 2)} subtitle="Posts" message="This is the number of posts" />
							<Smallbox width="42%" img="https://www.shoptik.co/images/analytics_icons/4.png" current={currentComponent.kFormatter(this.state.engagementRate, 4) + "%"} subtitle="Engagement Rate" message="This is the rate of their followers that actively engagemt(like, comment, share, etc)" />
							
							<Smallbox width="42%" img="https://www.shoptik.co/images/analytics_icons/7.png" current={this.state.price_range} subtitle="Price Range" message="This is the influencers price range" />
																					
								<br /><br />
								<Button style={{padding: "15px 15px", width: "90%", fontSize: "21px"}} class="tag_btn start_btn" variant="tag_btn"  onClick={event => this.showForm(this.state.influencer_tiktok, this.state.influencer_name, this.state.influencer_thumbnail, this.state.price_range)} > Request a Promo </Button>
								
							
							</center>
							

							</div>
						
							
                        </Modal.Body>
        			</Modal>
				
					
				
				<Modal size="m" show={this.state.showFormModal} onHide={() => this.setState({ showFormModal: false})}>
						<Modal.Header closeButton style={{borderBottom:"0 none", height: "0px"}}>
                        	<Modal.Title id="contained-modal-title-vcenter" centered>
							Request a Promo
							</Modal.Title>
							
                        </Modal.Header>
					
                        <Modal.Body >
							

							<form onSubmit={event => currentComponent.submitForm(event)}>
								<center>
							<img src={this.state.influencer_thumbnail} style={{borderRadius: "50%"}} width="20%"></img>
								<h5>{"@" + this.state.influencer_tiktok}</h5>
									<p>{this.kFormatter(this.state.followerCount, 2) + " followers"}</p>
									</center>
							<br />
							
						
								
							<Tooltip style={{marginLeft: "420px", marginTop: "20px", position: "absolute", opacity: "0.2", zIndex: "100"}} title="This is your business email, where you will be contacted if the promotion offer is approved">
							  <IconButton id="moreinfo" aria-label={this.props.message}>
								<FilterListIcon />
							  </IconButton>
							</Tooltip>
								
							<p class="input_title">What's your business email? <span id="required_span">*</span></p>
							<input required onChange={event => this.setState({business_email: event.target.value})} value={this.state.business_email} id="business_email" name="business_email" type="text" placeholder="ie. sara@fashionnova.com"/> <br /><br /><br />
								
							<Tooltip style={{marginLeft: "420px", marginTop: "20px", position: "absolute", opacity: "0.2", zIndex: "100"}} title="This is the price you are offering for the promotion">
							  <IconButton id="moreinfo" aria-label={this.props.message}>
								<FilterListIcon />
							  </IconButton>
							</Tooltip>
								
							<p class="input_title">What's your budget? <span id="required_span">*</span></p>
							<input required onChange={event => this.setState({budget: event.target.value})} value={this.state.budget} id="budget" name="budget" type="text" placeholder={"Note: this influencer charges " + this.state.price_range}/> <br /><br /><br />
							
							<Tooltip style={{marginLeft: "420px", marginTop: "20px", position: "absolute", opacity: "0.2", zIndex: "100"}} title="This is a description of the offer. Include as much information as possible">
							  <IconButton id="moreinfo" aria-label={this.props.message}>
								<FilterListIcon />
							  </IconButton>
							</Tooltip>
								
							<p class="input_title">Give us some details <span id="required_span">*</span></p>
							<textarea style={{width: "100%"}} required onChange={event => this.setState({details: event.target.value})} value={this.state.details} id="details" name="details" type="text" placeholder="Tell us a bit about the promo. Are there any hashtags, audio, captions, or content that you want to see? Timelines? etc"/>
								<br /><br />
								<center>
								<Button class="tag_btn" variant="tag_btn" type="submit" > Request Promo </Button>
								</center>
							</form>
                        </Modal.Body>
        			</Modal>
				
<NewSidebar selected={3}></NewSidebar>
				
	<br /><br /><br />
<img id="profile_image" onClick={event => this.navigatePage(event, "settings")} src={this.state.thumbnail_url} />
	<div class="main" id="main">
		
		<Select id="dateselect" autosize={false} className="select" options={ Options } placeholder={this.state.placeholder} onChange={this.handleChange.bind(this)} />	

			<h3>Our Influencers</h3>
		<p style={{fontSize: "12px"}}>Book a Tiktok promotion with our growing community of TikTok influencers</p><br /><hr />
		
		
		<div style={{height: "440px"}}>
		{influencers}
			
		</div>
		
		 <Snackbar open={this.state.openAlert} autoHideDuration={3000} onClose={()=>{this.setState({openAlert: false, alertMessage: ""})}}>
						
					<MuiAlert elevation={6} variant="filled">{this.state.alertMessage}</MuiAlert>
				 </Snackbar>
			
		
	
		
	<br />

		
		
					</div>
					
				
			</div>
			</div>

			)
		} else {
			return ( <div></div>
			)
		}

	}
}

export default withRouter(Influencers)
