import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Imageview from './components/imageview'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import shoptik_icon from './Help/shoptik_icon.png';


import cheerio from 'cheerio';

import Skeleton from '@material-ui/lab/Skeleton';

import Sidebar from "react-sidebar";
import verified from './Help/verified.png';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import FeedItems from './FeedItems';
import FeedProducts from './FeedProducts';

class Feed extends Component {
	constructor(props){
		super(props);
			 this.state = { //state is by default an object
				 videos: [],
				 links: [],
				 productstore: [],
				 show: false,
				 current_video: "",
				 feed: true,
				 left_opacity: 1,
				 right_opacity: 0.15,
				 showEmail: true,
				 showEmailAllowed: false,
				 email_placeholder: "",
				 email_title: "",
				 email_address: "",
				 showAnalytics: false,
				 final_items: [],
				 shouldHideFeed: "block",
				 shouldHideStore: "none"
			}

			this.getClientData = this.getClientData.bind(this);
			this.getProducts = this.getProducts.bind(this);
			this.productClicked = this.productClicked.bind(this);
			this.newestTikToks = this.newestTikToks.bind(this);
			this.testError = this.testError.bind(this);
			this.showEmailClicked = this.showEmailClicked.bind(this);
			this.sendEmail = this.sendEmail.bind(this);

		
			this.attemptUpdate = this.attemptUpdate.bind(this);
		}
	
	attemptUpdate(event){
		event.preventDefault();
		var plan = this.state.plan;
		var tiktok_handle = this.state.profile_name;
	}

		sendEmail(event){
			event.preventDefault();
			// ensure email_address is a real email
			var re = /\S+@\S+\.\S+/;
			if (!re.test(this.state.email_address)){
				this.setState({
					openAlert2: true,
					alertMessage2: "Invalid email address",
				})	
				//show msg
				return;
			}
		var info = {
			'tiktok_name': this.state.profile_name, 
			'email_address': this.state.email_address,
		}
		
		this.setState({
			openAlert: true,
			alertMessage: "Thanks for submitting!",
			showEmail: false,
		})	
		
		fetch('https://shoptik-app.herokuapp.com/dashboard/storeemail', {
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
					} else {
				   }
				})
			})
		}
	
		async newestTikToks(vid_id, client, videos) {
			var new_url = 'https://www.tiktok.com/@' + client + '/video/' + vid_id + '?lang=en'
			var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url
			
			const scrape_data = await fetch(scrape_url)
			const $ = cheerio.load(await scrape_data.text())
			
			var finalurl = JSON.parse($('div #videoObject').html())["contentUrl"];
			
			var newvideos = videos
			newvideos[0]['thumbnail'] = finalurl
			
			this.setState({
				videos: newvideos
			})
		}
	
		getClientData(client) {
			//fetch
			fetch('https://shoptik-app.herokuapp.com/' + client, {
				method: "GET",
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					var videos = data.data.videos;

				var feed = true;
				 var left_opacity = 1;
				 var right_opacity = 0.15;
					var shouldHideFeed = "block";
				 var shouldHideStore = "none";
					
					
					
					if (data.data.products_first){
						feed = false;
						left_opacity = 0.15;
						right_opacity = 1;
						shouldHideFeed = "none";
						shouldHideStore = "block"
					}

					if (data.status === 200){
						this.setState({
							feed: feed,
							left_opacity: left_opacity,
							right_opacity: right_opacity,
							shouldHideFeed: shouldHideFeed,
							shouldHideStore: shouldHideStore,
							
							
							isLogged: true,
							thumbnail_url: data.data.thumbnail_url,
							following: data.data.following,
							followers: data.data.followers,
							likes: data.data.likes,
							profile_name: data.data.profile_name,
							bio: data.data.bio,
							bio_link: data.data.bio_link,
							bio_string: data.data.bio_string,
							videos: videos,
							productstore: data.data.store.reverse(),
							
							showEmailAllowed: data.data.email_addon,
							email_placeholder: data.data.email_placeholder,
							email_title: data.data.email_title,
							products_first: data.data.products_first,
							plan: data.data.plan,
				
						}, () => setTimeout(function() {
							if (data.data.plan == "analytics_monthly" || data.data.plan == "analytics_annual"){
								this.setState({
									show: false,
									showAnalytics: true
								})
							}
						}.bind(this), 4000))
						//console.log("plan:", data.data.plan);

					} else {
						//probably change what happens here
						
						var base_url = window.location.origin;
						window.location.href = base_url + '/home';
						this.setState({
							isLogged: false,
						})
						//redirect them to login page here
				   }
				})
			})
		}
	
		//track_product_clicks
		productClicked(event, link_id, clicks, product_link){
			fetch('https://shoptik-app.herokuapp.com/trackproductclicks?product_id=' + link_id + '&product_clicks=' + clicks, {
					method: "GET",
					credentials: 'include',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
				}).then(response => {
					response.json().then(data =>{
						if (data.status === 400) {
							
						} else if (data.status === 200){
							window.location = product_link;
								var results = data.data;
						}
					})
				})
		}

		componentDidMount(){
			var pathArray = window.location.pathname.split('/');
			var client = pathArray[1];

			this.getClientData(client);

		}

		//fetch products with the same link id
		getProducts(event, vid_id, thumbnail, clicks) {
			this.setState({
				links: []
			})
			this.setState({
				current_vid: (thumbnail + '?rand=' + Math.random()),
				show: true,
			})
			fetch('https://shoptik-app.herokuapp.com/products?vid_id=' + vid_id + '&clicks=' + clicks, {
					method: "GET",
					credentials: 'include',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
				}).then(response => {
					response.json().then(data =>{
						if (data.status === 400) {
						
							//show error popup
						} else if (data.status === 200){
								var results = data.data;
								this.setState({
									links: results.links
								})	
						}
					})
				})
		}

	async testError(tiktok_vid_id, vid_id, index){
		var is_chrome = ((navigator.userAgent.toLowerCase().indexOf('chrome') > -1) &&(navigator.vendor.toLowerCase().indexOf("google") > -1));
		
		var is_chrome_ios = /CriOS/.test(navigator.userAgent);
		
		if (is_chrome){
			return;
		}
		
		var client = this.state.profile_name;
		var videos = this.state.videos;
		
		var new_url = 'https://www.tiktok.com/@' + client + '/video/' + tiktok_vid_id + '?lang=en'
			
		var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url
		
			const scrape_data = await fetch(scrape_url);
			var all_text = await scrape_data.text();
			var key3 = "urls\":";
			var key4 = "videoMeta\"";
		
			var finalurl = "";

			try {
				console.log("ATTEMPTED REQUEST");
				var fin_url = all_text.match(new RegExp(key3 + "(.*)" + key4));
				
				finalurl = fin_url[1].replace(/['",]+/g, '').slice(1,-1);
				finalurl = finalurl.replace('-web','');
				
				if (finalurl.includes("tiktokcdn")){
					
					var info = {
						'vid_id': vid_id,
						'video_url': finalurl,
					}
					
					fetch('https://shoptik-app.herokuapp.com/update_video', {
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
					} else {

				   }
				})
			})
					
				}
				
			
	
//			finalurl = JSON.parse($('div #videoObject').html())["contentUrl"];
//				
			} catch(err) {
				console.log(err);	
			}

			var newvideos = videos
			newvideos[index]['thumbnail'] = finalurl
			this.setState({
				videos: newvideos
			})
			
//			var info = {
//				'vid_id': vid_id,
//				'video_url': finalurl,
//			}
			
			if (finalurl == ""){
				
				return;
			}
		
//			if (finalurl !== ""){
//			fetch('https://shoptik-app.herokuapp.com/update_video', {
//				method: "POST",
//				body: JSON.stringify(info),
//				credentials: 'include',
//				headers: {
//					'Accept': 'application/json',
//					'Content-Type': 'application/json'
//				},
//			}).then(response => {
//				response.json().then(data =>{
//					if (data.status === 200){
//					} else {
//
//				   }
//				})
//			})
//			
//			}
	}
	
	showEmailClicked(event){
		var icon = document.getElementById('drop');
		
		if (this.state.showEmail == true){
			this.setState({
				showEmail: false
			})
			icon.className = 'fa fa-caret-down';
		} else {
			this.setState({
				showEmail: true
			})
			icon.className = 'fa fa-caret-down open';
		}
	}

	render() {
			let currentComponent = this;

//			var items = [];
			var sidebar = [];
			var video_content = [];
//			var product_items = [];
//			var final_items = [];
				var is_chrome = ((navigator.userAgent.toLowerCase().indexOf('chrome') > -1) &&(navigator.vendor.toLowerCase().indexOf("google") > -1));
		
		

			//get most recent links
			
//			var new_url = this.state.current_vid + '?rand=' + Math.random();
		
			var drop = "";
			var test = [0];
			var email_input = "";
		
			if (this.state.showEmailAllowed){

				drop = test.map(function(test, index){
				return(
					<center>
					<span class="email-span">
					<i className="fa fa-envelope-o" style={{marginTop:"25px"}}></i>
					<i onClick={event => currentComponent.showEmailClicked(event)} className="fa fa-caret-down open" id="drop" style={{fontSize: "17px", marginLeft:"5px"}}> </i>
						</span>
						</center>
				)
			})
			}
		
		var trybutton = "";
		if (this.state.profile_name == "brycehall" || this.state.profile_name == "joshrichards" || this.state.profile_name == "ani.energy"){
			trybutton = [0].map(function(item, index){
				return (
					<div class="fadetext" style={{display:currentComponent.state.showtry}} >
					<center>
						<i onClick={(event) => currentComponent.setState({showtry: "none"})} class="fa fa-times-circle exitthing" id="delete" style={{verticalAlign:"right", position:"fixed", zIndex:"999", fontSize: "25px"}}></i>
					<button class="shoppablediv" style={{display:currentComponent.state.showtry}} > <a href="#" onClick={event => (window.location.href = "https://apps.apple.com/us/app/tiktrends-stats-likes-fans/id1539055798")} id="shoppable">&nbsp;&nbsp;&nbsp;See who <b> stalks your TikTok</b> &nbsp;</a></button></center></div>
				)
			})
		}
		
		var biobutton = "";
		if (this.state.profile_name == "brycehall" || this.state.profile_name == "joshrichards" || this.state.profile_name == "ani.energy"){
			
			if (currentComponent.state.bio_link == "nolink"){
				biobutton = "";
			} else {
				biobutton = [0].map(function(item, index){
				return (
					
					<center>
						<br />
						<button class="fullbar5" style={{backgroundColor: "#2C88FF", width: "96%"}} >
					<a href="#" onClick={event => (window.location.href = currentComponent.state.bio_link)} id="previewfeedbtn2">{currentComponent.state.bio_string} </a></button></center>
				)
			})
			}
		}

			if (currentComponent.state.showEmail == true && currentComponent.state.showEmailAllowed){
				email_input = test.map(function(test, index){
				return(
					<center>
						<br />
						<p style={{marginBottom: "5px"}} id="bio">{currentComponent.state.email_title}</p>
						<form onSubmit={event => currentComponent.sendEmail(event)}>
						<input style={{width: "250px"}} maxlength="60" required onChange={event => currentComponent.setState({email_address: event.target.value})} value={currentComponent.state.email_address} id="product_title" name="product_title" type="text" placeholder={currentComponent.state.email_placeholder} />
						
						<Button style={{marginLeft: "5px", padding: "11px", fontSize: "14px"}} type="submit" class="tag_btn" variant="tag_btn"> Send </Button>
						</form>
					</center>
				)
			})
			} 

			//return render
			return (
				<div style={{fontWeight:"500"}}>
					
					<center>
					<img src={this.state.thumbnail_url} style={{width:"80px", borderRadius:"50%", marginTop: "7px", borderColor:"#fff", borderWidth:"3px", borderStyle:"solid", zIndex: "99999 !important"}}/>
						<p style={{fontSize: "14px"}}>@{this.state.profile_name}</p>
					</center>
<div style={{marginTop: "-24px"}}>
					<div style={{}}>
						<div>
						{drop}
						</div>
						<div> {email_input} </div>
						{biobutton}
						</div>
						
					<br />
					<hr style={{marginTop:"8px"}}/>
						<center>
							
						 <i onClick={event => this.setState({feed: true, left_opacity: 1, right_opacity: 0.15, shouldHideFeed: "block", shouldHideStore: "none"})} className="fa fa-th fa-selected" style={{display:"inlineBlock", height:"13px", verticalAlign:"left", fontSize: "17px", marginRight:"70px", opacity: this.state.left_opacity}}></i>
						
						 <i onClick={event => this.setState({feed: false, left_opacity: 0.15, right_opacity: 1, shouldHideFeed: "none", shouldHideStore: "block"})} className="fa fa-shopping-bag fa-unselected" style={{display:"inlineBlock", height:"13px", verticalAlign:"left", fontSize: "17px", opacity: this.state.right_opacity}}></i>
								
						</center>
						<hr style={{marginTop:"8px"}}/>
					
					<div style={{display: this.state.shouldHideStore}}>
					<FeedProducts productstore={this.state.productstore} />
					</div>
					
					<div style={{display: this.state.shouldHideFeed}}>
						<FeedItems videos={this.state.videos} />
					</div>
	
	{trybutton}
	
				
					
				<Snackbar open={this.state.openAlert} autoHideDuration={3000} onClose={()=>{this.setState({openAlert: false, alertMessage: ""})}}>
						
					<MuiAlert elevation={6} variant="filled">{this.state.alertMessage}</MuiAlert>
				 </Snackbar>
					
					
				<Snackbar open={this.state.openAlert2} autoHideDuration={3000} onClose={()=>{this.setState({openAlert2: false, alertMessage2: ""})}}>
						
					<MuiAlert elevation={6} variant="filled" severity="info">{this.state.alertMessage2}</MuiAlert>
				 </Snackbar>
		
					

	
				</div>
					</div>
				
			)
			
		} 

}

export default Feed

//<div class="account_stats">
						
//						<div class="stat">
//							<center>
//								<span>{this.state.following}</span>
//								<h6>Following</h6>
//							</center>
//						</div>
//						
//						<div class="stat">
//							<center>
//								<span>{this.state.followers}</span>
//								<h6>Followers</h6>
//							</center>
//						</div>
//						
//						<div class="stat">
//							<center>
//								<span>{this.state.likes}</span>
//								<h6>&nbsp;&nbsp;Likes&nbsp;&nbsp;</h6>
//							</center>
//						</div>
//					</div>

//	<center>
//								<br />
//								
//						<input style={{width: "270px"}} maxlength="60" required onChange={event => this.setState({userHashtags: event.target.value})} value={this.state.userHashtags} id="product_title" name="product_title" type="text" placeholder="email" />
//						</center>