import React, { Component, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import addnew from './add_vid.jpg';
import Sidebar from "react-sidebar";
import NewSidebar from "./NewSidebar";

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import cheerio from 'cheerio';
import NewAlert from './NewAlert';

import shoptik_icon from './Help/shoptik_icon.png';
import pic1 from './Help/1.jpg';
import pic2 from './Help/2.jpg';
import pic3 from './Help/3.jpg';
import pic4 from './Help/4.png';
import pic5 from './Help/5.jpg';
import pic6 from './Help/6.jpg';
import pic7 from './Help/7.png';

import manual1 from './Help/manual1.png';
import manual2 from './Help/manual2.jpg';
import manual3 from './Help/manual3.png';

import ReactPlayer from "react-player";

import moment from "moment";
import Draggable from "react-draggable";

import { range } from "moment-range";
import Grid from '@material-ui/core/Grid';

import { FileDrop } from 'react-file-drop';

var AWS = require("aws-sdk");
//AWS.config.update({region: 'us-east-2'});
var myCredentials = {
    accessKeyId: 'AKIAJQWBCCYIDE747TTA',
    secretAccessKey: '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'
}
AWS.config.credentials = myCredentials;
// Create S3 service object
var s3 = new AWS.S3({apiVersion: '2006-03-01'});

class PostScheduler extends Component {

	constructor(props){
		super(props);
		
		var dateObj = new Date();

		
var month = dateObj.getMonth() + 1; //months from 1-12
var day = dateObj.getDate();
var day2 = dateObj.getUTCDate();
		console.log("day1, day2, ", day, day2)
var year = dateObj.getFullYear();
		
		var hour = dateObj.getHours();
		
		var original_date = year + "-" + this.zeroPad(month, 2) + "-" + this.zeroPad(day, 2)
		console.log("original date: ", original_date);
		console.log("current hour = ", hour)
		console.log("currenthour2: ", "21:42".split(":")[0])
		
		
		
		this.state = {
			thumbnail_url: "",
			
			showYearTable: false,
    showMonthTable: false,
    showDateTable: true,
    dateObject: moment(),
    allmonths: moment.months(),
    selectedDay: null,
			showAddMedia: false,
			showUpdateMedia: false,
			influencers: [],
	selectedDate: new Date('2014-08-18T21:11:54'),
	setSelectedDate: new Date('2014-08-18T21:11:54'),
			videoLink: "",
			activeDrags: 0,
    deltaPosition: {
      x: 0, y: 0
    },
    controlledPosition: {
      x: -400, y: 200
    },
			showButton:"",
			posts: [],
			post_date: original_date,
			isloading: "",
		}
		
		this.loginStatus = this.loginStatus.bind(this);
		this.navigatePage = this.navigatePage.bind(this);
		this.updateData = this.updateData.bind(this);
		this.selectVideo = this.selectVideo.bind(this);
		this.onDrop = this.onDrop.bind(this);

		this.handleDateChange = this.handleDateChange.bind(this);
		this.videoClicked = this.videoClicked.bind(this);
		this.uploadFiles = this.uploadFiles.bind(this);
		
		this.submitPost = this.submitPost.bind(this);
		this.submitPostUpdate = this.submitPostUpdate.bind(this);
		this.prepareUpdate = this.prepareUpdate.bind(this);
		this.deleteVid = this.deleteVid.bind(this);
		this.addFromLibrary = this.addFromLibrary.bind(this);
	}
	
		attemptNewUpgrade(event, plan){
		event.preventDefault();
		this.setState({
			showUpgradeModal1: false,
			showUpgradeModal2: false
		})

		var tiktok_handle = this.state.current_user;
		
		//send post to api that will send us an email
		var info = {
			'tiktok_handle': tiktok_handle,
			'plan': plan
		}
		//fetch new route to handle payment
		fetch('https://shoptik-app.herokuapp.com/attemptnewupgrade',{
			method: "POST",
			body: JSON.stringify(info),
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		}).then(response => {
			response.json().then(async data =>{
				if (data.status === 400) {
					this.setState({
					})
				} else if (data.status === 200){
					//refresh window
					this.updateData(this.state.current_user, "1000");
//					this.getClientData(this.state.current_user);
					this.setState({
						showUpgradeModal: false,
						openAlert: true,
						alertMessage: "Successfully Upgraded Plan!"
					})
					//show footer thing
				}
			})
		})
	}
	
	deleteVid(event, post, index){
		event.stopPropagation();
		this.setState({
			showAddMedia: false
		})
		var objectId = this.state.posts[index].objectId;
		console.log("to be deleted: ", objectId);
		
		event.preventDefault();
		var info = {
			'tiktok_name': this.state.current_user,
			'objectId': objectId,
			'delete_type': "ScheduledPosts"
		}
		
		fetch('https://shoptik-app.herokuapp.com/dashboard/deletemedia',{
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(async data =>{
					if (data.status === 400) {
						//console.log("FAILED");
						this.setState({
							openAlert: true,
							alertMessage: "Oops, there was an error"
						})
					} else if (data.status === 200){
						//console.log("WORKED");
						//reload data
						this.setState({
							openAlert: true,
							alertMessage: "Video successfully deleted"
						})
						this.updateData(this.state.current_user, "1000");
					}
				})
			})
		
		
		
		//fetch to api to delete this post, similar to how media library can delete a post
		
		
		
	}
	
	prepareUpdate(event, post, index){
		//prevent event propogation
		event.stopPropagation();
		
		var post = this.state.posts[index];
		

		console.log("CAPTION:", post.post_caption);
		console.log("videolink", post.file_name);
		console.log("hashtags", post.post_hashtags);
		
		//set current post_date, post_time
		var post_date = post.post_date;
		var post_time = post.post_time;
		var post_date_min = this.year() + "-" + this.zeroPad(this.monthNUM() + 1, 2) + "-" + this.zeroPad(this.currentDay(), 2);
//		console.log("postdatemin: ", post_date_min);
		
		this.setState({
			showButton: "none",
			post_caption: post.post_caption,
			post_hashtags: post.post_hashtags,
			videoLink: post.file_name,
			post_date: post_date,
			post_time: post_time,
			post_date_min: post_date_min
		}, () => this.setState({
			updatePostObjectId: post.objectId,
			showUpdateMedia: true,
			showAddMedia: false
		}))
	}
	
	zeroPad(num, places) {
  var zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join("0") + num;
}

	submitPostUpdate(event){
		event.preventDefault();
		var file_name = this.state.videoLink;
		
		if (file_name == ""){
			this.setState({
				openAlert: true,
				alertMessage: "Please upload media to schedule post"
			})
			return;
		}
		
		var objectId = this.state.updatePostObjectId;
		
		var business_name = this.state.current_user;
		var post_caption = this.state.post_caption;
		var post_hashtags = this.state.post_hashtags;
		
		if (typeof post_caption == 'undefined'){
			post_caption = "";
		} if (typeof post_hashtags == 'undefined'){
			post_hashtags = "";
		}
		
		var post_date = document.getElementById("post_date").value;
		var post_time = document.getElementById("post_time").value;

//		var newdate = new Date(post_date);
//		newdate.setHours(post_time.split(":")[0])
//		newdate.setMinutes(post_time.split(":")[1])
//		
//		var month = newdate.getUTCMonth();
//		var day = newdate.getUTCDate();
//		var year = newdate.getUTCFullYear();
//		
//		var hour = newdate.getUTCHours();
//		var minutes = newdate.getUTCMinutes();
		
		var date = new Date(post_date);
		console.log("DATE: ", date, "POSTDATE: ", post_date);
		var hour = parseInt(post_time.split(":")[0]);
		var am = post_time.split(":")[1].split(" ")[1];
		if (am == "PM"){
			hour += 12
		}
		var year = parseInt(post_date.split("-")[0]);
		var month = parseInt(post_date.split("-")[1]-1);
		var day = parseInt(post_date.split("-")[2]);
		var minutes = parseInt(post_time.split(":")[1].split(" ")[0]);
		
		var newdate = new Date(year, month, day, hour, minutes, 1, 1);
		var UTCmonth = newdate.getUTCMonth();
		var UTCday = newdate.getUTCDate();
		var UTCyear = newdate.getUTCFullYear();
		var UTChour = newdate.getUTCHours();
		var UTCminutes = newdate.getUTCMinutes();
		
		var info = {
			'tiktok_name': business_name,
			'post_caption': post_caption,
			'post_hashtags': post_hashtags,
			'file_name': file_name,
			'year': year,
			'month': month,
			'day': day,
			'hour': hour,
			'minute': minutes,
			'UTCyear': UTCyear,
			'UTCmonth': UTCmonth,
			'UTCday': UTCday,
			'UTChour': UTChour,
			'UTCminute': UTCminutes,
			'post_date': post_date,
			'post_time': post_time,
			'objectId': objectId
		}
		
		fetch('https://shoptik-app.herokuapp.com/dashboard/updatepost',{
			method: "POST",
			body: JSON.stringify(info),
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		}).then(response => {
			response.json().then(async data =>{
				if (data.status === 400) {
					this.setState({
						isLogged: false,
						openAlert: true,
						alertMessage: ("There was an error")
					})
				} else if (data.status === 200){
					this.setState({
						openAlert: true,
						alertMessage: "Your scheduled post has been updated"
					}, () => {
						this.setState({
							showUpdateMedia: false, 
							videoLink: "",
		  					showButton: "",
		  					post_caption: "",
		  					post_hashtags: ""
						})})
					this.updateData(this.state.current_user, "1000");
					//show footer thing
				}
			})
		})
	}
	
	submitPost(event){
		event.preventDefault();
//		alert("submitted");
		var file_name = this.state.videoLink;
		
		if (file_name == ""){
			this.setState({
				openAlert: true,
				alertMessage: "Please upload media to schedule post"
			})
			return;
		}
		
		var business_name = this.state.current_user;
		
		var post_caption = this.state.post_caption;
		var post_hashtags = this.state.post_hashtags;
		
		if (typeof post_caption == 'undefined'){
			post_caption = "";
		} if (typeof post_hashtags == 'undefined'){
			post_hashtags = "";
		}
		
		var post_date = document.getElementById("post_date").value;
		var post_time = document.getElementById("post_time").value;

		var date = new Date(post_date);
		console.log("DATE: ", date, "POSTDATE: ", post_date);
		var hour = parseInt(post_time.split(":")[0]);
		var am = post_time.split(":")[1].split(" ")[1];
		if (am == "PM"){
			hour += 12
		}
		var year = parseInt(post_date.split("-")[0]);
		var month = parseInt(post_date.split("-")[1]-1);
		var day = parseInt(post_date.split("-")[2]);
		var minutes = parseInt(post_time.split(":")[1].split(" ")[0]);
		
		var newdate = new Date(year, month, day, hour, minutes, 1, 1);
		var UTCmonth = newdate.getUTCMonth();
		var UTCday = newdate.getUTCDate();
		var UTCyear = newdate.getUTCFullYear();
		var UTChour = newdate.getUTCHours();
		var UTCminutes = newdate.getUTCMinutes();
		
		var info = {
			'tiktok_name': business_name,
			'post_caption': post_caption,
			'post_hashtags': post_hashtags,
			'file_name': file_name,
			'year': year,
			'month': month,
			'day': day,
			'hour': hour,
			'minute': minutes,
			'UTCyear': UTCyear,
			'UTCmonth': UTCmonth,
			'UTCday': UTCday,
			'UTChour': UTChour,
			'UTCminute': UTCminutes,
			'post_date': post_date,
			'post_time': post_time,
		}
		
		console.log("INFO: ", info)

		fetch('https://shoptik-app.herokuapp.com/dashboard/schedulepost',{
			method: "POST",
			body: JSON.stringify(info),
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		}).then(response => {
			response.json().then(async data =>{
				console.log("info: ", data.message);
				if (data.status === 400) {
					this.setState({
						isLogged: false,
						openAlert: true,
						alertMessage: ("There was an error")
					})
				} else if (data.status === 200){
					this.setState({
						openAlert: true,
						alertMessage: "Your post has been scheduled"
					}, () => {
						this.setState({
					showAddMedia: false,  videoLink: "",
					  showButton: "",
					  post_caption: "",
					  post_hashtags: ""})})
					this.updateData(this.state.current_user, "1000");
					//show footer thing
				}
			})
		})
		
	}
	
		async addFile(file){
			this.setState({
				openAlert: true,
				alertMessage: "Uploading video...",
			})
		//console.log("file: ", file);
		//var file = event.target.files[0];
		
		var object_key = this.state.current_user + "_" + new Date().getTime().toString() + "_" + file.name 
		var uploadParams = {Bucket: 'shoptikappvideobucket', Key: object_key, Body: file};
		
		var current_user = this.state.current_user;
		var currentComponent = this;
		
		//try react method of uploading to s3
		await s3.upload (uploadParams, async function (err, data) {
        if (err) {
            //console.log("Error", err);
        } if (data) {
            //console.log("Upload Success", data.Location);
			
			currentComponent.setState({
				openAlert: true,
				alertMessage: "Video successfully uploaded",
			})
			
			//store data/Location in back4app
			var info = {
				'tiktok_name': current_user,
				'file_name': data.Location
			}
			
			//console.log("info: ", info);
			
            //window.alert("success");
			fetch('https://shoptik-app.herokuapp.com/dashboard/addmedia',{
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(async data =>{
					if (data.status === 400) {
						//console.log("FAILED");
					} else if (data.status === 200){
						//console.log("WORKED");
						//reload data
						currentComponent.updateData(current_user, "1000");
					}
				})
			})

        }
    });
		
		this.setState({
			showDrop: "none"
		})
		
	}
	
	
	async uploadFiles(files, event){
		event.preventDefault();
		
		//ensure file is video
		
		for (var j=0; j<files.length; j++){
			var file = files[j];
			
			if (!file.name.includes("mov") && !file.name.includes("mp4") && !file.name.includes("wav") && !file.name.includes("mpeg")){
			this.setState({
				openAlert: true,
				alertMessage: "File must have extension (.mov, .mp4, .mpeg, .wav)",
				showDrop: "none"
			})
			return;
		}
		
		var object_key = this.state.current_user + "_" + new Date().getTime().toString() + "_" + file.name;
		var uploadParams = {Bucket: 'shoptikappvideobucket', Key: object_key, Body: file};
		
		var current_user = this.state.current_user;
		var currentComponent = this;
		
		//try react method of uploading to s3
		await s3.upload (uploadParams, async function (err, data) {
        if (err) {
            //console.log("Error", err);
        } if (data) {
            //console.log("Upload Success", data.Location);
			
			currentComponent.setState({
				openAlert: true,
				alertMessage: "Video successfully uploaded",
				videoLink: data.Location
			})
			
			//store data/Location in back4app
			var info = {
				'tiktok_name': current_user,
				'file_name': data.Location
			}
			
			
            //window.alert("success");
			fetch('https://shoptik-app.herokuapp.com/dashboard/addmedia',{
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(async data =>{
					if (data.status === 400) {
					
					} else if (data.status === 200){
						
						//currentComponent.updateData(current_user, "1000");
						

					}
				})
			})

        }
    });
			
			
		}



		this.setState({
			showDrop: "none"
		})
	}
	
	onDrop(index, link){
//		console.log("index stopped: ", index);
//		console.log("link stopped: ", link);
	}
	
	selectVideo(event, video_url){
		//alert("video", video_url)
		this.setState({
			videoLink: video_url,
			showButton: 'none'
		})
	}
	
	handleDateChange(date){
		this.setState({
			setSelectedDate: date
		})
	}
	
	navigatePage(event, type){
		if (type == "feed"){
			var base_url = window.location.origin;
			window.open((base_url + '/' + this.state.current_user), '_blank');
		} else {
			var base_url = window.location.origin;
		window.location.href = base_url + '/dashboard/' + type;
		}
	}

	componentDidMount(){
		
		const script = document.createElement("script");

		script.src = "https://www.tiktok.com/embed.js";
		script.async = true;

		document.body.appendChild(script);

		this.loginStatus();

	}

	//new function, get plan type. if plan is basic, show upgrade popup with 2 modals. if plan is medium, show upgrade modal with one popup.
	getPlan(client){
		fetch('https://shoptik-app.herokuapp.com/api/' + client, {
				method: "GET",
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						var creation_date = new Date(data.data.creation_date).toUTCString();

						var plan = data.data.plan_type;
						var is_active = data.data.is_active;
						
						if (is_active == true && plan == "analytics_monthly"){
							this.setState({
								showUpgradeModal1: true
							})
						}
						if (is_active == true && plan == "standard_monthly"){
							this.setState({
								showUpgradeModal2: true
							})
						}

					} else {
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
						this.setState({
							isLogged: false,
						})
						//redirect them to login page here
				   }
				})
			})
	}

	
	async updateData(current_user, time_period){
		//fetch scheduled posts as well
		fetch('https://shoptik-app.herokuapp.com/dashboard/getscheduledposts', {
			method: "GET",
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			}).then(response => {
				response.json().then(data =>{
					
					console.log("posts", data.data.posts)
					
					if (data.data.message == "none"){
						this.setState({
							posts: []
						})
					}
					
					else if (data.status === 200){
						
						this.setState({
							posts: data.data.posts
						})
					}
				})
			})
		
//		this.setState({
//			influencers: []
//		})
		
		fetch('https://shoptik-app.herokuapp.com/dashboard/medialibrary', {
			method: "GET",
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			}).then(response => {
				response.json().then(data =>{
					if (data.data.message == "none"){
						this.setState({
							showIntro: true,
							influencers: [],
							isloading: "none"
						})
					}
					
					else if (data.status === 200){
						this.setState({
							showIntro: false,
							influencers: data.data.media_library,
							isloading: "none"
						})

					} else {
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
						this.setState({
							isLogged: false,
							current_user: ""
						})
				   }
				})
			})
		
		return;
	}

	addFromLibrary(event, index, video_url){
		this.setState({
			showModal: false,
			videoLink: video_url,
			showButton: "none"
		})
	}
	
	videoClicked(event, index, video_url){
		var original_date = this.year() + "-" + this.zeroPad(this.monthNUM() + 1, 2) + "-" + this.zeroPad(this.currentDay(), 2);
		
		var post_date_min = this.year() + "-" + this.zeroPad(this.monthNUM() + 1, 2) + "-" + this.zeroPad(this.currentDay(), 2);
		
		this.setState({
			post_date: original_date,
			post_date_min: post_date_min,
			showAddMedia: true,
			videoLink: video_url,
			showButton: "none"
		})

//		this.setState({
//			showAddMedia2: true,
//			videoLink: video_url,
//		})
	}
		
	
	

	loginStatus(){
		var curr = this;
		//fetch to see if logged in
		fetch('https://shoptik-app.herokuapp.com/dashboard/whoami', {
			method: "GET",
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						
						var current_user = data.data.current_user;
						this.getPlan(current_user);
						this.updateData(data.data.current_user, "1000");

						this.setState({
							isLogged: true,
							current_user: data.data.current_user,
							thumbnail_url: data.data.thumbnail_url
						})
					} else {
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
						this.setState({
							isLogged: false,
							current_user: ""
						})
				   }
				})
			})
	}
	
	
	
	  weekdayshort = moment.weekdaysShort();

  daysInMonth = () => {
    return this.state.dateObject.daysInMonth();
  };
  year = () => {
    return this.state.dateObject.format("Y");
  };
  currentDay = () => {
    return this.state.dateObject.format("D");
  };
  firstDayOfMonth = () => {
    let dateObject = this.state.dateObject;
    let firstDay = moment(dateObject).startOf("month").format("d"); // Day of week 0...1..5...6
    return firstDay;
  };
  month = () => {
    return this.state.dateObject.format("MMMM");
  };
  monthNUM = () => {
	  var month = this.state.dateObject.format("MMMM");
	  if (month == "January"){
		  return 0
	  }
	  if (month == "February"){
		  return 1
	  }
	  if (month == "March"){
		  return 2
	  }
	  if (month == "April"){
		  return 3
	  }
	  if (month == "May"){
		  return 4
	  }
	  if (month == "June"){
		  return 5
	  }
	  if (month == "July"){
		  return 6
	  }
	  if (month == "August"){
		  return 7
	  }
	  if (month == "September"){
		  return 8
	  }
	  if (month == "October"){
		  return 9
	  }
	  if (month == "November"){
		  return 10
	  }
	  if (month == "December"){
		  return 11
	  }
	  
    return this.state.dateObject.format("MMMM");
  };
  showMonth = (e, month) => {
    this.setState({
      showMonthTable: !this.state.showMonthTable,
      showDateTable: !this.state.showDateTable
    });
  };
  setMonth = (month) => {
    let monthNo = this.state.allmonths.indexOf(month);
    let dateObject = Object.assign({}, this.state.dateObject);
    dateObject = moment(dateObject).set("month", monthNo);
    this.setState({
      dateObject: dateObject,
      showMonthTable: !this.state.showMonthTable,
      showDateTable: !this.state.showDateTable
    });
  };
  MonthList = (props) => {
    let months = [];
    props.data.map((data) => {
      months.push(
        <td
          key={data}
          className="calendar-month"
          onClick={(e) => {
            this.setMonth(data);
          }}
        >
          <span>{data}</span>
        </td>
      );
    });
    let rows = [];
    let cells = [];

    months.forEach((row, i) => {
      if (i % 3 !== 0 || i == 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
    });
    rows.push(cells);
    let monthlist = rows.map((d, i) => {
      return <tr>{d}</tr>;
    });

    return (
      <table className="calendar-month">
        <thead>
          <tr>
            <th colSpan="4">Select a Month</th>
          </tr>
        </thead>
        <tbody>{monthlist}</tbody>
      </table>
    );
  };
  showYearTable = (e) => {
    this.setState({
      showYearTable: !this.state.showYearTable,
      showDateTable: !this.state.showDateTable
    });
  };

  onPrev = () => {
    let curr = "";
    if (this.state.showYearTable === true) {
      curr = "year";
    } else {
      curr = "month";
    }
    this.setState({
      dateObject: this.state.dateObject.subtract(1, curr)
    });
  };
  onNext = () => {
    let curr = "";
    if (this.state.showYearTable === true) {
      curr = "year";
    } else {
      curr = "month";
    }
    this.setState({
      dateObject: this.state.dateObject.add(1, curr)
    });
  };
  setYear = (year) => {
    // alert(year)
    let dateObject = Object.assign({}, this.state.dateObject);
    dateObject = moment(dateObject).set("year", year);
    this.setState({
      dateObject: dateObject,
      showMonthTable: !this.state.showMonthTable,
      showYearTable: !this.state.showYearTable
    });
  };
  onYearChange = (e) => {
    this.setYear(e.target.value);
  };
  getDates(startDate, stopDate) {
    var dateArray = [];
    var currentDate = moment(startDate);
    var stopDate = moment(stopDate);
    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format("YYYY"));
      currentDate = moment(currentDate).add(1, "year");
    }
    return dateArray;
  }
  YearTable = (props) => {
    let months = [];
    let nextten = moment().set("year", props).add("year", 12).format("Y");

    let tenyear = this.getDates(props, nextten);

    tenyear.map((data) => {
      months.push(
        <td
          key={data}
          className="calendar-month"
          onClick={(e) => {
            this.setYear(data);
          }}
        >
          <span>{data}</span>
        </td>
      );
    });
    let rows = [];
    let cells = [];

    months.forEach((row, i) => {
      if (i % 3 !== 0 || i == 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
    });
    rows.push(cells);
    let yearlist = rows.map((d, i) => {
      return <tr>{d}</tr>;
    });

    return (
      <table className="calendar-month">
        <thead>
          <tr>
            <th colSpan="4">Select a Yeah</th>
          </tr>
        </thead>
        <tbody>{yearlist}</tbody>
      </table>
    );
  };
  onDayClick = (e, d) => {
	  //lets update post_date
		var original_date = this.year() + "-" + this.zeroPad(this.monthNUM() + 1, 2) + "-" + this.zeroPad(d, 2);
	  
//	  var post_date = post.post_date;
//		var post_time = post.post_time;
		var post_date_min = this.year() + "-" + this.zeroPad(this.monthNUM() + 1, 2) + "-" + this.zeroPad(this.currentDay(), 2);
	  
		console.log("original date: ", original_date)
		
		if (d < this.currentDay()){
			this.setState({
							openAlert: true,
							alertMessage: "Oops, you can only select a day in the future"
						})
			return;
		}

    this.setState(
      {
		  
		  post_date: original_date,
		  post_date_min: post_date_min,
          selectedDay: d,
		  showAddMedia: true,
		  
      },
      () => {
		  //show modal for scheduling the post
        console.log("SELECTED DAY: ", this.state.selectedDay);
		console.log("SELECTED Month: ", this.month());
      }
    );
  };


  handleDrag = (e, ui) => {
    const {x, y} = this.state.deltaPosition;
    this.setState({
      deltaPosition: {
        x: x + ui.deltaX,
        y: y + ui.deltaY,
      }
    });
  };

  onStart = () => {
    this.setState({activeDrags: ++this.state.activeDrags});
  };

  onStop = () => {
	  //console.log("id:", this.id)
	  //console.log("stopped: ", index);
	  //which one stopped? 
	  console.log("stopped!");
    this.setState({activeDrags: --this.state.activeDrags});
  };

  // For controlled component
  adjustXPos = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {x, y} = this.state.controlledPosition;
    this.setState({controlledPosition: {x: x - 10, y}});
  };

  adjustYPos = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const {controlledPosition} = this.state;
    const {x, y} = controlledPosition;
    this.setState({controlledPosition: {x, y: y - 10}});
  };

  onControlledDrag = (e, position) => {
    const {x, y} = position;
    this.setState({controlledPosition: {x, y}});
  };

  onControlledDragStop = (e, position) => {
    this.onControlledDrag(e, position);
    this.onStop();
  };


	render() {
		let weekdayshortname = this.weekdayshort.map((day) => {
      return <th key={day}>{day}</th>;
    });
    let blanks = [];
    for (let i = 0; i < this.firstDayOfMonth(); i++) {
      blanks.push(<td className="calendar-day empty">{""}</td>);
    }
    let daysInMonth = [];
    for (let d = 1; d <= this.daysInMonth(); d++) {
      let currentDay = d == this.currentDay() ? "today" : "";
		let currentDayNum = this.currentDay();
		
//		console.log("currentday: ", currentDayNum)
		var values = [];
//		if (d < currentDayNum)
//			values.push(<td className="calendar-day empty">{""}</td>);
//		}
		
		for (let p=0; p<this.state.posts.length; p++){
			var current_post = this.state.posts[p];
			console.log("here");
			
			if (current_post.day == d && current_post.month == this.monthNUM()){
				
				values.push(<div style={{display: "inline-block", float: "left", paddingLeft: "8px"}}><i onClick={event => this.deleteVid(event, current_post, p)} class="fa fa-times-circle" id="delete" style={{verticalAlign:"right", fontSize: "15px", color: "white",marginLeft: "15px", position:"absolute",zIndex:"999"}}></i><video style={{borderRadius: "2px"}} src={current_post.file_name} width="30px" autoPlay playsInline loop muted onClick={(event) => this.prepareUpdate(event, current_post, p)}></video></div>)
			}
		}
//		for (var j=0;j<3;j++){
//			values.push(<div>o</div>)
//		}
//		console.log("this:::", d, this.month());
      daysInMonth.push(
        <td class="calendar-td" style={{borderStyle:"solid",borderBottomColor:"black",borderBottomWidth:"1px"}} onClick={(e) => {
              this.onDayClick(e, d);
            }} style={{zIndex: ""}} key={d} className={`calendar-day ${currentDay}`} >
             <span style={{float: "left", display: "inline-block"}}>{d}</span><br />{values} 
        </td>
      );
    }
    var totalSlots = [...blanks, ...daysInMonth];
    let rows = [];
    let cells = [];

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row);
      } else {
        rows.push(cells);
        cells = [];
        cells.push(row);
      }
      if (i === totalSlots.length - 1) {
        // let insertRow = cells.slice();
        rows.push(cells);
      }
    });

    let daysinmonth = rows.map((d, i) => {
      return <tr>{d}</tr>;
    });
		
		//get media posts
		let currentComponent = this;
					var influencers = this.state.influencers.map(function(link, index){
				
				var createdAt = new Date(link.createdAt).toUTCString().split(' ').slice(0, 4).join(' ');
				
				return (
					<div key={index} class="img influencerpill" style={{float:"left", width:"230px"}}>
						
						<div class="active_vid_container" style={{textAlignLast:"center",position:"relative"}} onClick={(event) => currentComponent.selectVideo(event, link.file_name)}>
							
							<div class="delete_vid" style={{zIndex:"999"}}>
								<i onClick={event => currentComponent.deleteVid(event, link.objectId)} class="fa fa-times-circle" id="delete" style={{verticalAlign:"right", fontSize: "20px", color: "white",marginLeft: "30px", position:"absolute", zIndex:"999"}}></i>
							</div>

							<video loop autoplay muted id="active_vids" class="influencer2" referrerPolicy="no-referrer" src={link.file_name} style={{objectFit: "cover", width: "200px", height:"340px", borderBottomStyle:"solid", borderBottomWidth:"30px", borderBottomColor:"#fafafa", borderRadius:"10px",zIndex:"1"}} />
							
							<div class="bottom-left-username2">posted: <b>{createdAt}</b> 
								<p class="follcount2"></p></div>
								
						<br/><br/>
						</div>
					</div>)
    		});
		
				var smallinfluencers = this.state.influencers.map(function(link, index){
				var createdAt = new Date(link.createdAt).toUTCString().split(' ').slice(0, 3).join(' ');
					
				const dragHandlers = {onStart: currentComponent.onStart};
    			const deltaPosition= currentComponent.state.deltaPosition;
					const controllerPosition = currentComponent.state.controlledPosition;
				
				if (index == 0){
					return (
						<div>
						<div key={index}>
	
					<div class="img influencerpill" style={{float:"left", width:"33%"}}>
						
						<div class="active_vid_container" style={{textAlignLast:"center", position:"relative"}}>
							
									<label for="file-upload" class="custom-file-upload">
<img src="https://www.shoptik.co/images/click_to_upload_2.png" id="active_vids" class="influencer2" referrerPolicy="no-referrer" style={{objectFit: "cover", width: "95%", height:"140px", borderRadius:"0px",zIndex:"1", marginTop: "-2px", borderRadius:"5px"}} />
</label>
		
		<input accept="video/*" id="file-upload" class="plus" style={{marginLeft: "10px", width:"40px", height:"40px", backgroundColor:"transparent", display:"none"}} type="file" onChange={ (e) => currentComponent.addFile(e.target.files[0]) }></input>
							
							 

						</div>
					</div>
						</div>
						
						<div key={index} onClick={(event) => currentComponent.videoClicked(event, index, link.file_name)}>
	
					<div class="img influencerpill" style={{float:"left", width:"33%"}}>
						
						<div class="active_vid_container" style={{textAlignLast:"center", position:"relative"}}>

							<video muted id="active_vids" class="influencer2" referrerPolicy="no-referrer" src={link.file_name} style={{objectFit: "cover", width: "95%", height:"140px", borderRadius:"5px",zIndex:"1"}} />

						</div>
					</div>
						</div>
					
					</div>
					)
				}	
					
				return (
					<div key={index} onClick={(event) => currentComponent.videoClicked(event, index, link.file_name)}>
	
					<div class="img influencerpill" style={{float:"left", width:"33%"}}>
						
						<div class="active_vid_container" style={{textAlignLast:"center", position:"relative"}}>

							<video muted  id="active_vids" class="influencer2" referrerPolicy="no-referrer" src={link.file_name} style={{objectFit: "cover", width: "95%", height:"140px", borderRadius:"5px",zIndex:"1"}} />

						</div>
					</div>
						</div>
						)
    		});
		
				var smallinfluencers3 = this.state.influencers.map(function(link, index){
				var createdAt = new Date(link.createdAt).toUTCString().split(' ').slice(0, 3).join(' ');
					
				const dragHandlers = {onStart: currentComponent.onStart};
    			const deltaPosition= currentComponent.state.deltaPosition;
					const controllerPosition = currentComponent.state.controlledPosition;
				
				return (
					<div key={index} onClick={(event) => currentComponent.addFromLibrary(event, index, link.file_name)}>

					<div class="img influencerpill" style={{float:"left", width:"230px"}}>
						
						<div class="active_vid_container" style={{textAlignLast:"center", position:"relative"}}>

							<video loop autoplay muted id="active_vids" class="influencer2" referrerPolicy="no-referrer" src={link.file_name} style={{objectFit: "cover", width: "200px", height:"340px",  borderRadius:"10px",zIndex:"1"}} />
							

						</div>
					</div>
					</div>
						)
    		});

		if (this.state.isloading == "none" && this.state.influencers.length == 0){
			return (
				<div>
					
					<NewSidebar selected={14}></NewSidebar>
					
				<br /><br /><br />
					<img id="profile_image" onClick={event => this.navigatePage(event, "settings")} src={this.state.thumbnail_url} />
					<div class="main">
										<center>
					<div class="rankbox_container" style={{width: "96%"}}>
						<br /><br />
						<p class="feature_description_text">TikTok Post Scheduling has Arrived!<br /></p><br /><br /><br />
						
						
						<div style={{float: "left", width: "44%", textAlign: "left", marginLeft: "5%", marginBottom: "50px"}}>
						<h5 style={{paddingBottom:"10px"}}>How does TikTok Post Scheduling work?</h5> 

							<p class="check-text">1. Add videos to the media library page</p>
							<p class="check-text">2. Click "Schedule New Post" </p>
							<p class="check-text">3. Add your video from the media library, along with hashtags and a caption</p>
							<p class="check-text">4. Download the Shoptik App and turn on push notifications</p>
							<p class="check-text">5. Get Notified on the app, and by email or text when your it's time to post your scheduled post!</p><br />
							
							<h5 style={{paddingBottom:"10px"}}>What else can I do with Post Scheduling </h5> 
						
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> The media library makes it really easy to plan your feed</p>
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> Use the app to see what posts would look like</p>
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> Schedule unlimited posts, and update or remove them anytime before the actual posting time</p>
							<br /><br />
							
								<Button style={{padding: "12px 15px", marginLeft: "0px", position:"relative", zIndex:"999"}} id="senddemo" name="senddemo" class="btn btn-primary">
				
								<label for="file-upload2">
								Schedule your first post
								</label>

									</Button>

									<input accept="video/*" id="file-upload2" class="plus" style={{marginLeft: "10px", width:"40px", height:"40px", backgroundColor:"transparent", display: "none"}}
							 type="file" onChange={ (e) => currentComponent.addFile(e.target.files[0]) }></input>	
							
							
							
							
						</div>
						<div style={{width: "44%", float: "right",}}>
							<center>
							<img width="600px" 
 src="https://www.socialbakers.com/website/img/pages/platforms/scheduling-publishing/01.png" style={{marginTop:"-20px"}}></img>
							</center>
						</div>
					</div>
						</center></div></div>
			)
		}

		if (this.state.isLogged){
			var showButton = this.state.showButton;
			var videoLink = this.state.videoLink;
			return(
				<div>	
					<Modal size="sm" class="widemodal" show={this.state.showAddMedia} onHide={() => this.setState({ showAddMedia: false, videoLink: "",
		  showButton: "",
		  post_caption: "",
		  post_hashtags: ""})}>
						<Modal.Header closeButton style={{borderBottom:"0 none", height: "0px"}}>
                        	<Modal.Title id="contained-modal-title-vcenter" centered>
							Schedule Post
							</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
							
						<div class="container" style={{float:"left", width:"40%", padding:"0px"}}>
							<div class=""><br /><br />
							<video autoPlay playsInline loop muted id="active_vids" referrerPolicy="no-referrer" src={videoLink}  style={{objectFit: "cover", width: "80%", borderRadius:"20px",zIndex:"1"}} />
								<center>
								<Button style={{display: showButton}} onClick={() => this.setState({showModal: true})}>Add from Shoptik Library</Button>
									</center>
							<p class="vid_username">@{this.state.current_user}</p>	
							<p class="vid_caption">{this.state.post_caption}<span class="vid_hashtag"> {this.state.post_hashtags}</span></p>
						</div>
						</div>
							<form onSubmit={event => currentComponent.submitPost(event)}>
								<div class="add_prod_form">
							<label class="captextlabel" for="captext">Post Caption</label>
							<textarea id="captext" class="captionarea" type="textarea" placeholder="Write caption here..." onChange={event => this.setState({post_caption: event.target.value})} value={this.state.post_caption} id="product_title" name="product_title" ></textarea>	
									<br /><br /><label class="captextlabel" for="captext">Post Hashtags</label>
							<textarea id="captext" class="captionarea" type="textarea" placeholder="#fyp..." onChange={event => this.setState({post_hashtags: event.target.value})} value={this.state.post_hashtags} id="product_price" name="product_price" ></textarea>
									<br/><br/>
										<label class="captextlabel" for="captext">Scheduled Date/Time (in your current time zone)</label>
  <input required type="date" id="post_date" name="post_date" value={this.state.post_date} onChange={event => this.setState({post_date: event.target.value})} min={this.state.post_date_min} />
  <input required type="time" id="post_time" name="post_time"  value={this.state.post_time} onChange={event => this.setState({post_time: event.target.value})}/>
	<br/><br/>
			<label class="captextlabel" for="captext">Media</label>
							<br/>		
						<div id="" class="mediaarea">

							<div class="delete_vid" style={{zIndex:"999"}}>
								<i onClick={event => this.setState({videoLink: "", showButton: ""})} class="fa fa-times-circle" id="delete" style={{verticalAlign:"right", fontSize: "20px", color: "white",marginLeft: "-110px", position:"absolute",zIndex:"999"}}></i>
							</div >
				
							<video autoPlay playsInline loop muted id="active_vids" referrerPolicy="no-referrer" src={videoLink}  style={{objectFit: "cover", width: "70px" ,zIndex:"1"}} />
						</div>
									<center>
								<Button type="submit" class="tag_btn" variant="tag_btn"> Schedule Post <i className="fa fa-plus-circle" style={{verticalAlign:"right", fontSize: "15px", padding: "10px, 20px"}}></i></Button>
								</center>
							</div>	
							</form>
							</Modal.Body>
        			</Modal>
					
					

				<Modal size="sm" class="widemodal" show={this.state.showUpdateMedia} onHide={() => this.setState({ showUpdateMedia: false, videoLink: "",
		  showButton: "",
		  post_caption: "",
		  post_hashtags: ""})}>
						<Modal.Header closeButton style={{borderBottom:"0 none", height: "0px"}}>
                        	<Modal.Title id="contained-modal-title-vcenter" centered>
							Update Post
							</Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
							
						<div class="container" style={{float:"left", width:"40%", padding:"0px"}}>
							<div class=""><br /><br />
							<video autoPlay playsInline loop muted id="active_vids" referrerPolicy="no-referrer" src={videoLink}  style={{objectFit: "cover", width: "80%", borderRadius:"20px",zIndex:"1"}} />
								<center>
								<Button style={{display: showButton}} onClick={() => this.setState({showModal: true})}>Add from Shoptik Library</Button>
									</center>
							<p class="vid_username">@{this.state.current_user}</p>	
							<p class="vid_caption">{this.state.post_caption}<span class="vid_hashtag"> {this.state.post_hashtags}</span></p>
						</div>
						</div>
							<form onSubmit={event => currentComponent.submitPostUpdate(event)}>
								<div class="add_prod_form">
							<label class="captextlabel" for="captext">Post Caption</label>
							<textarea id="captext" class="captionarea" type="textarea" placeholder="Write caption here..." onChange={event => this.setState({post_caption: event.target.value})} value={this.state.post_caption} id="product_title" name="product_title" ></textarea>	
									<br /><br /><label class="captextlabel" for="captext">Post Hashtags</label>
							<textarea id="captext" class="captionarea" type="textarea" placeholder="#fyp..." onChange={event => this.setState({post_hashtags: event.target.value})} value={this.state.post_hashtags} id="product_price" name="product_price"></textarea>
									<br/><br/>
										<label class="captextlabel" for="captext">Scheduled Date/Time (in your current time zone)</label>
  <input  required type="date" id="post_date" name="post_date" value={this.state.post_date} onChange={event => this.setState({post_date: event.target.value})} min={this.state.post_date_min} />
  
  <input required type="time" id="post_time" name="post_time" value={this.state.post_time} onChange={event => this.setState({post_time: event.target.value})}/>
	<br/><br/>
			<label class="captextlabel" for="captext">Media</label>
							<br/>		
						<div id="" class="mediaarea">

							<div class="delete_vid" style={{zIndex:"999"}}>
								<i onClick={event => this.setState({videoLink: "", showButton: ""})} class="fa fa-times-circle" id="delete" style={{verticalAlign:"right", fontSize: "20px", color: "white",marginLeft: "-110px", position:"absolute",zIndex:"999"}}></i>
							</div >
				
							<video autoPlay playsInline loop muted id="active_vids" referrerPolicy="no-referrer" src={videoLink}  style={{objectFit: "cover", width: "70px" ,zIndex:"1"}} />
						</div>
									<center>
								<Button type="submit" class="tag_btn" variant="tag_btn"> Update Post <i className="fa fa-plus-circle" style={{verticalAlign:"right", fontSize: "15px", padding: "10px, 20px"}}></i></Button>
								</center>
							</div>	
							</form>
							</Modal.Body>
        			</Modal>

					
	<Modal size="sm2" class="widemodal2" id="testy" backdrop="static" keyboard={false} show={this.state.showUpgradeModal1} onHide={() => this.setState({ showUpgradeModal1: false })}>
                        <Modal.Body>
							<center>
							<h3 style={{color:"white", marginTop:"40px"}}><b> Automatically upgrade your plan now to Schedule TikTok posts</b></h3><br/>
							<p style={{color:"white", fontSize:"14px", fontWeight:"100"}}><b> You're already set up, we'll automatically upgrade you to the plan you select</b></p>
							<br />
								<center>
    <div class="prices">
        
        <div class="pricingplans">
        <div class="priceingbox" >
            <h1 class="pricetitle">Standard</h1>
            <hr class="price-hr" />
            <span id="underline"></span>
            <br />
            <h1 id="p2" class="price">$99</h1><span class="pricetext">&nbsp;/ month</span>
            <p id="underpricetext">For small businesses and shopify stores</p>
            <br />
            <button id="basic_monthly" class="paybtn2" onClick={event => this.attemptNewUpgrade(event, "standard_monthly")}>SELECT</button>
            <br /><br /><br />
			<img src="https://www.shoptik.co/images/krisicons/shoppable feed.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px", marginLeft: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/Analytics.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/Schedule.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/right influencer.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
        </div>
    </div>
        
        <div class="pricingplans"style={{marginTop:"-50px"}} >
        <div class="priceingbox" style={{marginTop:"-50px"}} >
            <h1 class="pricetitle">Professional</h1>
            <hr class="price-hr" />
            <span id="underline"></span>
            <br />
            <h1 id="p3" class="price">$199</h1><span class="pricetext">&nbsp;/ month</span>
            <p id="underpricetext">For large brands and companies</p>
            <br />
            <button id="standard_monthly" class="paybtn2" onClick={event => this.attemptNewUpgrade(event, "premium_monthly")}>SELECT</button>
            <br /><br /><br />
			
			
			<img src="https://www.shoptik.co/images/krisicons/shoppable feed.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px", marginLeft: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/Analytics.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/Schedule.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/right influencer.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/listening.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
        </div>
    </div>
        </div>
   </center>
			<br />
							<p style={{fontSize: "10px", color: "#fff"}}>*By continuing, your plan will automatically be upgraded to the one you select, and your next bill will be updated to reflect this change. </p>
							</center>
                        </Modal.Body>
        			</Modal>
					
					
					

					
					
					
					
					
					
					
					
					

					<Modal size="lg" show={this.state.showAddMedia2} onHide={() => this.setState({ showAddMedia2: false, product_image: "", product_link: "", product_price: "", product_title: "" })}>
						<Modal.Header closeButton style={{borderBottom:"0 none", height: "0px"}}>
                        	<Modal.Title id="contained-modal-title-vcenter" centered>
							Schedule Post
							</Modal.Title>
							
                        </Modal.Header>
					
                        <Modal.Body >
							<br />
							<div class="container" style={{float:"left", width:"50%", padding:"0px"}}>
							<div class="shop_items">
							
									<a href={this.state.product_link} target="_blank">

										
							<video controls autoplay muted id="active_vids" referrerPolicy="no-referrer" src={videoLink}  style={{objectFit: "cover", width: "200px", height:"340px", borderRadius:"10px",zIndex:"1"}} />
										
							<p id="shop_text">{this.state.post_caption}<p id="price">{this.state.post_hashtags}</p></p></a>
						</div>
						</div>
							<br /><br />
							<form >
							
								<div class="add_prod_form">
							
								<input required maxlength="40" onChange={event => this.setState({product_title: event.target.value})} value={this.state.product_title} id="product_title" name="product_title" type="text" placeholder="Caption" /> <br /><br />

							<input required onChange={event => this.setState({product_price: event.target.value})} value={this.state.product_price} id="product_price" name="product_price" type="text" placeholder="Hashtags" /> <br /><br />
									


								<br /><br />
								<center>
								<Button type="submit" class="tag_btn" variant="tag_btn"> Schedule Post <i className="fa fa-plus-circle" style={{verticalAlign:"right", fontSize: "15px"}}></i></Button>
								</center>
							</div>
							</form>
							</Modal.Body>

						
        			</Modal>
					
					<Modal size="sm" class="widemodal" class="widemodal" show={this.state.showModal} onHide={() => this.setState({ showModal: false, product_image: "", product_link: "", product_price: "", product_title: "" })}>
							<Modal.Title id="contained-modal-title-vcenter" centered>
								<br /><br />
							<center>Select Video</center>
							</Modal.Title>
						
						 <Modal.Body >
							 <center>
						{smallinfluencers3}
								 </center>
							 </Modal.Body>
        			</Modal>

					<NewSidebar selected={14}></NewSidebar>
			
				
				<br /><br /><br />
					<img id="profile_image" onClick={event => this.navigatePage(event, "settings")} src={this.state.thumbnail_url} />
					<div class="main">
						
						 <Snackbar open={this.state.openAlert} autoHideDuration={6000} onClose={()=>{this.setState({openAlert: false, alertMessage: ""})}}>
			 <MuiAlert elevation={6} variant="filled">{this.state.alertMessage}</MuiAlert>
		</Snackbar>

						<center>
<span class="newloader" style={{display: this.state.isloading}}></span>
		
	<div className="tail-datetime-calendar calendar-sect">
	
        <div className="calendar-navi">
          <span
            onClick={(e) => {
              this.onPrev();
            }}
            class="calendar-button button-prev"
          />
          {!this.state.showMonthTable && (
            <span
              class="calendar-label"
            >
              {this.month()}
            </span>
          )}
          <span
            className="calendar-label"
          >
            {this.year()}
          </span>
          <span
            onClick={(e) => {
              this.onNext();
            }}
            className="calendar-button button-next"
          />
        </div>

        <div className="calendar-date">
          {this.state.showYearTable && <this.YearTable props={this.year()} />}
          {this.state.showMonthTable && (
            <this.MonthList data={moment.months()} />
          )}
        </div>

        {this.state.showDateTable && (
          <div className="calendar-date">
            <table className="calendar-day">
              <thead>
                <tr>{weekdayshortname}</tr>
              </thead>
              <tbody>{daysinmonth}</tbody>
            </table>
          </div>
        )}
      </div>
			</center>

						
	<div class="medialibrarycal">
		<div>
			
		<p style={{color:"#2c88ff",fontSize:"21px",fontWeight:"500"}}>Schedule a Post</p>
		<p style={{fontSize: "12px", textAlign:"left"}}>Click anywhere on the calendar, or click on a video to schedule it. </p><hr/>
	<div class="medialibrary" style={{overflow: "scroll", height: "600px"}}>
	
			

     
		
		{smallinfluencers}</div>
	</div>	
</div>
					</div>
				</div>)
		} else {
			return(<p>LOGGED OUT</p>)
		}
	}
}

export default PostScheduler
//						<center>
//					<div class="rankbox_container" style={{width: "96%"}}>
//						<br /><br />
//						<p class="feature_description_text">TikTok Post Scheduling is Coming Soon!<br /></p><br /><br /><br />
//						
//						
//						<div style={{float: "left", width: "44%", textAlign: "left", marginLeft: "5%", marginBottom: "50px"}}>
//						<h5 style={{paddingBottom:"10px"}}>How does TikTok Post Scheduling work?</h5> 
//
//							<p class="check-text">1. Add videos to the media library page</p>
//							<p class="check-text">2. Click "Schedule New Post" </p>
//							<p class="check-text">3. Add your video from the media library, along with hashtags and a caption</p>
//							<p class="check-text">4. Download the Shoptik App and turn on push notifications</p>
//							<p class="check-text">5. Get Notified when your it's time to post your scheduled post!</p><br />
//							
//							<h5 style={{paddingBottom:"10px"}}>What else can I do with Post Scheduling </h5> 
//						
//							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> The media library makes it really easy to plan your feed</p>
//							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> Use the app to see what posts would look like</p>
//							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> Schedule unlimited posts, and update or remove them anytime before the actual posting time</p>
//							<br /><br />
//							
//							<form name="post scheduling demo" id="freedemo" method="POST" data-netlify="true">
//                        <input id="freedemoinput" name="business email" type="email" placeholder="Business Email" required />  
//                        <Button style={{padding: "12px 20px", marginLeft: "0px"}} type="submit" id="senddemo" name="senddemo" class="btn btn-primary">Get Free Demo</Button>
//                        <p style={{marginTop:"10px"}} id="freedemotext">We'll send you an email with setup instructions within 24 hours</p>
//                        </form>
//							
//					
//						</div>
//						<div style={{width: "44%", float: "right",}}>
//							<center>
//								
//							<img width="600px" 
// src="https://www.socialbakers.com/website/img/pages/platforms/scheduling-publishing/01.png" style={{marginTop:"-20px"}}></img>
//
//							</center>
//						</div>
//					</div>
//						</center>

 //onClick={(e) => this.showYearTable()}

//
//
//<div style={{float: "left", display: "inline-block",width: "30%"}}>
//		<h1>Media Gallery</h1>
//	<div class="medialibrary">{smallinfluencers}</div>
//	</div>	


//		<FileDrop
//          onFrameDragEnter={(event) => this.setState({
//					showDrop: ""
//				})}
//          onFrameDragLeave={(event) => this.setState({
//					showDrop: "none"
//				})}
//          
//          onDrop={(files, event) => this.uploadFiles(files, event)}
//        >
//				<div id="" class="mediaarea">
//										
//<Button >
//				
//			<label for="file-upload2">
//			Upload from Computer
//			</label>
//			
//				</Button>
//							
//				<input accept="video/*" id="file-upload2" class="plus" style={{ width:"40px", height:"40px", backgroundColor:"transparent", display: "none"}}
//		 type="file" onChange={ (e) => currentComponent.addFile(e.target.files[0]) }></input>
//	
//				</div>


//MINI LIBRARY

//
//<Modal.Body>
//							<Modal.Title id="contained-modal-title-vcenter" centered>
//							Add from Library
//								<br /><br />
//								
//								{smallinfluencers}
//							</Modal.Title>
//                        </Modal.Body>


//<form name="post scheduling demo" id="freedemo" method="POST" data-netlify="true">
//                        <input id="freedemoinput" name="business email" type="email" placeholder="Business Email" required />  
//                        <Button style={{padding: "12px 20px", marginLeft: "0px"}} type="submit" id="senddemo" name="senddemo" class="btn btn-primary">Get Free Demo</Button>
//                        <p style={{marginTop:"10px"}} id="freedemotext">We'll send you an email with setup instructions within 24 hours</p>
//                        </form>
//							