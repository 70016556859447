import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import addnew from './add_vid.jpg';
import Sidebar from "react-sidebar";

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import cheerio from 'cheerio';
import NewAlert from './NewAlert';

import shoptik_icon from './Help/shoptik_icon.png';
import pic1 from './Help/1.jpg';
import pic2 from './Help/2.jpg';
import pic3 from './Help/3.jpg';
import pic4 from './Help/4.png';
import pic5 from './Help/5.jpg';
import pic6 from './Help/6.jpg';
import pic7 from './Help/7.png';

import manual1 from './Help/manual1.png';
import manual2 from './Help/manual2.jpg';
import manual3 from './Help/manual3.png';

import ReactPlayer from "react-player";


//var htmlDoc = {__html: Page};

class Home extends Component {
	constructor(props){
		super(props);
		
		this.navigatePage = this.navigatePage.bind(this);
		this.createMarkup = this.createMarkup.bind(this)
	}
	
	createMarkup() {
//		return {__html: 'First &middot; Second'}; 
	}
	
	navigatePage(event, type){
		if (type == "feed"){
			var base_url = window.location.origin;
			window.open((base_url + '/' + this.state.current_user), '_blank');
		} else {
			var base_url = window.location.origin;
		window.location.href = base_url + '/dashboard/' + type;
		}
	}

	componentDidMount(){
		

	}



	render() {
			return(
				<iframe src="https://www.shoptik.co/"
  style={{position: "fixed", top: "0px", bottom: "0px", right: "0px", width: "100%", border: "none", margin: "0", padding: "0", overflow: "hidden", zIndex: "999", height: "100%"}} />
				
			)
		}
}

export default Home

//<div dangerouslySetInnerHTML={htmlDoc} />
	
