import React, { useState, useEffect, Component } from 'react';
import Feed from './Feed';
import Dashboard from './Dashboard';
import Button from 'react-bootstrap/Button';

import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import Select from 'react-select';

import addnew from './add_vid.jpg';

import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Link, Switch, Redirect, withRouter } from "react-router-dom";

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import shoptik_icon from './Help/shoptik_icon.png';

import { loadStripe } from '@stripe/stripe-js';


import ReactPixel from 'react-facebook-pixel';


const stripePromise = loadStripe('pk_live_51H36KeIRpv6K8jIKujJotzP0qT6Bz8sipC2yaMYWLP7iYhur05TqxvXqUNtfN9wMqTZP3ISEPGBCyAHlXg89yMWf00xYpnid9Z');

let Options = [
	{ label: "Basic Free Trial", value: "analytics_monthly" },
  	{ label: "Standard Free Trial", value: "standard_monthly" },
	{ label: "Professional Free Trial", value: "premium_monthly" },
];

//<option value="analytics_monthly" selected={this.state.optionsState == "analytics_monthly"}>Basic Free Trial</option>
//					<option value="standard_monthly" selected={this.state.optionsState == "standard_monthly"}>Standard Free Trial</option>
//					<option value="premium_monthly" selected={this.state.optionsState == "premium_monthly"}>Professional Free Trial</option>

class Register extends Component {
	constructor(props){
		super(props);
		this.state = {
			isLogged: false,
			openAlert: false,
			alertMessage: "Your username or password is incorrect",
			showModal: false,
			discount: false,
			openDiscount: false,
			discountMessage: "Congratulations, Discount Code Applied!",
			discount_text: "",
			apply_discount_text: "Apply Discount Code",
			freediscount: false,
			optionsState: "standard_monthly",
			 hidden: true
		}
		this.attemptRegister = this.attemptRegister.bind(this);
		this.applyDiscount = this.applyDiscount.bind(this);
		this.kFormatter = this.kFormatter.bind(this);
		this.toggleShow = this.toggleShow.bind(this);
		this.handleChange = this.handleChange.bind(this);
	}
	
	handleChange(e){
		this.setState({
			optionsState: e.value
		})
	}
	
//	facebookClick() {
//
//		console.log("facebook clicked");
//		var data = {
//			value: 199,
//			currency: '$',
//			subscription_id: 
//		}
//		ReactPixel.track('StartTrial', data);
////  fbq('track', 'StartTrial', {
////    value: 199,
////    currency: '$',
////    subscription_id: 'tiktok_account',
////  });
////		
//	}
	
	async applyDiscount(event){
		event.preventDefault();
		
		
		var discount_code = document.getElementById("discount").value;
		
		if (discount_code == "free4life"){
			this.setState({
				freediscount: true,
				discount_text: "100% Discount on Checkout Applied!",
				apply_discount_text: "",
				openDiscount: true,
				showModal: false
			})
		}
//		else 
		else if (discount_code == "mutesix20"){
			
			//flash discount applied
			this.setState({
				discount: true,
				openDiscount: true,
				showModal: false,
				discount_text: "20% Discount on Checkout Applied!",
				apply_discount_text: ""
			})
		} else {
			this.setState({
				showModal: false,
				openAlert: true,
				alertMessage: "Oops. No discount found"
			})
		}
	}
	
kFormatter(num, digits) {

  var si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "k" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "B" },
    { value: 1E12, symbol: "T" },
    { value: 1E15, symbol: "P" },
    { value: 1E18, symbol: "E" }
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}
	
  
	
	async attemptRegister(event){
		event.preventDefault();
		
		var brand = document.getElementById("brand").value;
		var tiktok_handle = document.getElementById("tiktok_handle").value.replace("@", "");
		var email = document.getElementById("email").value;
		var password = document.getElementById("password").value;
//		var verifypassword = document.getElementById("password2").value;
//		
//		if (password != verifypassword){
//			this.setState({
//				openAlert: true,
//				alertMessage: "Please ensure password and verify password match"
//			})
//			return;
//		}
//		

		var plan = document.getElementById("plan").value;
		if (tiktok_handle == "asd"){
			return;
		}
		
		//console.log("facebook clicked");
		var data = {
			value: 199,
			currency: '$',
			subscription_id: email
		}
		ReactPixel.track('StartTrial', data);

		
//		console.log("PLAN:", plan);
//		var discount = this.state.discount;
//		var final_plan = "";
//		if (this.state.freediscount == true){
//			final_plan = "free";
//			plan = "free"
//		}
//		else if (discount == true){
//			if (plan == "standard_monthly"){
//				final_plan = "price_1H4zyRIRpv6K8jIKIAJeRwdP";
//			} else if (plan == "standard_annual"){
//				final_plan = "price_1H4zyRIRpv6K8jIKDCNS5G62";
//			} else if (plan == "premium_monthly"){
//				final_plan = "price_1H4zyRIRpv6K8jIKxXgzAZka";
//			} else if (plan == "premium_annual"){
//				final_plan = "price_1H4zyRIRpv6K8jIKTICbncSE";
//			} else if (plan == "analytics_monthly"){
//				final_plan = "price_1HKpgiIRpv6K8jIKJPpj8jrC";
//			} else if (plan == "analytics_annual"){
//				final_plan = "price_1HKpgiIRpv6K8jIKMdX3GVzT";
//			}
//		} else {
//			if (plan == "standard_monthly"){
//				final_plan = "price_1H4zyWIRpv6K8jIKDY0bePvj";
//			} else if (plan == "standard_annual"){
//				final_plan = "price_1H4zyWIRpv6K8jIKkpB3ImAy";
//			} else if (plan == "premium_monthly"){
//				final_plan = "price_1H4zyWIRpv6K8jIKVUgq9HBf";
//			} else if (plan == "premium_annual"){
//				final_plan = "price_1H4zyXIRpv6K8jIKSforpntN";
//			} else if (plan == "analytics_monthly"){
//				final_plan = "price_1HKpYrIRpv6K8jIKjE0138Dg";
//			} else if (plan == "analytics_annual"){
//				final_plan = "price_1HKpYsIRpv6K8jIKDMdOgHua";
//			}
//		}
		
//		console.log("plan: ", plan, "final_plan: ", final_plan
		//move this to on register
		
		//lets redo registration, need these 4
		
//		let newresp = await fetch("https://tiktok.p.rapidapi.com/live/user?username=" + current_user + "&fresh=1", {
//			"method": "GET",
//			"headers": {
//				"x-rapidapi-key": "865f499c17mshb07873cd88baaa1p116340jsneb0033f90f8e",
//				"x-rapidapi-host": "tiktok.p.rapidapi.com"
//			}
//		})
//		let newdata = await newresp.json();
//		
//		var followingCount = newdata.following_count;
//		var followerCount = newdata.follower_count;
//		var heartCount = newdata.total_favorited;
//		var videoCount = newdata.video_count;	
//		
//		'followers': this.kFormatter(newdata.followerCount);
//		'following': this.kFormatter(newdata.followingCount);
//		'likes': this.kFormatter(newdata.heartCount);
//		'thumbnail_url': newdata.avatar_thumb
//		
//		
//		
		
		
		
		
		
		
		
		var manual_link = 'https://www.tiktok.com/@' + tiktok_handle + '?lang=en'
		var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + manual_link;
		const scrape_data = await fetch(scrape_url);
		var all_text = await scrape_data.text();

		var key1 = "followingCount\":";
		var key2 = "followerCount\"";

		var following = "0";
		try {
			following = this.kFormatter((parseInt(all_text.match(new RegExp(key1 + "(.*)" + key2))[1].replace(/['",]+/g, ''), 10)), 1);
			
		var formatted_likes = 
			console.log("following: ", following);
		} catch (err) {
			
		}
		
		var key3 = "followerCount\":";
		var key4 = "heartCount\"";
		var followers = "0";
		
		try {
			followers = this.kFormatter((parseInt(all_text.match(new RegExp(key3 + "(.*)" + key4))[1].replace(/['",]+/g, ''), 10)), 1);
			//console.log("followers", followers);
		} catch (err) {
			
		}
		
		var key5 = "heartCount\":\"";
		var key6 = "videoCount\"";
		var likes = "0";
		
		try {
			likes = this.kFormatter((parseInt(all_text.match(new RegExp(key5 + "(.*)" + key6))[1].replace(/['",]+/g, ''), 10)), 1);
			//console.log("likes:", likes);
		} catch (err) {
			
		}

		var key7 = "avatarMedium\":\"";
		var key8 = "jpeg\",\"signature\"";
		var thumbnail_url = "https://p16-va-tiktok.ibyteimg.com/img/musically-maliva-obj/82ba0916b0ef0ce1dbd5f635274550ea~c5_720x720.jpeg";
		
		try {
			thumbnail_url = all_text.match(new RegExp(key7 + "(.*)" + key8))[1] + "jpeg";
			//console.log("thumbnail_url", thumbnail_url);
		} catch (err) {
			
		}
		
		var plan = "premium_monthly"
		
		var info = {
			'brand': brand,
			'tiktok_handle': tiktok_handle, 
			'email': email,
			'password': password,
			'plan': "price_1H4zyWIRpv6K8jIKVUgq9HBf",
			'plan_title': plan,
			'followers': followers,
			'following': following,
			'likes': likes,
			'thumbnail_url': thumbnail_url
		}
		//return;
		//lets fetch and scrape the profile data here
		//scrape tiktok for the needed values, append it to video state
				fetch('https://shoptik-app.herokuapp.com/newtrial',{
					method: "POST",
					body: JSON.stringify(info),
					credentials: 'include',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
				}).then(response => {
					response.json().then(async data =>{
						if (data.status === 400) {
							this.setState({
								openAlert: true,
								alertMessage: "Oops. An account with this name already exists. Login instead, or contact us if you believe this is a mistake"
							})
						} else if (data.status === 200){
//							if (data.message == "free"){
							const sessionId = data.message;
								var base_url = window.location.origin;
								window.location.href = base_url + '/dashboard/manage_media?l=t&f=t';
								return;
//							} else {
//								const sessionId = data.message.id
//								const stripe = await stripePromise;
//								const { error } = await stripe.redirectToCheckout({
//									sessionId
//								})
//							}
						}
					})
				})
				
		return;
	}
	
	componentDidMount() {
		let params = (new URL(document.location)).searchParams;
		let plan = params.get("plan");
		
		if (plan != null){
			this.setState({
				optionsState: plan
			})
			//auto select the dropdown with the plan
		}
		
		//const ReactPixel =  require('@zsajjad/react-facebook-pixel');
    	ReactPixel.init('2679971765575143');
    }

	toggleShow() {
		this.setState({ hidden: !this.state.hidden });
	  }
	
	render() {			
		return (
			
			<div class="full_page" style={{backgroundColor:"#fff"}}>
				<div class="rightside">
					<br/>
					<center>
				<h1>You're in good hands</h1>
						
				<h2>Get ready to convert followers into customers directly
on TikTok. Track performance and ensure your followers find
their favorite looks in a breeze.</h2>
						
				<h1>Trusted by these companies</h1>		
						<br /><br />
				
    <section class="" id="trusted">
        <div class="container">
            <div class="row">
                <div class="col-md client-logo mb-4 mb-lg-0">
                    <img src="https://www.boots.com/resource/blob/1984406/eefda1af785fdc9dfa62e3c4cd244b83/2019-08-project-indiana-bt-overlays-data.png" style={{transform: "scale(1.3)"}} alt="" class="img-fluid mx-auto d-block p-4" />
                </div>
                <div class="col-md client-logo mb-4 mb-lg-0">
                    <img src="https://cdn.shopify.com/s/files/1/0100/6727/9953/files/logo_410x.png?v=1569826441" alt="" class="img-fluid mx-auto d-block p-4" style={{marginTop:"-10px"}}/>
                </div>

            </div>
			 <div class="row">
                <div class="col-md client-logo mb-4 mb-lg-0">
                    <img src="https://images.typeform.com/images/ymSeLvz5Ld5f/image/default" alt="" class="img-fluid mx-auto d-block p-4" />
                </div>
                <div class="col-md client-logo mb-4 mb-lg-0">
                    <img src="https://cdn.shopify.com/s/files/1/2540/2076/files/Sunbae_transparent_black_text_x60.png?v=1584978260" style={{width:"300px",marginTop:"0px"}} alt="" class="img-fluid mx-auto d-block p-4" />
                </div>
            </div>
        </div>
    </section>
			<h2>...and many more</h2>			
						
					</center>
				</div>
				<br/><br/><br/><br/>
			<div>
				
				<div class="register_div" style={{marginTop:"20px"}}>
				<p id="welcome" style={{marginTop:"5px"}}>Try <span id="login-title">Shoptik</span> for Free</p>
				<h2>Start your 7 day free trial. <b>No credit card required</b>. Cancel anytime.</h2>
			
				<br />	
					
				<form onSubmit={event => this.attemptRegister(event)}>
				
				<div class="half_inputs">
				<p class="input_title">First name</p>
				<input required id="brand" name="email" type="text" placeholder="Fashion Nova" /> <br /><br />	
				</div>
					
				<div class="half_inputs" style={{marginLeft:"30px"}}>
				<p class="input_title">TikTok handle (case sensitive)</p>
				<input required id="tiktok_handle" name="email" type="text" placeholder="fashionnova" /> <br /><br />	
				</div>
				
				<br />
				<div class="half_inputs">
				<p class="input_title">Email address</p>
				<input required id="email" name="email" type="email" placeholder="john@fashionnova.com" /> <br /><br />	
				</div>
					
				<div class="half_inputs" style={{marginLeft:"30px"}}>
				<p class="input_title">Password<span id="required_span"><span><i onClick={(event) => this.setState({ hidden: !this.state.hidden })} className={this.state.hidden ? 'fa fa-eye' : 'fa fa-eye-slash'} style={{verticalAlign:"right", fontSize: "15px", marginLeft: "5px", display: "inline-block"}}></i> </span></span></p>
				<input minlength="8" required id="password" type={this.state.hidden ? 'password' : 'text'} name="email" placeholder="minimum 8 characters" /><br /><br />
				</div>
					
					
				
					
				<div class="full_inputs"><br />
				<p class="input_title">Free Trial Plan (you will not be billed)</p>
					
					
						

					
					
				<select required id="plan" name="subject" type="text">
                                         
					<option value="analytics_monthly" selected={this.state.optionsState == "analytics_monthly"}>Basic Free Trial</option>
					<option value="standard_monthly" selected={this.state.optionsState == "standard_monthly"}>Standard Free Trial</option>
					<option value="premium_monthly" selected={this.state.optionsState == "premium_monthly"}>Professional Free Trial</option>
					           
                </select> <br /><br />
				</div>
					<br />
					<button class="register_btn" id="startTrialButton" type="submit">Sign up</button>
					<h3><i>Credit card is not required</i></h3>
					
					
					
					<br /><br />
				
					<p id="no_acc">Already have an account? <a href="https://www.shoptik.app/dashboard/login" class="login_text2">Log in</a></p>	
				</form>
				
				</div>
				
	
				</div>
				
				
				<br /><br />
				
				
				
					<Modal size="m" show={this.state.showModal} onHide={() => this.setState({ showModal: false })}>
						<Modal.Header closeButton style={{borderBottom:"0 none", height: "0px"}}>
                        	<Modal.Title id="contained-modal-title-vcenter" centered>
							Add Discount Code
							</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
							<br /><br />
                     		<center>
								
							<input style={{width: "300px"}} id="discount" name="discount" type="text" placeholder="discount code" /> <br />
								
							</center><br />
							<center>
					  
						<Button style={{width: "150px", height: "40px"}} class="sign_in" variant="sign_in" id="sign_in_btn" onClick={event => this.applyDiscount(event)} > <b>Apply</b> </Button>	
						</center>
							
                        </Modal.Body>
        			</Modal>
				
				
				
				<Snackbar open={this.state.openAlert} autoHideDuration={10000} onClose={()=>{this.setState({openAlert: false})}}>
						
					<MuiAlert severity="error" elevation={6} variant="filled">{this.state.alertMessage}</MuiAlert>
				 </Snackbar>
				
				<Snackbar open={this.state.openDiscount} autoHideDuration={3000} onClose={()=>{this.setState({openDiscount: false})}}>
						
					<MuiAlert severity="success" elevation={6} variant="filled">{this.state.discountMessage}</MuiAlert>
				 </Snackbar>
				
				
				<div><br /><br /><br /><br /><br /><br /></div>
			
				
			</div>
			)
	}
}

export default withRouter(Register)
		
//				<div class="half_inputs" style={{marginLeft:"30px"}}>
//				<p class="input_title">Verify Password<span id="required_span">*</span></p>
//				<input minlength="8" required id="password2" type="password" name="email" placeholder="verify password" /> <br /><br />	
//				</div>

//				<div class="full_inputs">
//				<p class="input_title">Plan<span id="required_span">*</span></p>
//				<select required id="plan" name="subject" type="text" style={{marginBottom:"-4px"}}>
//                                         
//					<option value="standard_monthly" selected={this.state.optionsState == "standard_monthly"}>Basic Monthly Free Trial ($99 USD/mo)</option>
//					<option value="premium_monthly" selected={this.state.optionsState == "premium_monthly"}>Standard Monthly Free Trial ($199 USD/mo)</option>
//					<option value="standard_annual" selected={this.state.optionsState == "standard_annual"}>Basic Annual Free Trial ($79 USD/mo, Save 25%)</option>
//					<option value="premium_annual" selected={this.state.optionsState == "premium_annual"}>Standard Annual Free Trial ($149 USD/mo, Save 25%)</option>
//					<option value="analytics_monthly" selected={this.state.optionsState == "analytics_monthly"}>Analytics Monthly Free Trial ($24.99 USD/mo)</option>
//					<option value="analytics_annual" selected={this.state.optionsState == "analytics_annual"}>Analytics Annual Free Trial ($19.99 USD/mo, Save 20%)</option>
//                                            
//                </select> <br /><br />
//				</div>


//					<p style={{fontSize: "13px", color: "green"}} id="no_acc">{this.state.discount_text}<a href="#" onClick={event => this.setState({showModal: true})} class="login_text">{this.state.apply_discount_text}</a></p>
//					

//<Select required id="plan" name="subject" autosize={false} className="select" options={ Options } placeholder={this.state.optionsState} onChange={this.handleChange.bind(this)} />