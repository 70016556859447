import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import addnew from './add_vid.jpg';
import Sidebar from "react-sidebar";
import NewSidebar from "./NewSidebar";

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import cheerio from 'cheerio';
import NewAlert from './NewAlert';

import shoptik_icon from './Help/shoptik_icon.png';
import pic1 from './Help/1.jpg';
import pic2 from './Help/2.jpg';
import pic3 from './Help/3.jpg';
import pic4 from './Help/4.png';
import pic5 from './Help/5.jpg';
import pic6 from './Help/6.jpg';
import pic7 from './Help/7.png';

import manual1 from './Help/manual1.png';
import manual2 from './Help/manual2.jpg';
import manual3 from './Help/manual3.png';

import ReactPlayer from "react-player";

class VideoPreview extends Component {
	constructor(props){
		super(props);

	}
	

	componentDidMount(){
		
		const script = document.createElement("script");
		script.src = "https://www.tiktok.com/embed.js";
		script.async = true;
		document.body.appendChild(script);

	}

	render() {
 
		
			return(
				<div>
				</div>
				)
	}
}

export default VideoPreview