import React, { Component, PureComponent } from 'react';
import ReactDOM from 'react-dom';
import Imageview from './components/imageview'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import shoptik_icon from './Help/shoptik_icon.png';

import ReactPlayer from 'react-player';


import cheerio from 'cheerio';

import Skeleton from '@material-ui/lab/Skeleton';

import Sidebar from "react-sidebar";
import verified from './Help/verified.png';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import VideoPreview from './VideoPreview';

class FeedItems extends PureComponent {
	constructor(props){
		super(props);
			//set state(none for now)
			 this.state = { //state is by default an object
				 videos: [],
				 links: [],
				 productstore: [],
				 show: false,
				 current_video: "",
				 feed: true,
				 left_opacity: 1,
				 right_opacity: 0.15,
				 showEmail: true,
				 showEmailAllowed: false,
				 email_placeholder: "",
				 email_title: "",
				 email_address: "",
				 showAnalytics: false,
				 final_items: [],
				 current_index: 0
			}

			this.getClientData = this.getClientData.bind(this);
			this.getProducts = this.getProducts.bind(this);
			this.productClicked = this.productClicked.bind(this);
			this.newestTikToks = this.newestTikToks.bind(this);
			this.testError = this.testError.bind(this);
			this.testError2 = this.testError2.bind(this);
			this.testError3 = this.testError3.bind(this);
			this.showEmailClicked = this.showEmailClicked.bind(this);
			this.sendEmail = this.sendEmail.bind(this);

		
			this.attemptUpdate = this.attemptUpdate.bind(this);
		
//		this.pauseVideo = this.pauseVideo.bind(this);
		}
	
//	pauseVideo(indexref){
//		//alert("pausing");
//		var vid = document.getElementById(indexref);
//		vid.src = indexref;
//		vid.pause();
//	}
//	
	attemptUpdate(event){
		event.preventDefault();
		var plan = this.state.plan;
		var tiktok_handle = this.state.profile_name;
	}

		sendEmail(event){
			event.preventDefault();
			// ensure email_address is a real email
			var re = /\S+@\S+\.\S+/;
			if (!re.test(this.state.email_address)){
				this.setState({
					openAlert2: true,
					alertMessage2: "Invalid email address",
				})	
				//show msg
				return;
			}
		var info = {
			'tiktok_name': this.state.profile_name, 
			'email_address': this.state.email_address,
		}
		
		this.setState({
			openAlert: true,
			alertMessage: "Thanks for submitting!",
			showEmail: false,
		})	
		
		fetch('https://shoptik-app.herokuapp.com/dashboard/storeemail', {
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
					} else {
				   }
				})
			})
		}
	
		async newestTikToks(vid_id, client, videos) {
			var new_url = 'https://www.tiktok.com/@' + client + '/video/' + vid_id + '?lang=en'
			var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url
			
			const scrape_data = await fetch(scrape_url)
			const $ = cheerio.load(await scrape_data.text())
			
			var finalurl = JSON.parse($('div #videoObject').html())["contentUrl"];
			
			var newvideos = videos
			newvideos[0]['thumbnail'] = finalurl
			
			this.setState({
				videos: newvideos
			})
		}
	
		getClientData(client) {
			//fetch
			fetch('https://shoptik-app.herokuapp.com/' + client, {
				method: "GET",
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					
					//try producing products and feed here instead
					
					
					
					if (data.status === 200){
						this.setState({
							isLogged: true,
							thumbnail_url: data.data.thumbnail_url,
							following: data.data.following,
							followers: data.data.followers,
							likes: data.data.likes,
							profile_name: data.data.profile_name,
							bio: data.data.bio,
							bio_link: data.data.bio_link,
							bio_string: data.data.bio_string,
							videos: data.data.videos,
							productstore: data.data.store.reverse(),
							
							showEmailAllowed: data.data.email_addon,
							email_placeholder: data.data.email_placeholder,
							email_title: data.data.email_title,
							plan: data.data.plan,
				
						}, () => setTimeout(function() {
							if (data.data.plan == "analytics_monthly" || data.data.plan == "analytics_annual"){
								this.setState({
									show: false,
									showAnalytics: true
								})
							}
						}.bind(this), 4000))
						
						//console.log("plan:", data.data.plan);

					} else {
						//probably change what happens here
						
						var base_url = window.location.origin;
						window.location.href = base_url + '/home';
						this.setState({
							isLogged: false,
						})
						//redirect them to login page here
				   }
				})
			})

		}
	
		//track_product_clicks
		productClicked(event, link_id, clicks, product_link){
			fetch('https://shoptik-app.herokuapp.com/trackproductclicks?product_id=' + link_id + '&product_clicks=' + clicks, {
					method: "GET",
					credentials: 'include',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
				}).then(response => {
					response.json().then(data =>{
						if (data.status === 400) {
							
						} else if (data.status === 200){
							window.location = product_link;
//							window.open(product_link, '_blank');
								var results = data.data;
						}
					})
				})

		}

		componentDidMount(){
			var pathArray = window.location.pathname.split('/');
			var client = pathArray[1];
//			this.getClientData(client);
			
			const script = document.createElement("script");

		script.src = "https://www.tiktok.com/embed.js";
		script.async = true;

		document.body.appendChild(script);

		}
	
		//onerror function for current clicked video

		//fetch products with the same link id
		getProducts(event, vid_id, thumbnail, clicks, current_index) {
			this.setState({
				links: [],
				current_vid: this.props.videos[current_index]['thumbnail_link'],
				tapinfo: true
			})
			
			
			var current_vid = thumbnail;
			if (thumbnail.includes("tiktokcdn")){
				current_vid = "https://onlinetik.com/wp-admin/admin-ajax.php?action=wppress_tt_stream&url=" + thumbnail;
			}

			
//			var current_vid = "https://onlinetik.com/wp-admin/admin-ajax.php?action=wppress_tt_stream&url=" + thumbnail;
			this.setState({
				current_vid: current_vid,
				current_index: current_index,
				vidbackground: this.props.videos[current_index]['thumbnail_link'],
				current_vid_id: this.props.videos[current_index]['tiktok_video_id']
			}, () => this.setState({
				show: true,
			}))
			fetch('https://shoptik-app.herokuapp.com/products?vid_id=' + vid_id + '&clicks=' + clicks, {
					method: "GET",
					credentials: 'include',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
				}).then(response => {
					response.json().then(data =>{
						if (data.status === 400) {
						
							//show error popup
						} else if (data.status === 200){
								var results = data.data;
								this.setState({
									links: results.links
								})	
						}
					})
				})
		}
	
		async testError2(video_id, objectId, index){
			
			//console.log("INDEX: ", index, "video_id", video_id);
		
			var new_url = "https://www.tiktok.com/oembed?url=https://www.tiktok.com/@" + this.state.profile_name + "/video/" + video_id;
			
			var scrape_url = await fetch(new_url);
			var all_text2 = await scrape_url.text();

			var key3 = "thumbnail_url\":\"";
			var key4 = "\"provider_url";

			var fin_url = all_text2.match(new RegExp(key3 + "(.*)" + key4));
			try {
				var thumbnail_url = fin_url[1].replace(/['",]+/g, '');
				//var newvideos = this.props.videos;
				
				//console.log("thumb: ", thumbnail_url);
				//alert("newurl:", thumbnail_url);
				//newvideos[index]['thumbnail_link'] = thumbnail_url;
				
			var info = {
				'vid_id': objectId,
				'thumbnail_link': thumbnail_url,
			}
				
			if (thumbnail_url.includes("tiktokcdn")){
				fetch('https://shoptik-app.herokuapp.com/update_thumbnail', {
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{

					if (data.status === 200){
						//console.log("rerendering")
//						this.setState({
//							videos: newvideos
//						})
						
						window.location.reload();
					} else {

				   }
				})
			})
			}

			} catch (err) {
				
			}
	}
	
	async testError3(index){
		
		//console.log("testerror3 called");
		var tiktok_vid_id = this.props.videos[index].tiktok_video_id;
		var vid_id = this.props.videos[index].vid_id;
		
		//lets fetch the newest one, overwrite it, setstate of new_url to be that 
		
		//console.log("tiktok_vid_id", tiktok_vid_id);
		
		
		var is_chrome = ((navigator.userAgent.toLowerCase().indexOf('chrome') > -1) &&(navigator.vendor.toLowerCase().indexOf("google") > -1));
		
		var is_chrome_ios = /CriOS/.test(navigator.userAgent);
		
		if (is_chrome){

		}

		var client = this.state.profile_name;
		var videos = this.props.videos;
		
		var new_url = 'https://www.tiktok.com/@' + client + '/video/' + tiktok_vid_id + '?lang=en'
			
		var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url
		
			const scrape_data = await fetch(scrape_url);
			var all_text = await scrape_data.text();
			var key3 = "urls\":";
			var key4 = "videoMeta\"";
		
			var finalurl = "";

			try {
				
				var fin_url = all_text.match(new RegExp(key3 + "(.*)" + key4));
				
				finalurl = fin_url[1].replace(/['",]+/g, '').slice(1,-1);
				//finalurl = finalurl.replace('-web','');
			
				
				if (finalurl.includes("tiktokcdn")){
					
					this.setState({
						current_vid: "https://onlinetik.com/wp-admin/admin-ajax.php?action=wppress_tt_stream&url=" + finalurl
					})
					
					var info = {
						'vid_id': vid_id,
						'video_url': finalurl,
					}
					
					fetch('https://shoptik-app.herokuapp.com/update_video', {
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					
					//window.location.reload(false);
					
					if (data.status === 200){
					} else {

				   }
				})
			})
					
				}

			} catch(err) {
				//console.log(err);	
			}  
	}

	async testError(tiktok_vid_id, vid_id, index){
		var is_chrome = ((navigator.userAgent.toLowerCase().indexOf('chrome') > -1) &&(navigator.vendor.toLowerCase().indexOf("google") > -1));
		
		var is_chrome_ios = /CriOS/.test(navigator.userAgent);
		
		if (is_chrome){

		}

		var client = this.state.profile_name;
		var videos = this.state.videos;
		
		var new_url = 'https://www.tiktok.com/@' + client + '/video/' + tiktok_vid_id + '?lang=en'
			
		var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url
		
			const scrape_data = await fetch(scrape_url);
			var all_text = await scrape_data.text();
			var key3 = "urls\":";
			var key4 = "videoMeta\"";
		
			var finalurl = "";

			try {
				
				var fin_url = all_text.match(new RegExp(key3 + "(.*)" + key4));
				
				finalurl = fin_url[1].replace(/['",]+/g, '').slice(1,-1);
				finalurl = finalurl.replace('-web','');
			
				
				if (finalurl.includes("tiktokcdn")){
					
					var info = {
						'vid_id': vid_id,
						'video_url': finalurl,
					}
					
					fetch('https://shoptik-app.herokuapp.com/update_video', {
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					
					window.location.reload(false);
					
					if (data.status === 200){
					} else {

				   }
				})
			})
					
				}

			} catch(err) {
				//console.log(err);	
			}
	}
	
	showEmailClicked(event){
		var icon = document.getElementById('drop');
		
		if (this.state.showEmail == true){
			this.setState({
				showEmail: false
			})
			icon.className = 'fa fa-caret-down';
		} else {
			this.setState({
				showEmail: true
			})
			icon.className = 'fa fa-caret-down open';
		}
	}

	render() {

			let currentComponent = this;

			var items = [];
			var sidebar = [];
			var video_content = [];
			var product_items = [];
			var final_items = [];
//			if (this.state.feed){
	
				var is_chrome = ((navigator.userAgent.toLowerCase().indexOf('chrome') > -1) &&(navigator.vendor.toLowerCase().indexOf("google") > -1));


				if (navigator.userAgent.match(/Android/i)){
					items = this.props.videos.map(function(item, index){
						
						var thumburl = "https://www.tiktok.com/api/img/?itemId=" + item.tiktok_video_id + "&location=1";
//					if (!item.thumbnail_link.includes("trello")){
//						thumburl = item.thumbnail_link;	
//					}
					if (item.thumbnail.includes("florence")){
						thumburl = item.thumbnail_link;
					}
						
					return (
						<div style={{width: "33.3%", float: "left"}}>

							<p class="vid_bag"><i class="fa fa-shopping-bag" aria-hidden="true"></i></p>

							<img referrerPolicy="no-referrer" src={thumburl} style={{float:"left", width:"100%", padding:"1px", objectFit: "cover", height:"180px"}} onClick={event => currentComponent.getProducts(event, item.vid_id, item.thumbnail, item.clicks, index)}>
							</img>
					</div>

						)	
					});
				}
				
				 else if (is_chrome && this.props.visual != true){
					 return (<div><center>Videos and products at this link will only appear on mobile. Put this link in your tiktok bio.</center></div>)
					items = this.props.videos.map(function(item, index){
						
						
					var thumburl = "https://www.tiktok.com/api/img/?itemId=" + item.tiktok_video_id + "&location=1";
//					if (!item.thumbnail_link.includes("trello")){
//						thumburl = item.thumbnail_link;	
//					}
					if (item.thumbnail){
					if (item.thumbnail.includes("florence")){
						thumburl = item.thumbnail_link;
					}
					}
						
					return (
					
						<center>
					<div style={{width: "30%", position: "center"}}>
						<img referrerPolicy="no-referrer" src={thumburl} style={{float:"left", width:"50%", padding:"1px", objectFit: "cover", height:"300px"}} onClick={event => currentComponent.getProducts(event, item.vid_id, item.thumbnail, item.clicks, index)} />
					</div>
						</center>
						
						)	
					});

			} else {
				
				if (this.props.visual == true){
					items = this.props.videos.map(function(item, index){
					
					var thumburl = "https://www.tiktok.com/api/img/?itemId=" + item.tiktok_video_id + "&location=1";
						
					if (item.thumbnail){
					if (item.thumbnail.includes("florence")){
						thumburl = item.thumbnail_link;
					}
					}

				return (
					<div style={{width: "50%", float: "left"}}>
						
						
						<img referrerPolicy="no-referrer" src={thumburl} style={{float:"left", width:"100%", padding:"1px", objectFit: "cover", height:"220px"}} ></img>
	
					</div>
					)	
				});
				
				} else {
					items = this.props.videos.map(function(item, index){
					
					var thumburl = "https://www.tiktok.com/api/img/?itemId=" + item.tiktok_video_id + "&location=1";
					if (item.thumbnail.includes("florence")){
						thumburl = item.thumbnail_link;
					}

				return (
					<div style={{width: "50%", float: "left"}}>
						
						<p class="vid_bag"><i class="fa fa-shopping-bag" aria-hidden="true"></i></p>
						
						<img referrerPolicy="no-referrer" src={thumburl} style={{float:"left", width:"100%", padding:"1px", objectFit: "cover", height:"220px"}} onClick={event => currentComponent.getProducts(event, item.vid_id, item.thumbnail, item.clicks, index)}></img>
	
					</div>
					)	
				});
				
				}

				

			}
		


			var links = this.state.links.map(function(link, index){
				return (
					<div key={link.link_id} class="img" style={{float:"left", width:"50%", padding:"0px", height:"250px"}}>
						
						<div class="shop_items" >
						
							<div class="tag_num">
							{index+1}	
							</div>

							<a onClick={event => currentComponent.productClicked(event, link.link_id, link.clicks, link.product_link)} >
								
								<img id="shop_img" referrerPolicy="no-referrer" src={link.thumbnail_link} style={{width:"85%", padding:"0px"}}/>
							
								<p id="shop_text">{link.name}<p id="price">{link.price}</p></p>	</a>

						</div>
						
					</div>)
    		});
			//get most recent links
			
			var new_url = this.state.current_vid;
			var new_index = this.state.current_index;
			var vidbackground = this.state.vidbackground;
			var current_vid_id = this.state.current_vid_id;
		
			var drop = "";
			var test = [0];
			var email_input = "";
		
			if (this.state.showEmailAllowed){

				drop = test.map(function(test, index){
				return(
					<span class="email-span">
					<i className="fa fa-envelope-o" style={{marginLeft:"25px"}}></i>
					<i onClick={event => currentComponent.showEmailClicked(event)} className="fa fa-caret-down open" id="drop" style={{fontSize: "17px", marginLeft:"5px"}}> </i>
						</span>
				)
			})
			}
		
			var new_modal_video = [0].map(function(item, index){
				//maybe product the embedded thing here
				var cite_string = "https://www.tiktok.com/@" + currentComponent.client + "/video/" + current_vid_id;
//				<blockquote class="tiktok-embed" cite={cite_string} data-video-id={current_vid_id} style={{maxWidth: "605px", minWidth: "225px", zIndex: "1"}} > <section style={{zIndex: "1"}}> </section> </blockquote> 
				
				if (true){
					return (
						<div class="shrink">
							<blockquote class="tiktok-embed" cite={cite_string} data-video-id={current_vid_id} style={{borderRadius: "20px", width: "325px",  zIndex: "1", height: "555px"}} > <section style={{zIndex: "1", position: "relative"}}> </section> </blockquote></div>
					)
					
					//560, 325
				}
				
				
				
				
//				else if (!is_chrome){
//				return (
//					<img style={{borderRadius: "10px"}}onError={event => (currentComponent.testError3(new_index))} id="mainvid" referrerPolicy="no-referrer" crossOrigin src={new_url} poster={vidbackground} controls playsInline autoPlay loop width="70%" ></img>
//				)} else {
//					return (
//						<video style={{borderRadius: "10px"}}onError={event => (currentComponent.testError3(new_index))} id="mainvid" referrerPolicy="no-referrer" crossOrigin src={new_url} poster={vidbackground} controls muted playsInline autoPlay loop width="70%" ></video>
//					)
//				}
			})
			
		
			if (currentComponent.state.showEmail == true && currentComponent.state.showEmailAllowed){
				email_input = test.map(function(test, index){
				return(
					<center>
						<br />
						<p style={{marginBottom: "5px"}} id="bio">{currentComponent.state.email_title}</p>
						<form onSubmit={event => currentComponent.sendEmail(event)}>
						<input style={{width: "250px"}} maxlength="60" required onChange={event => currentComponent.setState({email_address: event.target.value})} value={currentComponent.state.email_address} id="product_title" name="product_title" type="text" placeholder={currentComponent.state.email_placeholder} />
						
						<Button style={{marginLeft: "5px", padding: "11px", fontSize: "14px"}} type="submit" class="tag_btn" variant="tag_btn"> Send </Button>
						</form>
					</center>
				)
			})
			} 
		


			//return render
			return (
				<div style={{fontWeight:"500", marginTop:"-16px"}}>
					
					
	
					<Modal size="lg" show={this.state.show} onHide={() => this.setState({ show: false })}>
						
						<Modal.Header closeButton style={{marginTop: "-5px", marginLeft: "-15px", borderBottom:"0 none", height: "10px", zIndex: "100"}}>
                        	<Modal.Title id="contained-modal-title-vcenter" centered />
                        </Modal.Header>
                        <Modal.Body>
							
							
                     		<center> 
								
								<VideoPreview></VideoPreview>
								{new_modal_video}
								
							</center>	

						<div style={{marginTop:"-45px"}}> {links} </div>
                        </Modal.Body>
						<a href="https://www.shoptik.co" style={{textAlignLast:"right", marginLeft:"85%", marginBottom:"10px"}}><img width="50px" src={shoptik_icon}></img></a>
        			</Modal>
					
					<Modal backdrop="static"
        keyboard={false} size="m" show={this.state.showAnalytics} onHide={() => this.setState({ showAnalytics: false })}>

                        <Modal.Body>
                     		<center>
							<h4>Oops 😥 Your plan doesn't support the Shoppable Feed link in bio</h4>
								<br />
								<video id="mainvid" referrerPolicy="no-referrer" crossOrigin src={"https://www.shoptik.co/images/florence2.mp4"} muted playsInline autoPlay loop width="55%" ></video>
								
								<br /><br />
								
					<h5>🎉 Log into shoptik.app now to upgrade your plan! 🎉 </h5>
								
							</center>	
							<br />
                        </Modal.Body>
						<a href="https://www.shoptik.co" style={{textAlignLast:"right", marginLeft:"85%", marginBottom:"10px"}}><img width="50px" src={shoptik_icon}></img></a>
        			</Modal>
					
					 
				<div> {items} </div>
				

					
					
				</div>
			)
			
		} 

}

export default FeedItems

		
//				<Snackbar open={this.state.tapinfo} autoHideDuration={2000} onClose={()=>{this.setState({tapinfo: false})}}>
//						
//					<MuiAlert elevation={6} variant="filled" severity="info">{"tap on the video to play"}</MuiAlert>
//				 </Snackbar>


//<img referrerPolicy="no-referrer" src={new_url} style={{padding:"1px", objectFit: "cover"}}  width="50%" ></img>


//	<video onError={event => (currentComponent.testError3(new_index))} id="mainvid" referrerPolicy="no-referrer" crossOrigin src={new_url} controls muted playsInline autoPlay loop width="70%" ></video>

//	<center>
//								<br />
//								
//						<input style={{width: "270px"}} maxlength="60" required onChange={event => this.setState({userHashtags: event.target.value})} value={this.state.userHashtags} id="product_title" name="product_title" type="text" placeholder="email" />
//						</center>