import React, { useState, useEffect, Component } from 'react';
import Feed from './Feed';
import Dashboard from './Dashboard';
import Button from 'react-bootstrap/Button';

import addnew from './add_vid.jpg';

import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Link, Switch, Redirect, withRouter } from "react-router-dom";

import Sidebar from "react-sidebar";

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/Info';
import Largebox from "./Largebox";
import Avatar from '@material-ui/core/Avatar';

class RanktableHashtags extends Component {
	

	constructor(props){
		super(props);
		this.kFormatter = this.kFormatter.bind(this);
	}
	
		kFormatter(num, digits) {

		  var si = [
			{ value: 1, symbol: "" },
			{ value: 1E3, symbol: "k" },
			{ value: 1E6, symbol: "M" },
			{ value: 1E9, symbol: "B" },
			{ value: 1E12, symbol: "T" },
			{ value: 1E15, symbol: "P" },
			{ value: 1E18, symbol: "E" }
		  ];
		  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
		  var i;
		  for (i = si.length - 1; i > 0; i--) {
			if (num >= si[i].value) {
			  break;
			}
		  }
		  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}
	
	componentDidMount() {
    }
	
	render() {
		
		
		var classtype = "fa fa-arrow-up";
		var color_code = "#46D3C2";
		var width = this.props.width;

		var symbol = "%";
		var sub_symbol = "%";

		var difference_percentage = 5;
		
		var left_class = "fa fa-arrow-down";
		var right_class = "fa fa-arrow-up";
		
		var left_color = "#F74B70";
		var right_color = "#46D3C2";
		var left_word = "lower by";
		var right_word = "higher by";
		var diff_percentage = Math.round(this.props.num_right - this.props.num_left);
		
		if (this.props.num_left > this.props.num_right){
			left_word = "higher by";
			right_word = "lower by";
			left_color = "#46D3C2";
			right_color = "#F74B70";
			diff_percentage = Math.round(this.props.num_left - this.props.num_right);
			
			left_class = "fa fa-arrow-up";
			right_class = "fa fa-arrow-down";
		}
		
		symbol = "";
		var currentComponent = this;

		var items = this.props.rows.map(function(item, index){
			return (
				<div>
				<div style={{width: "100%", backgroundColor: "#fff", borderRadius: "5px", borderTopWidth: "0px", height: "35px", borderTopStyle: "solid", borderTopColor: "#fff"}}>
			
				<div class="analysis_hover">
			
			<Avatar style={{backgroundColor: item.color, float: "left", }}>{item.profile_name[0]}</Avatar>
					
		
			
			<h4 style={{color: "black", height: "35px", lineHeight: "35px", textAlign: "center",  display: "inline-block", paddingLeft: "20px"}}>#{item.profile_name}</h4>
			
			<h4 style={{color: "black", float: "right", display: "inline-block", height: "35px", lineHeight: "35px", paddingRight: "20px"}}>{currentComponent.kFormatter(item.num, 1)}{symbol}</h4>
				</div>
						
			</div><hr /></div>
			)
		})
		
		return(
			
		<div class="rankbox_container" style={{width: width}}>
				
				
		<h4>
			<img style={{marginRight: "10px", marginBottom: "5px"}} src="https://assets.materialup.com/uploads/28cbb1b2-0e74-4925-9842-a0ac8fd90732/preview" width="23px" /> {this.props.title} 
			
			<Tooltip style={{marginTop: "-12px", position: "absolute", opacity: "0.1"}} title={this.props.description}>
				  <IconButton id="moreinfo" aria-label={this.props.description}>
					<FilterListIcon />
				  </IconButton>
				</Tooltip></h4><br />
				
			{items}

		</div>)
	}
}

export default RanktableHashtags