import React, { useState, useEffect, Component } from 'react';
import Feed from './Feed';
import Dashboard from './Dashboard';
import Button from 'react-bootstrap/Button';

import addnew from './add_vid.jpg';

import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Link, Switch, Redirect, withRouter } from "react-router-dom";

import Sidebar from "react-sidebar";

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/Info';
		
		
class Smallbox extends Component {
	

	constructor(props){
		super(props);
		this.kFormatter = this.kFormatter.bind(this);
	}
	
		kFormatter(num, digits) {

			  var si = [
				{ value: 1, symbol: "" },
				{ value: 1E3, symbol: "k" },
				{ value: 1E6, symbol: "M" },
				{ value: 1E9, symbol: "B" },
				{ value: 1E12, symbol: "T" },
				{ value: 1E15, symbol: "P" },
				{ value: 1E18, symbol: "E" }
			  ];
			  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
			  var i;
			  for (i = si.length - 1; i > 0; i--) {
				if (num >= si[i].value) {
				  break;
				}
			  }
			  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
		}
	
	componentDidMount() {
		
    }
	
	render() {
		var current = this.props.current;
		var width = this.props.width;
		var current_value = current;
		
		return(
			
		<div class="smallbox_container" style={{width: width}}>
				<Tooltip style={{marginTop: "-10px", marginLeft: "-100px", position: "absolute", opacity: "0.1"}} title={this.props.message}>
				  <IconButton id="moreinfo" aria-label={this.props.message}>
					<FilterListIcon />
				  </IconButton>
				</Tooltip>
			<center>
				<img class="backshadow" src={this.props.img} style={{width: "40px"}}/>
				
			<h1>{current_value}</h1>
			<h4>{this.props.subtitle}</h4>
		</center>
			
		</div>)
	}
}

export default Smallbox