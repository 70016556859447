import React, { useState, useEffect, Component } from 'react';
import Feed from './Feed';
import Dashboard from './Dashboard';
import Button from 'react-bootstrap/Button';

import addnew from './add_vid.jpg';

import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Link, Switch, Redirect, withRouter } from "react-router-dom";

import Sidebar from "react-sidebar";

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/Info';
		
		
class Largebox extends Component {
	

	constructor(props){
		super(props);
		this.kFormatter = this.kFormatter.bind(this);
	}
	
		kFormatter(num, digits) {

		  var si = [
			{ value: 1, symbol: "" },
			{ value: 1E3, symbol: "k" },
			{ value: 1E6, symbol: "M" },
			{ value: 1E9, symbol: "B" },
			{ value: 1E12, symbol: "T" },
			{ value: 1E15, symbol: "P" },
			{ value: 1E18, symbol: "E" }
		  ];
		  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
		  var i;
		  for (i = si.length - 1; i > 0; i--) {
			if (num >= si[i].value) {
			  break;
			}
		  }
		  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}
	
	componentDidMount() {
    }
	
	render() {
		
		
		var classtype = "fa fa-arrow-up";
		var color_code = "#46D3C2";
		
		var original_value = this.props.original;
		var current = this.props.current;
		
		var width = this.props.width;
		
//		console.log("original_value:", original_value);
//		console.log("current: ", current);
		

		var difference_percentage = Math.round((current - original_value) / original_value * 100);
		if (original_value == 0){
			difference_percentage = "0"
		}
		
		if (original_value > current){
			classtype = "fa fa-arrow-down";
			color_code = "#F74B70";
			difference_percentage = Math.round((original_value - current) / original_value * 100);
		}
		
		var current_value = this.kFormatter(current, 1);
		var symbol = "";
		var sub_symbol = "%"
		if (this.props.subtitle == "Follower Evolution Percentage"){
			symbol = "%";
			difference_percentage = current_value
		}
		if (this.props.subtitle == "Total Follower Evolution"){
			sub_symbol = " fans";
			difference_percentage = current_value;
		}
		
		return(
			
		<div class="largebox_container" style={{width: width}}>
				<Tooltip style={{marginTop: "-10px", position: "absolute", opacity: "0.1"}} title={this.props.message}>
				  <IconButton id="moreinfo" aria-label={this.props.message}>
					<FilterListIcon />
				  </IconButton>
				</Tooltip>
				<center>
				<img class="backshadow" src={this.props.img} style={{width: "45px", paddingBottom: "10px"}} /><br />
				
			<h1>{current_value}{symbol}</h1>
			<h4>{this.props.subtitle}</h4>
					<p class="change-arrow"><i class={classtype} style={{color: color_code}} aria-hidden="true"></i> {difference_percentage} {sub_symbol}</p>
				</center>
		</div>)
	}
}

export default Largebox