import React, { useState, useEffect, Component } from 'react';
import Feed from './Feed';
import Dashboard from './Dashboard';
import Button from 'react-bootstrap/Button';

import addnew from './add_vid.jpg';

import Comptable from "./Comptable"

import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Link, Redirect, withRouter } from "react-router-dom";

import Switch from '@material-ui/core/Switch';

import Sidebar from "react-sidebar";
import NewSidebar from "./NewSidebar";
import Largestbox from "./Largestbox";
import Largebox from "./Largebox";
import Comparebox from "./Comparebox";
import Smallbox from "./Smallbox";
import Select from 'react-select';
import Table from "./Table"

import EmailTable from "./EmailTable";
import ProductsTable from "./ProductsTable"
import shoptik_icon from './Help/shoptik_icon.png';

import { CSVLink } from 'react-csv';
import cheerio from 'cheerio';

import Chart from 'react-google-charts';
import { LineOptions } from './charts';

import Graphbox from "./Graphbox";

import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/Info';

import { follower_graph, engagement_graph, likes_graph, comments_graph } from './charts';

let Options = [
  { label: "posted in the last day", value: 1 },
  { label: "posted in the last week", value: 7 },
  { label: "posted in the last month", value: 30 },
  { label: "posted in the last 3 months", value: 90 },
  { label: "posted in the last 6 months", value: 182 },
  { label: "all time", value: 1000 },
];

class Benchmarks extends Component {
	constructor(props){
		super(props);
		this.state = {
			isLogged: false,
			placeholder:"all time",
			
			total_videos: 0,
			total_products: 0,
			
			shop_clicks_total: 0,
			video_clicks_total: 0,
			product_clicks_total: 0,
			video_clicks_average: 0,
			product_clicks_average: 0,
			conversion: 0,
			
			videos: [],
			rows: [],
			product_rows: [],
			email_rows: [],
			product_message: "",
			
			
			originalfollowingCount: 0,
			originalfollowerCount: 0,
			originalheartCount: 0,
			originalvideoCount: 0,
			originalaverageLikes: 0,
			originalfollowerRatio:0,
//			recent_posts: 1,
			originalcommentCount: 0,
			originallikeCount: 0,
			originalshareCount: 0,
			originalcommentAvg: 0,
			originallikeAvg: 0,
			originalshareAvg: 0,
			
			followingCount: 0,
			followerCount: 0,
			heartCount: 0,
			videoCount: 0,
			averageLikes: 0,
			followerRatio:0,
			commentCount: 0,
			likeCount: 0,
			shareCount: 0,
			commentAvg: 0,
			likeAvg: 0,
			shareAvg: 0,
			
			allLikes: [],
			allComments: [],
			allShares: [],
			allVideos: [],
			recentVideos: [],
			
			allLikesNum : [],
			allSharesNum : [],
			allCommentsNum : [],
			
			showVideo: false,
			
			current_video:"",
			isloading: "",
			thumbnail_url: "",
			
			engagement_average: 0,
			
			rows: ["test"]

		}
		
		this.navigatePage = this.navigatePage.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.updateData = this.updateData.bind(this);
		this.kFormatter = this.kFormatter.bind(this);
		
		this.videoClicked = this.videoClicked.bind(this);
		this.navigatePage = this.navigatePage.bind(this);
	
	}


	navigatePage(event, type){
		var base_url = window.location.origin;
		window.location.href = base_url + '/dashboard/' + type;
	}
	
	//DEPRECATED FUNCTION DO NOT USE
	async videoClicked(event, index){
//		var current_id = this.state.allIds[index];
//		var video_url = "https://www.tiktok.com/@" + this.state.current_user + '/video/' + current_id + '?lang=en';
//		
//		var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + video_url
//			
//		const scrape_data = await fetch(scrape_url)
//
//		var all_text = await scrape_data.text();
//
//		var key3 = "urls\":";
//		var key4 = "videoMeta\"";
//
//		var fin_url = all_text.match(new RegExp(key3 + "(.*)" + key4));
//		var finalurl = fin_url[1].replace(/['",]+/g, '').slice(1,-1);
//
//		var comments = this.state.allCommentsNum[index];
//		var likes = this.state.allLikesNum[index];
//		var shares = this.state.allSharesNum[index];
//		var engagement = comments + likes + shares;
//		var engagement_rate = Math.round((engagement / this.state.followerCount) * 100);
//		
//		var post_score = Math.round((likes - this.state.likeAvg) / this.state.likeAvg * 100);
//		var rate_info = "better than avg post";
//		
//		if (post_score < 0){
//			post_score = post_score * -1;
//			rate_info = "worse than avg post";
//		}
//		
//
//		this.setState({
//			showVideo: true,
//			current_video: finalurl,
//			
//			current_comments: this.kFormatter(comments, 1),
//			current_likes: this.kFormatter(likes, 1),
//			current_shares: this.kFormatter(shares, 1),
//			current_engagement: this.kFormatter(engagement, 2),
//			current_engagement_rate: engagement_rate,
//			current_post_score: post_score,
//			rate_info: rate_info
//			
//		})
//		
	}
	
	
	handleChange(e) {
        this.updateData(this.state.current_user, e.value, this.state.api_key);
    }
	
	navigatePage(event, type){
		if (type == "feed"){
			var base_url = window.location.origin;
			window.open((base_url + '/' + this.state.current_user), '_blank');
		} else {
			var base_url = window.location.origin;
		window.location.href = base_url + '/dashboard/' + type;
		}
	}
	

	kFormatter(num, digits) {

  var si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "k" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "B" },
    { value: 1E12, symbol: "T" },
    { value: 1E15, symbol: "P" },
    { value: 1E18, symbol: "E" }
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}
	
  
	
	
	async updateData(current_user, time_period, api_key){
		//scrape stuff from tiktok and urlbird
let newresp = await fetch("https://social-media-data-tt.p.rapidapi.com/live/user?username=" + current_user + "&fresh=1", {
			"method": "GET",
			"headers": {
				"x-rapidapi-key": api_key,
				"x-rapidapi-host": "social-media-data-tt.p.rapidapi.com"
			}
		})
		let newdata = await newresp.json();
			var followingCount = newdata.following_count;
				var followerCount = newdata.follower_count;
				var heartCount = newdata.total_favorited;
				var videoCount = newdata.video_count;
				
				var averageLikes = Math.round(heartCount / videoCount);
				var followerRatio = Math.round(followingCount / followerCount);
		
		this.setState({
					followingCount: followingCount,
					followerCount: followerCount,
					heartCount: heartCount,
					videoCount: videoCount,
					averageLikes: averageLikes,
					followerRatio: followerRatio,
					
				})
				
				
		if (this.state.originalfollowerRatio == 0){
			this.setState({
				originalaverageLikes: averageLikes,
				originalfollowerRatio: followerRatio,
			})
		}
		
		
//		var manual_link = 'https://www.tiktok.com/@' + current_user + '?lang=en'
//		var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + manual_link;
//		const scrape_data = await fetch(scrape_url)
//		var all_text = await scrape_data.text();
//		var key3 = "stats\":";
//		var key4 = "shareMeta\"";
//		var original = all_text.match(new RegExp(key3 + "(.*)" + key4))[1].replace(/['",]+/g, '').slice(1,-1);;
//
//		var sub_values = original.match(/\d+/g).map(Number);
//
//		var followingCount = sub_values[0];
//		var followerCount = sub_values[1];
//		var heartCount = sub_values[2];
//		var videoCount = sub_values[3];
//		
//		var averageLikes = Math.round(heartCount / videoCount);
//		var followerRatio = Math.round(followingCount / followerCount);
//		
//		this.setState({
//			followingCount: followingCount,
//			followerCount: followerCount,
//			heartCount: heartCount,
//			videoCount: videoCount,
//			averageLikes: averageLikes,
//			followerRatio: followerRatio,
//		})
//		
//		
//		
//		if (this.state.originalfollowerRatio == 0){
//			this.setState({
//				originalaverageLikes: averageLikes,
//				originalfollowerRatio: followerRatio,
//			})
//		}
		
		//SCRAPING URLEBIRD VALUES (may need to convert to integers)
		const new_scrape_data = await fetch('https://mysterious-reef-90754.herokuapp.com/https://urlebird.com/user/' + current_user + '/');
		
		const $ = cheerio.load(await new_scrape_data.text())
	
		var allVideos = [];
		
		var vids = $('.thumb .img img');
		$('.thumb .img img').each(function(i, elem) {
			if (i <= 4){
				allVideos[i] = $(this).attr("src");
			} else {
				allVideos[i] = $(this).attr("data-src");
			}
		});

		const allLikes = [];
		const allComments = [];
		const allShares = [];
		
		const allLikesNum = [];
		const allCommentsNum = [];
		const allSharesNum = [];
	
		var counter = 0
		$('span').each(function(i, elem) {
			
			var classname = $(this).attr("class");
			if (classname === undefined){
				
				if (counter == 0){
					var newnum = $(this).text();
					allLikes.push(newnum);
					if (newnum.includes("K")){
						allLikesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000);
					} else if (newnum.includes("M")){
						allLikesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000000);
					} else {
						allLikesNum.push(parseInt(newnum));
					}
				} else if (counter == 1){
					var newnum = $(this).text();
					allComments.push(newnum);
					if (newnum.includes("K")){
						allCommentsNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000)
					} else if (newnum.includes("M")){
						allCommentsNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000000)
					} else {
						allCommentsNum.push(parseInt(newnum));
					}
				} else {
					var newnum = $(this).text();
					allShares.push(newnum);
					if (newnum.includes("K")){
						allSharesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000)
					} else if (newnum.includes("M")){
						allSharesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000000)
					} else {
						allSharesNum.push(parseInt(newnum));
					}
				}
				counter = (counter + 1)%3
			}
		});
		
//		https://urlebird.com/video/
		const allHrefs = [];
		const allIds = [];
		
		var hrefs = $('a');

		$('a').each(function(i, elem) {
			
			var link = $(this).attr("href");
			if (link.includes("https://urlebird.com/video/")){
				allHrefs.push(link);
							  
				var new_id = [];
//				var name = "John Smith".split("");
				
				for(var i = link.length-2; i >= 0; i--) {
					
					if (/^\d+$/.test(link[i])){
						new_id.push(link[i]);
					} else {
						break;
					}
				}
				allIds.push(new_id.reverse().join(''));
			}
		});
		
		// CHANGE THIS BACK!
//		var new_all_videos = [];
//		for (var k=0; k < allHrefs.length; k++){
//			var new_url = allHrefs[k];
//			var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url
//
//			const scrape_data = await fetch(scrape_url)
//			const $ = cheerio.load(await scrape_data.text())
//			var final_url = $('video').attr("src");
//
//			new_all_videos.push(final_url);
//		}
//		allVideos = new_all_videos;
		this.setState({
			recentVideos : allVideos.slice(0, 4),
			allVideos : allVideos,
			allLikes : allLikes,
			allShares : allShares,
			allComments : allComments,
			
			allLikesNum : allLikesNum,
			allSharesNum : allSharesNum,
			allCommentsNum : allCommentsNum,
			
			allHrefs : allHrefs,
			allIds: allIds,
		})

		var commentCount = allCommentsNum.reduce(function(a, b){
        	return a + b;
    	}, 0);
		var likeCount = allLikesNum.reduce(function(a, b){
        	return a + b;
    	}, 0);
		var shareCount = allSharesNum.reduce(function(a, b){
        	return a + b;
    	}, 0);
		
		var denom = commentCount.length;
		
		var commentAvg = Math.round(commentCount/allCommentsNum.length);
		var likeAvg = Math.round(likeCount/allLikesNum.length);
		var shareAvg = Math.round(shareCount/allSharesNum.length);
		
		this.setState({
			commentCount: commentCount,
			likeCount: likeCount,
			shareCount: shareCount,
			
			commentAvg: commentAvg,
			likeAvg: likeAvg,
			shareAvg: shareAvg,
			isloading: "none"
		})
		
		
		//lets calculate general rows
		
		

		var info = {
			'tiktok_name': this.state.current_user,
			'following': followingCount,
			'followers': followerCount,
			'likes': heartCount,
			'videos': videoCount,
			'recent_comments': commentCount,
			'recent_likes': likeCount,
			'recent_shares': shareCount,
			'recent_posts': allLikes.length
		}

		fetch('https://shoptik-app.herokuapp.com/dashboard/overview', {
			method: "POST",
			credentials: 'include',
			body: JSON.stringify(info),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						data = data.data;

						if (data.message == "original_entry"){
							this.setState({
								originalfollowingCount: followingCount,
								originalfollowerCount: followerCount,
								originalheartCount: heartCount,
								originalvideoCount: videoCount,
								
								originalcommentCount: commentCount,
								originallikeCount: likeCount,
								originalshareCount: shareCount,
								originalcommentAvg: commentAvg,

								originalshareAvg: shareAvg,
								
								creation_date: data.creation_date
								
							})
							
							

						} else {
							var averageLikes = Math.round(data.likes / data.videos);
							var followerRatio = Math.round(data.following / data.followers);
							
							var commentAvg = Math.round(data.recent_comments/data.recent_posts);
							var likeAvg = Math.round(data.recent_likes/data.recent_posts);
							var shareAvg = Math.round(data.recent_shares/data.recent_posts);

							this.setState({
								originalfollowingCount: data.following,
								originalfollowerCount: data.followers,
								originalheartCount: data.likes,
								originalvideoCount: data.videos,
								originalaverageLikes: averageLikes,
								originalfollowerRatio: followerRatio,
								originalcommentCount: data.recent_comments,
								originallikeCount: data.recent_likes,
								originalshareCount: data.recent_shares,
								originalcommentAvg: commentAvg,
								originallikeAvg: likeAvg,
								originalshareAvg: shareAvg,
								
								creation_date: data.creation_date
							})
							
							//console.log("creation date: ", data.creation_date)
						}

					} else {
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
						this.setState({
							isLogged: false,
							current_user: ""
						})
				   }
				})
			})
		
		
		
		
		//for each likes, create array of increasing like sums and plot those, x axis is just posts
		
		
		
		
		
		
		
		return;

		
	}
	
	loginStatus(){
		var curr = this;
		//fetch to see if logged in
		fetch('https://shoptik-app.herokuapp.com/dashboard/whoami', {
			method: "GET",
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						var current_user = data.data.current_user;
						var api_key = data.data.api_key;
						this.updateData(data.data.current_user, "1000", api_key);
				
						this.setState({
							isLogged: true,
							current_user: data.data.current_user,
							thumbnail_url: data.data.thumbnail_url,
							api_key: api_key
						})

					} else {
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
						this.setState({
							isLogged: false,
							current_user: "",
							
						})
				   }
				})
			})
	}
	
	componentDidMount() {
		this.loginStatus();
    }
	
	createData(name, industry_average, your_tiktok) {
  		return { name, industry_average, your_tiktok };
	}
	
	render() {
		if (this.state.isLogged){
			
			let currentComponent = this;
			
			let likes = currentComponent.state.allLikes;
			let comments = currentComponent.state.allComments;
			let shares = currentComponent.state.allShares;

//			var tiktoklinks = this.state.recentVideos.map(function(link, index){
//				return (
//					<div key={index} class="img" style={{float:"left", width:"260px"}}>
//						
//						<div class="active_vid_container" style={{textAlignLast:"center"}}>
//
//							
//							<img id="active_vids" referrerPolicy="no-referrer" src={link} style={{objectFit: "cover", width: "230px"}} onClick={event => currentComponent.videoClicked(event, index)}/>
//							
//							<p id="shop_text" style={{marginLeft:"0%"}}>
//								<p id="video_stats">
//									💬 &nbsp; {comments[index]}&nbsp; &nbsp;
//									❤️ &nbsp; {likes[index]}&nbsp; &nbsp;
//									📤 &nbsp; {shares[index]}
//								</p>
//							</p>
//						</div>
//					</div>)
//    		});
			
			var creation_date = new Date(this.state.creation_date).toUTCString();

			var follower_columns = [
			  {
				type: "string",
				label: "year"
			  },
			  {
				label: "Followers",
				type: "number"
			  }
			];
			
			var engagement_columns = [
			  {
				type: "string",
				label: "post"
			  },
			  {
				label: "Actions",
				type: "number"
			  }
			];
			
			var likes_columns = [
			  {
				type: "string",
				label: "post"
			  },
			  {
				label: "Likes",
				type: "number"
			  }
			];
			
			var comments_columns = [
			  {
				type: "string",
				label: "post"
			  },
			  {
				label: "Comments",
				type: "number"
			  }
			];

			
			var rows = [[2015, 2], [2016, 3], [2018, 6], [2019, 8], [2020, 14]];
			//calculate rows for follower growth, engagement rate, likes history, comments history
			//also collect the date
			var creation_date = new Date(this.state.creation_date).toUTCString().split(' ').slice(0, 4).join(' ');;
			var today = new Date().toUTCString().split(' ').slice(0, 4).join(' ');;
			var follower_rows = [[creation_date, this.state.originalfollowerCount], [today, this.state.followerCount]]
			
			
			var likes_rows = [];
			var sum = 0;
			for (var i=0; i<this.state.allLikesNum.length; i++){
				sum += this.state.allLikesNum[i];
				var current = [i, sum];
				likes_rows.push(current);
			}
			
			var comments_rows = [];
			var sum = 0;
			for (var i=0; i<this.state.allCommentsNum.length; i++){
				sum += this.state.allCommentsNum[i];
				var current = [i, sum];
				comments_rows.push(current);
			}
			
			var engagement_rows = [];
			var engagementSum = 0;
			for (var i=0; i<this.state.allCommentsNum.length; i++){
				engagementSum += this.state.allCommentsNum[i] + this.state.allLikesNum[i] + this.state.allSharesNum[i];
				var current = [i, engagementSum];
				engagement_rows.push(current);
			}
			
			//calculate engagement average
			var engagement_average = 0;
			if (this.state.followers != 0){
				if (this.state.allCommentsNum != 0){
					engagement_average = Math.round(engagementSum/this.state.allCommentsNum.length/this.state.followerCount);
				}
			}
			
			//calculate likes before half and after half
			var posts = this.state.allCommentsNum.length;
			var mid = Math.round(posts/2);
			
			var firstlikes = 0;
			var secondlikes = 0;
			for (var i=0; i<this.state.allCommentsNum.length; i++){
				if (i < mid){
					firstlikes += this.state.allLikesNum[i];
				} else {
					secondlikes += this.state.allLikesNum[i];
				}
			}
			var average_likes = 0;
			if (firstlikes != 0){
				average_likes = Math.round((secondlikes - firstlikes)/firstlikes);
			}
			
			
			
			
			
			var follower_evolution = 0;
			var follower_evolution_percentage = 0;
			
			if (this.state.originalfollowerCount != 0){
				follower_evolution = (this.state.followerCount - this.state.originalfollowerCount);
				follower_evolution_percentage = (((this.state.followerCount - this.state.originalfollowerCount)/this.state.originalfollowerCount) * 100);
			}
			
//			var engagementSum = 0;
//			for (var i=0; i<this.state.allCommentsNum.length; i++){
//				engagementSum += this.state.allCommentsNum[i] + this.state.allLikesNum[i] + this.state.allSharesNum[i];
//			}
			var newrows = [];
			newrows.push(this.createData("Followers", "284,000", this.state.followerCount));
			newrows.push(this.createData("Average Likes/Post", "84,000", this.state.likeAvg));
			newrows.push(this.createData("Average Comments/Post", "42,000", this.state.commentAvg));
			newrows.push(this.createData("Average Shares/Post", "10,80", this.state.shareAvg));
			newrows.push(this.createData("Average Follower Growth Rate", "2%", (Math.round(follower_evolution_percentage) + "%")));
			newrows.push(this.createData("Average Likes Growth Rate", "2.1%", (average_likes + "%")));
			newrows.push(this.createData("Total Posts", 58, this.state.allCommentsNum.length));


	
		return (
			<div class="main_container">
			<div>
				
					<Modal centered size="lg" show={this.state.showVideo} onHide={() => this.setState({ showVideo: false })}>
						<Modal.Header closeButton style={{borderBottom:"0 none", height: "0px"}}>
                        	<Modal.Title id="contained-modal-title-vcenter" centered>
							Video Performance
							</Modal.Title>
                        </Modal.Header>
					
                        <Modal.Body >
							<br/>
							
							<div class="left-vid" style={{float:"left", width:"35%"}}>
							
							<video id="mainvid" referrerPolicy="no-referrer" crossOrigin src={this.state.current_video} controls muted playsInline autoPlay loop width="100%" ></video>
								
								</div>
							<br />
							
							
		
						<div class="vid-right" style={{float:"right", marginTop: "-40px", width:"65%"}}>
							<center>
							<Smallbox width="40%" img="https://www.shoptik.co/images/analytics_icons/2.png" current={this.state.current_likes} subtitle="Likes" message="This is the number of likes on this post" />
							<Smallbox width="40%" img="https://www.shoptik.co/images/analytics_icons/5g.png" current={this.state.current_comments} subtitle="Comments" message="This is the number of comments on this post" />
							<Smallbox width="40%" img="https://www.shoptik.co/images/analytics_icons/6g.png" current={this.state.current_shares} subtitle="Shares" message="This is the number of shares on this post" />
							<Smallbox width="40%" img="https://www.shoptik.co/images/analytics_icons/4.png" current={this.state.current_engagement} subtitle="Engagement Actions" message="This is the number of likes + comments + shares on this post" />
							<Smallbox width="40%" img="https://www.shoptik.co/images/analytics_icons/4g.png" current={this.state.current_engagement_rate + " %"} subtitle="Engagement Rate" message="This is the number of engagement actions divided by your follower count" />
							<Smallbox width="40%" img="https://www.shoptik.co/images/analytics_icons/7.png" current={this.state.current_post_score + " %"} subtitle={this.state.rate_info} message="This is an estimate of how well your post performed compared to your average post" />
								
							
							</center>
							

							</div>
							
                        </Modal.Body>
        			</Modal>
			
    	<NewSidebar selected={8}></NewSidebar>
			
				
					<br /><br /><br />
<span class="newloader" style={{display: this.state.isloading}}></span>
	<img id="profile_image" onClick={event => this.navigatePage(event, "settings")} src={this.state.thumbnail_url} />
	<div class="main">

			<h3>Industry Benchmarks</h3>
		<h5>Your Industry: Consumer-Facing Products</h5>
		<p style={{fontSize: "12px"}}>Compare your performance against your industry. Benchmarks are based on ShopTik clients in your industry</p>

		<br /><br />
		<h5>Top KPI's</h5><p style={{fontSize: "12px"}}>Based on the last 30 days</p><hr />
		

		
		<Comparebox width="96.5%" img_left="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQOCR2vq2rmKi_tndcevVIcabPDjh0-pzVcWg&usqp=CAU" title="Average Followers Growth Rate" img_right={this.state.thumbnail_url} num_left={2} num_right={follower_evolution_percentage} sub_left="Industry Average" sub_right={"@" + this.state.current_user} description="This is the percentage increase in your TikTok followers in the last 30 days" main_symbol="%"/>
		
		
		
		<Comparebox width="47.5%" img_left="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQOCR2vq2rmKi_tndcevVIcabPDjh0-pzVcWg&usqp=CAU" title="Average Engagement Growth Rate" img_right={this.state.thumbnail_url} num_left={1} num_right={engagement_average} sub_left="Industry Average" sub_right={"@" + this.state.current_user} description="This is the percentage increase of your average engagement event per post in the last 30 days" main_symbol="%"/>
		
		
		
		<Comparebox width="47.5%" img_left="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQOCR2vq2rmKi_tndcevVIcabPDjh0-pzVcWg&usqp=CAU" title="Average Likes Growth Rate" img_right={this.state.thumbnail_url} num_left={2} num_right={average_likes} sub_left="Industry Average" sub_right={"@" + this.state.current_user} description="This is the percentage increase of your average likes per post in the last 30 days" main_symbol="%"/>
		

		<br /><br />
		<h5>Comparative Table</h5><p style={{fontSize: "12px"}}>Based on the last 30 days</p><hr />
		
		
		<div style={{display: "inline-block", width: "100%"}}>
		<br /><br />
		<Comptable username={this.state.current_user} total_videos={8} rows={newrows} class="table_shadow" ></Comptable><br />
			<p style={{fontSize: "12px"}}>*Sort posts by clicking any column title</p>
		</div>
		

		
	<br /><br /><br /><br />
		
					</div>
					
				
			</div>
			</div>

			)
		} else {
			return ( <div></div>
			)
		}

	}
}

export default withRouter(Benchmarks)
	