import React, { useState, useEffect, Component } from 'react';
import Feed from './Feed';
import Dashboard from './Dashboard';
import Button from 'react-bootstrap/Button';

import addnew from './add_vid.jpg';



import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Link, Redirect, withRouter } from "react-router-dom";

import Switch from '@material-ui/core/Switch';

import Sidebar from "react-sidebar";
import NewSidebar from "./NewSidebar";
import Largestbox from "./Largestbox";
import Largebox from "./Largebox";
import Smallbox from "./Smallbox";
import Select from 'react-select';
import Table from "./Table"

import EmailTable from "./EmailTable";
import ProductsTable from "./ProductsTable"
import shoptik_icon from './Help/shoptik_icon.png';

import { CSVLink } from 'react-csv';
import cheerio from 'cheerio';

import Chart from 'react-google-charts';
import { LineOptions } from './charts';

import Graphbox from "./Graphbox";

import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/Info';
import ReactDOMServer from "react-dom/server";

import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

import { follower_graph, engagement_graph, likes_graph, comments_graph } from './charts';

let Options = [
  { label: "posted in the last day", value: 1 },
  { label: "posted in the last week", value: 7 },
  { label: "posted in the last month", value: 30 },
  { label: "posted in the last 3 months", value: 90 },
  { label: "posted in the last 6 months", value: 182 },
  { label: "all time", value: 1000 },
];

class Overview extends Component {
	constructor(props){
		super(props);
		this.state = {
			isLogged: false,
			placeholder:"all time",
			
			total_videos: 0,
			total_products: 0,
			
			shop_clicks_total: 0,
			video_clicks_total: 0,
			product_clicks_total: 0,
			video_clicks_average: 0,
			product_clicks_average: 0,
			conversion: 0,
			
			videos: [],
			rows: [],
			product_rows: [],
			email_rows: [],
			product_message: "",
			
			
			originalfollowingCount: 0,
			originalfollowerCount: 0,
			originalheartCount: 0,
			originalvideoCount: 0,
			originalaverageLikes: 0,
			originalfollowerRatio:0,
//			recent_posts: 1,
			originalcommentCount: 0,
			originallikeCount: 0,
			originalshareCount: 0,
			originalcommentAvg: 0,
			originallikeAvg: 0,
			originalshareAvg: 0,
			
			followingCount: 0,
			followerCount: 0,
			heartCount: 0,
			videoCount: 0,
			averageLikes: 0,
			followerRatio:0,
			commentCount: 0,
			likeCount: 0,
			shareCount: 0,
			commentAvg: 0,
			likeAvg: 0,
			shareAvg: 0,
			
			allLikes: [],
			allComments: [],
			allShares: [],
			allVideos: [],
			recentVideos: [],
			
			allLikesNum : [],
			allSharesNum : [],
			allCommentsNum : [],
			
			showVideo: false,
			
			current_video:"",
			isloading: "",
			thumbnail_url: "",
			
			isOriginal: true,
			
			api_key: ""

		}
		
		this.navigatePage = this.navigatePage.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.updateData = this.updateData.bind(this);
		this.kFormatter = this.kFormatter.bind(this);
		
		this.videoClicked = this.videoClicked.bind(this);
		this.navigatePage = this.navigatePage.bind(this);
		
		this.generateReport = this.generateReport.bind(this);
	
	}
	
		async newImgError(index, errorType){
			

		if (errorType == "feed"){
			var vids = this.state.recentVideos;
//			console.log("VIDS:", vids )
			vids[index] = "https://ttonlineviewer.com/app/img/default-poster.jpg?broke";
			//console.log("finalbigtuple: ", vids)
			this.setState({
				recentVideos: vids
			})
		}
	}
	
	generateReport(event){
//		event.preventDefault();
//		const input = document.getElementById('main');
//		html2canvas(ReactDOMServer.renderToStaticMarkup(this.render())).then((canvas) => {
//			const imgData = canvas.toDataURL('image/png');
//			const pdf = new jsPDF({
//			  orientation: "landscape"
//			});
//			pdf.addImage(imgData, 'PNG', 0, 0);
//			pdf.save("download.pdf");  
//		  });
		
//		var doc = new jsPDF();
//		
//		const imgdata = html
//doc.fromHTML(ReactDOMServer.renderToStaticMarkup(this.render()));
//doc.save("myDocument.pdf");
		
//		const doc = new jsPDF();
// 
//		doc.text("Hello world!", 10, 10);
//		doc.save("a4.pdf");	
	}


	navigatePage(event, type){
		var base_url = window.location.origin;
		window.location.href = base_url + '/dashboard/' + type;
	}
	
	async videoClicked(event, index){
		var vidbackground = this.state.recentVideos[index];
		this.setState({
			vidbackground: vidbackground
		})
		
		
		var current_id = this.state.allIds[index];
//		var video_url = "https://www.tiktok.com/@" + this.state.current_user + '/video/' + current_id + '?lang=en';
//		
//		var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + video_url
//			
//		const scrape_data = await fetch(scrape_url)
//
//		var all_text = await scrape_data.text();
//
//		var key3 = "urls\":";
//		var key4 = "videoMeta\"";
//
//		var fin_url = all_text.match(new RegExp(key3 + "(.*)" + key4));
//		var finalurl = fin_url[1].replace(/['",]+/g, '').slice(1,-1);
//
//		if (finalurl.includes("tiktokcdn")){
//			finalurl = "https://onlinetik.com/wp-admin/admin-ajax.php?action=wppress_tt_stream&url=" + finalurl;
//		}	
//		
		
		
//		console.log(video_url);
		
		
		var comments = this.state.allCommentsNum[index];
		var likes = this.state.allLikesNum[index];
		var shares = this.state.allSharesNum[index];
		var engagement = comments + likes + shares;
		var engagement_rate = Math.round((engagement / this.state.followerCount) * 100);
		
		var post_score = Math.round((likes - this.state.likeAvg) / this.state.likeAvg * 100);
		var rate_info = "better than avg post";
		
		if (post_score < 0){
			post_score = post_score * -1;
			rate_info = "worse than avg post";
		}
		

		this.setState({
			showVideo: true,
			current_video: vidbackground,
			current_vid_id: current_id,
			
			current_comments: this.kFormatter(comments, 1),
			current_likes: this.kFormatter(likes, 1),
			current_shares: this.kFormatter(shares, 1),
			current_engagement: this.kFormatter(engagement, 2),
			current_engagement_rate: engagement_rate,
			current_post_score: post_score,
			rate_info: rate_info
			
		})
		
	}
	
	handleChange(e) {
        this.updateData(this.state.current_user, e.value, this.state.api_key);
    }
	
	navigatePage(event, type){
		if (type == "feed"){
			var base_url = window.location.origin;
			window.open((base_url + '/' + this.state.current_user), '_blank');
		} else {
			var base_url = window.location.origin;
		window.location.href = base_url + '/dashboard/' + type;
		}
	}
	
	createData(name, calories, fat, carbs, newone, protein) {
  		return { name, calories, fat, carbs, newone, protein };
	}
	
	createProductData(name, thumb, calories, fat, carbs, newone, protein) {
  		return { name, thumb, calories, fat, carbs, newone, protein };
	}
	
	createEmailData(email_address, date, timestamp) {
  		return { email_address, date, timestamp};
	}
	
	kFormatter(num, digits) {

  var si = [
    { value: 1, symbol: "" },
    { value: 1E3, symbol: "k" },
    { value: 1E6, symbol: "M" },
    { value: 1E9, symbol: "B" },
    { value: 1E12, symbol: "T" },
    { value: 1E15, symbol: "P" },
    { value: 1E18, symbol: "E" }
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}
	

	async updateData(current_user, time_period ,api_key){
		
		
		let newresp = await fetch("https://social-media-data-tt.p.rapidapi.com/live/user?username=" + current_user + "&fresh=1", {
			"method": "GET",
			"headers": {
				"x-rapidapi-key": "9c363ae588mshd71440a101292d4p1c4061jsn32d687f5b44f",
				"x-rapidapi-host": "social-media-data-tt.p.rapidapi.com"
			}
		})
		let newdata = await newresp.json();
		console.log("newdata:", newdata)
		
			var followingCount = newdata.following_count;
			var followerCount = newdata.follower_count;
			var heartCount = newdata.total_favorited;
			var videoCount = newdata.video_count;
			
			var averageLikes = Math.round(heartCount / videoCount);
			var followerRatio = Math.round(followingCount / followerCount);
		
		this.setState({
					followingCount: followingCount,
					followerCount: followerCount,
					heartCount: heartCount,
					videoCount: videoCount,
					averageLikes: averageLikes,
					followerRatio: followerRatio,
					
				})
				
				
		if (this.state.originalfollowerRatio == 0){
			this.setState({
				originalaverageLikes: averageLikes,
				originalfollowerRatio: followerRatio,
			})
		}
		
		
//		
//		
//		var followingCount = 1;
//		var followerCount = 1;
//		var heartCount = 1;
//		var videoCount = 1;
//		var averageLikes = 1;
//		var followerRatio = 1;
//		
//		//lets try to scrape from our other api
//		fetch("https://tiktok.p.rapidapi.com/live/user?username=" + current_user + "&fresh=1", {
//			"method": "GET",
//			"headers": {
//				"x-rapidapi-key": "865f499c17mshb07873cd88baaa1p116340jsneb0033f90f8e",
//				"x-rapidapi-host": "tiktok.p.rapidapi.com"
//			}
//		})
//		.then(response => {
//			
//			console.log("SCRAPED DATA: ", response);
//			
//			response.json().then(data =>{
//				//
//				followingCount = data.following_count;
//				followerCount = data.follower_count;
//				heartCount = data.total_favorited;
//				videoCount = data.video_count;
//				
//				var averageLikes = Math.round(heartCount / videoCount);
//				var followerRatio = Math.round(followingCount / followerCount);
//
//				this.setState({
//					followingCount: followingCount,
//					followerCount: followerCount,
//					heartCount: heartCount,
//					videoCount: videoCount,
//					averageLikes: averageLikes,
//					followerRatio: followerRatio,
//					
//				})
//				
//				
//		if (this.state.originalfollowerRatio == 0){
//			this.setState({
//				originalaverageLikes: averageLikes,
//				originalfollowerRatio: followerRatio,
//			})
//		}
//
//			})
//
//		})
//		.catch(err => {
//			console.error("ERRRRR", err);
//		});
//		

//		var manual_link = 'https://www.tiktok.com/@' + current_user + '?lang=en'
//		var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + manual_link;
//		const scrape_data = await fetch(scrape_url)
//		var all_text = await scrape_data.text();
//
//		var key3 = "stats\":";
//		var key4 = "shareMeta\"";
//		
//		var original = "";
//		try {
//		var original = all_text.match(new RegExp(key3 + "(.*)" + key4))[1].replace(/['",]+/g, '').slice(1,-1);
//		} catch (err){
//			return;
//		}
//		var sub_values = original.match(/\d+/g).map(Number);
//		var followingCount = sub_values[0];
//		var followerCount = sub_values[1];
//		var heartCount = sub_values[2];
//		var videoCount = sub_values[3];
//		
//		var averageLikes = Math.round(heartCount / videoCount);
//		var followerRatio = Math.round(followingCount / followerCount);
//		
//		this.setState({
//			followingCount: followingCount,
//			followerCount: followerCount,
//			heartCount: heartCount,
//			videoCount: videoCount,
//			averageLikes: averageLikes,
//			followerRatio: followerRatio,
//		})
		
		
//		
//		if (this.state.originalfollowerRatio == 0){
//			this.setState({
//				originalaverageLikes: averageLikes,
//				originalfollowerRatio: followerRatio,
//			})
//		}

		const new_scrape_data = await fetch('https://mysterious-reef-90754.herokuapp.com/https://urlebird.com/user/' + current_user + '/');
		
		const $ = cheerio.load(await new_scrape_data.text())
	
		var allVideos = [];
		const allLikes = [];
		const allComments = [];
		const allShares = [];
		const allLikesNum = [];
		const allCommentsNum = [];
		const allSharesNum = [];
		const allHrefs = [];
		const allIds = [];
	
		var counter = 0
		$('span').each(function(i, elem) {
			
			var classname = $(this).attr("class");
			if (classname === undefined){
				
				if (counter == 0){
					var newnum = $(this).text();
					allLikes.push(newnum);
					if (newnum.includes("K")){
						allLikesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000);
					} else if (newnum.includes("M")){
						allLikesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000000);
					} else {
						allLikesNum.push(parseInt(newnum));
					}
				} else if (counter == 1){
					var newnum = $(this).text();
					allComments.push(newnum);
					if (newnum.includes("K")){
						allCommentsNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000)
					} else if (newnum.includes("M")){
						allCommentsNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000000)
					} else {
						allCommentsNum.push(parseInt(newnum));
					}
				} else {
					var newnum = $(this).text();
					allShares.push(newnum);
					if (newnum.includes("K")){
						allSharesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000)
					} else if (newnum.includes("M")){
						allSharesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000000)
					} else {
						allSharesNum.push(parseInt(newnum));
					}
				}
				counter = (counter + 1)%3
			}
		});
		
		var hrefs = $('a');

		$('a').each(function(i, elem) {
			
			var link = $(this).attr("href");
			if (link.includes("https://urlebird.com/video/")){
				allHrefs.push(link);			  
				var new_id = [];
				for(var i = link.length-2; i >= 0; i--) {
					if (/^\d+$/.test(link[i])){
						new_id.push(link[i]);
					} else {
						break;
					}
				}
				allIds.push(new_id.reverse().join(''));
			}
		});
		
		fetch("https://social-media-data-tt.p.rapidapi.com/live/user/feed?username=" + current_user + "&fresh=1", {
	"method": "GET",
	"headers": {
		"x-rapidapi-key": "9c363ae588mshd71440a101292d4p1c4061jsn32d687f5b44f",
		"x-rapidapi-host": "social-media-data-tt.p.rapidapi.com"
	}
})
.then(response => {
	console.log(response);
			response.json().then(data =>{
				console.log(data)
				
				var media = data["media"];
				for (var i=0; i<allIds.length;i++){
					console.log("i", media[i])
					var vid = media[i]
					
					allVideos[i] = vid["video"]["dynamicCover"]
					
				}
				
//		
//		for (var k=0; k < allIds.length; k++){
//			var new_id = allIds[k];
//			allVideos[k] = 'https://www.tiktok.com/api/img/?itemId=' + new_id + '&location=1';
//		}

		this.setState({

			recentVideos : allVideos.slice(0, 4),
			allVideos : allVideos,
			allLikes : allLikes,
			allShares : allShares,
			allComments : allComments,
			
			allLikesNum : allLikesNum,
			allSharesNum : allSharesNum,
			allCommentsNum : allCommentsNum,
			
			allHrefs : allHrefs,
			allIds: allIds,
		})
			})})

		var commentCount = allCommentsNum.reduce(function(a, b){
        	return a + b;
    	}, 0);
		var likeCount = allLikesNum.reduce(function(a, b){
        	return a + b;
    	}, 0);
		var shareCount = allSharesNum.reduce(function(a, b){
        	return a + b;
    	}, 0);
		
		var denom = commentCount.length;
		
		var commentAvg = Math.round(commentCount/allCommentsNum.length);
		var likeAvg = Math.round(likeCount/allLikesNum.length);
		var shareAvg = Math.round(shareCount/allSharesNum.length);
		
		this.setState({
			commentCount: commentCount,
			likeCount: likeCount,
			shareCount: shareCount,
			
			commentAvg: commentAvg,
			likeAvg: likeAvg,
			shareAvg: shareAvg,
//			isloading: "none"
		})

		var info = {
			'tiktok_name': this.state.current_user,
			'following': followingCount,
			'followers': followerCount,
			'likes': heartCount,
			'videos': videoCount,
			'recent_comments': commentCount,
			'recent_likes': likeCount,
			'recent_shares': shareCount,
			'recent_posts': allLikes.length,

		}

		fetch('https://shoptik-app.herokuapp.com/dashboard/overview', {
			method: "POST",
			credentials: 'include',
			body: JSON.stringify(info),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						data = data.data;

						if (data.message == "original_entry"){
							
							//this is the first time, show cover page
							this.setState({
								originalfollowingCount: followingCount,
								originalfollowerCount: followerCount,
								originalheartCount: heartCount,
								originalvideoCount: videoCount,
								
								originalcommentCount: commentCount,
								originallikeCount: likeCount,
								originalshareCount: shareCount,
								originalcommentAvg: commentAvg,

								originalshareAvg: shareAvg,
								
								creation_date: data.creation_date,
								isOriginal: true,
								isloading: "none",
							})
							
//							console.log("creation date: ", data.creation_date)

						} else {
							var averageLikes = Math.round(data.likes / data.videos);
							var followerRatio = Math.round(data.following / data.followers);
							
							var commentAvg = Math.round(data.recent_comments/data.recent_posts);
							var likeAvg = Math.round(data.recent_likes/data.recent_posts);
							var shareAvg = Math.round(data.recent_shares/data.recent_posts);

							this.setState({
								originalfollowingCount: data.following,
								originalfollowerCount: data.followers,
								originalheartCount: data.likes,
								originalvideoCount: data.videos,
								originalaverageLikes: averageLikes,
								originalfollowerRatio: followerRatio,
								originalcommentCount: data.recent_comments,
								originallikeCount: data.recent_likes,
								originalshareCount: data.recent_shares,
								originalcommentAvg: commentAvg,
								originallikeAvg: likeAvg,
								originalshareAvg: shareAvg,
								
								creation_date: data.creation_date,
								isOriginal: false,
								isloading: "none",
							})
							//console.log("creation date: ", data.creation_date)
						}

					} else {
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
						this.setState({
							isLogged: false,
							current_user: ""
						})
				   }
				})
			})

		return;

		
	}
	
	loginStatus(){
		var curr = this;
		//fetch to see if logged in
		fetch('https://shoptik-app.herokuapp.com/dashboard/whoami', {
			method: "GET",
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						var current_user = data.data.current_user;
						
						var api_key = data.data.api_key;
						this.updateData(data.data.current_user, "1000", api_key);
				
						this.setState({
							isLogged: true,
							current_user: data.data.current_user,
							thumbnail_url: data.data.thumbnail_url,
							
							api_key: api_key
						})

					} else {
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
						this.setState({
							isLogged: false,
							current_user: "",
							
						})
				   }
				})
			})
	}
	
	componentDidMount() {
		this.loginStatus();
    }
	
	render() {
		if (this.state.isLogged){
			// && this.state.isOriginal == true
			if (this.state.isloading == "none" && this.state.isOriginal == true){
				
				return(
					
					<div class="main_container">
			<div>
	<NewSidebar selected={5}></NewSidebar>
    <br /><br /><br />
				<div class="main">

					<center>
					<div class="rankbox_container" style={{width: "96%"}}>
						<br /><br />
						<p class="feature_description_text">All the key TikTok metrics you need <br /> to grow your account</p><br /><br /><br />
						
						
						<div style={{float: "left", width: "44%", textAlign: "left", marginLeft: "5%", marginBottom: "50px"}}>
						<h5 style={{paddingBottom:"10px"}}>What's in the analytics overview?</h5> 
						
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> Total followers, likes, comments, posts</p>
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> Follower evolution amount and percentage</p>
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> Likes, comments, engagement history graphs</p>
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> Most recent videos and performance</p>
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> Generate reports from the above stats</p><br />
							
							<h5 style={{paddingBottom:"10px"}}>What else can I see with Shoptik analytics? </h5> 
						
							
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> Likes, comments, shares, engagement rate, performance for each video itself</p>
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> Industry benchmarks and competitor tracking</p>
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> Ranking each post on any statistic</p>
							<br /><br />
							
							
							
							<Button style={{padding: "10px 20px"}} class="tag_btn start_btn" variant="tag_btn"  onClick={event => this.setState({isOriginal: false})} > Get Started with Analytics </Button>
							
							<p style={{fontSize: "11px"}}>Note: Stats may take up to 24 hours to fully load</p>
						</div>
						<div style={{width: "44%", float: "right", marginRight: "5%"}}>
							<center>
							
							<img width="600px" 
 src="https://cdn.iconosquare.com/themes/2018-logged/img/solutions/1x/analytics/feature1.png?v=0.1.013" style={{marginTop:"20px"}}></img>
						
						
							
							</center>
						</div>
						
						
					</div>
						</center>
					

		<br /><br />

				
						</div></div></div>
				)
				
			}
			
			//if competitors is [] and isloading is done
			
			
			let currentComponent = this;
			
			let likes = currentComponent.state.allLikes;
			let comments = currentComponent.state.allComments;
			let shares = currentComponent.state.allShares;

			var tiktoklinks = this.state.recentVideos.map(function(link, index){
				return (
					<div key={index} class="img" style={{float:"left", width:"260px"}}>
						
						<div class="active_vid_container" style={{textAlignLast:"center"}}>

							
							<img onError={event => currentComponent.newImgError(index, "feed")}  id="active_vids" referrerPolicy="no-referrer" src={link} style={{objectFit: "cover", width: "230px"}} onClick={event => currentComponent.videoClicked(event, index)}/>
							
							<p id="shop_text" style={{marginLeft:"0%"}}>
								<p id="video_stats">
									💬 &nbsp; {comments[index]}&nbsp; &nbsp;
									❤️ &nbsp; {likes[index]}&nbsp; &nbsp;
									📤 &nbsp; {shares[index]}
								</p>
							</p>
						</div>
					</div>)
    		});
			
			var creation_date = new Date(this.state.creation_date).toUTCString();

			var follower_columns = [
			  {
				type: "string",
				label: "year"
			  },
			  {
				label: "Followers",
				type: "number"
			  }
			];
			
			var engagement_columns = [
			  {
				type: "string",
				label: "post"
			  },
			  {
				label: "Actions",
				type: "number"
			  }
			];
			
			var likes_columns = [
			  {
				type: "string",
				label: "post"
			  },
			  {
				label: "Likes",
				type: "number"
			  }
			];
			
			var comments_columns = [
			  {
				type: "string",
				label: "post"
			  },
			  {
				label: "Comments",
				type: "number"
			  }
			];

			
			var rows = [[2015, 2], [2016, 3], [2018, 6], [2019, 8], [2020, 14]];
			//calculate rows for follower growth, engagement rate, likes history, comments history
			//also collect the date
			var creation_date = new Date(this.state.creation_date).toUTCString().split(' ').slice(0, 4).join(' ');;
			var today = new Date().toUTCString().split(' ').slice(0, 4).join(' ');;
			var follower_rows = [[creation_date, this.state.originalfollowerCount], [today, this.state.followerCount]]
			
			
			var likes_rows = [];
			var sum = 0;
			for (var i=0; i<this.state.allLikesNum.length; i++){
				sum += this.state.allLikesNum[i];
				var current = [i, sum];
				likes_rows.push(current);
			}
			
			var comments_rows = [];
			var sum = 0;
			for (var i=0; i<this.state.allCommentsNum.length; i++){
				sum += this.state.allCommentsNum[i];
				var current = [i, sum];
				comments_rows.push(current);
			}
			
			var engagement_rows = [];
			var sum = 0;
			for (var i=0; i<this.state.allCommentsNum.length; i++){
				sum += this.state.allCommentsNum[i] + this.state.allLikesNum[i] + this.state.allSharesNum[i];
				var current = [i, sum];
				engagement_rows.push(current);
			}
			
			
			var follower_evolution = 0;
			var follower_evolution_percentage = 0;
			
			if (this.state.originalfollowerCount != 0){
				follower_evolution = (this.state.followerCount - this.state.originalfollowerCount);
				follower_evolution_percentage = (((this.state.followerCount - this.state.originalfollowerCount)/this.state.originalfollowerCount) * 100);
			}
			
		//OLD VIDEO VERSION	
//			<video poster={this.state.vidbackground} id="mainvid" referrerPolicy="no-referrer" crossOrigin src={this.state.current_video} controls muted playsInline autoPlay loop width="100%" ></video>
//	
			var cite_string = "https://www.tiktok.com/@" + currentComponent.client + "/video/" + currentComponent.state.current_vid_id;
			
		return (
			<div class="main_container">
			<div id="main">
				
					<Modal centered size="lg" show={this.state.showVideo} onHide={() => this.setState({ showVideo: false })}>
						<Modal.Header closeButton style={{borderBottom:"0 none", height: "0px"}}>
                        	<Modal.Title id="contained-modal-title-vcenter" centered>
							Video Performance
							</Modal.Title>
                        </Modal.Header>
					
                        <Modal.Body >
							<br/>
							
							<div class="left-vid" style={{float:"left", width:"35%"}}>
							
								
								
								<img src={this.state.vidbackground} id="mainvid" referrerPolicy="no-referrer" crossOrigin width="100%" style={{borderRadius: "10px"}}></img>
								
							
								<br /><br />
								<center>
								<a href={cite_string} target="_blank">
										<Button style={{padding: "10px 20px"}} class="tag_btn start_btn" variant="tiktokbutton" >  Watch Video on TikTok </Button> </a>
									</center>
								
								
								</div>
							<br />
							
							
		
						<div class="vid-right" style={{float:"right", marginTop: "-40px", width:"65%"}}>
							<center>
							<Smallbox width="40%" img="https://www.shoptik.co/images/analytics_icons/2.png" current={this.state.current_likes} subtitle="Likes" message="This is the number of likes on this post" />
							<Smallbox width="40%" img="https://www.shoptik.co/images/analytics_icons/5g.png" current={this.state.current_comments} subtitle="Comments" message="This is the number of comments on this post" />
							<Smallbox width="40%" img="https://www.shoptik.co/images/analytics_icons/6g.png" current={this.state.current_shares} subtitle="Shares" message="This is the number of shares on this post" />
							<Smallbox width="40%" img="https://www.shoptik.co/images/analytics_icons/4.png" current={this.state.current_engagement} subtitle="Engagement Actions" message="This is the number of likes + comments + shares on this post" />
							<Smallbox width="40%" img="https://www.shoptik.co/images/analytics_icons/4g.png" current={this.state.current_engagement_rate + " %"} subtitle="Engagement Rate" message="This is the number of engagement actions divided by your follower count" />
							<Smallbox width="40%" img="https://www.shoptik.co/images/analytics_icons/7.png" current={this.state.current_post_score + " %"} subtitle={this.state.rate_info} message="This is an estimate of how well your post performed compared to your average post" />
								
							
							</center>
							

							</div>
							
                        </Modal.Body>
        			</Modal>
			
    	<NewSidebar selected={5}></NewSidebar>
				
					<br /><br /><br />
<span class="newloader" style={{display: this.state.isloading}}></span>
	<img id="profile_image" onClick={event => this.navigatePage(event, "settings")} src={this.state.thumbnail_url} />
	<div class="main">

			<h3>TikTok Overview</h3>

		<br /><br />

					<Largebox width="30%" img="https://www.shoptik.co/images/analytics_icons/1.png" current={this.state.followerCount} original={this.state.originalfollowerCount} subtitle="Total Followers" message="This is your total number of TikTok followers" />
		
					<Largebox width="30%" img="https://www.shoptik.co/images/analytics_icons/1.png" current={follower_evolution} original={1} subtitle="Total Follower Evolution"  message="This is your new followers" />
		
					<Largebox width="30%" img="https://www.shoptik.co/images/analytics_icons/1.png" current={follower_evolution_percentage} original={1} subtitle="Follower Evolution Percentage"  message="This is the percentage increase of your followers" />
		
					<Largebox width="46.5%" img="https://www.shoptik.co/images/analytics_icons/3.png" current={this.state.videoCount} original={this.state.originalvideoCount} subtitle="Posts"  message="This is your total number of TikTok posts" />
		
					<Largebox width="46.5%" img="https://www.shoptik.co/images/analytics_icons/4.png" current={this.state.followerRatio} original={this.state.originalfollowerRatio} subtitle="Follower Ratio"  message="This is your followers divided by your following" />
		

		<div class="graphbox_container" style={{width: "46.5%"}}>

				<br /><br />
				 <center><h4><img style={{marginRight: "10px", marginBottom: "5px"}} src="https://assets.materialup.com/uploads/28cbb1b2-0e74-4925-9842-a0ac8fd90732/preview" width="23px" />Follower Growth</h4>		
			<Chart
          chartType="AreaChart"
          legendToggle
          rows={follower_rows}
          columns={follower_columns}
			options={follower_graph}
			width="105%"
        />           
               </center>
			
		</div>
					
		
		<Graphbox rows={this.state.allCommentsNum} rows2={this.state.allLikesNum} rows3={this.state.allSharesNum} columns={engagement_columns} type="engagement_graph" width="46.5%" bigtitle={this.state.followerRatio + "%"} title="Engagement Actions"  message="This is the sum of the likes, comments, and shares on your recent posts" />
		<br /><br /><br />
		<h4>Recent Engagement</h4>
		
		<br />
		
		<Largebox width="22.25%" img="https://www.shoptik.co/images/analytics_icons/2.png" current={this.state.heartCount} original={this.state.originalheartCount} subtitle="Likes"  message="This is your total number of TikTok likes" />
		
		<Largebox width="22.25%" img="https://www.shoptik.co/images/analytics_icons/5g.png" current={this.state.commentCount} original={this.state.originalcommentCount} subtitle="Comments"  message="This is your total number of TikTok comments" />
		
		<Largebox width="22.25%" img="https://www.shoptik.co/images/analytics_icons/6g.png" current={this.state.shareCount} original={this.state.originalshareCount} subtitle="Shares"  message="This is your total number of TikTok shares" />
		
		<Largebox width="22.25%" img="https://www.shoptik.co/images/analytics_icons/2.png" current={this.state.averageLikes} original={this.state.originalaverageLikes} subtitle="Average Likes / Post"  message="This is the number of likes your posts get on average" />
				
		<br /><br />
		
		<h4>Most Recent Videos</h4>
		
		<br /><br />
		
		<div style={{height: "440px"}}>
		{tiktoklinks}
			<br/><br/><br/><br/><br/><br/><br/><br/>
			<a href="#" onClick={event => this.navigatePage(event, "videos")} class="seeall" style={{color:"black", marginTop:"300px"}}><h5>See all&nbsp;
			
				<i class="fa fa-arrow-right" aria-hidden="true" onClick={event => this.navigatePage(event, "videos")}></i></h5>
			
			</a>
			
		</div>
		
		<br /><br />
	<h4>History</h4>
		<br />
			
		<Graphbox rows={this.state.allLikesNum} columns={likes_columns} type="likes_graph" width="46.5%" bigtitle={this.state.followerRatio + "%"} title="Likes History"  message="This is the amount of likes your recent posts have received, by post" />
		<Graphbox rows={this.state.allCommentsNum} columns={comments_columns} type="comments_graph" width="46.5%" bigtitle={this.state.followerRatio + "%"} title="Comments History"  message="This is the amount of comments your recent posts have received, by post" />

		
		<br /><br /><br /><br />

		
		
					</div>
					
				
			</div>
			</div>

			)
		} else {
			return ( <div></div>
			)
		}

	}
}

export default withRouter(Overview)
	