import React, { useState, useEffect, Component } from 'react';
import Feed from './Feed';
import Dashboard from './Dashboard';
import Button from 'react-bootstrap/Button';

import addnew from './add_vid.jpg';

import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Link, Switch, Redirect, withRouter } from "react-router-dom";

import Sidebar from "react-sidebar";

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/Info';
		
		
class NewSidebar extends Component {
	

	constructor(props){
		super(props);
		this.navigatePage = this.navigatePage.bind(this);
		this.dropDown = this.dropDown.bind(this);
		
		this.moveIn = this.moveIn.bind(this);
		this.moveOut = this.moveOut.bind(this);
		
		this.state = {
			classes: ["sidebar-btn", "sidebar-btn", "sidebar-btn", "sidebar-btn","sidebar-btn", "sidebar-btn","sidebar-btn", "sidebar-btn","sidebar-btn", "sidebar-btn","sidebar-btn", "sidebar-btn", "sidebar-btn", "sidebar-btn", "sidebar-btn"],
			colors: ["", "", "", "","", "","", "","", "","", "", "", "", ""]
		}
	}
	
	moveIn(event){
		event.preventDefault();
		document.getElementById("mySidebar").style.width = "230px";
		
		var drop1 = document.getElementById("drop1");
		var drop2 = document.getElementById("drop2");
		var drop3 = document.getElementById("drop3");
		var drop4 = document.getElementById("drop4");
		var drop5 = document.getElementById("drop5");
		
		//window.freeze(0.2);
		drop1.style.display = "";
		drop2.style.display = "";
		drop3.style.display = "";
		drop4.style.display = "";
		drop5.style.display = "";
		

		
	} 
	moveOut(event){
		
		
		document.getElementById("mySidebar").style.width = "75px";
		for (var j=0; j<5; j++){
			if (document.getElementById(j).classList.contains("show")){
				document.getElementById(j).classList.toggle("show");
			}
		}
		
		var drop1 = document.getElementById("drop1");
		var drop2 = document.getElementById("drop2");
		var drop3 = document.getElementById("drop3");
		var drop4 = document.getElementById("drop4");
		var drop5 = document.getElementById("drop5");
		
		drop1.style.display = "none";
		drop2.style.display = "none";
		drop3.style.display = "none";
		drop4.style.display = "none";
		drop5.style.display = "none";
		
//		if (drop1.classList.contains("open") && drop != "drop1"){
			drop1.className = 'fa fa-angle-down';
//		}
//		if (drop2.classList.contains("open") && drop != "drop2"){
			drop2.className = 'fa fa-angle-down';
//		}
//		if (drop3.classList.contains("open") && drop != "drop3"){
			drop3.className = 'fa fa-angle-down';
//		}
//		if (drop4.classList.contains("open") && drop != "drop4"){
			drop4.className = 'fa fa-angle-down';
//		}
//		if (drop5.classList.contains("open") && drop != "drop5"){
			drop5.className = 'fa fa-angle-down';
		//}
		
		
		
		
	}
	
	dropDown(event, index, drop){
		
		//all go down first, except current
		//when removing hover all go down
		
		var drop1 = document.getElementById("drop1");
		var drop2 = document.getElementById("drop2");
		var drop3 = document.getElementById("drop3");
		var drop4 = document.getElementById("drop4");
		var drop5 = document.getElementById("drop5");
		
		if (drop1.classList.contains("open") && drop != "drop1"){
			drop1.className = 'fa fa-angle-down';
		}
		if (drop2.classList.contains("open") && drop != "drop2"){
			drop2.className = 'fa fa-angle-down';
		}
		if (drop3.classList.contains("open") && drop != "drop3"){
			drop3.className = 'fa fa-angle-down';
		}
		if (drop4.classList.contains("open") && drop != "drop4"){
			drop4.className = 'fa fa-angle-down';
		}
		if (drop5.classList.contains("open") && drop != "drop5"){
			drop5.className = 'fa fa-angle-down';
		}
		
		
		
		
		var icon = document.getElementById(drop);
		
		if (icon.classList.contains("open")){
			icon.className = 'fa fa-angle-down';
		} else {
			icon.className = 'fa fa-angle-down open';
		}
		
		
		for (var j=0; j<5; j++){
			if (index !== j){
			if (document.getElementById(j).classList.contains("show")){
				document.getElementById(j).classList.toggle("show");
			}
//				document.getElementById(j).classList.toggle("hide");;
			}
		}
//		
//		document.getElementById("0").style.show = "none";
//		document.getElementById("1").style.display = "none";
//		document.getElementById("2").style.display = "none";
//		document.getElementById("3").style.display = "none";
//		document.getElementById("4").style.display = "none";
		document.getElementById(index).classList.toggle("show");
	}
	
	componentDidMount() {
		var drop1 = document.getElementById("drop1");
		var drop2 = document.getElementById("drop2");
		var drop3 = document.getElementById("drop3");
		var drop4 = document.getElementById("drop4");
		var drop5 = document.getElementById("drop5");
		
		drop1.style.display = "none";
		drop2.style.display = "none";
		drop3.style.display = "none";
		drop4.style.display = "none";
		drop5.style.display = "none";
		
		
		
		
		var index = this.props.selected;
		if (index == 14 || index == 13){
			var newcolors = this.state.colors;
			newcolors[0] = "#2c88ff";
			this.setState({
				colors: newcolors
			})
		} else if (index == 0 || index == 1 || index == 2 || index == 4){
			var newcolors = this.state.colors;
			newcolors[1] = "#2c88ff";
			this.setState({
				colors: newcolors
			})
		} else if (index == 5 || index == 6 || index == 8 || index == 9){
			var newcolors = this.state.colors;
			newcolors[2] = "#2c88ff";
			this.setState({
				colors: newcolors
			})
		} else if (index == 7 || index == 12){
			var newcolors = this.state.colors;
			newcolors[3] = "#2c88ff";
			this.setState({
				colors: newcolors
			})
		} else if (index == 3){
			var newcolors = this.state.colors;
			newcolors[4] = "#2c88ff";
			this.setState({
				colors: newcolors
			})
		} else if (index == 10){
			var newcolors = this.state.colors;
			newcolors[6] = "#2c88ff";
			this.setState({
				colors: newcolors
			})
		} else if (index == 11){
			var newcolors = this.state.colors;
			newcolors[5] = "#2c88ff";
			this.setState({
				colors: newcolors
			})
		}
		
		
    }
	
		navigatePage(event, type){
		var base_url = window.location.origin;
		window.location.href = base_url + '/dashboard/' + type;
	}
	
	
	render() {
		
		var classes = this.state.classes;
		var colors = this.state.colors;
		
	
		
		classes[this.props.selected] = "sidebar-btn btn-active";
		
		//colors[this.props.selected] = "#2C88FF";
		
//		var isSafari = window.safari !== undefined;
//					
//		if (isSafari){
//			return (
//			<div>Test</div>
//			
//			)
//		} else {

		return(
			
		<div>
				
								
<div id="mySidebar" class="sidebar" onMouseEnter={event => this.moveIn(event)} onMouseLeave={event => this.moveOut(event)} >
		
	<center>
		<img style={{ marginTop:"-80px", borderRadius:"3px"}} src="https://www.shoptik.co/images/fav2.jpg" width="25px" />
	</center>	
	
		<div style={{paddingLeft: "26px", marginTop:"-30px"}}>
			
			<br/><br/>
			
			<p style={{marginLeft:"4px", fontWeight:"600", fontSize:"15px"}} onClick={event => this.dropDown(event, 0, "drop1")} id="sidehead"><i className="fa fa-calendar" style={{verticalAlign:"right", fontSize: "22px",  paddingRight:"25px", color: colors[0]}}></i>Scheduling <i className="fa fa-angle-down" id="drop1" style={{fontSize: "17px", marginLeft:"5px"}}></i></p>
			
			<div id="0" class="dropdown-content">
				
							<button class={classes[14]} onClick={event => this.navigatePage(event, "schedule")} style={{marginLeft:"50px"}}>Calendar</button>
							<br />
							<button class={classes[13]} onClick={event => this.navigatePage(event, "media")} style={{marginLeft:"50px"}}>Media Library </button>
			
			</div>
				
				
			
			<p onClick={event => this.dropDown(event, 1, "drop2")}  style={{marginLeft:"4px", fontWeight:"600", fontSize:"15px",marginTop:"30px"}}id="sidehead"><i className="fa fa-tag" style={{verticalAlign:"right", fontSize: "25px",  paddingRight:"25px", color: colors[1]}}></i>Link in Bio<i className="fa fa-angle-down" id="drop2" style={{fontSize: "17px", marginLeft:"5px"}}> </i></p>
			
			<div id="1" class="dropdown-content">
			
							<button class={classes[0]} onClick={event => this.navigatePage(event, "manage_media")} style={{marginLeft:"50px"}}>Publish</button>
							<br />
							<button class={classes[1]} onClick={event => this.navigatePage(event, "profile")} style={{marginLeft:"50px"}}>Manage Bio </button><br />
							<button class={classes[2]} onClick={event => this.navigatePage(event, "analytics")} style={{marginLeft:"50px"}}>Performance</button>
							<br />
							<button class={classes[4]} onClick={event => this.navigatePage(event, "embedfeed")} style={{marginLeft:"50px"}}>Web Gallery</button>
							<br />
							<button class={classes[11]} onClick={event => this.navigatePage(event, "info")} style={{marginLeft:"50px"}}>Help</button>
			</div>
						
			
			
			<p onClick={event => this.dropDown(event, 2, "drop3")} style={{marginLeft:"4px", fontWeight:"600", fontSize:"15px",marginTop:"30px"}} id="sidehead"><i className="fa fa-bar-chart" style={{verticalAlign:"right", fontSize: "20px",  paddingRight:"23px", color: colors[2]}}></i>Analytics<i className="fa fa-angle-down" id="drop3" style={{fontSize: "17px", marginLeft:"5px"}}> </i></p>
			
			<div id="2" class="dropdown-content">
							<button class={classes[5]} onClick={event => this.navigatePage(event, "overview")} style={{marginLeft:"50px"}}>Overview</button>
							<br />
							<button class={classes[6]} onClick={event => this.navigatePage(event, "videos")} style={{marginLeft:"50px"}}>Video Stats</button>
							
							
							<br />	
							<button class={classes[8]} onClick={event => this.navigatePage(event, "benchmarks")} style={{marginLeft:"50px"}}>Benchmarks</button>
							<br />
							<button class={classes[9]} onClick={event => this.navigatePage(event, "rankings")} style={{marginLeft:"50px"}}>Post Ranks</button><br />
			</div>
				
			

			<p onClick={event => this.dropDown(event, 3, "drop4")} style={{marginLeft:"4px", fontWeight:"600", fontSize:"15px",marginTop:"30px"}} id="sidehead"><i className="fa fa-hashtag" style={{verticalAlign:"right", fontSize: "20px",  paddingRight:"25px", color: colors[3]}}></i>Listening<i className="fa fa-angle-down" id="drop4" style={{fontSize: "17px", marginLeft:"5px"}}> </i></p>
			
			<div id="3" class="dropdown-content">
		
							<button class={classes[7]} onClick={event => this.navigatePage(event, "competitors")} style={{marginLeft:"50px"}}>Competitors</button>
							<br />
							
							<button class={classes[12]} onClick={event => this.navigatePage(event, "hashtags")}style={{marginLeft:"50px"}}>Hashtags <button class="fullbar3"> <b> NEW </b> </button></button>
			</div>
							

			<p onClick={event => this.dropDown(event, 4, "drop5")} style={{marginLeft:"4px", fontWeight:"600", fontSize:"15px",marginTop:"30px"}} id="sidehead"><i className="fa fa-address-book-o" style={{verticalAlign:"right", fontSize: "22px", paddingRight:"24px", color: colors[4]}}></i>Campaigns<i className="fa fa-angle-down" id="drop5" style={{fontSize: "17px", marginLeft:"5px"}}> </i></p>
			
			<div id="4" class="dropdown-content">
			
			<button class={classes[3]} onClick={event => this.navigatePage(event, "influencers")} style={{marginLeft:"50px"}}>Influencers</button>
			</div>
							
			
			
			<div class="bottom-side">
			<p onClick={event => this.navigatePage(event, "info")} style={{marginLeft:"4px", fontWeight:"600", fontSize:"15px",marginTop:"30px"}} id="sidehead"><i className="fa fa-question" style={{verticalAlign:"right", fontSize: "24px", paddingRight:"31px", color: colors[5]}}></i>Help</p>
			
			<p onClick={event => this.navigatePage(event, "settings")} style={{marginLeft:"4px", fontWeight:"600", fontSize:"15px",marginTop:"30px"}} id="sidehead"><i className="fa fa-cog" style={{verticalAlign:"right", fontSize: "22px", paddingRight:"26px", color: colors[6]}}></i>Account</p>
			</div>
		</div>
    </div>
				
		</div>)
	}
	
}

export default NewSidebar



//			
//							<div class="bot"><hr />
//								<p style={{marginLeft:"50px", fontWeight:"600", fontSize:"15px"}}>Account</p>
//							<button class={classes[10]} onClick={event => this.navigatePage(event, "settings")}><i className="fa fa-cog" style={{verticalAlign:"right", fontSize: "18px", paddingRight:"26px", color: colors[10]}}></i>Account Info</button>
//							<br />
//							<button class={classes[11]} onClick={event => this.navigatePage(event, "info")}><i className="fa fa-question" style={{marginLeft: "3px", verticalAlign:"right", fontSize: "20px",paddingRight:"26px", color: colors[11]}}></i>Help</button>
//							</div>