import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import addnew from './add_vid.jpg';
import Sidebar from "react-sidebar";
import NewSidebar from "./NewSidebar";
import shoptik_icon from './Help/shoptik_icon.png';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import Switch from '@material-ui/core/Switch';

import cheerio from 'cheerio';
import NewAlert from './NewAlert';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/Info';


import verified from './Help/verified.png';



class Settings extends Component {
	constructor(props){
		super(props);
		this.state = {
			is_checked: true,
			thumbnail_url: ""
		}
		this.loginStatus = this.loginStatus.bind(this);
		this.navigatePage = this.navigatePage.bind(this);
		this.handleChecked = this.handleChecked.bind(this);
//		this.updateSettings = this.updateSettings.bind(this);
		this.extractFramesFromVideo = this.extractFramesFromVideo.bind(this);
	}
	
	async extractFramesFromVideo(videoUrl, fps) {
  		return new Promise(async (resolve) => {

    // fully download it first (no buffering):
    let videoBlob = await fetch(videoUrl, {mode: 'no-cors'}).then(r => r.blob());
    let videoObjectUrl = URL.createObjectURL(videoBlob);
    let video = document.createElement("video");

    let seekResolve;
    video.addEventListener('seeked', async function() {
      if(seekResolve) seekResolve();
    });

    video.src = videoObjectUrl;

    // workaround chromium metadata bug (https://stackoverflow.com/q/38062864/993683)
    while((video.duration === Infinity || isNaN(video.duration)) && video.readyState < 2) {
      await new Promise(r => setTimeout(r, 1000));
      video.currentTime = 10000000*Math.random();
    }
    let duration = video.duration;

    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    let [w, h] = [video.videoWidth, video.videoHeight]
    canvas.width =  w;
    canvas.height = h;

    let frames = [];
    let interval = 1 / fps;
    let currentTime = 0;

    while(currentTime < duration) {
      video.currentTime = currentTime;
      await new Promise(r => seekResolve=r);

      context.drawImage(video, 0, 0, w, h);
      let base64ImageData = canvas.toDataURL();
      frames.push(base64ImageData);
		console.log(base64ImageData)

      currentTime += interval;
    }
    resolve(frames);
  });
};

	
//	async extractFramesFromVideo(videoUrl, fps){
//		
//		console.log("called");
//	}
	
//	updateSettings(event){
//		event.preventDefault();
//		
//		//show alert
//			
//		var info = {
//			'bio': this.state.bio,
//			'bio_link': this.state.bio_link, 
//			'bio_string': this.state.bio_string,
//			'thumbnail_url': this.state.thumbnail_url,
//			'client': this.state.current_user
//		}
//		
//		this.setState({
//			showEditProduct: false,
//			current_item_id: "",
//			openAlert: true,
//			alertMessage: "Item Successfully Edited"
//		})	
//		
//		fetch('https://shoptik-app.herokuapp.com/dashboard/updatesettings', {
//				method: "POST",
//				body: JSON.stringify(info),
//				credentials: 'include',
//				headers: {
//					'Accept': 'application/json',
//					'Content-Type': 'application/json'
//				},
//			}).then(response => {
//				response.json().then(data =>{
//					if (data.status === 200){
//					} else {
//				   }
//				})
//			})
//		
//	}
	
	navigatePage(event, type){
		if (type == "feed"){
			var base_url = window.location.origin;
			window.open((base_url + '/' + this.state.current_user), '_blank');
		} else {
			var base_url = window.location.origin;
		window.location.href = base_url + '/dashboard/' + type;
		}
	}
	
	handleChecked(){
		if (this.state.is_checked){
			this.setState({
				is_checked: false
			})
		} else {
			this.setState({
				is_checked: true
			})
		}
	}

	componentDidMount(){

		this.loginStatus();

	}

	loginStatus(){
		var curr = this;
		//fetch to see if logged in
		fetch('https://shoptik-app.herokuapp.com/dashboard/whoami', {
			method: "GET",
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						
						var current_user = data.data.current_user;
						
						this.getClientData(current_user);
						
						this.setState({
							isLogged: true,
							current_user: data.data.current_user,
							thumbnail_url: data.data.thumbnail_url
						})
					} else {
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
						this.setState({
							isLogged: false,
							current_user: ""
						})
				   }
				})
			})
	}
	
	getClientData(client) {
			//fetch
			fetch('https://shoptik-app.herokuapp.com/api/' + client, {
				method: "GET",
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						var creation_date = new Date(data.data.creation_date).toUTCString();
						
						this.setState({
							isLogged: true,
							thumbnail_url: data.data.thumbnail_url,
							following: data.data.following,
							followers: data.data.followers,
							likes: data.data.likes,
							profile_name: data.data.profile_name,
							bio: data.data.bio,
							bio_link: data.data.bio_link,
							bio_string: data.data.bio_string,
							plan_type: data.data.plan_type,
							creation_date: creation_date

						})

					} else {
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
						this.setState({
							isLogged: false,
						})
						//redirect them to login page here
				   }
				})
			})

		}
	

	render() {
		if (this.state.isLogged){
			return(
				<div>
  <NewSidebar selected={10}></NewSidebar>
			
					
					<br /><br /><br />
					<img id="profile_image" onClick={event => this.navigatePage(event, "settings")} src={this.state.thumbnail_url} />
					<div class="main">

						<div style={{float: "left", width: "30%"}}>
						
							<h3>Account Details</h3>
						<p style={{fontSize: "12px"}} id="no_acc">Note: To make any changes to your plan, please contact us at support@shoptik.co</p>

						<br />
						<span>TikTok Username</span><input disabled readonly class="notedit" value={this.state.profile_name} type="text" placeholder="loading" /> <br /><br /><br />
							
							<span>Creation Date</span><input disabled readonly class="notedit" value={this.state.creation_date} type="text" placeholder="loading" /> <br /><br /><br />
							
							<span>Plan Type</span><input disabled readonly class="notedit" value={this.state.plan_type} type="text" placeholder="loading" /> <br /><br /><br /><br /><br />

							
						</div>
						

					<div style={{fontWeight:"500", float: "right", width: "70%"}}>
						

						
						</div>
					</div>
						
						 <Snackbar open={this.state.openAlert} autoHideDuration={3000} onClose={()=>{this.setState({openAlert: false, alertMessage: ""})}}>
						
					<MuiAlert elevation={6} variant="filled">{this.state.alertMessage}</MuiAlert>
				 </Snackbar>
						
					
				</div>)
			
		} else {
			return(<p></p>)
		}
	}
}

export default Settings

	
//						<Switch
//							checked={this.state.is_checked}
//							onChange={() => this.handleChecked()}
//							name="checkedA"
//							inputProps={{ 'aria-label': 'secondary checkbox' }}
//						  />
//						