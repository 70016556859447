import React, { useState, useEffect, Component } from 'react';
import Feed from './Feed';
import Dashboard from './Dashboard';
import Button from 'react-bootstrap/Button';

import addnew from './add_vid.jpg';

import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Link, Switch, Redirect, withRouter } from "react-router-dom";

import Sidebar from "react-sidebar";

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/Info';
import Largebox from "./Largebox";
		
class Comparebox extends Component {
	

	constructor(props){
		super(props);
		this.kFormatter = this.kFormatter.bind(this);
	}
	
		kFormatter(num, digits) {

		  var si = [
			{ value: 1, symbol: "" },
			{ value: 1E3, symbol: "k" },
			{ value: 1E6, symbol: "M" },
			{ value: 1E9, symbol: "B" },
			{ value: 1E12, symbol: "T" },
			{ value: 1E15, symbol: "P" },
			{ value: 1E18, symbol: "E" }
		  ];
		  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
		  var i;
		  for (i = si.length - 1; i > 0; i--) {
			if (num >= si[i].value) {
			  break;
			}
		  }
		  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}
	
	componentDidMount() {
    }
	
	render() {
		
		
		var classtype = "fa fa-arrow-up";
		var color_code = "#46D3C2";
		var width = this.props.width;

		var symbol = "%";
		var sub_symbol = "%";

		var difference_percentage = 5;
		
		var left_class = "fa fa-arrow-down";
		var right_class = "fa fa-arrow-up";
		
		var left_color = "#F74B70";
		var right_color = "#46D3C2";
		var left_word = "lower by";
		var right_word = "higher by";
		var diff_percentage = Math.round(this.props.num_right - this.props.num_left);
		
		if (this.props.num_left > this.props.num_right){
			left_word = "higher by";
			right_word = "lower by";
			left_color = "#46D3C2";
			right_color = "#F74B70";
			diff_percentage = Math.round(this.props.num_left - this.props.num_right);
			
			left_class = "fa fa-arrow-up";
			right_class = "fa fa-arrow-down";
		}
		
		return(
			
		<div class="comparebox_container" style={{width: width}}>
				
				
		<h4><img style={{marginRight: "10px", marginBottom: "5px"}} src="https://assets.materialup.com/uploads/28cbb1b2-0e74-4925-9842-a0ac8fd90732/preview" width="23px" /> {this.props.title} 
			
			<Tooltip style={{marginTop: "-12px", position: "absolute", opacity: "0.1"}} title={this.props.description}>
				  <IconButton id="moreinfo" aria-label={this.props.description}>
					<FilterListIcon />
				  </IconButton>
				</Tooltip></h4>
				
				
			<div style={{width: "49%", float: "left", backgroundColor: "#FEEEEE", borderRadius: "5px", borderTopWidth: "2px", borderTopStyle: "solid", borderTopColor: "#FCAEAE"}}><br /><br />
				<center>
					<img class="backshadow" src={this.props.img_left} style={{width: "45px", borderRadius: "50%"}} /><br />
			<h1>{this.kFormatter(this.props.num_left, 1)}{symbol}</h1>
			<h4 style={{color: "black"}}>{this.props.sub_left}</h4>
					<p class="change-arrow"><i class={left_class} style={{color: left_color}} aria-hidden="true"></i> {left_word} {diff_percentage} %</p>
					</center>
				</div>	
				
				<div style={{width: "49%", backgroundColor: "#E9F4FF", borderRadius: "5px", float: "right", borderTopWidth: "2px", borderTopStyle: "solid", borderTopColor: "#2C89F9"}}><br /><br />
					<center>
						<img class="backshadow" src={this.props.img_right} style={{width: "45px", borderRadius: "50%"}} /><br />
			<h1>{this.kFormatter(this.props.num_right, 1)}{symbol}</h1>
			<h4 style={{color: "black"}}>{this.props.sub_right}</h4>
					<p class="change-arrow"><i class={right_class} style={{color: right_color}} aria-hidden="true"></i> {right_word} {diff_percentage} %</p>
					</center>
				</div>
				
		</div>)
	}
}

export default Comparebox