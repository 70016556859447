import React, { useState, useEffect, Component } from 'react';
import Feed from './Feed';
import Dashboard from './Dashboard';
import Button from 'react-bootstrap/Button';

import addnew from './add_vid.jpg';

import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Link, Switch, Redirect, withRouter } from "react-router-dom";

import Sidebar from "react-sidebar";
import NewSidebar from "./NewSidebar";
import Box from "./Box";
import Select from 'react-select';
import Table from "./Table"

import EmailTable from "./EmailTable";
import ProductsTable from "./ProductsTable"
import shoptik_icon from './Help/shoptik_icon.png';

import { CSVLink } from 'react-csv';


let Options = [
  { label: "posted in the last day", value: 1 },
  { label: "posted in the last week", value: 7 },
  { label: "posted in the last month", value: 30 },
  { label: "posted in the last 3 months", value: 90 },
  { label: "posted in the last 6 months", value: 182 },
  { label: "all time", value: 1000 },
];

class Analytics extends Component {
	constructor(props){
		super(props);
		this.state = {
			isLogged: false,
			placeholder:"all time",
			
			total_videos: 0,
			total_products: 0,
			
			shop_clicks_total: 0,
			video_clicks_total: 0,
			product_clicks_total: 0,
			video_clicks_average: 0,
			product_clicks_average: 0,
			conversion: 0,
			
			videos: [],
			rows: [],
			product_rows: [],
			email_rows: [],
			product_message: "",
			thumbnail_url: ""
		}
		
		this.navigatePage = this.navigatePage.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.updateData = this.updateData.bind(this);
	}
	
	
	handleChange(e) {
        this.updateData(e.value)
    }
	
	navigatePage(event, type){
		if (type == "feed"){
			var base_url = window.location.origin;
			window.open((base_url + '/' + this.state.current_user), '_blank');
		} else {
			var base_url = window.location.origin;
		window.location.href = base_url + '/dashboard/' + type;
		}
	}

	createData(name, clicks, clicks_per_day, product_count, product_clicks, click_through_rate){
		return {name, clicks, clicks_per_day, product_count, product_clicks, click_through_rate};
	}

	createProductData(name, product_video, product_name, price, clicks, clicks_per_day, estimated_earnings) {
		return {name, product_video, product_name, price, clicks, clicks_per_day, estimated_earnings}
	}
	
	createEmailData(email_address, date, timestamp) {
  		return { email_address, date, timestamp};
	}
	
	
	updateData(time_period){
		//fetch data from api
        fetch('https://shoptik-app.herokuapp.com/analytics?timeperiod=' + time_period, {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
			credentials: 'include',
        }).then(response => {
            response.json().then(data =>{
                if (data.status === 200){
					
					var rows = [];
					for (var i=0; i < data.data.rows.length; i++){
						var curr = data.data.rows[i];
//						rows.push(this.createData(curr[0], curr[1], curr[2], curr[3], curr[4], curr[5]))
						rows.push(this.createData("https://cdn4.iconfinder.com/data/icons/social-media-2346/512/Tik_Tok-01-512.png", curr[1], curr[2], curr[3], curr[4], curr[5]))
					}
					
					var product_rows = [];
					for (var i=0; i < data.data.product_rows.length; i++){
						var val = data.data.product_rows[i];
//						product_rows.push(this.createProductData(val[0], val[1], val[2], val[3], val[4], val[5], val[6]))
						product_rows.push(this.createProductData(val[0], "https://cdn4.iconfinder.com/data/icons/social-media-2346/512/Tik_Tok-01-512.png", val[2], val[3], val[4], val[5], val[6]))
						
						
						
						
					}
					
					var email_rows = [];
					for (var i=0; i < data.data.email_rows.length; i++){
						var val = data.data.email_rows[i];
						console.log(val[0], val[1], val[2])
						email_rows.push(this.createEmailData(val[2], val[1], val[0]))
					}
					
					
					var plan = data.data.plan_type;
//					console.log("plan:", plan);
//					if ((plan == "standard_monthly") || (plan == "standard_annual")){
//						
////						console.log("BASIC")
//						product_rows = [];
//						this.setState({
//							product_message: "*Upgrade your plan to see individual product analytics"
//						})
//					}
					
					this.setState({
						total_videos: data.data.total_videos,
						total_products: data.data.total_products,
						shop_clicks_total: data.data.shop_clicks_total,
						video_clicks_total: data.data.video_clicks_total,
						product_clicks_total: data.data.product_clicks_total,
						video_clicks_average: (data.data.video_clicks_average * data.data.total_videos).toFixed(0),
						product_clicks_average: (data.data.product_clicks_average * data.data.total_products).toFixed(0),
						conversion: data.data.conversion,
						product_rows: product_rows,
						rows: rows,
						email_rows: email_rows
					})
					
				} else {
					
				}
			})
		})
	}
	
	loginStatus(){
		var curr = this;
		//fetch to see if logged in
		fetch('https://shoptik-app.herokuapp.com/dashboard/whoami', {
			method: "GET",
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						var current_user = data.data.current_user;
						
						this.updateData("1000");
				
						this.setState({
							isLogged: true,
							current_user: data.data.current_user,
							thumbnail_url: data.data.thumbnail_url
						})
						console.log("thumbnail url: ", data.data.thumbnail_url);

					} else {
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
						this.setState({
							isLogged: false,
							current_user: ""
						})
				   }
				})
			})
	}
	
	componentDidMount() {
		this.loginStatus();
    }
	
	render() {
		if (this.state.isLogged){
		return (
			
			<div class="main_container">
				
			<div>
				
		<NewSidebar selected={2}></NewSidebar>
				
					<br /><br /><br />
				
<img id="profile_image" onClick={event => this.navigatePage(event, "settings")} src={this.state.thumbnail_url} />
				
	<div class="main">
		<Select autosize={false} className="select" options={ Options } placeholder={this.state.placeholder} onChange={this.handleChange.bind(this)} />		
		
			<h3>Performance</h3>
		<br />
		
					<Box bigtitle={this.state.shop_clicks_total} title="All-time Total" subtitle="Shop clicks" message="This is the total number of visits your ShopTik Feed has"></Box>
					<Box bigtitle={this.state.video_clicks_total} title="Total" subtitle="Video clicks"  message="This is the total number of clicks videos in your selected time frame have receieved"></Box>
					<Box bigtitle={this.state.product_clicks_total} title="Total" subtitle="Product clicks"  message="This is the total number of clicks products in your selected time frame have receieved"></Box>
					<Box bigtitle={this.state.video_clicks_average} title="Daily Average" subtitle="Video clicks"  message="This is the average number of daily clicks videos in your selected time frame have receieved"></Box>
					<Box bigtitle={this.state.product_clicks_average} title="Daily Average" subtitle="Product Clicks"  message="This is the average number of clicks products in your selected time frame have receieved"></Box>
					<Box bigtitle={this.state.conversion + "%"} title="Average" subtitle="CTR"  message="This is your product clicks divided by video clicks. It represents the percentage of people who click a product after clicking a video"></Box>
				
		<br /><br />
		<Table username={this.state.current_user} total_videos={this.state.total_videos} rows={this.state.rows} class="table_shadow" ></Table><br />
		
		<p>{this.state.product_message}</p>
		<ProductsTable username={this.state.current_user} total_videos={this.state.total_products} rows={this.state.product_rows} class="table_shadow" ></ProductsTable>
		<br /><br />
		
		<EmailTable username={this.state.current_user} total_videos={this.state.email_rows.length} rows={this.state.email_rows} class="table_shadow" ></EmailTable><br />
					
					</div>
				
			</div>
			</div>

			)
		} else {
			return ( <div></div>
			)
		}

	}
}

export default withRouter(Analytics)