import React, { Component, PureComponent } from 'react';
import ReactDOM from 'react-dom';
import Imageview from './components/imageview'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import shoptik_icon from './Help/shoptik_icon.png';


import cheerio from 'cheerio';

import Skeleton from '@material-ui/lab/Skeleton';

import Sidebar from "react-sidebar";
import verified from './Help/verified.png';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import FeedItems from './FeedItems';

class FeedProducts extends PureComponent {
	constructor(props){
		super(props);
			//set state(none for now)
			 this.state = { //state is by default an object
				 videos: [],
				 links: [],
				 productstore: [],
				 show: false,
				 current_video: "",
				 feed: true,
				 left_opacity: 1,
				 right_opacity: 0.15,
				 showEmail: true,
				 showEmailAllowed: false,
				 email_placeholder: "",
				 email_title: "",
				 email_address: "",
				 showAnalytics: false,
				 final_items: []
			}

			this.getClientData = this.getClientData.bind(this);
			this.getProducts = this.getProducts.bind(this);
			this.productClicked = this.productClicked.bind(this);
			this.newestTikToks = this.newestTikToks.bind(this);
			this.testError = this.testError.bind(this);
			this.showEmailClicked = this.showEmailClicked.bind(this);
//			this.sendEmail = this.sendEmail.bind(this);

		
			this.attemptUpdate = this.attemptUpdate.bind(this);
		}
	
	attemptUpdate(event){
		event.preventDefault();
//		var plan = this.state.plan;
//		var tiktok_handle = this.state.profile_name;
	}

//		sendEmail(event){
//			event.preventDefault();
//			// ensure email_address is a real email
//			var re = /\S+@\S+\.\S+/;
//			if (!re.test(this.state.email_address)){
//				this.setState({
//					openAlert2: true,
//					alertMessage2: "Invalid email address",
//				})	
//				//show msg
//				return;
//			}
//		var info = {
//			'tiktok_name': this.state.profile_name, 
//			'email_address': this.state.email_address,
//		}
		
//		this.setState({
//			openAlert: true,
//			alertMessage: "Thanks for submitting!",
//			showEmail: false,
//		})	
//		
//		fetch('https://shoptik-app.herokuapp.com/dashboard/storeemail', {
//				method: "POST",
//				body: JSON.stringify(info),
//				credentials: 'include',
//				headers: {
//					'Accept': 'application/json',
//					'Content-Type': 'application/json'
//				},
//			}).then(response => {
//				response.json().then(data =>{
//					if (data.status === 200){
//					} else {
//				   }
//				})
//			})
//		}
	
		async newestTikToks(vid_id, client, videos) {
			var new_url = 'https://www.tiktok.com/@' + client + '/video/' + vid_id + '?lang=en'
			var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url
			
			const scrape_data = await fetch(scrape_url)
			const $ = cheerio.load(await scrape_data.text())
			
			var finalurl = JSON.parse($('div #videoObject').html())["contentUrl"];
			
			var newvideos = videos
			newvideos[0]['thumbnail'] = finalurl
			
			this.setState({
				videos: newvideos
			})
		}
	
		getClientData(client) {
			//fetch
			fetch('https://shoptik-app.herokuapp.com/' + client, {
				method: "GET",
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					
					//try producing products and feed here instead
					
					
					
					if (data.status === 200){
						this.setState({
							isLogged: true,
							thumbnail_url: data.data.thumbnail_url,
							following: data.data.following,
							followers: data.data.followers,
							likes: data.data.likes,
							profile_name: data.data.profile_name,
							bio: data.data.bio,
							bio_link: data.data.bio_link,
							bio_string: data.data.bio_string,
							videos: data.data.videos,
							productstore: data.data.store.reverse(),
							
							showEmailAllowed: data.data.email_addon,
							email_placeholder: data.data.email_placeholder,
							email_title: data.data.email_title,
							plan: data.data.plan,
				
						}, () => setTimeout(function() {
							if (data.data.plan == "analytics_monthly" || data.data.plan == "analytics_annual"){
								this.setState({
									show: false,
									showAnalytics: true
								})
							}
						}.bind(this), 4000))
						
						//console.log("plan:", data.data.plan);

					} else {
						//probably change what happens here
						
						var base_url = window.location.origin;
						window.location.href = base_url + '/home';
						this.setState({
							isLogged: false,
						})
						//redirect them to login page here
				   }
				})
			})

		}
	
		//track_product_clicks
		productClicked(event, link_id, clicks, product_link){
			fetch('https://shoptik-app.herokuapp.com/trackproductclicks?product_id=' + link_id + '&product_clicks=' + clicks, {
					method: "GET",
					credentials: 'include',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
				}).then(response => {
					response.json().then(data =>{
						if (data.status === 400) {
							
						} else if (data.status === 200){
							window.location = product_link;
							window.open(product_link, '_blank');
								var results = data.data;
						}
					})
				})

		}

		componentDidMount(){
//			var pathArray = window.location.pathname.split('/');
//			var client = pathArray[1];

			//this.getClientData(client);

		}

		//fetch products with the same link id
		getProducts(event, vid_id, thumbnail, clicks) {
			this.setState({
				links: []
			})
			this.setState({
				current_vid: (thumbnail + '?rand=' + Math.random()),
				show: true,
			})
			fetch('https://shoptik-app.herokuapp.com/products?vid_id=' + vid_id + '&clicks=' + clicks, {
					method: "GET",
					credentials: 'include',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
				}).then(response => {
					response.json().then(data =>{
						if (data.status === 400) {
						
							//show error popup
						} else if (data.status === 200){
								var results = data.data;
								this.setState({
									links: results.links
								})	
						}
					})
				})
		}

	async testError(tiktok_vid_id, vid_id, index){
		return;
		var is_chrome = ((navigator.userAgent.toLowerCase().indexOf('chrome') > -1) &&(navigator.vendor.toLowerCase().indexOf("google") > -1));
		
		var is_chrome_ios = /CriOS/.test(navigator.userAgent);
		
		if (is_chrome){
			return;
		}
		
		var client = this.state.profile_name;
		var videos = this.state.videos;
		
		var new_url = 'https://www.tiktok.com/@' + client + '/video/' + tiktok_vid_id + '?lang=en'
			
		var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url
		
			const scrape_data = await fetch(scrape_url);
			var all_text = await scrape_data.text();
			var key3 = "urls\":";
			var key4 = "videoMeta\"";
		
			var finalurl = "";

			try {
				console.log("ATTEMPTED REQUEST");
				var fin_url = all_text.match(new RegExp(key3 + "(.*)" + key4));
				
				finalurl = fin_url[1].replace(/['",]+/g, '').slice(1,-1);
				
				if (finalurl.includes("tiktokcdn")){
					
					var info = {
						'vid_id': vid_id,
						'video_url': finalurl,
					}
					
					fetch('https://shoptik-app.herokuapp.com/update_video', {
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
					} else {

				   }
				})
			})
					
				}
				
			
	
//			finalurl = JSON.parse($('div #videoObject').html())["contentUrl"];
//				
			} catch(err) {
				console.log(err);	
			}

			var newvideos = videos
			newvideos[index]['thumbnail'] = finalurl
			this.setState({
				videos: newvideos
			})
			
//			var info = {
//				'vid_id': vid_id,
//				'video_url': finalurl,
//			}
			
			if (finalurl == ""){
				
				return;
			}
		
//			if (finalurl !== ""){
//			fetch('https://shoptik-app.herokuapp.com/update_video', {
//				method: "POST",
//				body: JSON.stringify(info),
//				credentials: 'include',
//				headers: {
//					'Accept': 'application/json',
//					'Content-Type': 'application/json'
//				},
//			}).then(response => {
//				response.json().then(data =>{
//					if (data.status === 200){
//					} else {
//
//				   }
//				})
//			})
//			
//			}
	}
	
	showEmailClicked(event){
		var icon = document.getElementById('drop');
		
		if (this.state.showEmail == true){
			this.setState({
				showEmail: false
			})
			icon.className = 'fa fa-caret-down';
		} else {
			this.setState({
				showEmail: true
			})
			icon.className = 'fa fa-caret-down open';
		}
	}

	render() {
			let currentComponent = this;

			var items = [];
			var sidebar = [];
			var video_content = [];
			var product_items = [];
			var final_items = [];
//			if (this.state.feed){
	
				var is_chrome = ((navigator.userAgent.toLowerCase().indexOf('chrome') > -1) &&(navigator.vendor.toLowerCase().indexOf("google") > -1));

				if (navigator.userAgent.match(/Android/i)){
									
			product_items = this.props.productstore.map(function(item, index){
				return (	
			<div class="img" style={{float:"left", width:"50%", padding:"0px", height:"260px"}}>
						
						<div class="shop_items">
							
						<a onClick={event => currentComponent.productClicked(event, item.link_id, item.clicks, item.product_link)}>
							
						<img id="shop_img" referrerPolicy="no-referrer" src={item.thumbnail_link} style={{width:"85%", padding:"1px", objectFit: "cover", height:"180px"}} />
						
						<p id="shop_text">{item.name}<p id="price">{item.price}</p></p>	

					</a>
					</div>
			</div>
					)	
    		});
				}
				
				
				else if (is_chrome && this.props.visual != true){
									
				product_items = this.props.productstore.map(function(item, index){
				return (	
					<center>
					<div style={{width: "30%", position: "center"}}>
					
				<div class="img" style={{float:"left", width:"50%", padding:"0px", height:"330px"}}>
						
						<div class="shop_items">
							
						<a onClick={event => currentComponent.productClicked(event, item.link_id, item.clicks, item.product_link)}>
							
						<img id="shop_img" referrerPolicy="no-referrer" src={item.thumbnail_link} style={{width:"85%", padding:"1px", objectFit: "cover", height:"250px"}} />
						
						<p id="shop_text">{item.name}<p id="price">{item.price}</p></p>
					</a>
					</div>
			</div></div></center>
					)
    		});
					
				} else {
					
				
						product_items = this.props.productstore.map(function(item, index){
				return (	
						<div class="img" style={{float:"left", width:"50%", padding:"0px", height:"260px"}}>
						
						<div class="shop_items">
							
						<a onClick={event => currentComponent.productClicked(event, item.link_id, item.clicks, item.product_link)}>
							
						<img id="shop_img" referrerPolicy="no-referrer" src={item.thumbnail_link} style={{width:"85%", padding:"1px", objectFit: "cover", height:"180px"}} />
						
						<p id="shop_text">{item.name}<p id="price">{item.price}</p></p>	
					</a>
					</div>
			</div>
					)	
    		});
					
				}
//			}
	
//	if (this.state.feed){
//		if (this.state.final_items == []){
//			this.setState({
//				final_items: items
//			})
//			}
//		
//	} else {
//		this.setState({
//			final_items: product_items
//		})
//		
//	}
//		
		
			
			//get videos
//			const items = this.state.videos.map(function(item, index){
//				return (
//						<img onError={event => (currentComponent.testError(item.tiktok_video_id, item.vid_id, index))} referrerPolicy="no-referrer" src={item.thumbnail} style={{float:"left", width:"33.33%", padding:"1px", objectFit: "cover", height:"180px"}} onClick={event => currentComponent.getProducts(event, item.vid_id, item.thumbnail, item.clicks)} />
//					)	
//    		});

		
			//get most recent links
			
			//var new_url = this.state.current_vid + '?rand=' + Math.random();
		
			var drop = "";
//			var test = [0];
			var email_input = "";
		

//			var new_modal_video = [0].map(function(item, index){
//				return (
//					<img id="mainvid" referrerPolicy="no-referrer" crossOrigin src={new_url} width="70%" />
//					
//				)
//			})

//			else {
//				email_input = test.map(function(test, index){
//				return(
//					<center>
//						<br />
//						<p style={{marginBottom: "5px"}} id="bio">Follow us on these platforms!</p>
//						<i className="fa fa-instagram" style={{margin: "20px", fontSize: "23px"}} onClick={event => currentComponent.quick(event)}></i>
//						<i className="fa fa-facebook-official" style={{margin: "20px", fontSize: "23px"}}></i>
//						<i className="fa fa-pinterest" style={{margin: "20px", fontSize: "23px"}}></i>
//						<i className="fa fa-twitter-square" style={{margin: "20px", fontSize: "23px"}}></i>
//						<i className="fa fa-snapchat-square" style={{margin: "20px", fontSize: "23px"}}></i>
//					</center>
//				)
//			})
//			}
		
			


			//return render
			return (
				<div style={{fontWeight:"500", marginTop:"16px"}}>

					<div> {product_items}</div>

				</div>
			)
			
		} 

}

export default FeedProducts

//	<center>
//								<br />
//								
//						<input style={{width: "270px"}} maxlength="60" required onChange={event => this.setState({userHashtags: event.target.value})} value={this.state.userHashtags} id="product_title" name="product_title" type="text" placeholder="email" />
//						</center>