import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Imageview from './components/imageview'
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import shoptik_icon from './Help/shoptik_icon.png';


import cheerio from 'cheerio';

import Skeleton from '@material-ui/lab/Skeleton';

import Sidebar from "react-sidebar";
import verified from './Help/verified.png';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';


import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import VideoPreview from './VideoPreview';

const responsive = {
	  desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 2,
		slidesToSlide: 1 // optional, default to 1.
	  },
	  tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
		slidesToSlide: 2 // optional, default to 1.
	  },
	  mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 2,
		slidesToSlide: 2 // optional, default to 1.
	  }
};

class EmbedGallery extends Component {
	constructor(props){
		super(props);
			//set state(none for now)
			 this.state = { //state is by default an object
				 videos: [],
				 links: [],
				 productstore: [],
				 show: false,
				 showphone: false,
				 current_video: "",
				 feed: true,
				 left_opacity: 1,
				 right_opacity: 0.15,
				 showEmail: true,
				 showEmailAllowed: false,
				 email_placeholder: "",
				 email_title: "",
				 email_address: ""
			}

			this.getClientData = this.getClientData.bind(this);
			this.getProducts = this.getProducts.bind(this);
			this.productClicked = this.productClicked.bind(this);
			this.newestTikToks = this.newestTikToks.bind(this);
			this.testError = this.testError.bind(this);
			this.showEmailClicked = this.showEmailClicked.bind(this);
			this.sendEmail = this.sendEmail.bind(this);
			this.quick = this.quick.bind(this);
		}
	
	quick(event){
		event.preventDefault();
		window.open(("https://www.instagram.com/aldo_shoes/?hl=en"), '_blank');
		
	}
	
		sendEmail(event){
			event.preventDefault();
			// ensure email_address is a real email
			var re = /\S+@\S+\.\S+/;
			if (!re.test(this.state.email_address)){
				this.setState({
					openAlert2: true,
					alertMessage2: "Invalid email address",
				})	
				//show msg
				return;
			}
		var info = {
			'tiktok_name': this.state.profile_name, 
			'email_address': this.state.email_address,
		}
		
		this.setState({
			openAlert: true,
			alertMessage: "Thanks for submitting!",
			showEmail: false,
		})	
		
		fetch('https://shoptik-app.herokuapp.com/dashboard/storeemail', {
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
					} else {
				   }
				})
			})
		}
	
		async newestTikToks(vid_id, client, videos) {
			var new_url = 'https://www.tiktok.com/@' + client + '/video/' + vid_id + '?lang=en'
			var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url
			
			const scrape_data = await fetch(scrape_url)
			const $ = cheerio.load(await scrape_data.text())
			
			var finalurl = JSON.parse($('div #videoObject').html())["contentUrl"];
			
			var newvideos = videos
			newvideos[0]['thumbnail'] = finalurl
			
			this.setState({
				videos: newvideos
			})
		}
	
		getClientData(client) {
			//fetch
			fetch('https://shoptik-app.herokuapp.com/' + client, {
				method: "GET",
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
					
						this.setState({
							isLogged: true,
							thumbnail_url: data.data.thumbnail_url,
							following: data.data.following,
							followers: data.data.followers,
							likes: data.data.likes,
							profile_name: data.data.profile_name,
							bio: data.data.bio,
							bio_link: data.data.bio_link,
							bio_string: data.data.bio_string,
							videos: data.data.videos,
							productstore: data.data.store,
							
							showEmailAllowed: data.data.email_addon,
							email_placeholder: data.data.email_placeholder,
							email_title: data.data.email_title
							
							
						})

					} else {
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
						this.setState({
							isLogged: false,
						})
						//redirect them to login page here
				   }
				})
			})

		}
	
		//track_product_clicks
		productClicked(event, link_id, clicks, product_link){
			fetch('https://shoptik-app.herokuapp.com/trackproductclicks?product_id=' + link_id + '&product_clicks=' + clicks, {
					method: "GET",
					credentials: 'include',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
				}).then(response => {
					response.json().then(data =>{
						if (data.status === 400) {
							
						} else if (data.status === 200){
							
							if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
								window.location = product_link;
 // some code..
} else {
	window.open(product_link, '_blank');
}

							//window.location = product_link;
								var results = data.data;
						}
					})
				})

		}

		componentDidMount(){
			var pathArray = window.location.pathname.split('/');
			var client = pathArray[1];
			
				const script = document.createElement("script");

		script.src = "https://www.tiktok.com/embed.js";
		script.async = true;

		document.body.appendChild(script);

			this.getClientData(client);

		}

		//fetch products with the same link id
		getProducts(event, vid_id, thumbnail, clicks, tiktok_vid_id) {
			
			console.log("tiktok vid id: ", tiktok_vid_id);
			
			this.setState({
				links: []
			})
			
			//if mobile, show another popup
			if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
							fetch('https://shoptik-app.herokuapp.com/products?vid_id=' + vid_id + '&clicks=' + clicks, {
					method: "GET",
					credentials: 'include',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
				}).then(response => {
					response.json().then(data =>{
						if (data.status === 400) {
						
							//show error popup
						} else if (data.status === 200){
								var results = data.data;
								this.setState({
									links: results.links,
									current_vid: (thumbnail + '?rand=' + Math.random()),
									showphone: true,
									tiktok_vid_id: tiktok_vid_id
								})	
						}
					})
				})
//				this.setState({
//					current_vid: (thumbnail + '?rand=' + Math.random()),
//					showphone: true,
//				})
 				// some code..
			} else {
				fetch('https://shoptik-app.herokuapp.com/products?vid_id=' + vid_id + '&clicks=' + clicks, {
					method: "GET",
					credentials: 'include',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
				}).then(response => {
					response.json().then(data =>{
						if (data.status === 400) {
						
							//show error popup
						} else if (data.status === 200){
								var results = data.data;
								this.setState({
									links: results.links,
									current_vid: (thumbnail + '?rand=' + Math.random()),
									show: true,
									tiktok_vid_id: tiktok_vid_id
								})	
						}
					})
				})
//				this.setState({
//					current_vid: (thumbnail + '?rand=' + Math.random()),
//					show: true,
//				})
			}

		}

	async testError(tiktok_vid_id, vid_id, index){
		var is_chrome = ((navigator.userAgent.toLowerCase().indexOf('chrome') > -1) &&(navigator.vendor.toLowerCase().indexOf("google") > -1));
		
		var is_chrome_ios = /CriOS/.test(navigator.userAgent);
		
		if (is_chrome){
			
			
//			alert('Shoptik is not supported on Chrome Web. Please access this website from a mobile device');
			return;
		}

		
		var client = this.state.profile_name;
		var videos = this.state.videos;
		
		var new_url = 'https://www.tiktok.com/@' + client + '/video/' + tiktok_vid_id + '?lang=en'
			var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url
		
			const scrape_data = await fetch(scrape_url)
			const $ = cheerio.load(await scrape_data.text())
			
			var finalurl = JSON.parse($('div #videoObject').html())["contentUrl"];

			var newvideos = videos
			newvideos[index]['thumbnail'] = finalurl
			this.setState({
				videos: newvideos
			})
			
			var info = {
				'vid_id': vid_id,
				'video_url': finalurl,
			}
			
			fetch('https://shoptik-app.herokuapp.com/update_video', {
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
//						this.setState({
//							openAlert: true,
//							alertMessage: "Video Successfully Added to Feed"
//						})
					} else {

				   }
				})
			})
	}
	
	showEmailClicked(event){
		var icon = document.getElementById('drop');
		
		if (this.state.showEmail == true){
			this.setState({
				showEmail: false
			})
			icon.className = 'fa fa-caret-down';
		} else {
			this.setState({
				showEmail: true
			})
			icon.className = 'fa fa-caret-down open';
		}
	}

	render() {
			let currentComponent = this;
			var items = []
			var sidebar = []
			if (this.state.feed){
	
				var is_chrome = ((navigator.userAgent.toLowerCase().indexOf('chrome') > -1) &&(navigator.vendor.toLowerCase().indexOf("google") > -1));

//				if ((this.state.profile_name == "beekeepers_naturals") || (this.state.profile_name == "ebay") || (this.state.profile_name == "mejuri")){
//					
//				if (navigator.userAgent.match(/Android/i)){
//					items = this.state.videos.map(function(item, index){
//					return (
//						<div style={{width: "33.3%", float: "left"}}>
//
//							<p class="vid_bag"><i class="fa fa-shopping-bag" aria-hidden="true"></i></p>
//
//							<img referrerPolicy="no-referrer" src={item.thumbnail_link} style={{float:"left", width:"100%", padding:"1px", objectFit: "cover", height:"180px"}} onClick={event => currentComponent.getProducts(event, item.vid_id, item.thumbnail, item.clicks)}>
//							</img>
//					</div>
//
//						)	
//					});
//				}
//
//					else if (is_chrome){
//					
//						items = this.state.videos.map(function(item, index){
//						return (
//
//						<center>
//						<div style={{width: "30%", position: "center"}}>
//							<video referrerPolicy="no-referrer" src={item.thumbnail} autoPlay loop muted playsInline style={{float:"left", width:"33.33%", padding:"1px", objectFit: "cover", height:"300px"}} onClick={event => currentComponent.getProducts(event, item.vid_id, item.thumbnail, item.clicks)} />
//						</div>
//							</center>
//
//							)	
//						});
//					} else {
//
//						
//						items = this.state.videos.map(function(item, index){
//						return (
//						<div style={{width: "30%", float: "left"}}>
//
//							<p class="vid_bag"><i class="fa fa-shopping-bag" aria-hidden="true"></i></p>
//
//							<video playsInline autoPlay muted loop referrerPolicy="no-referrer" src={item.thumbnail} style={{float:"left", width:"33.33%", padding:"1px", objectFit: "cover", height:"300px"}} onClick={event => currentComponent.getProducts(event, item.vid_id, item.thumbnail, item.clicks)}>
//
//						
//						
//						</video>
//						
//				</div>
//						
//						)	
//					});
//					}
//
//				} 
//				
//				else if ( /iPhone|iPad|iPod/i.test(navigator.userAgent)) {
//					items = this.state.videos.map(function(item, index){
//							return (
//
//								<center>
//							<div style={{width: "100%", float: "left"}}>
//								<video referrerPolicy="no-referrer" src={item.thumbnail} style={{float:"left", width:"33%", padding:"1px"}} onClick={event => currentComponent.getProducts(event, item.vid_id, item.thumbnail, item.clicks)} />
//							</div>
//								</center>
//
//								)	
//							});
//				}
				

//				else if( /webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
//					
// 					// some code..
//						items = this.state.videos.map(function(item, index){
//							return (
//
//								<center>
//							<div style={{width: "100%", position: "center"}}>
//								<img referrerPolicy="no-referrer" src={item.thumbnail} style={{float:"left", width:"33%", padding:"1px"}} onClick={event => currentComponent.getProducts(event, item.vid_id, item.thumbnail, item.clicks)} />
//							</div>
//								</center>
//
//								)	
//							});
//				}
				
//				else if( /Android|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
//						 items = this.state.videos.map(function(item, index){
//							return (
//
//								<center>
//							<div style={{width: "100%", float: "left"}}>
//								<img referrerPolicy="no-referrer" src={item.thumbnail_link} style={{float:"left", width:"33%", padding:"1px"}} onClick={event => currentComponent.getProducts(event, item.vid_id, item.thumbnail, item.clicks)} />
//							</div>
//								</center>
//
//								)	
//							});
//					 }
//				
				

				 
					 
					 
					 
					items = this.state.videos.map(function(item, index){
						
						var thumburl = "https://www.tiktok.com/api/img/?itemId=" + item.tiktok_video_id + "&location=1";
		
					if (item.thumbnail_link.includes("trello")){
						thumburl = item.thumbnail_link;	
					}
						
					return (
					
						<center>
					<div style={{width: "100%", position: "center"}}>
						<img referrerPolicy="no-referrer" src={thumburl} style={{float:"left", width:"20%", padding:"15px", objectFit: "cover", height: "700px"}} onClick={event => currentComponent.getProducts(event, item.vid_id, item.thumbnail, item.clicks, item.tiktok_video_id)} />
					</div>
						</center>
						
						)	
					});

			}
		
		var test = [1];
		

			var links = this.state.links.map(function(link, index){
				
				return (
					<div key={link.link_id} class="" style={{width:"100%", padding:"20px"}}>
						
						<div>
						
							<a onClick={event => currentComponent.productClicked(event, link.link_id, link.clicks, link.product_link)} >
								
								<img id="" referrerPolicy="no-referrer" src={link.thumbnail_link} style={{width:"100%"}}/>
							
								<p id="shop_text_embed">{link.name}<p id="price">{link.price}</p></p>	</a>

						</div>
						
					</div>)
    		});
		
			var link_carousel = "";
		
		var links2 = this.state.links.map(function(link, index){
			
				return (
					<div key={link.link_id} class="img" style={{float:"left", width:"50%", padding:"0px", height:"250px"}}>
						
						<div class="shop_items" >
						
							<div class="tag_num">
							{index+1}	
							</div>

							<a onClick={event => currentComponent.productClicked(event, link.link_id, link.clicks, link.product_link)} >
								
								<img id="shop_img" referrerPolicy="no-referrer" src={link.thumbnail_link} style={{width:"85%", padding:"0px"}}/>
							
								<p id="shop_text">{link.name}<p id="price">{link.price}</p></p>	</a>

						</div>
						
					</div>)
    		});
			//get most recent links

		if (links.length == 1){
			var link = this.state.links[0];
			link_carousel = test.map(function(testthing, index){
				return (
					
					<div style={{width: "100%"}}>
					<center>
					<a onClick={event => currentComponent.productClicked(event, link.link_id, link.clicks, link.product_link)} >
						
					<img id="" referrerPolicy="no-referrer" src={link.thumbnail_link} style={{width:"50%"}}/>

					<p id="shop_text_embed">{link.name}<p id="price2">{link.price}</p></p>	</a></center></div>

			)
		})

		} else {
		
			link_carousel = test.map(function(testthing, index){
			return (
				<Carousel
					responsive={responsive}
					infinite={true}
					autoPlay={currentComponent.props.deviceType !== "mobile" ? true : false}
					autoPlaySpeed={5000}
					deviceType={currentComponent.props.deviceType} 
					containerClass="carousel-container" 
					
					draggable={true} swipeable={true}>
					
					{links}
					
				</Carousel>
			)
		})
		}
			//get most recent links
			
			//var new_url = "https://onlinetik.com/wp-admin/admin-ajax.php?action=wppress_tt_stream&url=" + this.state.current_vid;
		

		
			var vid_id = this.state.tiktok_vid_id;
			//console.log("tiktok_vid_id: ", vid_id);
		
			var cite_string = "https://www.tiktok.com/@" + currentComponent.client + "/video/" + vid_id;
		
			var new_url = [0].map(function(item, index){
				return (
					
					<blockquote class="tiktok-embed" cite={cite_string} data-video-id={vid_id} style={{borderRadius: "20px", width: "90%",  zIndex: "1"}} > <section style={{zIndex: "1", position: "relative"}}> </section> </blockquote>
						
				)
			})
		
			//return render
			return (
				<div style={{fontWeight:"500"}}>
					
					
					<Modal size="sm" class="widemodal" aria-labelledby="contained-modal-title-vcenter"
      centered show={this.state.show} onHide={() => this.setState({ show: false })}>
					
						
                        <Modal.Body>
							
							<h3 style={{float: "right", width: "65%"}} id="gallery_title">IN THIS VIDEO</h3>
                     		
							<div style={{width: "35%", float: "left"}}>
								
								
								
								<VideoPreview></VideoPreview>
								{new_url}
								
								
							</div>
						
							
							<div style={{marginLeft: "auto",marginRight: "auto", display: "block", float: "right", marginTop: "10%", width: "65%", zIndex: "9999 !important"}}>{link_carousel}</div>
				
							
							<br />
                        </Modal.Body>
						
        			</Modal>
					
						<Modal size="sm" class="widemodal" show={this.state.showphone} onHide={() => this.setState({ showphone: false })}>
						<Modal.Header closeButton style={{borderBottom:"0 none", height: "0px"}}>
                        	<Modal.Title id="contained-modal-title-vcenter" centered />
                        </Modal.Header>
                        <Modal.Body>
                     		<center>
								
								<VideoPreview></VideoPreview>
								{new_url}
				
							</center>	
							
							<br />
							
						<div> {links2} </div>
                        </Modal.Body>
						<a href="https://www.shoptik.co" style={{textAlignLast:"right", marginLeft:"85%", marginBottom:"10px"}}><img width="50px" src={shoptik_icon}></img></a>
        			</Modal>
					
					
					
				<div style={{marginTop:"-16px"}}> {items} </div>
					
				<Snackbar open={this.state.openAlert} autoHideDuration={3000} onClose={()=>{this.setState({openAlert: false, alertMessage: ""})}}>
						
					<MuiAlert elevation={6} variant="filled">{this.state.alertMessage}</MuiAlert>
				 </Snackbar>
					
					
				<Snackbar open={this.state.openAlert2} autoHideDuration={3000} onClose={()=>{this.setState({openAlert2: false, alertMessage2: ""})}}>
						
					<MuiAlert elevation={6} variant="filled" severity="info">{this.state.alertMessage2}</MuiAlert>
				 </Snackbar>
		
				</div>
			)
			
		} 

}

export default EmbedGallery

//	<center>
//								<br />
//								
//						<input style={{width: "270px"}} maxlength="60" required onChange={event => this.setState({userHashtags: event.target.value})} value={this.state.userHashtags} id="product_title" name="product_title" type="text" placeholder="email" />
//						</center>