import React, { useState, useEffect, Component } from 'react';
import Feed from './Feed';
import Dashboard from './Dashboard';
import Button from 'react-bootstrap/Button';

import addnew from './add_vid.jpg';

import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Link, Switch, Redirect, withRouter } from "react-router-dom";

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import shoptik_icon from './Help/shoptik_icon.png';
		
		
class Login extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			isLogged: false,
			openAlert: false,
			alertMessage: "Your username or password is incorrect",
			alertMessage2: "Your shoptik account was created successfully. Log in now to get started"
		}
		this.attemptLogin = this.attemptLogin.bind(this);
		this.attemptLoginMobile = this.attemptLoginMobile.bind(this);
	}
	
	attemptLogin(event){
		
		event.preventDefault();
		
		var email = document.getElementById("email").value;
		var password = document.getElementById("password").value;
		
		//add form body
		var info = {
			'email': email,
			'password': password, 
		}
		
		//attempt login
		fetch('https://shoptik-app.herokuapp.com/dashboard/login', {
			method: "POST",
			credentials: 'include',
			body: JSON.stringify(info),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						
						var current_user = data.data.user;
						window.location = window.location.origin + '/dashboard/manage_media?l=t';

					} else {
						this.setState({
							openAlert: true,
						})
				   }
				})
			})
	}
	
	attemptLoginMobile(event){
		
		event.preventDefault();
		
		var email = document.getElementById("email2").value;
		var password = document.getElementById("password2").value;
		
		//add form body
		var info = {
			'email': email,
			'password': password, 
		}
		
		//attempt login
		fetch('https://shoptik-app.herokuapp.com/dashboard/login', {
			method: "POST",
			credentials: 'include',
			body: JSON.stringify(info),
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						
						var current_user = data.data.user;
						window.location = window.location.origin + '/dashboard/manage_media?l=t';

					} else {
						this.setState({
							openAlert: true,
						})
				   }
				})
			})
	}


	componentDidMount() {
//		https://shoptik.app/dashboard/login?session_id={CHECKOUT_SESSION_ID}&u=' + new_user_id + '&acc=' + brand)
		//check for special, new signup case
		let params = (new URL(document.location)).searchParams;
		
		let session_id = params.get("session_id");
		let u = params.get("u");
		let acc = params.get("acc");
	
		//fetch db, pass in u. set activated to true
		let cancel_payment = params.get("free_trial");
		if (cancel_payment !== null){
			//user cancelled payment
			this.setState({
				openAlert2: true,
			})
		}
		
		if (u !== null){
			this.setState({
				openAlert2: true,
			})
			
			//fetch
			var info = {
				'objectId': u
			}
			
			fetch('https://shoptik-app.herokuapp.com/activate', {
			method: "POST",
				credentials: 'include',
				body: JSON.stringify(info),
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				}).then(response => {
					response.json().then(data =>{
						if (data.status === 200){
						} else {
					   }
					})
				})

			} else {

			}
		}
		//attempt login
	render() {
		
//		<img src={shoptik_icon} width="100px"></img><br />
		return (
			<div class="full_page">
				<div class="mobile_error"><br /><br /><br />
					<form onSubmit={event => this.attemptLoginMobile(event)}>
							
					<input id="email2" name="email2" class="logininput2" type="text" placeholder="TikTok Username" /> <br /><br />
							
					<input id="password2" class="logininput" type="password" name="password2" placeholder="Password" /> <br />
						
					<a class="login_text" href="mailto:kris@shoptik.co?subject=Forgot Password">Forgot Password?</a><br /><br /><br />
								
					<Button class="sign_in" variant="sign_in" id="sign_in_btn" type="submit" > <b>Sign in</b> </Button>
								
					<br /><br />
					
					<p id="no_acc">Don't have an account? <a href="https://www.shoptik.app/dashboard/register" class="login_text2">Get Started</a></p>
				</form>
	
				</div>
				
			
				<h2>&nbsp;</h2>
				
				<div class="left-login">
					
					<center>
					<div class="login_container">
						
				
				<p id="welcome">Welcome to <span id="login-title">Shoptik</span></p>
				<p id="login-title-desc" style={{textAlign:"left"}}>We make your TikTok shoppable and give you valuable analytics to help you grow.</p><br /><br />
				
				<form onSubmit={event => this.attemptLogin(event)}>
							
					<input style={{paddingLeft:"20px"}} id="email" name="email" class="logininput" type="text" placeholder="TikTok Username" /> <br /><br />
							
					<input style={{paddingLeft:"20px"}} id="password" class="logininput" type="password" name="password" placeholder="Password" /> <br />
						
					<a class="login_text" href="mailto:kris@shoptik.co?subject=Forgot Password">Forgot Password?</a><br /><br /><br />
								
					<Button class="sign_in" variant="sign_in" id="sign_in_btn" type="submit" > <b>Sign in</b> </Button>
								
					<br /><br />

					
					<p id="no_acc">Don't have an account? <a href="https://www.shoptik.app/dashboard/register" class="login_text2">Get Started</a></p>
				</form>
					
				</div>
						</center>
					</div>
				
				<div class="right-login">
				</div>
			
				
				<Snackbar open={this.state.openAlert} autoHideDuration={3000} onClose={()=>{this.setState({openAlert: false})}}>
						
					<MuiAlert severity="error" elevation={6} variant="filled">{this.state.alertMessage}</MuiAlert>
				 </Snackbar>
				
				<Snackbar open={this.state.openAlert2} autoHideDuration={10000} onClose={()=>{this.setState({openAlert2: false})}}>
						
					<MuiAlert severity="success" elevation={6} variant="filled">{this.state.alertMessage2}</MuiAlert>
				 </Snackbar>
				
				
			
			</div>
			)
	}
}

export default withRouter(Login)