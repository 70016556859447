import React, { useState, useEffect, Component } from 'react';
import Feed from './Feed';
import Dashboard from './Dashboard';
import Button from 'react-bootstrap/Button';

import addnew from './add_vid.jpg';

import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Link, Switch, Redirect, withRouter } from "react-router-dom";

import Sidebar from "react-sidebar";

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/Info';
		
		
class Box extends Component {
	
	constructor(props){
		super(props);
		
	}
	
	componentDidMount() {
    }
	
	render() {
		
		return(
			
		<div class="box_container">
				<Tooltip style={{marginLeft: "105px", marginTop: "-20px", position: "absolute", opacity: "0.1"}} title={this.props.message}>
				  <IconButton id="moreinfo" aria-label={this.props.message}>
					<FilterListIcon />
				  </IconButton>
				</Tooltip>
			
			<h2>{this.props.bigtitle}</h2>
			<h4>{this.props.subtitle}</h4>
			<p>{this.props.title}</p>
				
		</div>)
	}
}

export default Box