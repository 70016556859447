import React, { useState, useEffect, Component } from 'react';
import Feed from './Feed';
import Dashboard from './Dashboard';
import Button from 'react-bootstrap/Button';

import addnew from './add_vid.jpg';

import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Link, Switch, Redirect, withRouter } from "react-router-dom";

import Sidebar from "react-sidebar";
import NewSidebar from "./NewSidebar";
import Largestbox from "./Largestbox";
import Largebox from "./Largebox";
import Smallbox from "./Smallbox";
import Select from 'react-select';
import VideosTable from "./VideosTable"

import EmailTable from "./EmailTable";
import ProductsTable from "./ProductsTable"
import shoptik_icon from './Help/shoptik_icon.png';

import { CSVLink } from 'react-csv';
import cheerio from 'cheerio';

import Chart from 'react-google-charts';
import { LineOptions } from './charts';

import Graphbox from "./Graphbox";

import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

let Options = [
	{ label: "order by newest to oldest", value: 0 },
  { label: "order by most likes to least likes", value: 1 },
{ label: "order by most shares to least shares", value: 2 },
  { label: "order by most comments to least comments", value: 3 },
];

class Postranks extends Component {
	constructor(props){
		super(props);
		this.state = {
			isLogged: false,
			placeholder:"order by newest to oldest",
			
			total_videos: 0,
			total_products: 0,
			
			shop_clicks_total: 0,
			video_clicks_total: 0,
			product_clicks_total: 0,
			video_clicks_average: 0,
			product_clicks_average: 0,
			conversion: 0,
			
			videos: [],
			product_message: "",
			
			originalfollowingCount: 560,
			originalfollowerCount: 7550,
			originalheartCount: 4560,
			originalvideoCount: 450,
			originalaverageLikes: 450,
			originalfollowerRatio:540,
			originalcommentCount: 540,
			originallikeCount: 450,
			originalshareCount: 450,
			originalcommentAvg: 450,
			originallikeAvg: 540,
			originalshareAvg: 540,
			
			followingCount: 0,
			followerCount: 0,
			heartCount: 0,
			videoCount: 0,
			averageLikes: 0,
			followerRatio:0,	
			
			commentCount: 0,
			likeCount: 0,
			shareCount: 0,
			commentAvg: 0,
			likeAvg: 0,
			shareAvg: 0,
			
			allLikes: [],
			allComments: [],
			allShares: [],
			allVideos: [],
			recentVideos: [],
			
			showVideo: false,
			
			current_video:"",
			
			big_tuple: [],
			
			rows: [],
			thumbnail_url: ""
		}
		
		this.navigatePage = this.navigatePage.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.updateData = this.updateData.bind(this);
		this.kFormatter = this.kFormatter.bind(this);
		
		this.videoClicked = this.videoClicked.bind(this);
		this.navigatePage = this.navigatePage.bind(this);
		
		this.sortByLikes = this.sortByLikes.bind(this);
		this.sortByShares = this.sortByShares.bind(this);
		this.sortByComments = this.sortByComments.bind(this);
	}
	
	createData(name, likes, comments, shares, performance, recentness) {
  		return { name, likes, comments, shares, performance, recentness };
	}
	
	navigatePage(event, type){
		var base_url = window.location.origin;
		window.location.href = base_url + '/dashboard/' + type;
	}
	
	//DEPRECATED FUNCTION DO NOT USE
	async videoClicked(event, index){
//		var current_id = this.state.big_tuple[index][5];
//		var video_url = "https://www.tiktok.com/@" + this.state.current_user + '/video/' + current_id + '?lang=en';
//		
//		var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + video_url
//			
//		const scrape_data = await fetch(scrape_url)
//
//		var all_text = await scrape_data.text();
//
//		var key3 = "urls\":";
//		var key4 = "videoMeta\"";
//
//		var fin_url = all_text.match(new RegExp(key3 + "(.*)" + key4));
//		var finalurl = fin_url[1].replace(/['",]+/g, '').slice(1,-1);
//		
//		
//		var comments = this.state.big_tuple[index][3];
//		var likes = this.state.big_tuple[index][1];
//		var shares = this.state.big_tuple[index][2];
//		
//		var engagement = comments + likes + shares;
//		var engagement_rate = Math.round((engagement / this.state.followerCount) * 100);
//		
//		var post_score = Math.round((likes - this.state.likeAvg) / this.state.likeAvg * 100);
//		var rate_info = "better than avg post";
//		
//		if (post_score < 0){
//			post_score = post_score * -1;
//			rate_info = "worse than avg post";
//		}
//
//		this.setState({
//			showVideo: true,
//			current_video: finalurl,
//			
//			current_comments: this.kFormatter(comments, 1),
//			current_likes: this.kFormatter(likes, 1),
//			current_shares: this.kFormatter(shares, 1),
//			current_engagement: this.kFormatter(engagement, 2),
//			current_engagement_rate: engagement_rate,
//			current_post_score: post_score,
//			rate_info: rate_info
//			
//		})
//		
	}
	
	
	handleChange(e) {
        this.updateData(this.state.current_user, e.value);
    }
	
	navigatePage(event, type){
		if (type == "feed"){
			var base_url = window.location.origin;
			window.open((base_url + '/' + this.state.current_user), '_blank');
		} else {
			var base_url = window.location.origin;
		window.location.href = base_url + '/dashboard/' + type;
		}
	}
	
	kFormatter(num, digits) {

	  var si = [
		{ value: 1, symbol: "" },
		{ value: 1E3, symbol: "k" },
		{ value: 1E6, symbol: "M" },
		{ value: 1E9, symbol: "B" },
		{ value: 1E12, symbol: "T" },
		{ value: 1E15, symbol: "P" },
		{ value: 1E18, symbol: "E" }
	  ];
	  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
	  var i;
	  for (i = si.length - 1; i > 0; i--) {
		if (num >= si[i].value) {
		  break;
		}
	  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}
	
  
	sortByLikes(a, b) {
		if (a[1] === b[1]) {
			return 0;
		} else {
			return (a[1] < b[1]) ? 1 : -1;
		}
	}
	sortByShares(a, b) {
		if (a[2] === b[2]) {
			return 0;
		} else {
			return (a[2] < b[2]) ? 1 : -1;
		}
	}
	sortByComments(a, b) {
		if (a[3] === b[3]) {
			return 0;
		} else {
			return (a[3] < b[3]) ? 1 : -1;
		}
	}
	
	async updateData(current_user, time_period){
		//scrape stuff from tiktok and urlbird
let newresp = await fetch("https://tiktok.p.rapidapi.com/live/user?username=" + current_user + "&fresh=1", {
			"method": "GET",
			"headers": {
				"x-rapidapi-key": "865f499c17mshb07873cd88baaa1p116340jsneb0033f90f8e",
				"x-rapidapi-host": "tiktok.p.rapidapi.com"
			}
		})
		let newdata = await newresp.json();
			var followingCount = newdata.following_count;
				var followerCount = newdata.follower_count;
				var heartCount = newdata.total_favorited;
				var videoCount = newdata.video_count;
				
				var averageLikes = Math.round(heartCount / videoCount);
				var followerRatio = Math.round(followingCount / followerCount);
		
		this.setState({
					followingCount: followingCount,
					followerCount: followerCount,
					heartCount: heartCount,
					videoCount: videoCount,
					averageLikes: averageLikes,
					followerRatio: followerRatio,
					
				})
				
				
		if (this.state.originalfollowerRatio == 0){
			this.setState({
				originalaverageLikes: averageLikes,
				originalfollowerRatio: followerRatio,
			})
		}
		

//		var manual_link = 'https://www.tiktok.com/@' + current_user + '?lang=en'
//		
//		var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + manual_link;
//		const scrape_data = await fetch(scrape_url)
//		var all_text = await scrape_data.text();
//
//		var key3 = "stats\":";
//		var key4 = "shareMeta\"";
//		
//		var original = all_text.match(new RegExp(key3 + "(.*)" + key4))[1].replace(/['",]+/g, '').slice(1,-1);;
//		console.log(original);
//		
//		var sub_values = original.match(/\d+/g).map(Number);
//		console.log(sub_values);
//		
//		var followingCount = sub_values[0];
//		var followerCount = sub_values[1];
//		var heartCount = sub_values[2];
//		var videoCount = sub_values[3];
//		
//		var averageLikes = Math.round(heartCount / videoCount);
//		var followerRatio = Math.round(followingCount / followerCount);
//		
//		this.setState({
//			followingCount: followingCount,
//			followerCount: followerCount,
//			heartCount: heartCount,
//			videoCount: videoCount,
//			averageLikes: averageLikes,
//			followerRatio: followerRatio
//		})
		
		
		//SCRAPING URLEBIRD VALUES (may need to convert to integers)
		const new_scrape_data = await fetch('https://mysterious-reef-90754.herokuapp.com/https://urlebird.com/user/' + current_user + '/');
		
		const $ = cheerio.load(await new_scrape_data.text())
	
		var allVideos = [];
		
//		var vids = $('.thumb .img img');
//		$('.thumb .img img').each(function(i, elem) {
//			var reg_src = $(this).attr("src");
//			var data_src = $(this).attr("data-src");
//			
//			if (reg_src.includes("data:image")){
//				allVideos[i] = data_src;
//			} else if (reg_src.includes("https://p16")){
//				allVideos[i] = reg_src;
//			}
//		});

		const allLikes = [];
		const allComments = [];
		const allShares = [];
		
		const allLikesNum = [];
		const allCommentsNum = [];
		const allSharesNum = [];
	
		var counter = 0
		$('span').each(function(i, elem) {
			
			var classname = $(this).attr("class");
			if (classname === undefined){
				
				if (counter == 0){
					var newnum = $(this).text();
					allLikes.push(newnum);
					if (newnum.includes("K")){
						allLikesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000);
					} else if (newnum.includes("M")){
						allLikesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000000);
					} else {
						allLikesNum.push(parseInt(newnum));
					}
				} else if (counter == 1){
					var newnum = $(this).text();
					allComments.push(newnum);
					if (newnum.includes("K")){
						allCommentsNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000);
					} else if (newnum.includes("M")){
						allCommentsNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000000);
					} else {
						allCommentsNum.push(parseInt(newnum));
					}
				} else {
					var newnum = $(this).text();
					allShares.push(newnum);
					if (newnum.includes("K")){
						allSharesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000);
					} else if (newnum.includes("M")){
						allSharesNum.push(parseInt(newnum.replace(/[^\d.-]/g, '')) * 1000000);
					} else {
						allSharesNum.push(parseInt(newnum));
					}
				}
				counter = (counter + 1)%3
			}
		});
		
//		https://urlebird.com/video/
		const allHrefs = [];
		const allIds = [];
		
		var hrefs = $('a');

		$('a').each(function(i, elem) {
			
			var link = $(this).attr("href");
			if (link.includes("https://urlebird.com/video/")){
				allHrefs.push(link);
							  
				var new_id = [];
//				var name = "John Smith".split("");
				
				for(var i = link.length-2; i >= 0; i--) {
					
					if (/^\d+$/.test(link[i])){
						new_id.push(link[i]);
					} else {
						break;
					}
				}
				allIds.push(new_id.reverse().join(''));
			}
		});
		
		for (var k=0; k < allIds.length; k++){
			var new_id = allIds[k];
			
			allVideos[k] = 'https://www.tiktok.com/api/img/?itemId=' + new_id + '&location=1';
			
//			
//			var new_url = "https://www.tiktok.com/oembed?url=https://www.tiktok.com/@" + current_user + "/video/" + new_id;
//			var scrape_url = await fetch(new_url);
//			var all_text = await scrape_url.text();
//
//			var key3 = "thumbnail_url\":\"";
//			var key4 = "\"provider_url";
//
//			var fin_url = all_text.match(new RegExp(key3 + "(.*)" + key4));
//			try {
//				console.log("RESULT: ", fin_url[1].slice(0,-1));
//				allVideos[k] = fin_url[1].replace(/['",]+/g, '');
//			}
//			catch (err){
//				
//			}
		}
		
		// CHANGE THIS BACK!
//		var new_all_videos = [];
//		for (var k=0; k < allHrefs.length; k++){
//			var new_url = allHrefs[k];
//			var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url
//
//			const scrape_data = await fetch(scrape_url)
//			const $ = cheerio.load(await scrape_data.text())
//			var final_url = $('video').attr("src");
//
//			new_all_videos.push(final_url);
//		}
//		allVideos = new_all_videos;
		
		//could potentially sort videos here
		//need a tuple type of thing here
		
		var big_tuple = [];
		var rows = [];
		
		var max_performance = Math.max(...allLikesNum) + Math.max(...allSharesNum) + Math.max(...allCommentsNum);
		for (var i=0;i<allVideos.length;i++){
			big_tuple.push([allVideos[i], allLikesNum[i], allSharesNum[i], allCommentsNum[i], allHrefs[i], allIds[i]]);
			
			
			var performance = Math.round(((allLikesNum[i] + allCommentsNum[i] + allSharesNum[i])/max_performance) * 100);
//			rows.push(this.createData(allVideos[i], allLikesNum[i], allCommentsNum[i], allSharesNum[i], performance, i));
			
			rows.push(this.createData("https://cdn4.iconfinder.com/data/icons/social-media-2346/512/Tik_Tok-01-512.png", allLikesNum[i], allCommentsNum[i], allSharesNum[i], performance, i));
			
		}

//		sort big tuple
		if (time_period == 1){
			big_tuple.sort(this.sortByLikes);
		} else if (time_period == 2){
			big_tuple.sort(this.sortByShares);
		} else if (time_period == 3){
			big_tuple.sort(this.sortByComments);
		}
		
		
		this.setState({
			rows: rows,
			big_tuple: big_tuple,
			
			recentVideos : allVideos,
			allVideos : allVideos,
			allLikes : allLikes,
			allShares : allShares,
			allComments : allComments,
			
			allLikesNum : allLikesNum,
			allSharesNum : allSharesNum,
			allCommentsNum : allCommentsNum,
			
			allHrefs : allHrefs,
			allIds: allIds,
		})

		var commentCount = allCommentsNum.reduce(function(a, b){
        	return a + b;
    	}, 0);
		var likeCount = allLikesNum.reduce(function(a, b){
        	return a + b;
    	}, 0);
		var shareCount = allSharesNum.reduce(function(a, b){
        	return a + b;
    	}, 0);
		
		var denom = commentCount.length;
		
		var commentAvg = Math.round(commentCount/allCommentsNum.length);
		var likeAvg = Math.round(likeCount/allLikesNum.length);
		var shareAvg = Math.round(shareCount/allSharesNum.length);
		
		this.setState({
			commentCount: commentCount,
			likeCount: likeCount,
			shareCount: shareCount,
			
			commentAvg: commentAvg,
			likeAvg: likeAvg,
			shareAvg: shareAvg
		})

		return;

		
	}
	
	loginStatus(){
		var curr = this;
		//fetch to see if logged in
		fetch('https://shoptik-app.herokuapp.com/dashboard/whoami', {
			method: "GET",
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						var current_user = data.data.current_user;
						
						this.updateData(data.data.current_user, "1000");
				
						this.setState({
							isLogged: true,
							current_user: data.data.current_user,
							thumbnail_url: data.data.thumbnail_url
						})

					} else {
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
						this.setState({
							isLogged: false,
							current_user: ""
						})
				   }
				})
			})
	}
	
	componentDidMount() {
		this.loginStatus();
    }
	
	render() {
		if (this.state.isLogged){
			
			let currentComponent = this;
//			
//			let likes = currentComponent.state.allLikes;
//			let comments = currentComponent.state.allComments;
//			let shares = currentComponent.state.allShares;
//
//			var tiktoklinks = this.state.big_tuple.map(function(link, index){
//				return (
//					<div key={index} class="img" style={{float:"left", width:"260px"}}>
//						
//						<div class="active_vid_container" style={{textAlignLast:"center"}}>
//
//							
//							<img id="active_vids" referrerPolicy="no-referrer" src={link[0]} style={{objectFit: "cover", width: "230px", height:"230px"}} onClick={event => currentComponent.videoClicked(event, index)}/>
//							
//							<p id="shop_text" style={{marginLeft:"0%"}}>
//								<p id="video_stats">
//									💬 &nbsp; {link[3]}&nbsp;
//									❤️ &nbsp; {link[1]}&nbsp;
//									📤 &nbsp; {link[2]}
//								</p>
//							</p>
//						</div>
//					</div>)
//    		});

		return (
			<div class="main_container">
			<div>
				
<NewSidebar selected={9}></NewSidebar>
				
	<br /><br /><br />
<img id="profile_image" onClick={event => this.navigatePage(event, "settings")} src={this.state.thumbnail_url} />
	<div class="main">
		
		
			<h3>Post Ranks</h3><br />
		

		<div style={{display: "inline-block", width: "100%"}}>
			
		<br /><br />
		<VideosTable username={this.state.current_user} total_videos={this.state.big_tuple.length} rows={this.state.rows} class="table_shadow" ></VideosTable><br />
			<p>*Sort posts by clicking any column title</p>
		</div>
	
		
	<br />

		
		
					</div>
					
				
			</div>
			</div>

			)
		} else {
			return ( <div></div>
			)
		}

	}
}

export default withRouter(Postranks)
