import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import addnew from './add_vid.jpg';
import Sidebar from "react-sidebar";
import NewSidebar from "./NewSidebar";

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import cheerio from 'cheerio';
import NewAlert from './NewAlert';

import shoptik_icon from './Help/shoptik_icon.png';
import pic1 from './Help/1.jpg';
import pic2 from './Help/2.jpg';
import pic3 from './Help/3.jpg';
import pic4 from './Help/4.png';
import pic5 from './Help/5.jpg';
import pic6 from './Help/6.jpg';
import pic7 from './Help/7.png';

import manual1 from './Help/manual1.png';
import manual2 from './Help/manual2.jpg';
import manual3 from './Help/manual3.png';

import ReactPlayer from "react-player";

class Info extends Component {
	constructor(props){
		super(props);
		this.state = {
			thumbnail_url: ""
		}
		
		this.loginStatus = this.loginStatus.bind(this);
		this.navigatePage = this.navigatePage.bind(this);
	}
	
	navigatePage(event, type){
		if (type == "feed"){
			var base_url = window.location.origin;
			window.open((base_url + '/' + this.state.current_user), '_blank');
		} else {
			var base_url = window.location.origin;
		window.location.href = base_url + '/dashboard/' + type;
		}
	}

	componentDidMount(){
		
		const script = document.createElement("script");

		script.src = "https://www.tiktok.com/embed.js";
		script.async = true;

		document.body.appendChild(script);

		this.loginStatus();

	}

	loginStatus(){
		var curr = this;
		//fetch to see if logged in
		fetch('https://shoptik-app.herokuapp.com/dashboard/whoami', {
			method: "GET",
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						
						var current_user = data.data.current_user;
						
						//console.log('current_user:', current_user);
//						this.getClientData(current_user);
						
						this.setState({
							isLogged: true,
							current_user: data.data.current_user,
							thumbnail_url: data.data.thumbnail_url
						})
					} else {
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
						this.setState({
							isLogged: false,
							current_user: ""
						})
				   }
				})
			})
	}

	render() {
		if (this.state.isLogged){
			return(
				<div>
					<NewSidebar selected={11}></NewSidebar>
			
				
				<br /><br /><br />
					<img id="profile_image" onClick={event => this.navigatePage(event, "settings")} src={this.state.thumbnail_url} />
					<div class="main">
						<h3>Tutorial</h3>
						<br />
						
						<a href="#update_bio"> &bull; How can I Update my Bio </a>(51 sec)<br />
						<a href="#add_from_recent"> &bull; Add Videos from Recent TikToks </a>(13 sec)<br />
						<a href="#add_from_tiktok"> &bull; Add Videos from TikTok </a>(15 sec)<br />
						<a href="#add_by_username"> &bull; Add Videos by Username </a>(18 sec)<br />
						<a href="#add_by_hashtag"> &bull; Add Videos by Hashtag </a>(19 sec)<br />
						<a href="#tag_new_product"> &bull; Tag New Products </a>(55 sec)<br />
						<a href="#tag_from_library"> &bull; Tag Products from Library </a>(17 sec)<br />
						<a href="#edit_item"> &bull; Edit Products and Delete Videos </a>(33 sec) <br />
						<a href="#analytics"> &bull; Analytics and Sales </a>(17 sec)<br />
						<a href="#view_shoppable_feed"> &bull; View Shoppable Feed </a>(24 sec)
						
						
						
						
						
						
						
						
						
						<br />
						<div id="update_bio"><br /><br /><br />
							<h5> Update Bio </h5>
								<ReactPlayer 
									url="https://www.youtube.com/watch?v=RYGR450NOH4"
								/>
							</div><br />
							
						<div id="add_from_recent"><br /><br /><br />
							<h5> Add from Recent </h5>
								<ReactPlayer 
									url="https://www.youtube.com/watch?v=nkqNagTKe6I"
								/></div><br />
							
							<div  id="add_from_tiktok"><br /><br /><br />
							<h5> Add from TikTok </h5>
								<ReactPlayer 
									url="https://www.youtube.com/watch?v=X53_cKHeAaE"
								/></div><br />
							
						<div id="add_by_username"><br /><br /><br />
							<h5> Add by Username </h5>
								<ReactPlayer 
									url="https://www.youtube.com/watch?v=fUYbGu7-6To"
								/></div><br />
							
							<div id="add_by_hashtag"><br /><br /><br />
							<h5> Add by Hashtag </h5>
								<ReactPlayer 
									url="https://www.youtube.com/watch?v=-yQzVgcKTdY"
								/></div><br />
							
						<div id="tag_new_product"><br /><br /><br />
							<h5> Tag New Product </h5>
								<ReactPlayer 
									url="https://www.youtube.com/watch?v=XdCekZE8lJ8"
								/></div><br />
							
							<div id="tag_from_library"><br /><br /><br />
							<h5> Tag from Library </h5>
								<ReactPlayer 
									url="https://www.youtube.com/watch?v=bchWo3YaHxo"
								/></div><br />
							
							<div id="edit_item"><br /><br /><br />
							<h5> Edit Item and Delete Post </h5>
								<ReactPlayer 
									url="https://www.youtube.com/watch?v=9gOGMm4afhg"
								/></div><br />
						
							<div id="analytics"><br /><br /><br />
							<h5> Analytics </h5>
								<ReactPlayer 
									url="https://www.youtube.com/watch?v=Y6S9S9WWs7w"
								/></div><br />
							
							<div id="view_shoppable_feed"><br /><br /><br />
							<h5> View Shoppable Feed </h5>
								<ReactPlayer 
									url="https://www.youtube.com/watch?v=bhF4loNDzwY"
								/></div><br />
						
						<br /><br />

						
						
						
					
					</div>
					
					
					
					
					
				</div>)
			
				
			
		} else {
			return(<p>LOGGED OUT</p>)
		}
	}
}

export default Info

//	<div style={{position: "relative"}}>
//							
//						<blockquote class="tiktok-embed" cite="https://www.tiktok.com/@celinedion/video/6863497151306042630" data-video-id="6863497151306042630" style={{maxWidth: "605px", minWidth: "225px", zIndex: "1"}} > <section style={{zIndex: "1"}}> </section> </blockquote> 
//						
//						<div style={{backgroundColor: "red", zIndex: "999", marginTop: "-200px", height: "200px"}}><br /></div>
//						
//						</div>
//						
