import React, { useState, useEffect, Component } from 'react';
import Feed from './Feed';
import Embed from './Embed';
import EmbedGallery from './EmbedGallery';
import Dashboard from './Dashboard';
import Login from './Login';
import Register from './Register';
import Analytics from './Analytics';
import Settings from './Settings';
import Info from './Info';
import EmbedPage from './EmbedPage';
import Profile from './Profile';
import Overview from './Overview';
import Videos from './Videos';
import Postranks from './Postranks';
import Benchmarks from './Benchmarks';
import Competitors from './Competitors';
import Hashtags from './Hashtags';
import Influencers from './Influencers';
import MediaLibrary from './MediaLibrary';
import PostScheduler from './PostScheduler';

import Home from './Home';

import addnew from './add_vid.jpg';

import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Link, Switch, Redirect, withRouter } from "react-router-dom";

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

class App extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			isLogged: false
		}
		this.logoutUser = this.logoutUser.bind(this);

	}
	
	componentDidMount() {
		//fetch real name and login status
		this.setState({
			isLogged: false,
		})
		//maybe go fetch actual login status here, if logged throw in the profile picture in the nav
    }

	logoutUser(){
		var info = {
			'action': 'logout'
		}
		
		fetch('https://shoptik-app.herokuapp.com/dashboard/logout', {
			method: "POST",
			body: JSON.stringify(info),
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
					} else {
						
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
//						alert("cant logout");
				   }
				})
			})
	}
	

	render() {
		return (
			<BrowserRouter>
				<Switch>
			
			
			
			<Route path="/home">
			<Home> </Home>
				
			</Route>
			
			
			
			<Route path="/dashboard/media">
					<div class="nav">
					<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light nav-dark">
						<div class="container">
							<a class="navbar-brand logo" href="#">
							</a>
							<div>
								<ul class="navbar-nav">
									<li class="nav-item" id="navlogout">
										<a onClick={() => this.logoutUser()} class="nav-link" >Logout</a>
									</li>
									<li class="nav-item badone">
										<a onClick={() => this.logoutUser()} class="nav-link">&nbsp;</a>
									</li>
								</ul>
							</div>
						</div>
					</nav>
					</div>	
				<MediaLibrary> </MediaLibrary>
			</Route>
										
				<Route path="/dashboard/schedule">
					<div class="nav">
					<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light nav-dark">
						<div class="container">
							<a class="navbar-brand logo" href="#">
							</a>
							<div>
								<ul class="navbar-nav">
									<li class="nav-item" id="navlogout">
										<a onClick={() => this.logoutUser()} class="nav-link" >Logout</a>
									</li>
									<li class="nav-item badone">
										<a onClick={() => this.logoutUser()} class="nav-link">&nbsp;</a>
									</li>
								</ul>
							</div>
						</div>
					</nav>
					</div>	
				<PostScheduler></PostScheduler>
			</Route>
			
			
			<Route path="/dashboard/influencers">
					<div class="nav">
					<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light nav-dark">
						<div class="container">
							<a class="navbar-brand logo" href="#">
							</a>
							<div>
								<ul class="navbar-nav">
									<li class="nav-item" id="navlogout">
										<a onClick={() => this.logoutUser()} class="nav-link" >Logout</a>
									</li>
									<li class="nav-item badone">
										<a onClick={() => this.logoutUser()} class="nav-link">&nbsp;</a>
									</li>
								</ul>
							</div>
						</div>
					</nav>
					</div>	
				<Influencers> </Influencers>
			</Route>

			<Route path="/dashboard/overview">
					<div class="nav">
					<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light nav-dark">
						<div class="container">
							<a class="navbar-brand logo" href="#">
							</a>
							<div>
								<ul class="navbar-nav">
									<li class="nav-item" id="navlogout">
										<a onClick={() => this.logoutUser()} class="nav-link" >Logout</a>
									</li>
									<li class="nav-item badone">
										<a onClick={() => this.logoutUser()} class="nav-link">&nbsp;</a>
									</li>
								</ul>
							</div>
						</div>
					</nav>
					</div>	
				<Overview> </Overview>
			</Route>
		
			<Route path="/dashboard/videos">
							
			<div class="nav">
					<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light nav-dark">
						<div class="container">
							<a class="navbar-brand logo" href="#">

							</a>
							<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
								<i class="mdi mdi-menu"></i>
							</button>
							<div class="collapse navbar-collapse" id="navbarCollapse">
								<ul class="navbar-nav ml-auto" id="mySidenav">
									<li class="nav-item" id="navlogout">
										<a onClick={() => this.logoutUser()} class="nav-link">Logout</a>
									</li>
										<li class="nav-item badone">
										<a onClick={() => this.logoutUser()} class="nav-link">&nbsp;</a>
									</li>
								</ul>
							</div>
						</div>
					</nav>
					</div>	
				<Videos> </Videos>
			</Route>
										
			<Route path="/dashboard/rankings">
							
			<div class="nav">
					<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light nav-dark">
						<div class="container">
							<a class="navbar-brand logo" href="#">

							</a>
							<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
								<i class="mdi mdi-menu"></i>
							</button>
							<div class="collapse navbar-collapse" id="navbarCollapse">
								<ul class="navbar-nav ml-auto" id="mySidenav">
									<li class="nav-item" id="navlogout">
										<a onClick={() => this.logoutUser()} class="nav-link">Logout</a>
									</li>
										<li class="nav-item badone">
										<a onClick={() => this.logoutUser()} class="nav-link">&nbsp;</a>
									</li>
								</ul>
							</div>
						</div>
					</nav>
					</div>	
				<Postranks> </Postranks>
			</Route>
										
			<Route path="/dashboard/benchmarks">
							
			<div class="nav">
					<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light nav-dark">
						<div class="container">
							<a class="navbar-brand logo" href="#">

							</a>
							<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
								<i class="mdi mdi-menu"></i>
							</button>
							<div class="collapse navbar-collapse" id="navbarCollapse">
								<ul class="navbar-nav ml-auto" id="mySidenav">
									<li class="nav-item" id="navlogout">
										<a onClick={() => this.logoutUser()} class="nav-link">Logout</a>
									</li>
										<li class="nav-item badone">
										<a onClick={() => this.logoutUser()} class="nav-link">&nbsp;</a>
									</li>
								</ul>
							</div>
						</div>
					</nav>
					</div>	
				<Benchmarks> </Benchmarks>
			</Route>
										
			<Route path="/dashboard/competitors">
					<div class="nav">
					<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light nav-dark">
						<div class="container">
							<a class="navbar-brand logo" href="#">
							</a>
							<div>
								<ul class="navbar-nav">
									<li class="nav-item" id="navlogout">
										<a onClick={() => this.logoutUser()} class="nav-link" >Logout</a>
									</li>
									<li class="nav-item badone">
										<a onClick={() => this.logoutUser()} class="nav-link">&nbsp;</a>
									</li>
								</ul>
							</div>
						</div>
					</nav>
					</div>	
				<Competitors> </Competitors>
			</Route>
										
				<Route path="/dashboard/hashtags">
					<div class="nav">
					<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light nav-dark">
						<div class="container">
							<a class="navbar-brand logo" href="#">
							</a>
							<div>
								<ul class="navbar-nav">
									<li class="nav-item" id="navlogout">
										<a onClick={() => this.logoutUser()} class="nav-link" >Logout</a>
									</li>
									<li class="nav-item badone">
										<a onClick={() => this.logoutUser()} class="nav-link">&nbsp;</a>
									</li>
								</ul>
							</div>
						</div>
					</nav>
					</div>	
				<Hashtags> </Hashtags>
			</Route>
			
			

			<Route path="/dashboard/register">
			<div class="nav">
					<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light nav-dark">
						<div class="container">
							<a class="navbar-brand logo" href="#">

							</a>
							<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
								<i class="mdi mdi-menu"></i>
							</button>
							<div class="collapse navbar-collapse" id="navbarCollapse">
								<ul class="navbar-nav ml-auto" id="mySidenav">
									<li class="nav-item" id="navlogout">
										<a href="https://www.shoptik.app/" class="nav-link">Home</a>
									</li>
										<li class="nav-item badone">
										<a onClick={() => this.logoutUser()} class="nav-link">&nbsp;</a>
									</li>
								</ul>
							</div>
						</div>
					</nav>
					</div>	
					<Register>
			
				<Snackbar open={this.state.openAlert} autoHideDuration={3000} onClose={()=>{this.setState({openAlert: false, alertMessage: ""})}}>
						
					<MuiAlert elevation={6} variant="filled">{this.state.alertMessage}</MuiAlert>
				 </Snackbar>
				 
			</Register>
				</Route>
			
			<Route path="/dashboard/login">
			<div class="nav">
					<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light nav-dark">
						<div class="container">
							<a class="navbar-brand logo" href="#">

							</a>
							<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
								<i class="mdi mdi-menu"></i>
							</button>
							<div class="collapse navbar-collapse" id="navbarCollapse">
								<ul class="navbar-nav ml-auto" id="mySidenav">
									<li class="nav-item" id="navlogout">
										<a href="https://www.shoptik.app/" class="nav-link">Home</a>
									</li>
										<li class="nav-item badone">
										<a onClick={() => this.logoutUser()} class="nav-link">&nbsp;</a>
									</li>
								</ul>
							</div>
						</div>
					</nav>
					</div>	
					<Login>
			
				<Snackbar open={this.state.openAlert} autoHideDuration={3000} onClose={()=>{this.setState({openAlert: false, alertMessage: ""})}}>
						
					<MuiAlert elevation={6} variant="filled">{this.state.alertMessage}</MuiAlert>
				 </Snackbar>
				 
			</Login>
				</Route>
			
			<Route path="/dashboard/analytics">
							
			<div class="nav">
					<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light nav-dark">
						<div class="container">
							<a class="navbar-brand logo" href="#">

							</a>
							<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
								<i class="mdi mdi-menu"></i>
							</button>
							<div class="collapse navbar-collapse" id="navbarCollapse">
								<ul class="navbar-nav ml-auto" id="mySidenav">
									<li class="nav-item" id="navlogout">
										<a onClick={() => this.logoutUser()} class="nav-link">Logout</a>
									</li>
										<li class="nav-item badone">
										<a onClick={() => this.logoutUser()} class="nav-link">&nbsp;</a>
									</li>
								</ul>
							</div>
						</div>
					</nav>
					</div>	
				<Analytics> </Analytics>
			</Route>
			
			
		<Route path="/dashboard/manage_media">	
			<div class="nav">
					<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light nav-dark">
						<div class="container">
							<a class="navbar-brand logo" href="#">

							</a>
							<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
								<i class="mdi mdi-menu"></i>
							</button>
							<div class="collapse navbar-collapse" id="navbarCollapse">
								<ul class="navbar-nav ml-auto" id="mySidenav">
									
								
									<li class="nav-item" id="navlogout">
										<a onClick={() => this.logoutUser()} class="nav-link">Logout</a>
									</li>
										<li class="nav-item badone">
										<a onClick={() => this.logoutUser()} class="nav-link">&nbsp;</a>
									</li>
								</ul>
							</div>
						</div>
					</nav>
					</div>	
			
			<Dashboard isLogged={this.state.isLogged} />	
			
		</Route>
			
			<Route path="/dashboard/profile">	
			<div class="nav">
					<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light nav-dark">
						<div class="container">
							<a class="navbar-brand logo" href="#">

							</a>
							<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
								<i class="mdi mdi-menu"></i>
							</button>
							<div class="collapse navbar-collapse" id="navbarCollapse">
								<ul class="navbar-nav ml-auto" id="mySidenav">
									
								
									<li class="nav-item" id="navlogout">
										<a onClick={() => this.logoutUser()} class="nav-link">Logout</a>
									</li>
										<li class="nav-item badone">
										<a onClick={() => this.logoutUser()} class="nav-link">&nbsp;</a>
									</li>
								</ul>
							</div>
						</div>
					</nav>
					</div>	
			
			<Profile isLogged={this.state.isLogged} />	
			
		</Route>
			
			
		<Route path="/dashboard/settings">	
			<div class="nav">
					<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light nav-dark">
						<div class="container">
							<a class="navbar-brand logo" href="#">

							</a>
							<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
								<i class="mdi mdi-menu"></i>
							</button>
							<div class="collapse navbar-collapse" id="navbarCollapse">
								<ul class="navbar-nav ml-auto" id="mySidenav">
									
								
									<li class="nav-item" id="navlogout">
										<a onClick={() => this.logoutUser()} class="nav-link">Logout</a>
									</li>
										<li class="nav-item badone">
										<a onClick={() => this.logoutUser()} class="nav-link">&nbsp;</a>
									</li>
								</ul>
							</div>
						</div>
					</nav>
					</div>	
			
			<Settings isLogged={this.state.isLogged} />	
			
			</Route>
			
		<Route path="/dashboard/info">	
			<div class="nav">
					<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light nav-dark">
						<div class="container">
							<a class="navbar-brand logo" href="#">

							</a>
							<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
								<i class="mdi mdi-menu"></i>
							</button>
							<div class="collapse navbar-collapse" id="navbarCollapse">
								<ul class="navbar-nav ml-auto" id="mySidenav">
									
								
									<li class="nav-item" id="navlogout">
										<a onClick={() => this.logoutUser()} class="nav-link">Logout</a>
									</li>
										<li class="nav-item badone">
										<a onClick={() => this.logoutUser()} class="nav-link">&nbsp;</a>
									</li>
								</ul>
							</div>
						</div>
					</nav>
					</div>	
			
			<Info isLogged={this.state.isLogged} />	
				
			</Route>
			<Route path="/dashboard/embedfeed">	
			<div class="nav">
					<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light nav-dark">
						<div class="container">
							<a class="navbar-brand logo" href="#">

							</a>
							<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
								<i class="mdi mdi-menu"></i>
							</button>
							<div class="collapse navbar-collapse" id="navbarCollapse">
								<ul class="navbar-nav ml-auto" id="mySidenav">
									
								
									<li class="nav-item" id="navlogout">
										<a onClick={() => this.logoutUser()} class="nav-link">Logout</a>
									</li>
										<li class="nav-item badone">
										<a onClick={() => this.logoutUser()} class="nav-link">&nbsp;</a>
									</li>
								</ul>
							</div>
						</div>
					</nav>
					</div>	
			
			<EmbedPage isLogged={this.state.isLogged} />	
			</Route>
	
					<Route path="/:brand/embed"><Embed isLogged={this.state.isLogged} /></Route>
						
					
					<Route path="/:brand/embedgallery"><EmbedGallery isLogged={this.state.isLogged} /></Route>	
						
					<Route path="/:brand"><Feed isLogged={this.state.isLogged} /></Route>
					<Route exact path="/"><Redirect to="/home" /></Route>
					
					
				</Switch>
					
			</BrowserRouter>
		)
	}
}

export default App


			
					