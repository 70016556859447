//options for bar graphs
export const LineOptions = {
	height: '360px',
    animation: {
        startup: true,
        easing: 'out',
        duration: 2000,
    },
    backgroundColor: { fill:'transparent' },
    colors: ['#3B98F9', '#E9F4FF'],
    hAxis: {
        title: "Time",
        textStyle:{color: '#8091AB', fontSize: 12},
        gridlines: {
                color: 'transparent'
    },
    border: {
        color: "transparent"
    },
    titleTextStyle: {
        color: '#8091AB'
    }
//        slantedText:true,
//        slantedTextAngle:90
    },
    vAxis: {
        title: "Followers",
        textStyle:
        {color: '#8091AB'},
        gridlines: {
            color: 'transparent'
        },
        titleTextStyle: {
            color: '#8091AB'
    }
    },
    legend: { position: "none" },
}

//options for bar graphs
export const follower_graph = {
	height: '360px',
    animation: {
        startup: true,
        easing: 'out',
        duration: 2000,
    },
    backgroundColor: { fill:'transparent' },
    colors: ['#3B98F9', '#E9F4FF'],
    hAxis: {
        title: "Time",
        textStyle:{color: '#8091AB', fontSize: 12},
        gridlines: {
                color: 'transparent'
    },
    border: {
        color: "transparent"
    },
    titleTextStyle: {
        color: '#8091AB'
    }
//        slantedText:true,
//        slantedTextAngle:90
    },
    vAxis: {
        title: "Followers",
        textStyle:
        {color: '#8091AB'},
        gridlines: {
            color: 'transparent'
        },
        titleTextStyle: {
            color: '#8091AB'
    }
    },
    legend: { position: "none" },
}

//options for bar graphs
export const engagement_graph = {
	height: '360px',
    animation: {
        startup: true,
        easing: 'out',
        duration: 2000,
    },
    backgroundColor: { fill:'transparent' },
    colors: ['#3B98F9', '#E9F4FF'],
    hAxis: {
        title: "Posts",
        textStyle:{color: '#8091AB', fontSize: 12},
        gridlines: {
                color: 'transparent'
    },
    border: {
        color: "transparent"
    },
    titleTextStyle: {
        color: '#8091AB'
    }
//        slantedText:true,
//        slantedTextAngle:90
    },
    vAxis: {
        title: "Engagement Actions",
        textStyle:
        {color: '#8091AB'},
        gridlines: {
            color: 'transparent'
        },
        titleTextStyle: {
            color: '#8091AB'
    }
    },
    legend: { position: "none" },
}


//options for bar graphs
export const likes_graph = {
	height: '360px',
    animation: {
        startup: true,
        easing: 'out',
        duration: 2000,
    },
    backgroundColor: { fill:'transparent' },
    colors: ['#3B98F9', '#E9F4FF', '#78C657'],
    hAxis: {
        title: "Posts",
        textStyle:{color: '#8091AB', fontSize: 12},
        gridlines: {
                color: 'transparent'
    },
    border: {
        color: "transparent"
    },
    titleTextStyle: {
        color: '#8091AB'
    }
//        slantedText:true,
//        slantedTextAngle:90
    },
    vAxis: {
        title: "Likes",
        textStyle:
        {color: '#8091AB'},
        gridlines: {
            color: 'transparent'
        },
        titleTextStyle: {
            color: '#8091AB'
    }
    },
    legend: { position: "none" },
}


//options for bar graphs
export const comments_graph = {
	height: '360px',
    animation: {
        startup: true,
        easing: 'out',
        duration: 2000,
    },
    backgroundColor: { fill:'transparent' },
    colors: ['#3B98F9', '#E9F4FF'],
    hAxis: {
        title: "Posts",
        textStyle:{color: '#8091AB', fontSize: 12},
        gridlines: {
                color: 'transparent'
    },
    border: {
        color: "transparent"
    },
    titleTextStyle: {
        color: '#8091AB'
    }
//        slantedText:true,
//        slantedTextAngle:90
    },
    vAxis: {
        title: "Comments",
        textStyle:
        {color: '#8091AB'},
        gridlines: {
            color: 'transparent'
        },
        titleTextStyle: {
            color: '#8091AB'
    }
    },
    legend: { position: "none" },
}

