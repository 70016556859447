import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import addnew from './add_vid.jpg';
import Sidebar from "react-sidebar";
import NewSidebar from "./NewSidebar";

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import cheerio from 'cheerio';
import NewAlert from './NewAlert';

import shoptik_icon from './Help/shoptik_icon.png';
import pic1 from './Help/1.jpg';
import pic2 from './Help/2.jpg';
import pic3 from './Help/3.jpg';
import pic4 from './Help/4.png';
import pic5 from './Help/5.jpg';
import pic6 from './Help/6.jpg';
import pic7 from './Help/7.png';

import manual1 from './Help/manual1.png';
import manual2 from './Help/manual2.jpg';
import manual3 from './Help/manual3.png';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/Info';

import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

class EmbedPage extends Component {
	constructor(props){
		super(props);
		this.state = {
			openAlert: false,
			thumbnail_url: ""
		}
		
		this.loginStatus = this.loginStatus.bind(this);
		this.navigatePage = this.navigatePage.bind(this);
		
		this.copyClipboard = this.copyClipboard.bind(this);
	}
	
	copyClipboard(event, index){
		
		if (index == 0){
			var copyText = document.getElementById("myInput");
			copyText.select();
			document.execCommand('copy');

			this.setState({
				openAlert: true
			})
		}
		else {
			var copyText = document.getElementById("myInput2");
			copyText.select();
			document.execCommand('copy');

			this.setState({
				openAlert: true
			})
		}
		//show tooltip
	}
	
	navigatePage(event, type){
		if (type == "feed"){
			var base_url = window.location.origin;
			window.open((base_url + '/' + this.state.current_user), '_blank');
		} else {
			var base_url = window.location.origin;
		window.location.href = base_url + '/dashboard/' + type;
		}
	}

	componentDidMount(){

		this.loginStatus();

	}

	loginStatus(){
		var curr = this;
		//fetch to see if logged in
		fetch('https://shoptik-app.herokuapp.com/dashboard/whoami', {
			method: "GET",
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						
						var current_user = data.data.current_user;
						
						console.log('current_user:', current_user);
//						this.getClientData(current_user);
						
						this.setState({
							isLogged: true,
							current_user: data.data.current_user,
							thumbnail_url: data.data.thumbnail_url
						})
					} else {
						var base_url = window.location.origin;
						window.location.href = base_url + '/dashboard/login';
						this.setState({
							isLogged: false,
							current_user: ""
						})
				   }
				})
			})
	}

	render() {
		const frame_source = 'https://shoptik.app/' + this.state.current_user + '/embed';
		
		const frame_source_v2 = 'https://shoptik.app/' + this.state.current_user + '/embedgallery';
//		const frame_source2 = 'http://localhost:3000/' + this.state.current_user + '/embed';
		const codeString = '<iframe src="https://shoptik.app/' + this.state.current_user + '/embed" scrolling="no" width="70%" height="600px" style="border:none; overflow: hidden;display:block;margin-left:auto;margin-right:auto;"></iframe>';
		
		const codeString2 = '<iframe src="https://shoptik.app/' + this.state.current_user + '/embedgallery" width="100%" height="800px" style="border:none;display:block;margin-left:auto;margin-right:auto;"></iframe>';
		
		if (this.state.isLogged){
			return(
				<div>
  <NewSidebar selected={4}></NewSidebar>
			
					
				
				<br /><br /><br />
					<img id="profile_image" onClick={event => this.navigatePage(event, "settings")} src={this.state.thumbnail_url} />
					<div class="main">	
						
						
						<input style={{opacity: "0"}} type="text" id="myInput" value={codeString} />
						

						
						<h3> Carousel Strip </h3><p>This is a thin, autoscrolling strip with a fade animation when you hover. When clicked, it goes to the first tagged product link</p>
						<iframe src={frame_source} scrolling="no" width="100%" height="500px" style={{paddingRight: "50px", border:"none", overflow: "hidden", display:"block", marginLeft:"auto", marginRight:"auto"}}></iframe>
					<br/>
						<div style={{width:"75%", display:"inline-block"}}>
						<SyntaxHighlighter id="myInput" language="html" style={docco} wrapLines={true}>
      						{codeString}
							
    					</SyntaxHighlighter>
						</div>
						
						<Button class="recent_vid_add" variant="recent_vid_add" class="" style={{display:"inline-block", marginLeft: "20px", marginTop:"-60px"}} onClick={event => this.copyClipboard(event, 0)}><i class="fa fa-clone" aria-hidden="true"></i></Button>

						
						<h3> On-Site Embeddable Content</h3>
						<div style={{width:"75%"}}>
						<br />
						You can embed these items onto your website to demonstrate social proof. To embed, copy the respective code snippet and paste it anywhere inside your html body tags, adjusting the width and height parameters to your preference.
						</div>

	
							<input style={{opacity: "0"}} type="text" id="myInput2" value={codeString2} />
						
						
						<h5 class="guide"> Gallery</h5><p>This is a full page gallery, with all your videos. When clicked, a stunning popover is presented displaying all linked products for easy shopping</p>
						
						<iframe src={frame_source_v2} width="100%" height="1000px" style={{paddingRight: "50px", border:"none", overflow: "hidden", display:"block", marginLeft:"auto", marginRight:"auto"}}></iframe>
						
						<br /><br />
						<div style={{width:"75%", display:"inline-block"}}>
						<SyntaxHighlighter id="myInput2" language="html" style={docco} wrapLines={true}>
      						{codeString2}
							
    					</SyntaxHighlighter>
						</div>
						
						<Button class="recent_vid_add" variant="recent_vid_add" class="" style={{display:"inline-block", marginLeft: "20px", marginTop:"-60px"}} onClick={event => this.copyClipboard(event, 1)}><i class="fa fa-clone" aria-hidden="true"></i></Button>
						

						
						
				 <Snackbar open={this.state.openAlert} autoHideDuration={3000} onClose={()=>{this.setState({openAlert: false, alertMessage: "Successfully Copied to Clipboard!"})}}>
						
					<MuiAlert elevation={6} variant="filled">Successfully Copied to Clipboard!</MuiAlert>
				 </Snackbar>
						
					</div>
					
				</div>)
			
				
			
		} else {
			return(<p>LOGGED OUT</p>)
		}
	}
}

export default EmbedPage

//
//						<input style={{opacity: "0"}} type="text" id="myInput" value={codeString} />
//						
//
//						
//						<h5 class="guide"> Carousel Strip </h5><p>This is a thin, autoscrolling strip with a fade animation when you hover. When clicked, it goes to the first tagged product link</p>
//						<iframe src={frame_source} scrolling="no" width="100%" height="500px" style={{paddingRight: "50px", border:"none", overflow: "hidden", display:"block", marginLeft:"auto", marginRight:"auto"}}></iframe>
//					<br/>
//						<div style={{width:"75%", display:"inline-block"}}>
//						<SyntaxHighlighter id="myInput" language="html" style={docco} wrapLines={true}>
//      						{codeString}
//							
//    					</SyntaxHighlighter>
//						</div>
//						
//						<Button class="recent_vid_add" variant="recent_vid_add" class="" style={{display:"inline-block", marginLeft: "20px", marginTop:"-60px"}} onClick={event => this.copyClipboard(event, 0)}><i class="fa fa-clone" aria-hidden="true"></i></Button>

//	
