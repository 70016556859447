import React, { Component } from 'react';

class imageview extends Component {
	constructor(props){
		super(props);
		//set state(none for now)
	}
	
	componentDidMount(){
		
	}
	
	render() {
		return(	
			<div>
				<div class="img" style={{float:"left", width:"33.33%", padding:"0px"}}><img src={this.props.thumb} style={{width:"100%"}}/></div>
				<div class="img" style={{float:"left", width:"33.33%", padding:"0px"}}><img src={this.props.thumb}  style={{width:"100%"}}/></div>
				<div class="img" style={{float:"left", width:"33.33%", padding:"0px"}}><img src={"https://jooinn.com/images/transparent-3.png"} style={{width:"100%"}}/></div>


			</div>
		)
		
	}
}  

export default imageview