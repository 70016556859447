import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';
import addnew from './add_vid.jpg';
import addmanually from './addmanually.jpg';
import Sidebar from "react-sidebar";
import NewSidebar from "./NewSidebar";
import shoptik_icon from './Help/shoptik_icon.png';

import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';

import cheerio from 'cheerio';
import NewAlert from './NewAlert';

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/Info';


import FeedItems from './FeedItems';

import NewFeedItems from './FeedItems2';
//import FeedItems from './FeedItems';
import FeedProducts from './FeedProducts';
import VideoPreview from './VideoPreview';

import { loadStripe } from '@stripe/stripe-js'

const stripePromise = loadStripe('pk_live_51H36KeIRpv6K8jIKujJotzP0qT6Bz8sipC2yaMYWLP7iYhur05TqxvXqUNtfN9wMqTZP3ISEPGBCyAHlXg89yMWf00xYpnid9Z');

class Dashboard extends Component {
	constructor(props){
		super(props);
		this.state = {
			sidebarOpen: true,
			showNewVideo: false,
			
			showNewMention: false,
			showNewHashtag: false,
			
			showNewProduct: false,
			showAddProduct: false,
			showEditProduct: false,
			showPayModal: false,
			allVideos: [],
			
			allVideosMentions: [],
			allVideosHashtags: [],
			userMention: "",
			userHashtags: "",
			
			weirdvideos: [],
			videos: [],
			links: [],
			recent_links: [],
			current_video_url: "",
			isLogged: false,
			openAlert: false,
			openAlert2: false,
			alertMessage: "",
			alertMessage2: "",
			edit_product_title: "",
			edit_product_price: "",
			edit_product_link: "",
			edit_product_image: "",	
			current_item_id: "",
			bio_link: "",
			
			showManual: false,
			manual_link: "",
			
			is_active: true,
			creation_date: "",
			
			product_store: [],
			isloading: "",
			thumbnail_url: "",
			
			showUpgradeModal: false,
			showPlanModal: false,
			shownPay: false,
			showEndTrialModal:false,
			showMobilePopup: false,
			
			topmsg: "Update your billing information",
			shouldHideFeed: "block",
			shouldHideStore: "none",
			
			 showEmail: true,
				 showEmailAllowed: false,
			
			newbackupThumbs: [],
			newbackupThumbsIds: []
		}

//		this.addVideo = this.addVideo.bind(this);
		this.submitEditedProduct = this.submitEditedProduct.bind(this);
		this.editProduct = this.editProduct.bind(this);
		this.addProduct = this.addProduct.bind(this);
		this.manageProduct = this.manageProduct.bind(this);
		this.storeVideo = this.storeVideo.bind(this);
		this.storeVideoMentions = this.storeVideoMentions.bind(this);
		this.storeVideoHashtags = this.storeVideoHashtags.bind(this);
		this.saveProduct = this.saveProduct.bind(this);
		this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
		this.getClientData = this.getClientData.bind(this);
		this.showNewVideoModal = this.showNewVideoModal.bind(this);
		this.loginStatus = this.loginStatus.bind(this);
		this.navigatePage = this.navigatePage.bind(this);
		this.deleteVid = this.deleteVid.bind(this);
		this.testError = this.testError.bind(this);
		this.addManually = this.addManually.bind(this);	
		this.searchUser = this.searchUser.bind(this);
		
		this.moveLeft = this.moveLeft.bind(this);
		this.moveRight = this.moveRight.bind(this);
		
		this.attemptUpdate = this.attemptUpdate.bind(this);
		this.attemptNewUpgrade = this.attemptNewUpgrade.bind(this);
		
		this.productFromLibraryClicked = this.productFromLibraryClicked.bind(this);
		this.newImgError = this.newImgError.bind(this)
	}
	
	async newImgError(index, errorType){
		return
		//alert("happening");
		if (errorType == "recents"){
			var newrecents = this.state.recent_links;
			newrecents[index] = this.state.backupThumbs[index];
			
			//JUST CHANGED THIS
			
//				"https://ttonlineviewer.com/app/img/default-poster.jpg?broke";
			this.setState({
				recent_links: newrecents
			})
		}
		
		if (errorType == "shoppablefeedvids"){
			
			var newbackupThumbs = this.state.newbackupThumbs;
			var newbackupThumbsIds = this.state.newbackupThumbsIds;
			console.log("error:", index, newbackupThumbs.length)
			
			var newvideos = this.state.videos;
			
			var vid_id = newvideos[index]['tiktok_video_id']
//			for (var i=0;i<newbackupThumbsIds.length;i++){
//				console.log(vid_id, newbackupThumbsIds[i])
//				if (vid_id == newbackupThumbsIds[i]){
//					console.log("newimglink: ", newbackupThumbs[i])
//					newvideos[index]['thumbnail_link'] = newbackupThumbs[i]
//					newvideos[index]['new'] = true
//					this.setState({
//						videos: newvideos,
//						test: index
//					})
//					
//					this.forceUpdate()
//				}
//			}
			
			newvideos[index]['new'] = true
					this.setState({
						videos: newvideos,
						test: index
					})
					
					this.forceUpdate()
			
			

//		console.log("Index:", newbackupThumbsIds.findIndex(newvideos[index]['tiktok_video_id']))
			
			
			
//			alert("here")
//			console.log("ERROR ON INDEX: ", index)
//			var newvideos = this.state.videos;
//			newvideos[index]['thumbnail_link'] = "https://ttonlineviewer.com/app/img/default-poster.jpg?broke?resolved=true";
//		
//			this.setState({
//				videos: newvideos,
//				test: index
//			})
//			this.forceUpdate()
		}
		
		if (errorType == "reg"){
			var video_id = this.state.allIds[index];
			var client = this.state.current_user;

			var newVideos = this.state.allVideos;
			newVideos[index] = this.state.backupThumbs[index];
			this.setState({
				allVideos: newVideos
			})
		} else if (errorType == "hashtags"){
			
			var video_id = this.state.allIdsHashtags[index];
			var client = this.state.current_user;

			var newVideos = this.state.allVideosHashtags;
			newVideos[index] = this.state.backupThumbsHashtags[index];
			this.setState({
				allVideosHashtags: newVideos
			})
			
		} else if (errorType == "mentions"){
			
			var video_id = this.state.allIdsMentions[index];
			var client = this.state.current_user;

			var newVideos = this.state.allVideosMentions;
			newVideos[index] = this.state.backupThumbsMentions[index];
			this.setState({
				allVideosMentions: newVideos
			})
			
		}
		

	}
	

	
	productFromLibraryClicked(event, name, price, product_link, thumbnail_link){
		event.preventDefault()
		//console.log(name, price, product_link, thumbnail_link);
		
		document.getElementById("product_title").value = name;
		document.getElementById("product_price").value = price;
		document.getElementById("product_link").value = product_link;
		document.getElementById("product_image").value = thumbnail_link;
		
		this.setState({product_image: thumbnail_link, product_title: name, product_price: price, product_link: product_link})
		
	}
	
	attemptNewUpgrade(event, plan){
		event.preventDefault();
		this.setState({
			showPayModal: false
		})

		var tiktok_handle = this.state.profile_name;
		
		//send post to api that will send us an email
		var info = {
			'tiktok_handle': tiktok_handle,
			'plan': plan
		}
		
		//fetch new route to handle payment
		fetch('https://shoptik-app.herokuapp.com/attemptnewupgrade',{
			method: "POST",
			body: JSON.stringify(info),
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		}).then(response => {
			response.json().then(async data =>{
				if (data.status === 400) {
					this.setState({
					})
				} else if (data.status === 200){
					//refresh window
					this.getClientData(this.state.current_user);
					this.setState({
						showUpgradeModal: false,
						openAlert: true,
						alertMessage: "Successfully Upgraded Plan!"
					})
					//show footer thing
				}
			})
		})
	}
	
	//actually charges
	attemptUpdate(event, plan){
		event.preventDefault();
		
		this.setState({
			showPlanModal: false
		})

//		var plan = this.state.plan;
		var tiktok_handle = this.state.profile_name;
		
//		alert(tiktok_handle);
		var final_plan = "price_1H4zyWIRpv6K8jIKVUgq9HBf";
		if (plan == "standard_monthly"){
			final_plan = "price_1H4zyWIRpv6K8jIKDY0bePvj";
		} else if (plan == "standard_annual"){
			final_plan = "price_1H4zyWIRpv6K8jIKkpB3ImAy";
		} else if (plan == "premium_monthly"){
			final_plan = "price_1H4zyWIRpv6K8jIKVUgq9HBf";
		} else if (plan == "premium_annual"){
			final_plan = "price_1H4zyXIRpv6K8jIKSforpntN";
		} else if (plan == "analytics_monthly"){
			final_plan = "price_1HKpYrIRpv6K8jIKjE0138Dg";
		} else if (plan == "analytics_annual"){
			final_plan = "price_1HKpYsIRpv6K8jIKDMdOgHua";
		} 
		
		var info = {
			'tiktok_handle': tiktok_handle,
			'plan': final_plan
		}
		
		//fetch new route to handle payment
		fetch('https://shoptik-app.herokuapp.com/endtrial',{
			method: "POST",
			body: JSON.stringify(info),
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		}).then(response => {
			response.json().then(async data =>{
				if (data.status === 400) {
					this.setState({
					})
				} else if (data.status === 200){

						const sessionId = data.message.id;
						const stripe = await stripePromise;
						const { error } = await stripe.redirectToCheckout({
							sessionId
						})
				}
			})
		})	
	}
	
	moveLeft(event, index){
//		alert("move left", index);
		if (index == 0){
			return;
		}
		
		var new_videos = this.state.videos;
		var b = new_videos[index];
		new_videos[index] = new_videos[index - 1];
		new_videos[index - 1] = b;
		
		this.setState({
			videos: new_videos
		})
		
		//save new order in db
		
//		console.log(index);
	}
	moveRight(event, index){
		if (index == (this.state.videos.length - 1)){
			return;
		}
		
		var new_videos = this.state.videos;
		var b = new_videos[index];
		new_videos[index] = new_videos[index + 1];
		new_videos[index + 1] = b;
		
		this.setState({
			videos: new_videos
		})
		
//		alert("move right", index)
//		console.log(index);
	}
	
// ADD VIDEO TO FEED BY LINK
	async addManually(event){
		event.preventDefault();
		
		this.setState({
			showManual: false
		})
		
		var vid_len = this.state.videos.length;
		if (vid_len >= 5){
			
			//if trial restrict vids amount
			if (this.state.is_active == false){
				this.setState({
					openAlert2: true,
					alertMessage2: "Oops. To add unlimited videos on free trial, please verify your account first"
				});
				return;
			}
		}
		
		var manual_link = document.getElementById("manual_link").value;
		
		//check that link contains tiktok.com
		if (!manual_link.includes("tiktok.com")){
			//show an error
			this.setState({
				openAlert2: true,
				alertMessage2: "Oops, your link must be from tiktok.com"
			});
			return;
		}

		var vid_id = manual_link.match(/\d/g).join("");

		var content_url = "https://www.tiktok.com";
		var thumbnail_url = "https://www.tiktok.com/api/img/?itemId=" + vid_id + "&location=1";
		var new_vid_id = vid_id;
		
		//need to also scrape video thumbnail, maybe scrape everything from urlebird
		var client = this.state.current_user;
		var info = {
			'client': this.state.current_user,
			'video_url': content_url,
			'thumbnail_url': thumbnail_url,
			'video_id': new_vid_id
		}

			fetch('https://shoptik-app.herokuapp.com/dashboard/'+ client + '/addvideo', {
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						this.setState({
							openAlert: true,
							alertMessage: "Video Successfully Added to Feed"
						})
						
						this.setState({
							openAlert: true,
							alertMessage: "Video Successfully Added to Feed"
						})
						this.getClientData(this.state.current_user);

					} else {
				   }
				})
			})
	}
	
	navigatePage(event, type){
		if (type == "feed"){
			//var base_url = window.location.origin;
			var base_url = 'https://www.like2.shop/'
			window.open((base_url + this.state.current_user), '_blank');
		} else {
			var base_url = window.location.origin;
			window.location.href = base_url + '/dashboard/' + type;
		}
	}

	onSetSidebarOpen(open) {
    	this.setState({ sidebarOpen: open });
  	}
	
	deleteVid(event, index, delete_type) {
		var index_type = this.state.videos[index]['vid_id'];
		if (delete_type == "Products"){
			index_type = this.state.links[index]['link_id'];
		}
		
		var curr_url = 'https://shoptik-app.herokuapp.com/delete?vid_id=' + index_type + '&delete_type' + '=' + delete_type;
		
		this.setState({
			openAlert: true,
			alertMessage: "Successfully Removed"
		})
		
		fetch(curr_url,{
					method: "GET",
					credentials: 'include',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
				}).then(response => {
					response.json().then(data =>{
						
						if (data.status === 400) {
							
							//show error popup
						} else if (data.status === 200){
							if (delete_type == "Products"){
								this.setState({
									showNewProduct: false,
									openAlert: true,
									alertMessage: "Successfully Removed"
								})
							}
							
							//reload if successful
							this.getClientData(this.state.current_user);
							//reload videos
//								var results = data.data;
//								this.setState({
//									links: results.links
//								})	
						}
					})
				})
	}
	
	showNewVideoModal(){
		this.setState({
			showNewVideo: true
		})
	}
	
	//store video
	async storeVideoMentions(event, index){
		var vid_len = this.state.videos.length;
		
		if (this.state.is_active == false && vid_len >= 5){
					this.setState({
						openAlert2: true,
						alertMessage2: "Oops. To add unlimited videos on free trial, please verify your account first"
					});
					return;
		}

//		if (vid_len >= 10){
//			if ((this.state.plan == "standard_monthly") || (this.state.plan == "standard_annual")){
//				//you've hit cap
//			
//				this.setState({
//					openAlert2: true,
//					alertMessage2: "Oops. You've reached the maximum number of videos for the basic plan. Upgrade to premium for unlimited videos and products"
//				});
//				return;
//			} 
//		}

		var new_url = this.state.allHrefsMentions[index];
		
//			console.log("NEW URL:", new_url);
		
		//scrape full video url
		var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url
		
		const scrape_data = await fetch(scrape_url)
		const $ = cheerio.load(await scrape_data.text())
		var final_url = $('video').attr("src").replace("https://www.tuda-suda.by/url.php?to=", "");

		//fetch to flask, store video_url, tiktokname
		this.setState({
			showNewMention: false
		})
		
		var info = {
			'client': this.state.current_user,
			'video_url': final_url,
			'thumbnail_url': this.state.allVideosMentions[index],
			'video_id': this.state.allIdsMentions[index]
		}
		
		
		
		
		var client = this.state.current_user;
		
			fetch('https://shoptik-app.herokuapp.com/dashboard/'+ client + '/addvideo', {
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
//					
					if (data.status === 200){
						this.setState({
							openAlert: true,
							alertMessage: "Video Successfully Added to Feed"
						})
//						var pathArray = window.location.pathname.split('/');
//						var client = pathArray[1];
						this.getClientData(this.state.current_user);


						//
					} else {
//						this.setState({
//							isLogged: false,
//						})
				   }
				})
			})
		
	}
	//store video
	async storeVideoHashtags(event, index){
		var vid_len = this.state.videos.length;
		
		if (this.state.is_active == false && vid_len >= 5){
				this.setState({
					openAlert2: true,
					alertMessage2: "Oops. To add unlimited videos on free trial, please verify your account first"
				});
				return;
			}

//		if (vid_len >= 10){
//			if ((this.state.plan == "standard_monthly") || (this.state.plan == "standard_annual")){
//				//you've hit cap
//			
//				this.setState({
//					
//					
//					openAlert2: true,
//					alertMessage2: "Oops. You've reached the maximum number of videos for the basic plan. Upgrade to premium for unlimited videos and products"
//				});
//				return;
//			} 
//		}

		var new_url = this.state.allHrefsHashtags[index];

		//scrape full video url
		var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url
		
		const scrape_data = await fetch(scrape_url)
		const $ = cheerio.load(await scrape_data.text())
		var final_url = $('video').attr("src").replace("https://www.tuda-suda.by/url.php?to=", "");

		//fetch to flask, store video_url, tiktokname
		this.setState({
			showNewHashtag: false
		})
		
		var info = {
			'client': this.state.current_user,
			'video_url': final_url,
			'thumbnail_url': this.state.allVideosHashtags[index],
			'video_id': this.state.allIdsHashtags[index]
		}

		var client = this.state.current_user;
		
			fetch('https://shoptik-app.herokuapp.com/dashboard/'+ client + '/addvideo', {
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						this.setState({
							openAlert: true,
							alertMessage: "Video Successfully Added to Feed"
						})
//						var pathArray = window.location.pathname.split('/');
//						var client = pathArray[1];
						this.getClientData(this.state.current_user);


						//
					} else {
//						this.setState({
//							isLogged: false,
//						})
				   }
				})
			})
		
	}
	
	//store video
	async storeVideo(event, index){
		var vid_len = this.state.videos.length;
		
		if (this.state.is_active == false && vid_len >= 5){
				this.setState({
					openAlert2: true,
					alertMessage2: "Oops. To add unlimited videos on free trial, please verify your account first"
				});
				return;
			}

//		if (vid_len >= 10){
//			if ((this.state.plan == "standard_monthly") || (this.state.plan == "standard_annual")){
//				//you've hit cap
//			
//				this.setState({
//					openAlert2: true,
//					alertMessage2: "Oops. You've reached the maximum number of videos for the basic plan. Upgrade to premium for unlimited videos and products"
//				});
//				return;
//			} 
//		}

//		var new_url = this.state.allHrefs[index];
//		
//
//		//scrape full video url
//		var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url
//		
//		const scrape_data = await fetch(scrape_url)
//		const $ = cheerio.load(await scrape_data.text())
//		var final_url = $('video').attr("src").replace("https://www.tuda-suda.by/url.php?to=", "");

		//fetch to flask, store video_url, tiktokname
		this.setState({
			showNewVideo: false
		})
		
		
		
		var info = {
			'client': this.state.current_user,
			'video_url': this.state.allVideos[index],
			'thumbnail_url': this.state.allVideos[index],
			'video_id': this.state.allIds[index]
		} 
		

		var client = this.state.current_user;
		
			fetch('https://shoptik-app.herokuapp.com/dashboard/'+ client + '/addvideo', {
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
//					
					if (data.status === 200){
						this.setState({
							openAlert: true,
							alertMessage: "Video Successfully Added to Feed"
						})
//						var pathArray = window.location.pathname.split('/');
//						var client = pathArray[1];
						this.getClientData(this.state.current_user);
						//
					} else {
//						this.setState({
//							isLogged: false,
//						})
				   }
				})
			})
		
	}
	
	
	
	saveProduct(event){	
		event.preventDefault();
	
		var product_title = document.getElementById("product_title").value;
		var product_price = document.getElementById("product_price").value;
		var product_link = document.getElementById("product_link").value;
		var product_image = document.getElementById("product_image").value;
		
		var video_id = this.state.current_video_id;
		
		var info = {
			'product_title': product_title,
			'product_price': product_price, 
			'product_link': product_link,
			'product_image': product_image,
			'video_id': video_id
		}
		
		document.getElementById("product_title").value = "";
		document.getElementById("product_price").value = "";
		document.getElementById("product_link").value = "";
		document.getElementById("product_image").value = "";

		this.setState({
			showAddProduct: false,
			openAlert: true,
			alertMessage: "Item Successfully Added to Video",
			product_title: "",
			product_price: "",
			product_link: "",
			product_image: "",
			video_id: ""
		})	
		
		
		fetch('https://shoptik-app.herokuapp.com/dashboard/' + this.state.current_user + '/addproduct', {
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
						//just adding this now
						this.getClientData(this.state.current_user);
					} else {
				   }
				})
			})
	}
	
	addProduct(event, index){
		this.setState({
			current_video_id: this.state.videos[index]['vid_id'],
			showAddProduct: true,
		})		
	}
	
	editProduct(event, name, price, thumbnail_link, product_link, link_id){
		this.setState({
			edit_product_title: name,
			edit_product_price: price,
			edit_product_link: product_link,
			edit_product_image: thumbnail_link,	
			current_item_id: link_id,
			showEditProduct: true,
			showNewProduct: false,
			
		})		
		
		event.preventDefault();

	}
	
	//called after editing a product
	submitEditedProduct(event, link_id){
		event.preventDefault();
		
		//need to know product id for url
		var product_title = document.getElementById("edit_product_title").value;
		var product_price = document.getElementById("edit_product_price").value;
		var product_link = document.getElementById("edit_product_link").value;
		var product_image = document.getElementById("edit_product_image").value;
		
		var video_id = this.state.current_video_id;
		

		var info = {
			'product_title': product_title,
			'product_price': product_price, 
			'product_link': product_link,
			'product_image': product_image,
			'product_id': link_id
		}
		

		this.setState({
			showEditProduct: false,
			current_item_id: "",
			openAlert: true,
			alertMessage: "Item Successfully Edited"
		})	
		
		fetch('https://shoptik-app.herokuapp.com/dashboard/' + this.state.current_user + '/editproduct', {
				method: "POST",
				body: JSON.stringify(info),
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){
					} else {
				   }
				})
			})
	}
		
	async manageProduct(event, index){
		this.setState({
			links: []
		})

		//fetch relevant products
		fetch('https://shoptik-app.herokuapp.com/products?vid_id=' + this.state.videos[index]['vid_id'] + '&clicks=-1', {
					method: "GET",
					credentials: 'include',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
				}).then(response => {
					response.json().then(data =>{
						
						
						if (data.status === 400) {
							
							//show error popup
						} else if (data.status === 200){
								var results = data.data;
								this.setState({
									links: results.links
								})	
						}
					})
				})
		

		var current_vid = this.state.videos[index]['thumbnail'];
		var vidbackground = this.state.videos[index]['thumbnail_link'];
		var current_tiktok_video = "https://www.tiktok.com/api/img/?itemId=" + this.state.videos[index]['tiktok_video_id'] + "&location=0";
		var current_vid_id = this.state.videos[index]['tiktok_video_id']
		

			var new_url = this.state.allHrefs[index];

//		console.log("allhrfs", this.state.allHrefs);
//			var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url
				var scrape_url = new_url
				
				
				
				
				
				
				
				
				
//						//fetch relevant products
//		fetch(scrape_url, {
//			mode: "no-cors",
//					method: "GET",
//					credentials: 'include',
//			
//			
//					headers: {
//						'X-Requested-With': 'XMLHttpRequest',
//						'Accept': 'application/json',
//						'Content-Type': 'application/json'
//					},
//				}).then(response => {
//					response.json().then(data =>{
//						console.log("DATA: ", data);
//
//					})
//				})
//		
//				
				
			
			console.log("scrape url: ", scrape_url)
			const scrape_data = await fetch(scrape_url, {mode: 'no-cors'})
			
			
			
			
//			console.log(scrape_data)
			const $ = cheerio.load(await scrape_data.text())
//			console.log(scrape_data.text())
			var final_url = $('video').attr("src");
		console.log("finalurl: ", final_url)
		
			current_vid = final_url;

		this.setState({
			current_video_url: current_vid,
			current_index: index,
			current_vid_id: current_vid_id,
			vidbackground: current_tiktok_video,
			//vidbackground: vidbackground,
			showNewProduct: true,
		})
	}
	
	loginStatus(){
		var curr = this;
		//fetch to see if logged in
		fetch('https://shoptik-app.herokuapp.com/dashboard/whoami', {
			method: "GET",
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
			credentials: 'include',
			}).then(response => {
				response.json().then(data =>{
					var isSafari = window.safari !== undefined;
					
//					if (isSafari){
//						alert("Please use chrome to access the ShopTik dashboard");
//						var base_url = window.location.origin;
//						window.location.href = base_url + '/dashboard/login';
//						this.setState({
//							isLogged: false,
//							current_user: ""
//						})
//					}
					
//					console.log(data)
					if (data.status === 200){
						var current_user = data.data.current_user;
						this.getClientData(current_user);
						this.setState({
							isloading:""
						})
						
						this.setState({
							isLogged: true,
							current_user: data.data.current_user,
							thumbnail_url: data.data.thumbnail_url
						})
						
						//console.log("thumbnail url: ", data.data.thumbnail_url);
//						var base_url = window.location.origin;
//						window.location.href = base_url + '/dashboard/login';
					} else {
//						if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)){
//							alert("Please use chrome to access the ShopTik dashboard");
//						}
//						var base_url = window.location.origin;
//						window.location.href = base_url + '/dashboard/login';
//						this.setState({
//							isLogged: false,
//							current_user: ""
//						})
				   }
				})
			})
	}
	
	componentDidMount(){
		
		//fetch logged in status before getting client data
		let params = (new URL(document.location)).searchParams;
		let session_id = params.get("session_id");
		let login = params.get("l");
		let first = params.get("f");
		let u = params.get("u");
		
		if (login !== null){
			this.setState({login: true})
		} if (first !== null){
			this.setState({first: true})
		} if (session_id !== null){
			this.setState({session_id:session_id})
		}
		
		//for embeds to work
		const script = document.createElement("script");
		script.src = "https://www.tiktok.com/embed.js";
		script.async = true;
		document.body.appendChild(script);
		

//		if (login !== null){
//			
//			if (login !== null){
//				this.setState({
//					session_id: session_id,
//					login: true
//				})
//				
//			} else {
//				this.setState({
//					session_id: session_id
//				})
//			}
//		}
//		//		
//		if (login !== null){
//			
//			if (login !== null){
//				this.setState({
//					session_id: session_id,
//					login: true
//				})
//				
//			} else {
//				this.setState({
//					session_id: session_id
//				})
//			}
//		}
		
		var info = {
			'objectId': u
		}
	
		if (u !== null){
			//fetch the activate link
			fetch('https://shoptik-app.herokuapp.com/activate', {
			method: "POST",
				credentials: 'include',
				body: JSON.stringify(info),
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				}).then(response => {
					response.json().then(data =>{
						if (data.status === 200){
						} else {
					   }
					})
				})
		}
		
		this.loginStatus();
		
//		this.setState({
//			isLogged: true
//		})
//		this.addVideo();
//		var pathArray = window.location.pathname.split('/');
//		var client = pathArray[1];
//		this.getClientData(client);
	}
	
	async searchUser(event) {
		
		event.preventDefault();

		var client = this.state.userMention;
		
		const scrape_data = await fetch('https://mysterious-reef-90754.herokuapp.com/https://urlebird.com/user/' + client + '/');
		
		const $ = cheerio.load(await scrape_data.text())
	
		const allVideosMentions = [];
		
		var backupThumbsMentions = [];
		var vids = $('.thumb .img img');
		$('.thumb .img img').each(function(i, elem) {
			var reg_src = $(this).attr("src");
			var data_src = $(this).attr("data-src");
			
			if (reg_src.includes("data:image")){
				backupThumbsMentions[i] = data_src;
			} else if (reg_src.includes("https://p16")){
				backupThumbsMentions[i] = reg_src
			}
		});
		
		
		
		
		

		const allLikesMentions = [];
		const allViewsMentions = [];
		const allSharesMentions = [];
	
		var counter = 0
		$('span').each(function(i, elem) {
			
			var classname = $(this).attr("class");
			if (classname === undefined){
				
				if (counter == 0){
					allLikesMentions.push($(this).text());
				} else if (counter == 1){
					allViewsMentions.push($(this).text());
				} else {
					allSharesMentions.push($(this).text());
				}
				counter = (counter + 1)%3
			}
		});
		
//		https://urlebird.com/video/
		const allHrefsMentions = [];
		const allIdsMentions = [];
		
		var hrefs = $('a');

		$('a').each(function(i, elem) {
			
			
			var link = $(this).attr("href");
			if (link.includes("https://urlebird.com/video/")){
				allHrefsMentions.push(link);
							  
				var new_id = [];

				for(var i = link.length-2; i >= 0; i--) {
					
					if (/^\d+$/.test(link[i])){
						new_id.push(link[i]);
					} else {
						break;
					}
				}
				allIdsMentions.push(new_id.reverse().join(''));
			}
		});
		
		//given all ids, scrape each ones thumbnail url from tiktok
		for (var k=0; k < allIdsMentions.length; k++){
			var new_id = allIdsMentions[k];
			
			allVideosMentions[k] = 'https://www.tiktok.com/api/img/?itemId=' + new_id + '&location=1';
			
//			
//			var new_url = "https://www.tiktok.com/oembed?url=https://www.tiktok.com/@" + client + "/video/" + new_id;
//			var scrape_url = await fetch(new_url);
//			var all_text = await scrape_url.text();
//
//			var key3 = "thumbnail_url\":\"";
//			var key4 = "\"provider_url";
//
//			var fin_url = all_text.match(new RegExp(key3 + "(.*)" + key4));
//			try {
//				//console.log("RESULT: ", fin_url[1].slice(0,-1));
//				allVideosMentions[k] = fin_url[1].replace(/['",]+/g, '');
//			}
//			catch (err){
//				
//			}
		}

		this.setState({
			allVideosMentions : allVideosMentions,
			allLikesMentions : allLikesMentions,
			allSharesMentions : allSharesMentions,
			allViewsMentions : allViewsMentions,
			allHrefsMentions : allHrefsMentions,
			allIdsMentions: allIdsMentions,
			
			backupThumbsMentions: backupThumbsMentions
		})
		
	}

	showEmailClicked(event){
		var icon = document.getElementById('drop');
		
		if (this.state.showEmail == true){
			this.setState({
				showEmail: false
			})
			icon.className = 'fa fa-caret-down';
		} else {
			this.setState({
				showEmail: true
			})
			icon.className = 'fa fa-caret-down open';
		}
	}
	
	async searchHashtags(event) {
		
		event.preventDefault();
//		console.log("search: ", client);
		
		var client = this.state.userHashtags;
		
		
		//RECENTLY ADDED /page/10
		const scrape_data = await fetch('https://mysterious-reef-90754.herokuapp.com/https://urlebird.com/hash/' + client + '/');
		
		const $ = cheerio.load(await scrape_data.text())
	
		const allVideosHashtags = [];
		
		var backupThumbsHashtags = [];
		var vids = $('.thumb .img img');
		$('.thumb .img img').each(function(i, elem) {
			var reg_src = $(this).attr("src");
			var data_src = $(this).attr("data-src");
			
			if (reg_src.includes("data:image")){
				backupThumbsHashtags[i] = data_src;
			} else if (reg_src.includes("https://p16")){
				backupThumbsHashtags[i] = reg_src
			}
		});
		
		
		
		
		
		
		
		
//		var vids = $('.thumb .img img');
//		$('.thumb .img img').each(function(i, elem) {
//			var reg_src = $(this).attr("src");
//			var data_src = $(this).attr("data-src");
//			
//			if (reg_src.includes("data:image")){
//				if (data_src.includes("muscdn")){
//					var newlink = data_src;
//					newlink = newlink.replace("https://p16.muscdn.com/", "https://p16-va-default.akamaized.net/");
//					allVideosHashtags[i] = newlink;
//				} else {
//					allVideosHashtags[i] = data_src;
//				}
//				
//			} else if (reg_src.includes("https://p16")){
//				if (reg_src.includes("muscdn")){
//					var newlink = reg_src;
//					newlink = newlink.replace("https://p16.muscdn.com/", "https://p16-va-default.akamaized.net/");
//					allVideosHashtags[i] = newlink;
//				} else {
//					allVideosHashtags[i] = reg_src;
//				}
//			}
//		});
		
//		console.log("hashtags", allVideosHashtags);
//		return;
		const allLikesHashtags = [];
		const allViewsHashtags = [];
		const allSharesHashtags= [];
	
		var counter = 0
		$('span').each(function(i, elem) {
			
			var classname = $(this).attr("class");
			if (classname === undefined){
				
				if (counter == 0){
					allLikesHashtags.push($(this).text());
				} else if (counter == 1){
					allViewsHashtags.push($(this).text());
				} else {
					allSharesHashtags.push($(this).text());
				}
				counter = (counter + 1)%3
			}
		});
//		https://urlebird.com/video/
		const allHrefsHashtags = [];
		const allIdsHashtags = [];
		
		var hrefs = $('a');
//		console.log("hrefs:", hrefs);

		$('a').each(function(i, elem) {
			

			var link = $(this).attr("href");
//			console.log("link: ", link);
			
			if (!link){
				
			}
			else if (link.includes("https://urlebird.com/video/")){
				allHrefsHashtags.push(link);
							  
							  
				var new_id = [];
//				var name = "John Smith".split("");
				
				for(var i = link.length-2; i >= 0; i--) {
					
					if (/^\d+$/.test(link[i])){
						new_id.push(link[i]);
					} else {
						break;
					}
				}
				allIdsHashtags.push(new_id.reverse().join(''));
			}
		});
		
		//given all ids, scrape each ones thumbnail url from tiktok
		for (var k=0; k < allIdsHashtags.length; k++){
			var new_id = allIdsHashtags[k];
			
			allVideosHashtags[k] = 'https://www.tiktok.com/api/img/?itemId=' + new_id + '&location=1';
			
			var new_url = "https://www.tiktok.com/oembed?url=https://www.tiktok.com/@" + client + "/video/" + new_id;
//			var scrape_url = await fetch(new_url);
//			var all_text = await scrape_url.text();
//
//			var key3 = "thumbnail_url\":\"";
//			var key4 = "\"provider_url";
//
//			var fin_url = all_text.match(new RegExp(key3 + "(.*)" + key4));
//			try {
//				//console.log("RESULT: ", fin_url[1].slice(0,-1));
//				allVideosHashtags[k] = fin_url[1].replace(/['",]+/g, '');
//			}
//			catch (err){
//				
//			}
		}
		
		this.setState({
			allVideosHashtags : allVideosHashtags,
			allLikesHashtags : allLikesHashtags,
			allSharesHashtags : allSharesHashtags,
			allViewsHashtags : allViewsHashtags,
			allHrefsHashtags : allHrefsHashtags,
			allIdsHashtags: allIdsHashtags,
			
			backupThumbsHashtags: backupThumbsHashtags
		})
	}
	
	async getClientData(client) {
		//fetch imgs from apis, make array where video ids match img vid ids, use it for onimgerror func like backup thumbs
		
		
		
		
//		
//		
//		const scrape_data = await fetch('https://urlebird.com/user/' + client + '/');
//		
//		const $ = cheerio.load(await scrape_data.text())
//	
//		var allVideos = [];
//		
//		var backupThumbs = [];
//		var vids = $('.thumb .img img');
//		$('.thumb .img img').each(function(i, elem) {
//			var reg_src = $(this).attr("src");
//			var data_src = $(this).attr("data-src");
//			
//			if (reg_src.includes("data:image")){
//				backupThumbs[i] = data_src;
//			} else if (reg_src.includes("https://p16")){
//				backupThumbs[i] = reg_src
//			}
//		});
//
//		const allLikes = [];
//		const allViews = [];
//		const allShares = [];
//	
//		var counter = 0
//		$('span').each(function(i, elem) {
//			
//			var classname = $(this).attr("class");
//			if (classname === undefined){
//				
//				if (counter == 0){
//					allLikes.push($(this).text());
//				} else if (counter == 1){
//					allViews.push($(this).text());
//				} else {
//					allShares.push($(this).text());
//				}
//				counter = (counter + 1)%3
//			}
//		});
		
//		https://urlebird.com/video/
//		const allHrefs = [];
//		const allIds = [];
//		
//		var hrefs = $('a');
//
//		$('a').each(function(i, elem) {
//			
//			var link = $(this).attr("href");
//			if (link.includes("https://urlebird.com/video/")){
//				allHrefs.push(link);
//							  
//				var new_id = [];
////				var name = "John Smith".split("");
//				
//				for(var i = link.length-2; i >= 0; i--) {
//					
//					if (/^\d+$/.test(link[i])){
//						new_id.push(link[i]);
//					} else {
//						break;
//					}
//				}
//				allIds.push(new_id.reverse().join(''));
//			}
//		});
//		
		
		
		
		
		//given all ids, scrape each ones thumbnail url from tiktok
//		for (var k=0; k < allIds.length; k++){
//			var new_id = allIds[k];
//			
//			
//			//try to use api
//			allVideos[k] = 'https://www.tiktok.com/api/img/?itemId=' + new_id + '&location=1';
			

//			var new_url = "https://www.tiktok.com/oembed?url=https://www.tiktok.com/@" + client + "/video/" + new_id;
//			var scrape_url = await fetch(new_url);
//			var all_text = await scrape_url.text();
//
//			var key3 = "thumbnail_url\":\"";
//			var key4 = "\"provider_url";
//
//			var fin_url = all_text.match(new RegExp(key3 + "(.*)" + key4));
//			try {
//				console.log("RESULT: ", fin_url[1].slice(0,-1));
//				allVideos[k] = fin_url[1].replace(/['",]+/g, '');
//			}
//			catch (err){
//				
//			}
//		}
		
		var allVideos = []
		var allLikes = []
		var allShares = []
		var allViews = []
		var allHrefs = []
		var allIds = []

		var backupThumbs = []
		
		var newbackupThumbs = []
		var newbackupThumbsIds = []
		fetch("https://social-media-data-tt.p.rapidapi.com/live/user/feed?username=" + client + "&fresh=1", {
			"method": "GET",
			"headers": {
				"x-rapidapi-key": "9c363ae588mshd71440a101292d4p1c4061jsn32d687f5b44f",
				"x-rapidapi-host": "social-media-data-tt.p.rapidapi.com"
			}
		})
		.then(response => {
			console.log(response);
					response.json().then(data =>{
						console.log(data)

						var media = data["media"];
						for (var i=0; i<media.length;i++){
							var vid = media[i]
							newbackupThumbs[i] = vid["video"]["cover"]
							newbackupThumbsIds[i] = vid["video_id"]
							
							allVideos[i] = vid["video"]["cover"]
							allLikes[i] = vid["statistics"]["diggCount"]
							allShares[i] = vid["statistics"]["shareCount"]
							allViews[i] = vid["statistics"]["playCount"]
							allIds[i] = vid["video_id"]
							allHrefs[i] = ""
							backupThumbs[i] = vid["video"]["cover"]
						}
//						
//								console.log("newthumbs: ", newbackupThumbsIds)

		
		this.setState({
			newbackupThumbs: newbackupThumbs,
			newbackupThumbsIds: newbackupThumbsIds,
			
			allVideos : allVideos,
			allLikes : allLikes,
			allShares : allShares,
			allViews : allViews,
			allHrefs : allHrefs,
			allIds: allIds,
			
			backupThumbs: backupThumbs
		})

			//fetch
			fetch('https://shoptik-app.herokuapp.com/api/' + client, {
				method: "GET",
				credentials: 'include',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
			}).then(response => {
				response.json().then(data =>{
					if (data.status === 200){

						var new_array = [];
						var complete_array = [];
						var recent_index_array = [];
						for (var j=0; j < data.data.videos.length; j++){
							new_array.push(data.data.videos[j]["tiktok_video_id"])
						}
						
					
						for (var i=0; i < allIds.length; i++){
							
							if (!new_array.includes(allIds[i])){
								
								//RECENT ADDITION
								if (typeof allVideos[i] 
									!== 'undefined'){

									complete_array.push([allVideos[i]])
									recent_index_array.push(i);
								}
							}
						}
						
						var d1 = data.data.creation_date;
						d1 = d1.substring(0, d1.indexOf('T'));
						d1 = new Date(d1);
						var d2 = new Date();
						var Difference_In_Time = d2.getTime() - d1.getTime(); 
						var daily_diff = Math.round(Difference_In_Time / (1000 * 3600 * 24)); 

						if (daily_diff >= 7){
							
						//show alert
						if (this.state.showPayModal != true){
								if (data.data.is_active == false){
									if (data.data.plan_type !== "free"){
										this.setState({
											showPayModal: true
										})
									}
								}
							}
						}
						
						
						//if plan type is unknown, show plan modal
						if (data.data.is_active == false && this.state.login == true && this.state.shownPay == false && daily_diff < 6){
							
							var topmsg = "Update your billing information"
							if (typeof this.state.first !== 'undefined'){
								topmsg = "🎉 Congratulations " + data.data.profile_name + ", your account is active! 🎉"
							}
							
							this.setState({
								topmsg:topmsg,
								showPlanModal: true,
								shownPay: true
							})
						}
						
//						var bio_link = window.location.origin + '/' + client;
						
						
						
						
				var feed = true;
				 var left_opacity = 1;
				 var right_opacity = 0.15;
				var shouldHideFeed = "block";
				 var shouldHideStore = "none";
					if (data.data.products_first){
						feed = false;
						left_opacity = 0.15;
						right_opacity = 1;
						shouldHideFeed = "none";
						shouldHideStore = "block"
					}
						
						console.log("COMPLETE ARRAY: ", complete_array)
						
						var videos = data.data.videos
						
//						for ()
						console.log("These are all the videos:", allVideos);
						console.log("THESE ARE THE LOWLY VIDEOS:", videos)
						
						var weirdvideos = []
						for (var i=0; i < allVideos.length; i++){
							var newvid = videos[i];
							newvid["thumbnail"] = allVideos[i]
							newvid["thumbnail_link"] = allVideos[i]
							weirdvideos.push(newvid)
						}
						
						console.log("these are the weird videos", weirdvideos)
						
						
						//for each video, update it to be the one matching thumb or backupthumb

						this.setState({
							thumbnail_url: data.data.thumbnail_url,
							following: data.data.following,
							followers: data.data.followers,
							likes: data.data.likes,
							profile_name: data.data.profile_name,
							videos: data.data.videos,
							recent_links: complete_array.slice(0, 5),
							recent_index_array: recent_index_array,
							bio_link: data.data.bio_link,
							bio_string: data.data.bio_string,
							plan: data.data.plan_type,
							is_active: data.data.is_active,
							creation_date: data.data.creation_date,
							
							product_store: data.data.store.reverse(),
							isloading: "none",
							
							feed: feed,
							left_opacity: left_opacity,
							right_opacity: right_opacity,
							shouldHideFeed: shouldHideFeed,
							shouldHideStore: shouldHideStore,
							showEmailAllowed: data.data.email_addon,
							email_placeholder: data.data.email_placeholder,
							email_title: data.data.email_title,
							products_first: data.data.products_first,
							
							weirdvideos: weirdvideos
							
							
						})
					
					} else {
						
				   }
				})
			})	
		})})
		
		// CHANGE THIS BACK!
//		var new_all_videos = [];
//		for (var k=0; k < allHrefs.length; k++){
//			var new_url = allHrefs[k];
//			var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url
//
//			const scrape_data = await fetch(scrape_url)
//			const $ = cheerio.load(await scrape_data.text())
//			var final_url = $('video').attr("src");
//
//			new_all_videos.push(final_url);
//		}
//		allVideos = new_all_videos;
		
		
		
		
		//FIGURE THIS OUT

//		console.log("newthumbs: ", newbackupThumbsIds)
//
//		this.setState({
//			newbackupThumbs: newbackupThumbs,
//			newbackupThumbsIds: newbackupThumbsIds,
//			
//			allVideos : allVideos,
//			allLikes : allLikes,
//			allShares : allShares,
//			allViews : allViews,
//			allHrefs : allHrefs,
//			allIds: allIds,
//			
//			backupThumbs: backupThumbs
//		})
//
//			//fetch
//			fetch('https://shoptik-app.herokuapp.com/api/' + client, {
//				method: "GET",
//				credentials: 'include',
//				headers: {
//					'Accept': 'application/json',
//					'Content-Type': 'application/json'
//				},
//			}).then(response => {
//				response.json().then(data =>{
//					if (data.status === 200){
//
//						var new_array = [];
//						var complete_array = [];
//						var recent_index_array = [];
//						for (var j=0; j < data.data.videos.length; j++){
//							new_array.push(data.data.videos[j]["tiktok_video_id"])
//						}
//						
//					
//						for (var i=0; i < allIds.length; i++){
//							
//							if (!new_array.includes(allIds[i])){
//								
//								//RECENT ADDITION
//								if (typeof allVideos[i] 
//									!== 'undefined'){
//
//									complete_array.push([allVideos[i]])
//									recent_index_array.push(i);
//								}
//							}
//						}
//						
//						var d1 = data.data.creation_date;
//						d1 = d1.substring(0, d1.indexOf('T'));
//						d1 = new Date(d1);
//						var d2 = new Date();
//						var Difference_In_Time = d2.getTime() - d1.getTime(); 
//						var daily_diff = Math.round(Difference_In_Time / (1000 * 3600 * 24)); 
//
//						if (daily_diff >= 7){
//							
//						//show alert
//						if (this.state.showPayModal != true){
//								if (data.data.is_active == false){
//									if (data.data.plan_type !== "free"){
//										this.setState({
//											showPayModal: true
//										})
//									}
//								}
//							}
//						}
//						
//						
//						//if plan type is unknown, show plan modal
//						if (data.data.is_active == false && this.state.login == true && this.state.shownPay == false && daily_diff < 6){
//							
//							var topmsg = "Update your billing information"
//							if (typeof this.state.first !== 'undefined'){
//								topmsg = "🎉 Congratulations " + data.data.profile_name + ", your account is active! 🎉"
//							}
//							
//							this.setState({
//								topmsg:topmsg,
//								showPlanModal: true,
//								shownPay: true
//							})
//						}
//						
////						var bio_link = window.location.origin + '/' + client;
//						
//						
//						
//						
//				var feed = true;
//				 var left_opacity = 1;
//				 var right_opacity = 0.15;
//				var shouldHideFeed = "block";
//				 var shouldHideStore = "none";
//					if (data.data.products_first){
//						feed = false;
//						left_opacity = 0.15;
//						right_opacity = 1;
//						shouldHideFeed = "none";
//						shouldHideStore = "block"
//					}
//						
//						var videos = data.data.videos
//						//for each video, update it to be the one matching thumb or backupthumb
//
//						this.setState({
//							thumbnail_url: data.data.thumbnail_url,
//							following: data.data.following,
//							followers: data.data.followers,
//							likes: data.data.likes,
//							profile_name: data.data.profile_name,
//							videos: data.data.videos,
//							recent_links: complete_array.slice(0, 5),
//							recent_index_array: recent_index_array,
//							bio_link: data.data.bio_link,
//							bio_string: data.data.bio_string,
//							plan: data.data.plan_type,
//							is_active: data.data.is_active,
//							creation_date: data.data.creation_date,
//							
//							product_store: data.data.store.reverse(),
//							isloading: "none",
//							
//							feed: feed,
//							left_opacity: left_opacity,
//							right_opacity: right_opacity,
//							shouldHideFeed: shouldHideFeed,
//							shouldHideStore: shouldHideStore,
//							showEmailAllowed: data.data.email_addon,
//							email_placeholder: data.data.email_placeholder,
//							email_title: data.data.email_title,
//							products_first: data.data.products_first,
//							
//						})
//					
//					} else {
//						
//				   }
//				})
//			})
		}


	//DEPRECATED FUNCTION, DO NOT USE
	async testError(index){

//		var client = this.state.profile_name;
//		var videos = this.state.videos;
//
//		for (var i=0; i<videos.length; i++){
//			var tiktok_vid_id = videos[i]['tiktok_video_id'];
//			var vid_id = videos[i]['vid_id'];
//			
//			var new_url = 'https://www.tiktok.com/@' + client + '/video/' + tiktok_vid_id + '?lang=en'
//			var scrape_url = 'https://mysterious-reef-90754.herokuapp.com/' + new_url
//			
//			const scrape_data = await fetch(scrape_url);
//			
//			var all_text = await scrape_data.text();

//			var key3 = "urls\":";
//			var key4 = "videoMeta\"";
			
//			var key3 = "playAddr";
//			var key4 = "downloadAddr";
//			
//			var fin_url = all_text.match(new RegExp(key3 + "(.*)" + key4));
			//console.log("finurl: ". fin_url)
			
//			var finalurl = "";
//			
//			try {
//				finalurl = fin_url[1].replace(/['",]+/g, '').slice(1,-1);
//				finalurl = finalurl.replace('-web','');
//				console.log("FINALURL: ", finalurl);
//				
//				var current_video_url = finalurl;
//				if (finalurl.includes("tiktokcdn")){
//					current_video_url = "https://onlinetik.com/wp-admin/admin-ajax.php?action=wppress_tt_stream&url=" + current_video_url
//				}
//
//				if (i == index){
//					this.setState({
//						current_video_url: current_video_url
//					})
//				}
//				
//			} catch(err){
//				
//			}
			
			//var newvideos = videos
//			videos[i]['thumbnail'] = "https://onlinetik.com/wp-admin/admin-ajax.php?action=wppress_tt_stream&url=" + finalurl;
//			
//			var info = {
//				'vid_id': vid_id,
//				'video_url': finalurl,
//			}
			
//			if (finalurl.includes("tiktokcdn") && finalurl != ""){
//			fetch('https://shoptik-app.herokuapp.com/update_video', {
//				method: "POST",
//				body: JSON.stringify(info),
//				credentials: 'include',
//				headers: {
//					'Accept': 'application/json',
//					'Content-Type': 'application/json'
//				},
//			}).then(response => {
//				response.json().then(data =>{
//
//					if (data.status === 200){
//					} else {
//
//				   }
//				})
//			})
//			}
			
//		}
		
		//I should just setstate once here
//		this.setState({
//			videos: videos,
//		})
		
	}
	
	storePlan(plan){
//		alert("storing plan: ")
		this.setState({
			showNewPayModal: true, 
			showPlanModal: false,
			new_plan: plan
		})
		
		var info = {
			'brand': this.state.profile_name,
			'plan': plan
		}
		//return;
		//lets fetch and scrape the profile data here
		//scrape tiktok for the needed values, append it to video state
				fetch('https://shoptik-app.herokuapp.com/setnewplan',{
					method: "POST",
					body: JSON.stringify(info),
					credentials: 'include',
					headers: {
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
				}).then(response => {
					response.json().then(async data =>{
						if (data.status === 200){
//							var base_url = window.location.origin;
//							window.location.href = base_url + '/dashboard/manage_media/';
//							return;
					}})
				})
	}
	illdoitlater(event){
		this.setState({
			showPlanModal: false
		})
		
		if (/Mobi/.test(navigator.userAgent)){
			this.setState({
				showMobilePopup: true
			})
		}
	}
	
	verifyCard(plan){
//		this.attemptNewUpgrade(event, "standard_monthly")
//		alert("plan", plan)
		this.setState({showPlanModal: false})
		var d1 = this.state.creation_date;
			d1 = d1.substring(0, d1.indexOf('T'));
			d1 = new Date(d1);
			var d2 = new Date();
			var Difference_In_Time = d2.getTime() - d1.getTime(); 
			var daily_diff = Math.round(Difference_In_Time / (1000 * 3600 * 24)); 
			
			if (daily_diff > 7){
				daily_diff = 7;
			}
			var days_left = 7 - daily_diff;
//			this.setState({
//				days_left: days_left
//			})

//				event.preventDefault();
		this.setState({
			showPlanModal: false
		})

//		var plan = this.state.plan;
		var tiktok_handle = this.state.profile_name;
		
//		alert(tiktok_handle);
		var final_plan = "price_1H4zyWIRpv6K8jIKVUgq9HBf";
		if (plan == "standard_monthly"){
			final_plan = "price_1H4zyWIRpv6K8jIKDY0bePvj";
		} else if (plan == "standard_annual"){
			final_plan = "price_1H4zyWIRpv6K8jIKkpB3ImAy";
		} else if (plan == "premium_monthly"){
			final_plan = "price_1H4zyWIRpv6K8jIKVUgq9HBf";
		} else if (plan == "premium_annual"){
			final_plan = "price_1H4zyXIRpv6K8jIKSforpntN";
		} else if (plan == "analytics_monthly"){
			final_plan = "price_1HKpYrIRpv6K8jIKjE0138Dg";
		} else if (plan == "analytics_annual"){
			final_plan = "price_1HKpYsIRpv6K8jIKDMdOgHua";
		} 
		
		var info = {
			'tiktok_handle': tiktok_handle,
			'plan': final_plan,
			'daysleft': days_left
		}
		
		//fetch new route to handle payment
		fetch('https://shoptik-app.herokuapp.com/starttrial',{
			method: "POST",
			body: JSON.stringify(info),
			credentials: 'include',
			headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'
			},
		}).then(response => {
			response.json().then(async data =>{
				if (data.status === 400) {
					alert("failed")
					this.setState({
						
					})
				} else if (data.status === 200){

						const sessionId = data.message.id;
						const stripe = await stripePromise;
						const { error } = await stripe.redirectToCheckout({
							sessionId
						})
				}
			})
		})	
		

		//stripe charge them for their current plan
		

	}
	
	render() {
		if (this.state.isLogged){
			
			if (this.state.isloading == "none" && this.state.videos.length == 0){
				return(
					<div class="main_container">
			<div>
					
<NewSidebar selected={0}></NewSidebar>
			
	<br /><br /><br />
				<div class="main">
					<Modal backdrop="static"
        keyboard={false} class="widemodal" size="lg" show={this.state.showPlanModal}  onHide={() => this.setState({ showPlanModal: false })}>
						<br /><br />
						
						<center>
						<Modal.Title style={{paddingLeft: "10px", paddingRight: "10px"}} id="contained-modal-title-vcenter" centered>
							{this.state.topmsg}
							</Modal.Title></center>
                        <Modal.Body>
							
							<p style={{padding: "30px"}}>Add billing information now to prevent missing out on orders placed by your customers once your free trial has ended. <b>YOU WILL NOT BE BILLED</b>, and can cancel anytime in the settings. </p>
							<center>
							
							<div style={{textAlign: "center"}}>
								<div style={{display: "inline-block", textAlign: "left"}}>
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> You will <b>NOT</b> be charged until the end of your trial</p>
							
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> You can <b>cancel anytime</b> before your trial ends</p>
						</div>
							</div>
							<br /><br />

								<Button style={{padding: "25px 25px", width:"100%"}} onClick={event => this.verifyCard(this.state.plan)}>Add Billing Information</Button>
							</center><br /><br /> <a onClick={(event) => this.illdoitlater(event)} style={{color: "grey"}} ><u>I'll do it later</u></a>
                        </Modal.Body>
        			</Modal>
					
		<Modal backdrop="static"
        keyboard={false} class="widemodal" size="lg" show={this.state.showMobilePopup} onHide={() => this.setState({ showMobilePopup: false })}>
			
			<center>
                        	
                       </center>
                        <Modal.Body>
							<center>
							<img src={shoptik_icon} width="120px"></img><br/><br/>
								This site is not intended for mobile, please use a computer instead. Thanks.

							</center>
                        </Modal.Body>
        			</Modal>
						

					<center>
					<div class="rankbox_container" style={{width: "96%"}}>
						<br /><br />
						<p class="feature_description_text">Welcome to Shoptik! Let's set up <br />your shoppable link in bio!</p><br /><br /><br />
						
						
						<div style={{float: "left", width: "44%", textAlign: "left", marginLeft: "5%", marginBottom: "50px"}}>
						<h5 style={{paddingBottom:"10px"}}>How do I setup my Shoptik link in bio? </h5> 

							<p class="check-text">1. Add videos to your shoppable feed from Recents, TikTok, Username, Hashtag, or Link</p>
							<p class="check-text">2. Tag your videos with products/items</p>
							<p class="check-text">3. Go to "manage bio" on the sidebar, and update your bio and profile pic</p>
							<p class="check-text">4. Finally, publish your shoptik link in your TikTok bio</p>
							<p class="check-text">5. Update your shoptik feed whenever you add new TikTok videos</p><br />
							
							<h5 style={{paddingBottom:"10px"}}>What else can I do with Shoptik? </h5> 
						
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> You can track the performance of your feed</p>
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> Embed web galleries in your home site</p>
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> Use our suite of analytics tools to understand and grow your account</p>
							<br /><br />
							
							
							
							<Button style={{padding: "10px 20px"}} class="tag_btn start_btn" variant="tag_btn" onClick={event => this.setState({videos: [""]}, this.showNewVideoModal(event))} > Add your first video </Button>
						</div>
						<div style={{width: "44%", float: "right",}}>
							<center>
							
							<video width="300px" autoPlay playsInline loop muted
 src="https://www.shoptik.co/images/florence2.mp4" style={{marginTop:"-90px"}}></video>

							</center>
						</div>
						
						
					</div>
						</center>
					

		<br /><br />

				
				</div></div></div>
				)
				alert("here")
			} 
			
			
			
			
			let currentComponent = this;
			
			let likes = currentComponent.state.allLikes;
			let views = currentComponent.state.allViews;
			let shares = currentComponent.state.allShares;
			
			console.log("ALLVIDS: ", this.state.allVideos);

			var tiktoklinks = this.state.allVideos.map(function(link, index){
				return (
					<div key={index} class="img" style={{float:"left", width:"33.3%", padding:"0px"}}>
						
						<div class="shop_items" style={{textAlignLast:"center"}}>
						
							<div class="order_num" style={{marginLeft:"5px"}}>
								{index+1}	
							</div>
							
							<img id="select_vid" class="add_video" referrerPolicy="no-referrer" onError={event => currentComponent.newImgError(index, "reg")} src={link} style={{width:"85%", height:"400px", padding:"0px", }} onClick={event => currentComponent.storeVideo(event, index)}/>
							
						
									<p id="shop_text" style={{marginLeft:"0%"}}>
										<p id="video_stats">
											
											💬 &nbsp; {views[index]}&nbsp; &nbsp;
											❤️ &nbsp; {likes[index]}&nbsp; &nbsp;
											📤 &nbsp; {shares[index]}
										</p>
									</p>
	
						
						</div>
					</div>)
    		});
			
			
			
			let likesMentions = currentComponent.state.allLikesMentions;
			let viewsMentions = currentComponent.state.allViewsMentions;
			let sharesMentions = currentComponent.state.allSharesMentions;

			var tiktoklinksMentions = this.state.allVideosMentions.map(function(link, index){
				return (
					<div key={index} class="img" style={{float:"left", width:"33.3%", padding:"0px"}}>
						
						<div class="shop_items" style={{textAlignLast:"center"}}>
						
							<div class="order_num" style={{marginLeft:"5px"}}>
								{index+1}	
							</div>
							
							<img id="select_vid" class="add_video" referrerPolicy="no-referrer" onError={event => currentComponent.newImgError(index, "mentions")} src={link} style={{width:"85%", height:"400px", padding:"0px", }} onClick={event => currentComponent.storeVideoMentions(event, index)}/>
							
							
								<p id="shop_text" style={{marginLeft:"0%"}}>
										<p id="video_stats">
											💬 &nbsp; {viewsMentions[index]}&nbsp; &nbsp;
											❤️ &nbsp; {likesMentions[index]}&nbsp; &nbsp;
											📤 &nbsp; {sharesMentions[index]}
										</p>
									</p>
	
	
						</div>
					</div>)
    		});
			
			let likesHashtags = currentComponent.state.allLikesHashtags;
			let viewsHashtags= currentComponent.state.allViewsHashtags;
			let sharesHashtags = currentComponent.state.allSharesHashtags;

			var tiktoklinksHashtags = this.state.allVideosHashtags.map(function(link, index){
				return (
					<div key={index} class="img" style={{float:"left", width:"33.3%", padding:"0px"}}>
						
						<div class="shop_items" style={{textAlignLast:"center"}}>
						
							<div class="order_num" style={{marginLeft:"5px"}}>
								{index+1}	
							</div>
							
							<img id="select_vid" class="add_video" referrerPolicy="no-referrer" onError={event => currentComponent.newImgError(index, "hashtags")} src={link} style={{width:"85%", height:"400px", padding:"0px", }} onClick={event => currentComponent.storeVideoHashtags(event, index)}/>
							
								<p id="shop_text" style={{marginLeft:"0%"}}>
										<p id="video_stats">
											💬 &nbsp; {viewsHashtags[index]}&nbsp; &nbsp;
											❤️ &nbsp; {likesHashtags[index]}&nbsp; &nbsp;
											📤 &nbsp; {sharesHashtags[index]}
										</p>
									</p>
	

						</div>
					</div>)
    		});
			
			var recent_links = this.state.recent_links.map(function(item, index){
				return (
					<div id="active_vid_container" style={{float:"left", width:"160px"}}>
						
						<center>
							
						
						<img onError={event => currentComponent.newImgError(index, "recents")} id="active_vids" referrerPolicy="no-referrer" src={(item)} style={{width:"160px", height: "265px", objectFit: "cover"}}/>
						
							<center>

							<Button class="recent_vid_add" variant="recent_vid_add" style={{width:"140px", margin:"10px"}} onClick={event => currentComponent.storeVideo(event, currentComponent.state.recent_index_array[index])}> Add to feed <i className="fa fa-plus-circle" style={{verticalAlign:"right", fontSize: "15px"}}></i> </Button>
							</center>
							
						</center>
					</div>
					)
    		});
			
			var update_bar = "";
			var d1 = currentComponent.state.creation_date;
			d1 = d1.substring(0, d1.indexOf('T'));
			d1 = new Date(d1);
			var d2 = new Date();
			var Difference_In_Time = d2.getTime() - d1.getTime(); 
			var daily_diff = Math.round(Difference_In_Time / (1000 * 3600 * 24)); 
			
			if (daily_diff > 7){
				daily_diff = 7;
			}
			var days_left = 7 - daily_diff;
//			this.setState({
//				days_left: days_left
//			})
//			if (daily_diff >= 7){
//						//show alert
//						if (currentComponent.showPayModal != true){
//							currentComponent.setState({
//								showPayModal: true
//							})
//						}
//					}
//			console.log("daily_diff", this.state.is_active);
			update_bar = [0].map(function(item, index){
				if (currentComponent.state.is_active == false){
					//lets calculate how many actual days left
					

					if (currentComponent.state.plan == "free"){
						return (<div></div>)
					}
					
					if (daily_diff > 7){
						daily_diff = 7;
					}
					var days_left = 7 - daily_diff;
					
					return (
						<div class="fullbar" style={{zIndex: "1000"}}> <i className="fa fa-bell" style={{fontSize: "15px"}}> </i> {days_left} days left, <a href="#" onClick={event => currentComponent.setState({
							showEndTrialModal: true
						})} id="upgradenow"><b> upgrade now</b></a></div>	   
					)
				}
				else {
					return (
						<div></div>		   
					)
				}
			})
			
			
			var uptodate;
			if (this.state.recent_links.length != 0){
				uptodate = [0].map(function(item, index){
				return (
					<div>
					<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /></div>				   
				)
				})
			} else {
				
				var msg = "You're all up to date!";

			}
			

//			var upgrade = "";
//				if (this.state.plan == "analytics_monthly" || this.state.plan == "analytics_annual"){
//					if (this.state.is_active == true){
//						upgrade = [0].map(function(item, index){
//						return (
//							<Button id="upgrade_button" class="recent_vid_add" variant="recent_vid_add" style={{width:"130px", margin:"10px"}} onClick={event => currentComponent.setState({
//							showUpgradeModal: true
//							})} > Upgrade <i className="fa fa-exclamation-circle" style={{verticalAlign:"right", fontSize: "15px"}}></i> </Button>
//						)
//					})
//				}
//			}
			
						
			var upgrade = "";
					if (this.state.is_active == false){
						upgrade = [0].map(function(item, index){
						return (
							<Button id="upgrade_button" class="recent_vid_add" variant="recent_vid_add" style={{width:"150px", margin:"10px"}} onClick={() => currentComponent.setState({
							showPlanModal: true
							})} > Verify Account <i className="fa fa-exclamation-circle" style={{verticalAlign:"right", fontSize: "15px"}}></i> </Button>
						)
					})
				}
			
			var drop = "";
			var test = [0];
			var email_input = "";
		
			if (this.state.showEmailAllowed){

				drop = test.map(function(test, index){
				return(
					<center>
					<span class="email-span">
					<i className="fa fa-envelope-o" style={{marginTop:"25px"}}></i>
					<i onClick={event => currentComponent.showEmailClicked(event)} className="fa fa-caret-down open" id="drop" style={{fontSize: "17px", marginLeft:"5px"}}> </i>
						</span>
						</center>
				)
			})
			}
			if (currentComponent.state.showEmail == true && currentComponent.state.showEmailAllowed){
				email_input = test.map(function(test, index){
				return(
					<center>
						<br />
						<p style={{marginBottom: "5px"}} id="bio">{currentComponent.state.email_title}</p>
						<form onSubmit={event => event.preventDefault()}>
						<input style={{width: "250px"}} maxlength="60" required onChange={event => currentComponent.setState({email_address: event.target.value})} value={currentComponent.state.email_address} id="product_title" name="product_title" type="text" placeholder={currentComponent.state.email_placeholder} />
						
						<Button style={{marginLeft: "5px", padding: "11px", fontSize: "14px"}} type="submit" class="tag_btn" variant="tag_btn"> Send </Button>
						</form>
					</center>
				)
			})
			} 

		var biobutton = "";
		if (this.state.profile_name == "brycehall" || this.state.profile_name == "joshrichards" || this.state.profile_name == "ani.energy"){
			
			if (currentComponent.state.bio_link == "nolink"){
				biobutton = "";
			} else {
				biobutton = [0].map(function(item, index){
				return (
					
					<center>
						<br />
						
						<button onClick={event => (window.location.href = currentComponent.state.bio_link)} class="fullbar5" style={{backgroundColor: "#2C88FF", width: "96%"}} >
					 <a href="#" onClick={event => (window.location.href = currentComponent.state.bio_link)} id="previewfeedbtn2">{currentComponent.state.bio_string}</a></button></center>
				)
			})
			}
		}
			
			
			
			
			
//			var items = [];

			var items = this.state.videos.map(function(item, index){
				
				var current_users = ["beekeepers_naturals", "florencebymills", "inkboxtattoos", "mylumasol", "fashionnova", "siempregolden"]
				
//				current_users.includes(currentComponent.state.profile_name)
					if (!item.thumbnail_link){
						return(<div></div>)
					}
				
				//
				var thumburl = "https://www.tiktok.com/api/img/?itemId=" + item.tiktok_video_id + "&location=1";
				var itemId = item.tiktok_video_id
				var allIds = currentComponent.state.allIds
				
				var total = 0
				for (var i = 0;i<allIds.length;i++){
					if (itemId == allIds[i]){
						thumburl = currentComponent.state.backupThumbs[i]
						total += 1
					}
				}
				
				//for thumburl, maybe we take id
//				if (item.thumbnail.includes("florence")){
//					thumburl = item.thumbnail_link;
//				}
//				
				if (item.new == true){
					thumburl = "https://trello-attachments.s3.amazonaws.com/607722bf591d8d888e6addb1/1080x1920/650576898256ba2960020aba6419955e/click4.png"//item.thumbnail_link;
				}
				
				if (!thumburl.includes("tiktokcdn")){
					thumburl = "https://trello-attachments.s3.amazonaws.com/607722bf591d8d888e6addb1/1080x1920/650576898256ba2960020aba6419955e/click4.png"
				}
				
				return (
					<div id="active_vid_container" style={{float:"left", width:"180px"}}>
						
						<center>
							<div class="delete_vid" >
								<i onClick={event => currentComponent.deleteVid(event, index, "Videos")} class="fa fa-times-circle" id="delete" style={{verticalAlign:"right", fontSize: "20px"}}></i>
							</div >
						<div class="order_num">
								{index+1}
							</div>

							<img id="active_vids" onError={event => currentComponent.newImgError(index, "shoppablefeedvids")} referrerPolicy="no-referrer" src={thumburl} style={{width:"180px", height:"320px", objectFit: "cover"}} onClick={event => currentComponent.manageProduct(event, index)}></img>

							<center>
							<Button variant="light" style={{width:"70px", margin:"10px"}} onClick={event => currentComponent.manageProduct(event, index)}> Edit <i className="fa fa-pencil" style={{verticalAlign:"right", fontSize: "13px"}}></i></Button >

							<Button class="tag_btn" variant="tag_btn" style={{width:"70px", margin:"10px"}} onClick={event => currentComponent.addProduct(event, index)}> Tag <i className="fa fa-tag" style={{verticalAlign:"right", fontSize: "14px"}}></i></Button>
								
							</center>
							
						</center>

					</div>
					)});
			
			//IMAGES USED TO BE THIS
//			<img id="active_vids" onError={event => currentComponent.imgError(index, item.tiktok_video_id, item.vid_id)} referrerPolicy="no-referrer" src={(item.thumbnail_link)} style={{width:"180px", height:"320px", objectFit: "cover"}} onClick={event => currentComponent.manageProduct(event, index)}></img>
			
			var links = this.state.links.map(function(link, index){
				return (<div key={link.link_id} class="img" style={{float:"left", width:"50%", padding:"0px"}}>
						
						<div class="shop_items">
						
							<div class="order_num" style={{marginLeft:"10px"}}>
							{index+1}	
							</div>


							<a href={link.product_link} target="_blank"><img id="shop_img" referrerPolicy="no-referrer" src={link.thumbnail_link} style={{width:"85%", height: "300px", padding:"0px"}}/>
							<p id="shop_text">{link.name}<p id="price">{link.price}</p></p>	</a>
							
								
							<Button class="tag_btn" variant="tag_btn" style={{width:"110px", marginBottom:"20px", marginTop:"-17px"}} onClick={event => currentComponent.editProduct(event, link.name, link.price, link.thumbnail_link, link.product_link, link.link_id)}> Edit <i className="fa fa-pencil" style={{verticalAlign:"right", fontSize: "14px"}}></i></Button>
							
							<Button variant="danger" style={{width:"110px", marginBottom:"20px", marginTop:"-17px", marginLeft: "20px"}} onClick={event => currentComponent.deleteVid(event, index, "Products")}> Remove <i className="fa fa-times-circle" style={{verticalAlign:"right", fontSize: "15px"}}></i></Button>
							
						</div>
						
					</div>)
    		});
			
			var editicon = "";
			if (this.state.thumbnail_url == "https://p16-va-tiktok.ibyteimg.com/img/musically-maliva-obj/82ba0916b0ef0ce1dbd5f635274550ea~c5_720x720.jpeg"){
				editicon = [0].map(function(item){
					return (
				<div onClick={event => currentComponent.navigatePage(event, "profile")}><i className="fa fa-pencil" style={{verticalAlign:"right", fontSize: "20px"}}></i></div>
					)
				})
				
			}
			
			var current_index = currentComponent.state.current_index;
			var current_video_url = currentComponent.state.current_video_url;
			var vidbackground = this.state.vidbackground;
			var current_vid_id = this.state.current_vid_id;
			
			//create embed url
			var cite_string = "";
			
			//NOTE  IMPORTANT: WHEREnewmodalvideo is, used to be this: 
//											<video poster={this.state.vidbackground} id="mainvid" referrerPolicy="no-referrer" crossOrigin src={current_video_url} controls muted playsInline autoPlay loop width="30%" ></video>

			
			
			var new_modal_video = [0].map(function(item, index){
				cite_string = "https://www.tiktok.com/@" + currentComponent.state.current_user + "/video/" + current_vid_id;
//				
				console.log("cite string: ", cite_string);
			
					return (
						<div class="shrink">
							<blockquote class="tiktok-embed" cite={cite_string} data-video-id={current_vid_id} style={{borderRadius: "20px", width: "325px",  zIndex: "1", height: "555px"}} > <section style={{zIndex: "1", position: "relative"}}> </section> </blockquote></div>
					)
			})		
			
			
			var edit_product_title = this.state.edit_product_title
			var edit_product_price = this.state.edit_product_price
			var edit_product_link = this.state.edit_product_link
			var edit_product_image = this.state.edit_product_image	
			
			var current_item_id = this.state.current_item_id;
			
			var product_library = this.state.product_store.map(function(item, index){
				return (	
			<div class="img library_img" style={{float:"left", width:"25%", padding:"0px", height:"260px"}}>
						
						<div class="shop_items">
							
						<a onClick={event => currentComponent.productFromLibraryClicked(event, item.name, item.price, item.product_link, item.thumbnail_link)}>
							
						<img id="shop_img" referrerPolicy="no-referrer" src={item.thumbnail_link} style={{width:"85%", padding:"1px", objectFit: "cover", height:"180px"}} />
						
						<p id="shop_text">{item.name}<p id="price">{item.price}</p></p>	

					</a>
					</div>
			</div>
					)	
    		});
		
			return(
				<div>
							<Modal backdrop="static"
        keyboard={false} class="widemodal" size="lg" show={this.state.showMobilePopup}  onHide={() => this.setState({ showMobilePopup: false })}>
			
                       
                        <Modal.Body>
							<center>
							<img src={shoptik_icon} width="120px"></img><br/><br/>
								This site is not intended for mobile, please use a computer instead. Thanks.

							</center>
                        </Modal.Body>
        			</Modal>
										<Modal backdrop="static"
        keyboard={false} class="widemodal" size="lg" show={this.state.showPlanModal}  onHide={() => this.setState({ showPlanModal: false })}>
						<br /><br />
						
						<center>
						<Modal.Title style={{paddingLeft: "10px", paddingRight: "10px"}} id="contained-modal-title-vcenter" centered>
							{this.state.topmsg}
							</Modal.Title></center>
                        <Modal.Body>
							
							<p style={{padding: "30px"}}>Add billing information now to prevent missing out on orders placed by your customers once your free trial has ended. <b>YOU WILL NOT BE BILLED</b>, and can cancel anytime in the settings. </p>
							<center>
							
							<div style={{textAlign: "center"}}>
								<div style={{display: "inline-block", textAlign: "left"}}>
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> You will <b>NOT</b> be charged until the end of your trial</p>
							
							<p class="check-text"><i class="fa fa-check" aria-hidden="true"></i> You can <b>cancel anytime</b> before your trial ends</p>
						</div>
							</div>
							<br /><br />
							
								<Button style={{padding: "25px 25px", width:"100%"}} onClick={event => this.verifyCard(this.state.plan)}>Add Billing Information</Button>
							</center><br /><br /> <a onClick={(event) => this.illdoitlater(event)} style={{color: "grey"}} ><u>I'll do it later</u></a>
                        </Modal.Body>
        			</Modal>
					
				<Modal size="sm2" class="widemodal2" id="testy" backdrop="static" keyboard={false} show={this.state.showPayModal} onHide={() => this.setState({ showPayModal: false })}>
                        <Modal.Body>
							<center>
							<h3 style={{color:"white", marginTop:"40px"}}><b> Upgrade to a plan now to continue getting sales on TikTok</b></h3><br/>
							<p style={{color:"white", fontSize:"14px", fontWeight:"100"}}><b> No commitments. You're already set up. Cancel anytime, or contact us to extend your free trial</b></p>
							<br />
								<center>
    <div class="prices">
    <div class="pricingplans">
        <div class="priceingbox" >
            <h1 class="pricetitle">Basic</h1>
            <hr class="price-hr" />
            <span id="underline"></span>
            <br />
            <h1 id="p1" class="price">$25</h1><span class="pricetext">&nbsp;/ month</span>
            <p id="underpricetext">For individuals and influencers</p>
            
            <br />
            <button id="analytics_monthly" class="paybtn" onClick={event => this.attemptUpdate(event, "analytics_monthly")}>GET STARTED</button>
                        <br /><br /><br />
			<img src="https://www.shoptik.co/images/krisicons/shoppable feed.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px", marginLeft: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/Analytics.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
        </div>
    </div>
        
        <div class="pricingplans">
        <div class="priceingbox" >
            <h1 class="pricetitle">Standard</h1>
            <hr class="price-hr" />
            <span id="underline"></span>
            <br />
            <h1 id="p2" class="price">$99</h1><span class="pricetext">&nbsp;/ month</span>
            <p id="underpricetext">For small businesses and shopify stores</p>
            <br />
            <button id="basic_monthly" class="paybtn2" onClick={event => this.attemptUpdate(event, "standard_monthly")}>GET STARTED</button>
            <br /><br /><br />
			<img src="https://www.shoptik.co/images/krisicons/shoppable feed.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px", marginLeft: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/Analytics.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/Schedule.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/right influencer.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
        </div>
    </div>
        
        <div class="pricingplans"style={{marginTop:"-50px"}} >
        <div class="priceingbox" style={{marginTop:"-50px"}} >
            <h1 class="pricetitle">Professional</h1>
            <hr class="price-hr" />
            <span id="underline"></span>
            <br />
            <h1 id="p3" class="price">$199</h1><span class="pricetext">&nbsp;/ month</span>
            <p id="underpricetext">For large brands and companies</p>
            <br />
            <button id="standard_monthly" class="paybtn" onClick={event => this.attemptUpdate(event, "premium_monthly")}>GET STARTED</button>
            <br /><br /><br />
			
			
			<img src="https://www.shoptik.co/images/krisicons/shoppable feed.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px", marginLeft: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/Analytics.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/Schedule.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/right influencer.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/listening.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
        </div>
    </div>
        </div>
   </center>
			<br />
							<p style={{fontSize: "10px", color: "#fff"}}>*By continuing, I agree to terms and conditions </p>
							</center>
                        </Modal.Body>
        			</Modal>
					
					<Modal size="sm2" class="widemodal2" id="testy" show={this.state.showEndTrialModal} onHide={() => this.setState({ showEndTrialModal: false })}>
                        <Modal.Body>
							<center>
							<h3 style={{color:"white", marginTop:"40px"}}><b> End your trial and upgrade to a plan</b></h3><br/>
							<p style={{color:"white", fontSize:"14px", fontWeight:"100"}}><b> No commitments. You're already set up. Cancel anytime.</b></p>
							<br />
								<center>
    <div class="prices">
    <div class="pricingplans">
        <div class="priceingbox" >
            <h1 class="pricetitle">Basic</h1>
            <hr class="price-hr" />
            <span id="underline"></span>
            <br />
            <h1 id="p1" class="price">$25</h1><span class="pricetext">&nbsp;/ month</span>
            <p id="underpricetext">For individuals and influencers</p>
            
            <br />
            <button id="analytics_monthly" class="paybtn" onClick={event => this.attemptUpdate(event, "analytics_monthly")}>GET STARTED</button>
                        <br /><br /><br />
			<img src="https://www.shoptik.co/images/krisicons/shoppable feed.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px", marginLeft: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/Analytics.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
        </div>
    </div>
        
        <div class="pricingplans">
        <div class="priceingbox" >
            <h1 class="pricetitle">Standard</h1>
            <hr class="price-hr" />
            <span id="underline"></span>
            <br />
            <h1 id="p2" class="price">$99</h1><span class="pricetext">&nbsp;/ month</span>
            <p id="underpricetext">For small businesses and shopify stores</p>
            <br />
            <button id="basic_monthly" class="paybtn2" onClick={event => this.attemptUpdate(event, "standard_monthly")}>GET STARTED</button>
            <br /><br /><br />
			<img src="https://www.shoptik.co/images/krisicons/shoppable feed.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px", marginLeft: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/Analytics.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/Schedule.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/right influencer.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
        </div>
    </div>
        
        <div class="pricingplans"style={{marginTop:"-50px"}} >
        <div class="priceingbox" style={{marginTop:"-50px"}} >
            <h1 class="pricetitle">Professional</h1>
            <hr class="price-hr" />
            <span id="underline"></span>
            <br />
            <h1 id="p3" class="price">$199</h1><span class="pricetext">&nbsp;/ month</span>
            <p id="underpricetext">For large brands and companies</p>
            <br />
            <button id="standard_monthly" class="paybtn" onClick={event => this.attemptUpdate(event, "premium_monthly")}>GET STARTED</button>
            <br /><br /><br />
			
			
			<img src="https://www.shoptik.co/images/krisicons/shoppable feed.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px", marginLeft: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/Analytics.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/Schedule.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/right influencer.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
			<img src="https://www.shoptik.co/images/krisicons/listening.png" style={{width: "40px", height: "40px", display: "inline-block", marginRight: "20px"}} />
        </div>
    </div>
        </div>
   </center>
			<br />
							<p style={{fontSize: "10px", color: "#fff"}}>*By continuing, I agree to terms and conditions </p>
							</center>
                        </Modal.Body>
        			</Modal>
					
					
					<Modal size="lg" show={this.state.showUpgradeModal} onHide={() => this.setState({ showUpgradeModal: false })}>

                        <Modal.Body>
							<center>
							<img src={shoptik_icon} width="120px"></img><br/><br/>
							<h3 style={{color:"#2c88ff"}}><b> 😀 Yay! We're glad you're upgrading <br /> your Shoptik account 🎉</b></h3>
							<br />
								
							
							<p style={{width:"80%", lineHeight:"1.1em", color:"black"}}>By upgrading your plan, you'll get a ton of cool new features like: </p><br/><br />
								
							<center>
								<div style={{marginLeft:"25%"}}>
								
								<p style={{width:"100%", lineHeight:"1.1em", color:"grey",textAlign:"left"}}>- A shoppable feed link in bio with UNLIMITED Videos <br /> for Standard (and 10 for basic)</p><br/>
								<p style={{width:"100%", lineHeight:"1.1em", color:"grey",textAlign:"left"}}>- A product library for easy tagging </p><br/>
								<p style={{width:"100%", lineHeight:"1.1em", color:"grey",textAlign:"left"}}>- Advanced analytics to see how your store performs </p><br/>
								<p style={{width:"100%", lineHeight:"1.1em", color:"grey",textAlign:"left"}}>- UGC based web galleries for your home site </p><br/>
								<p style={{width:"100%", lineHeight:"1.1em", color:"grey",textAlign:"left"}}>- and much more </p><br/>
									</div>
								</center>
								<br /><br />
							
							<Button class="tag_btn" variant="tag_btn"  onClick={event => this.attemptNewUpgrade(event, "standard_monthly")} > Get Shoptik Basic($99/mo) </Button> &nbsp;&nbsp;&nbsp;&nbsp;
								
							<Button class="tag_btn" variant="tag_btn"  onClick={event => this.attemptNewUpgrade(event, "premium_monthly")} > Get Shoptik Standard ($199/mo) </Button>
								<br />
								
							<p style={{fontSize: "10px", color: "grey"}}>*By continuing, I recognize that my current subscription will automatically be upgraded<br /> to the ShopTik plan I selected, billed monthly at the price above </p>
							
							</center>

                        </Modal.Body>
        			</Modal>
					

					
					<Modal size="lg" show={this.state.showNewMention} onHide={() => this.setState({ showNewMention: false })}>
						<Modal.Header closeButton style={{borderBottom:"0 none", height: "0px"}}>
                        	<Modal.Title id="contained-modal-title-vcenter" centered>
							Add Videos from @
							</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
							<br /><br />
								<Tooltip style={{marginLeft: "40%", marginTop: "25px", position: "absolute", opacity: "0.2", zIndex: "100"}} title="Search for any public user to find their videos">
								  <IconButton id="moreinfo" aria-label={this.props.message}>
									<FilterListIcon />
								  </IconButton>
								</Tooltip>
								
								<form onSubmit={event => this.searchUser(event)}>

								<span>Search for a username</span><br /><input style={{width: "50%"}} maxlength="60" required onChange={event => this.setState({userMention: event.target.value.replace("@", "")})} value={this.state.userMention} id="product_title" name="product_title" type="text" placeholder="ie. lorengray (don't include the @)" />
									
									<Button style={{marginLeft: "30px"}} type="submit" class="tag_btn" variant="tag_btn"> Search for Account </Button>
								</form>
						<br />
							{tiktoklinksMentions}
                        </Modal.Body>
        			</Modal>
					
					
					<Modal size="lg" show={this.state.showNewHashtag} onHide={() => this.setState({ showNewHashtag: false })}>
						<Modal.Header closeButton style={{borderBottom:"0 none", height: "0px"}}>
                        	<Modal.Title id="contained-modal-title-vcenter" centered>
							Add Videos by #
							</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
							<br /><br />
								<Tooltip style={{marginLeft: "40%", marginTop: "25px", position: "absolute", opacity: "0.2", zIndex: "100"}} title="Search for a hashtag to find videos using the hashtag">
								  <IconButton id="moreinfo" aria-label={this.props.message}>
									<FilterListIcon />
								  </IconButton>
								</Tooltip>
								
								<form onSubmit={event => this.searchHashtags(event)}>

								<span>Search for a hashtag</span><br /><input style={{width: "50%"}} maxlength="60" required onChange={event => this.setState({userHashtags: event.target.value.replace("#", "")})} value={this.state.userHashtags} id="product_title" name="product_title" type="text" placeholder="ie. nordstrom (don't include the #)" />
									
									<Button style={{marginLeft: "30px"}} type="submit" class="tag_btn" variant="tag_btn"> Search for Hashtag </Button>
								</form>
						<br />
							{tiktoklinksHashtags}
                        </Modal.Body>
        			</Modal>
					

					
					
					<Modal size="lg" show={this.state.showNewVideo} onHide={() => this.setState({ showNewVideo: false })}>
						<Modal.Header closeButton style={{borderBottom:"0 none", height: "0px"}}>
                        	<Modal.Title id="contained-modal-title-vcenter" centered>
							Add New Shoppable TikTok Video
							</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
							<br />
							<p>If videos do not load, please use add by link instead</p>
						
                     		<center>
							<video id="mainvid" referrerPolicy="no-referrer" crossOrigin src="" controls muted playsInline autoPlay loop width="0%" ></video>
							</center><br />
							
							{tiktoklinks}
							
						
                        </Modal.Body>
        			</Modal>

					<Modal size="lg" show={this.state.showNewProduct} onHide={() => this.setState({ showNewProduct: false })}>
						<Modal.Header closeButton style={{borderBottom:"0 none", height: "0px"}}>
                        	<Modal.Title id="contained-modal-title-vcenter" centered>
							Manage Products
							</Modal.Title>
                        </Modal.Header>
					
                        <Modal.Body >
							
							<center>
								<VideoPreview></VideoPreview>
								{new_modal_video}
								

								<a href={cite_string} target="_blank">
										<Button style={{padding: "10px 20px"}} class="tag_btn start_btn" variant="tiktokbutton" >  Watch Video on TikTok </Button> </a>
								
								</center><br />
							
							<div>{links}</div>
					
						
                        </Modal.Body>
        			</Modal>
						
					<Modal size="lg" show={this.state.showAddProduct} onHide={() => this.setState({ showAddProduct: false, product_image: "", product_link: "", product_price: "", product_title: "" })}>
						<Modal.Header closeButton style={{borderBottom:"0 none", height: "0px"}}>
                        	<Modal.Title id="contained-modal-title-vcenter" centered>
							Add New Product
							</Modal.Title>
							
                        </Modal.Header>
					
                        <Modal.Body >
							<br />
							<div class="container" style={{float:"left", width:"50%", padding:"0px"}}>
							<div class="shop_items">
						
							<div class="order_num" style={{marginLeft:"15px"}}>
							1
							</div>
							
									<a href={this.state.product_link} target="_blank">
							<img id="shop_img" referrerPolicy="no-referrer" src={this.state.product_image} style={{width:"85%", height:"350px", padding:"0px"}}/>
							<p id="shop_text">{this.state.product_title}<p id="price">{this.state.product_price}</p></p></a>

						</div>
						</div>
							
							<br /><br />
							<form onSubmit={event => currentComponent.saveProduct(event)}>
								
							
								<div class="add_prod_form">
							<Tooltip style={{marginLeft: "340px", marginTop: "0px", position: "absolute", opacity: "0.2", zIndex: "100"}} title="This is the title of the item. Maximum 30 characters">
							  <IconButton id="moreinfo" aria-label={this.props.message}>
								<FilterListIcon />
							  </IconButton>
							</Tooltip>
								
								<input required maxlength="40" onChange={event => this.setState({product_title: event.target.value})} value={this.state.product_title} id="product_title" name="product_title" type="text" placeholder="Item Display Name" /> <br /><br />
								
							
							<Tooltip style={{marginLeft: "340px", marginTop: "0px", position: "absolute", opacity: "0.2", zIndex: "100"}} title="This is the price of the item, formatted with no spaces. ie. $29.99">
							  <IconButton id="moreinfo" aria-label={this.props.message}>
								<FilterListIcon />
							  </IconButton>
							</Tooltip>
							
							<input required onChange={event => this.setState({product_price: event.target.value})} value={this.state.product_price} id="product_price" name="product_price" type="text" placeholder="Price (formatted like: $29.99) " /> <br /><br />
								
							<Tooltip style={{marginLeft: "340px", marginTop: "0px", position: "absolute", opacity: "0.2", zIndex: "100"}} title="This is the link to the product page on your website">
							  <IconButton id="moreinfo" aria-label={this.props.message}>
								<FilterListIcon />
							  </IconButton>
							</Tooltip>

							<input required onChange={event => this.setState({product_link: event.target.value})} value={this.state.product_link} id="product_link" name="product_link" type="text" placeholder="Product page link" /> <br /><br />
							
							<Tooltip style={{marginLeft: "340px", marginTop: "0px", position: "absolute", opacity: "0.2", zIndex: "100"}} title="This is the link to the product image. Right click on the image, select copy image address, and paste it here">
							  <IconButton id="moreinfo" aria-label={this.props.message}>
								<FilterListIcon />
							  </IconButton>
							</Tooltip>
								
							<input required onChange={event => this.setState({product_image: event.target.value})} value={this.state.product_image} id="product_image" name="product_image" type="text" placeholder="Image link" /> 
								<br /><br />
								<center>
								<Button type="submit" class="tag_btn" variant="tag_btn"> Save Item <i className="fa fa-plus-circle" style={{verticalAlign:"right", fontSize: "15px"}}></i></Button>
								</center>
							</div>
							</form>
							</Modal.Body>
						<Modal.Body>
							<Modal.Title id="contained-modal-title-vcenter" centered>
							Add from Library
								<br /><br />
							</Modal.Title>
							{product_library}
                        </Modal.Body>
						
        			</Modal>
					
					<Modal size="m" show={this.state.showEditProduct} onHide={() => this.setState({ showEditProduct: false, current_item_id: "" })}>
						<Modal.Header closeButton style={{borderBottom:"0 none", height: "0px"}}>
                        	<Modal.Title id="contained-modal-title-vcenter" centered>
							Edit Product
							</Modal.Title>
							
                        </Modal.Header>
					
                        <Modal.Body >
							<br />
							<div class="container" style={{float:"left", width:"100%", padding:"0px"}}>
							<div class="shop_items">
						
							<div class="order_num" style={{marginLeft:"15px"}}>
							1
							</div>
							
								<a href={this.state.edit_product_link} target="_blank">
							<img id="shop_img" referrerPolicy="no-referrer" src={this.state.edit_product_image} style={{width:"85%", height:"350px", padding:"0px"}}/>
							<p id="shop_text">{this.state.edit_product_title}<p id="price">{this.state.edit_product_price}</p></p>
								</a>

							
						</div>
						</div>
	
							<br /><br />
							<form onSubmit={event => currentComponent.submitEditedProduct(event, current_item_id)}>
								
								<Tooltip style={{marginLeft: "420px", marginTop: "0px", position: "absolute", opacity: "0.2", zIndex: "100"}} title="This is the title of the item">
							  <IconButton id="moreinfo" aria-label={this.props.message}>
								<FilterListIcon />
							  </IconButton>
							</Tooltip>
								
							<input required onChange={event => this.setState({edit_product_title: event.target.value})} value={this.state.edit_product_title} id="edit_product_title" name="edit_product_title" type="text" placeholder="Item Display Name"/> <br /><br />
								
							<Tooltip style={{marginLeft: "420px", marginTop: "0px", position: "absolute", opacity: "0.2", zIndex: "100"}} title="This is the price of the item, formatted with no spaces. ie. $29.99">
							  <IconButton id="moreinfo" aria-label={this.props.message}>
								<FilterListIcon />
							  </IconButton>
							</Tooltip>
								
							<input required onChange={event => this.setState({edit_product_price: event.target.value})} value={this.state.edit_product_price} id="edit_product_price" name="edit_product_price" type="text" placeholder="Price"/> <br /><br />
	
							<Tooltip style={{marginLeft: "420px", marginTop: "0px", position: "absolute", opacity: "0.2", zIndex: "100"}} title="This is the link to the product page on your website">
							  <IconButton id="moreinfo" aria-label={this.props.message}>
								<FilterListIcon />
							  </IconButton>
							</Tooltip>
								
							<input required onChange={event => this.setState({edit_product_link: event.target.value})} value={this.state.edit_product_link} id="edit_product_link" name="edit_product_link" type="text" placeholder="Product page link"/> <br /><br />
							
									<Tooltip style={{marginLeft: "420px", marginTop: "0px", position: "absolute", opacity: "0.2", zIndex: "100"}} title="This is the link to the product image. Right click on the image, select copy image address, and paste it here">
							  <IconButton id="moreinfo" aria-label={this.props.message}>
								<FilterListIcon />
							  </IconButton>
							</Tooltip>
								
							<input required onChange={event => this.setState({edit_product_image: event.target.value})} value={this.state.edit_product_image} id="edit_product_image" name="edit_product_image" type="text" placeholder="Image link"/> 
								<br /><br />
								<center>
								<Button class="tag_btn" variant="tag_btn" type="submit" > Update Item <i className="fa fa-plus-circle" style={{verticalAlign:"right", fontSize: "15px"}}></i></Button>
								</center>
							</form>
                        </Modal.Body>
        			</Modal>
					
			
					<Modal size="lg" show={this.state.showManual} onHide={() => this.setState({ showManual: false })}>
							<Modal.Header closeButton style={{borderBottom:"0 none", height: "0px"}}>
								<Modal.Title id="contained-modal-title-vcenter" centered>
								Add Custom Video
								</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								<br /><br />
								
								<Tooltip style={{marginLeft: "40%", marginTop: "25px", position: "absolute", opacity: "0.2", zIndex: "100"}} title="Go to tiktok.com on a computer and log into your account. Click your profile picture on the top right. Click the video you want to add to your shoptik feed and copy the link. Paste it here.">
								  <IconButton id="moreinfo" aria-label={this.props.message}>
									<FilterListIcon />
								  </IconButton>
								</Tooltip>
								
								<span>TikTok video link</span><br />
								<input style={{width: "50%"}} id="manual_link" name="discount" type="text" placeholder="paste your video link from the tiktok website" />
								
							<Button style={{marginLeft: "30px"}} class="tag_btn" variant="tag_btn"  onClick={event => this.addManually(event)} > Add Video Link </Button>	
							
							</Modal.Body>
        			</Modal>
		<NewSidebar selected={0}></NewSidebar>
			
	<br /><br /><br />
						
						<span class="newloader" style={{display: this.state.isloading}}></span>
					<img id="profile_image" onClick={event => this.navigatePage(event, "settings")} src={this.state.thumbnail_url} />
					
					{upgrade}
					
					
			<div class="main">
				
				
				
				
	<a href="" onClick={event => this.navigatePage(event, "feed")} id="link_bio"> <i className="fa fa-link storelink" style={{verticalAlign:"left", fontSize: "18px", color:"#2C88FF"}}></i> like2.shop/{this.state.current_user}</a>
				
				
		<div style={{width: "70%", float:"left"}}>
				
			<h3>New Posts From TikTok</h3>
				{recent_links}
				{uptodate}			
			<hr />
				<br />
			<h3 style={{display:"inlineBlock", float: "left"}}>Shoppable Feed </h3>	
				
				<br /><br />
				 <Snackbar open={this.state.openAlert} autoHideDuration={3000} onClose={()=>{this.setState({openAlert: false, alertMessage: ""})}}>	
					<MuiAlert elevation={6} variant="filled">{this.state.alertMessage}</MuiAlert>
				 </Snackbar>
				 <Snackbar open={this.state.openAlert2} autoHideDuration={5000} onClose={()=>{this.setState({openAlert2: false, alertMessage2: ""})}}>
					<MuiAlert elevation={6} variant="filled" severity="info">{this.state.alertMessage2}</MuiAlert>
				 </Snackbar>
				<div>
				<Button variant="light" style={{width:"180px", margin:"10px"}} onClick={event => currentComponent.showNewVideoModal(event)}> Add from TikTok <img src="https://image.flaticon.com/icons/svg/1946/1946538.svg" width="15px"/></Button >
				
				</div>
				<br />
				{update_bar}
				{items}	
				</div>

			<div style={{float:"right", textAlign:"left", width:"27%", borderLeftStyle:"solid", borderLeftColor: "#f8f8f8", height:"100vh", borderLeftWidth:"1px", position:"fixed", right:"0"}}>
				
				
				<center>

					<button class="fullbar2"> <a href="#" onClick={event => this.navigatePage(event, "feed")} style={{display:"inlineBlock"}} id="previewfeedbtn">&nbsp;&nbsp;&nbsp;<b>My Shoptik:</b> <span style={{textDecoration:"underline"}}>like2.shop/{this.state.current_user}</span>  &nbsp;</a></button><br /><br />
					
				<div style={{fontWeight:"500", borderStyle:"solid", borderColor: "black", borderWidth: "10px", borderRadius: "45px", height:"80vh", overflow:"scroll", width: "73%"}}>
					
					
					<center>

					<img src={this.state.thumbnail_url} style={{width:"80px", borderRadius:"50%", marginTop: "7px", borderColor:"#fff", borderWidth:"3px", borderStyle:"solid", zIndex: "99999 !important"}}/>
						<div>{editicon}</div>
						
						<p style={{fontSize: "14px"}}>@{this.state.profile_name}</p>
					</center>
									
					<div style={{marginTop: "-24px"}}>
					<div style={{}}>
						<div>
						{drop}
						</div>
						<div> {email_input} </div>
						{biobutton}
						</div>
						
					<br />
					<hr style={{marginTop:"8px"}}/>
						<center>
							
						 <i onClick={event => this.setState({feed: true, left_opacity: 1, right_opacity: 0.15, shouldHideFeed: "block", shouldHideStore: "none"})} className="fa fa-th fa-selected" style={{display:"inlineBlock", height:"13px", verticalAlign:"left", fontSize: "17px", marginRight:"20px", opacity: this.state.left_opacity}}></i>
						
						 <i onClick={event => this.setState({feed: false, left_opacity: 0.15, right_opacity: 1, shouldHideFeed: "none", shouldHideStore: "block"})} className="fa fa-shopping-bag fa-unselected" style={{display:"inlineBlock", height:"13px", verticalAlign:"left", fontSize: "17px", opacity: this.state.right_opacity}}></i>
								
						</center>
						<hr style={{marginTop:"8px"}}/>
					
					<div style={{display: this.state.shouldHideStore}}>
					<FeedProducts visual={true} productstore={this.state.product_store} />
					</div>
					
					<div style={{display: this.state.shouldHideFeed}}>
						<NewFeedItems visual={true} videos={this.state.videos} />
					</div>

	
				</div>
					</div>
				
				
				
				
				</center>
				
				
			</div>
					</div>
				
	
					
					
					
				</div>
			)
			
		} else {
			return(
				<div>
					<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
				</div>
			)
		}
	}
}

export default Dashboard

// Additional buttons

//<Button variant="light" style={{width:"180px", margin:"10px"}} onClick={event => currentComponent.setState({showNewMention: true})}> Add by Username <i className="fa fa-user" style={{verticalAlign:"right", fontSize: "15px"}}></i></Button >
//				<Button variant="light" style={{width:"180px", margin:"10px"}} onClick={event => currentComponent.setState({showNewHashtag: true})}> Add by Hashtag <i className="fa fa-hashtag" style={{verticalAlign:"right", fontSize: "15px"}}></i></Button >
//				 <Button variant="light" style={{width:"180px", margin:"10px"}} onClick={event => currentComponent.setState({showManual: true})}> Add by Link <i className="fa fa-link" style={{verticalAlign:"right", fontSize: "15px"}}></i></Button >
//



//					<Modal size="lg" show={this.state.showPayModal} onHide={() => this.setState({ showPayModal: false })} backdrop="static"
//        keyboard={false}>
//
//                        <Modal.Body>
//							<center>
//							<img src={shoptik_icon} width="120px"></img><br/><br/>
//							<h3 style={{color:"#5356ea"}}><b>Your Free Trial has Expired</b></h3>
//							<br />
//							
//							<p style={{width:"80%", lineHeight:"1.1em", color:"grey"}}>Your 7-day Shoptik free trial has expired. You can continue to get sales on TikTok by upgrading your plan. You can email us at support@shoptik.co if you require any assistance</p><br/>
//						
//							<Button class="tag_btn" variant="tag_btn"  onClick={event => this.attemptUpdate(event, this.state.plan)} > Upgrade Plan </Button>
//							<br />
//							</center>
//
//                        </Modal.Body>
//        			</Modal>





//				<div id="active_vid_container" style={{float:"left", width:"180px"}}>	
//					<img src={addnew} id="active_vids" class="quickaddbtn" width="180px" onClick={event => currentComponent.showNewVideoModal(event)} />
//				</div>
//				
//				<div id="active_vid_container" style={{float:"left", width:"180px"}}>
//					<img src={addnew} id="active_vids" class="quickaddbtn" width="180px" onClick={event => currentComponent.setState({showNewMention: true})} />
//				</div>
//				
//				<div id="active_vid_container" style={{float:"left", width:"180px"}}>
//					<img src={addnew} id="active_vids" class="quickaddbtn" width="180px" onClick={event => currentComponent.setState({showNewHashtag: true})} />
//				</div>
//				{items}