import React, { useState, useEffect, Component } from 'react';
import Feed from './Feed';
import Dashboard from './Dashboard';
import Button from 'react-bootstrap/Button';

import addnew from './add_vid.jpg';

import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Link, Redirect, withRouter } from "react-router-dom";

import Sidebar from "react-sidebar";

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/Info';

import Chart from "react-google-charts";
import { follower_graph, engagement_graph, likes_graph, comments_graph } from './charts';

import Switch from '@material-ui/core/Switch';


		

class graphboxComps extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			isChecked: true
		}
		this.handleChecked = this.handleChecked.bind(this);
	}
	
	componentDidMount() {
		
    }
	
	handleChecked(){
		if (this.state.isChecked){
			this.setState({
				isChecked: false
			})
		} else {
			this.setState({
				isChecked: true
			})
		}
	}
	
	render() {
		var width = this.props.width;
		
		var graph_type;
		var rows = [];
		var given_array = this.props.rows;

		if (this.props.type == "likes_graph"){
			graph_type=likes_graph;
		}
		
		
		//options for bar graphs
const comp_graph = {
	height: '360px',
    animation: {
        startup: true,
        easing: 'out',
        duration: 2000,
    },
    backgroundColor: { fill:'transparent' },
    colors: ['#3B98F9', this.props.color1, this.props.color2, this.props.color3],
    hAxis: {
        title: "Posts",
        textStyle:{color: '#8091AB', fontSize: 12},
        gridlines: {
                color: 'transparent'
    },
    border: {
        color: "transparent"
    },
    titleTextStyle: {
        color: '#8091AB'
    }
    },
    vAxis: {
        title: this.props.graphtable,
        textStyle:
        {color: '#8091AB'},
        gridlines: {
            color: 'transparent'
        },
        titleTextStyle: {
            color: '#8091AB'
    }
    },
    legend: { position: 'bottom', alignment: 'start' },
}

const hashtag_graph = {
	height: '360px',
    animation: {
        startup: true,
        easing: 'out',
        duration: 2000,
    },
    backgroundColor: { fill:'transparent' },
    colors: [this.props.color1, this.props.color2, this.props.color3],
    hAxis: {
        title: "Posts",
        textStyle:{color: '#8091AB', fontSize: 12},
        gridlines: {
                color: 'transparent'
    },
    border: {
        color: "transparent"
    },
    titleTextStyle: {
        color: '#8091AB'
    }
    },
    vAxis: {
        title: this.props.graphtable,
        textStyle:
        {color: '#8091AB'},
        gridlines: {
            color: 'transparent'
        },
        titleTextStyle: {
            color: '#8091AB'
    }
    },
    legend: { position: 'bottom', alignment: 'start' },
}

var graph_options = comp_graph;
		if (this.props.graphtype == 2){
			graph_options = hashtag_graph;
		}

		return(
			
		<div class="graphbox_container" style={{width: width}}>
				
				
				<Tooltip style={{marginTop: "-10px", position: "absolute", opacity: "0.1"}} title={this.props.message}>
				  <IconButton id="moreinfo" aria-label={this.props.message}>
					<FilterListIcon />
				  </IconButton>
				</Tooltip>

				<br /><br />

				        <center><h4><img style={{marginRight: "10px", marginBottom: "5px"}} src="https://assets.materialup.com/uploads/28cbb1b2-0e74-4925-9842-a0ac8fd90732/preview" width="23px" />{this.props.title}</h4>
							
		<Chart
          chartType="AreaChart"

          rows={this.props.rows}
          columns={this.props.columns}
			options={graph_options}
			width="105%"
        />
                         
                        </center>
			
			

		</div>)
	}
}

export default graphboxComps