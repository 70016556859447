import React, { useState, useEffect, Component } from 'react';
import Feed from './Feed';
import Dashboard from './Dashboard';
import Button from 'react-bootstrap/Button';

import addnew from './add_vid.jpg';

import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Link, Redirect, withRouter } from "react-router-dom";

import Sidebar from "react-sidebar";

import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/Info';

import Chart from "react-google-charts";
import { follower_graph, engagement_graph, likes_graph, comments_graph } from './charts';

import Switch from '@material-ui/core/Switch';
		

class Graphbox extends Component {
	
	constructor(props){
		super(props);
		this.state = {
			isChecked: true
		}
		this.handleChecked = this.handleChecked.bind(this);
	}
	
	componentDidMount() {
		
    }
	
	handleChecked(){
		if (this.state.isChecked){
			this.setState({
				isChecked: false
			})
		} else {
			this.setState({
				isChecked: true
			})
		}
	}
	
	render() {
		var width = this.props.width;
		
		var graph_type;
		var rows = [];
		var given_array = this.props.rows;
		
		console.log(given_array);
		

		if (this.props.type == "follower_graph"){
			graph_type = follower_graph;
			
		} else if (this.props.type == "engagement_graph"){
			graph_type = engagement_graph;
			
			var given_array2 = this.props.rows2;
			var given_array3 = this.props.rows3;
			
			if (this.state.isChecked == true){
			var sum = 0;
			for (var i=0; i<given_array.length; i++){
				sum += given_array[i] + given_array2[i] + given_array3[i];
				var current = [i, sum];
				rows.push(current);
			}
		} else {
			var sum = 0;
			for (var i=0; i<given_array.length; i++){
				sum = given_array[i];
				var current = [i, sum];
				rows.push(current);
			}
		}
		} else if (this.props.type == "likes_graph"){
			if (this.state.isChecked == true){
			var sum = 0;
			for (var i=0; i<given_array.length; i++){
				sum += given_array[i];
				var current = [i, sum];
				rows.push(current);
			}
		} else {
			var sum = 0;
			for (var i=0; i<given_array.length; i++){
				sum = given_array[i];
				var current = [i, sum];
				rows.push(current);
			}
		}
			graph_type = likes_graph;
		} else if (this.props.type == "comments_graph"){
			graph_type = comments_graph;
			if (this.state.isChecked == true){
			var sum = 0;
			for (var i=0; i<given_array.length; i++){
				sum += given_array[i];
				var current = [i, sum];
				rows.push(current);
			}
		} else {
			var sum = 0;
			for (var i=0; i<given_array.length; i++){
				sum = given_array[i];
				var current = [i, sum];
				rows.push(current);
			}
		}
		}
		return(
			
		<div class="graphbox_container" style={{width: width}}>
				
				
				<Tooltip style={{marginTop: "-10px", position: "absolute", opacity: "0.1"}} title={this.props.message}>
				  <IconButton id="moreinfo" aria-label={this.props.message}>
					<FilterListIcon />
				  </IconButton>
				</Tooltip>
				<div style={{float: "right"}}>
				
					<span style={{color: "grey"}}>per post</span>
				<Switch name="checkedB" checked={this.state.isChecked}
            onChange={event => this.handleChecked(event)} style={{width: "70%",  float: "right"}} inputProps={{ 'aria-label': 'primary checkbox' }} /><span style={{color: "grey"}}>all posts</span>	</div>
				<br /><br />

				        <center><h4><img style={{marginRight: "10px", marginBottom: "5px"}} src="https://assets.materialup.com/uploads/28cbb1b2-0e74-4925-9842-a0ac8fd90732/preview" width="23px" />{this.props.title}</h4>
							
							<Chart
          chartType="AreaChart"
          legendToggle
          rows={rows}
          columns={this.props.columns}
			options={graph_type}
								width="105%"
        />
                         
                        </center>
			
			

		</div>)
	}
}

export default Graphbox